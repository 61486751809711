import { CHART_LABELS } from "@constants";
import FTButton from "atoms/FTButton";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { partial } from "lodash-es";
import { useEffect, useState } from "react";
import { AllocationAtAge, ProjectedAllocationSelectionType, assetClassColors } from "./portfolio-mapper-helpers";

const FTStackedBarChart = (props: any) => {
    const { chartData } = props;
    let data: AllocationAtAge[] = chartData;


    // let legendData: any = [];
    let cashTxt = "Cash";
    let fixedIncomeTxt = "Fixed Income";
    let equityTxt = "Equity";

    const [assetType, setAssetType] = useState<ProjectedAllocationSelectionType>("all");
    let selectedProjectedAllocationType: ProjectedAllocationSelectionType = assetType;

    const _getChartDataBySelectedProjectedAllocationType = (
        data: AllocationAtAge,
        type: "cash" | "fixedIncome" | "equity"
    ) => {
        return selectedProjectedAllocationType === type || selectedProjectedAllocationType === "all" ? data[type] : 0;
    };

    const defaultSeries: any = [
        {
            id: "all",
            type: "column",
            borderRadius: 0,
            color: "#fff",
            name: "",
            data: data?.map((v: any) => ({
                x: v.age,
                y: selectedProjectedAllocationType === "all" ? 0 : 100 - v[selectedProjectedAllocationType],
                name: "",
            })),
            showInLegend: false,
            enableMouseTracking: false,
        },
        {
            id: "cash",
            type: "column",
            borderRadius: 0,
            color: assetClassColors.cash,
            name: cashTxt,
            showInLegend: true,
            data: data?.map((v: any) => ({
                x: v.age,
                y: _getChartDataBySelectedProjectedAllocationType(v, "cash"),
                name: cashTxt,
            })),
        },
        {
            id: "fixedIncome",
            type: "column",
            borderRadius: 0,
            color: assetClassColors.fixedIncome,
            name: fixedIncomeTxt,
            showInLegend: true,
            data: data?.map((v: any) => ({
                x: v.age,
                y: _getChartDataBySelectedProjectedAllocationType(v, "fixedIncome"),
                name: fixedIncomeTxt,
            })),
        },
        {
            id: "equity",
            type: "column",
            borderRadius: 0,
            color: assetClassColors.equity,
            name: equityTxt,
            showInLegend: true,
            data: data?.map((v: any) => ({
                x: v.age,
                y: _getChartDataBySelectedProjectedAllocationType(v, "equity"),
                name: equityTxt,
            })),
        },
    ];

    const handleProjectedAllocationToggle = (assetType: ProjectedAllocationSelectionType) => {
        setAssetType(assetType);
    };

    let stackedBarChartOptions: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
            useHTML: true,
            align: "left",
            verticalAlign: "bottom",
            itemMarginTop: 30,
            itemDistance: 100,
        },
        title: { text: "" },
        yAxis: { title: { text: "" }, max: 100, min: 0 },
        accessibility: {
            enabled: false,
        },
        tooltip: {
            useHTML: true,
            shared: true,
            backgroundColor: "#fff",
            borderWidth: 2,
            formatter: function () {
                return tooltipChartFormatter(this.point.x);
            },
        },
        plotOptions: {
            series: {
                stacking: "percent",
                borderWidth: 0,
            },
            column: {
                events: {
                    legendItemClick: function () {
                        return false;
                    },
                },
                cursor: "none",
            },
        },
        chart: {
            backgroundColor: "transparent",
            events: {
                // Using partial to inject the component object into the function, because "this" refers to Chart
                // inside the scope of this function, but we need both.
                render: partial(function () {
                    // @ts-ignore
                    const chart = this as Highcharts.Chart;

                    const getIconSvg = (svgColor: string) => {
                        return `<svg height="16" width="16">
                                <rect x="1" y="1" rx="6" ry="6" width="12" height="12" fill={"${svgColor}"} className="highcharts-point" data-z-index="4"></rect>
                            </svg>`;
                    };

                    const getLegendName = (name: string) => {
                        switch (name) {
                            case fixedIncomeTxt:
                                return CHART_LABELS.fixedIncome;
                            case equityTxt:
                                return CHART_LABELS.equity;
                            default:
                                return name;
                        }
                    };

                    const _legendData = [];
                    for (const s of chart.series) {
                        if (s.name === "") {
                            continue;
                        }
                        if (s.type === "column") {
                            _legendData.push({
                                name: getLegendName(s.name),
                                // @ts-ignore
                                icon: getIconSvg(s.color),
                            });
                            // console.log("color =>", _legendData);
                        }
                    }
                    // legendData = _legendData;
                }, this),
            },
            marginTop: 30,
        },
        series: defaultSeries,
    };

    const tooltipChartFormatter = (age: number) => {
        const selectedObj = (data as any)?.find((a: any) => a.age === age);
        let selectedObjData: any = [];

        Object.keys(selectedObj).forEach((key) => {
            if (key !== "age") {
                selectedObjData.push({
                    name: key,
                    y: selectedObj[key],
                    color: (assetClassColors as any)[key],
                });
            }
        });

        // The tooltip popup of highcharts wont be rendered until the pointer moves on top of the chart
        // so that we need to add setTimeout here to wait until the tooltip popup got render
        // then starts rendering the chart inside the tooltip popup.
        setTimeout(
            () =>
                // @ts-ignore
                Highcharts.chart("tooltip-chart", {
                    credits: {
                        enabled: false,
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        height: "100%",
                        margin: 0,
                        type: "pie",
                    },
                    title: {
                        text: "",
                    },
                    legend: {
                        enabled: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    plotOptions: {
                        pie: {
                            size: "100%",
                            allowPointSelect: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                                inactive: {
                                    enabled: false,
                                },
                            },
                        },
                    },
                    accessibility: {
                        point: {
                            valueSuffix: "%",
                        },
                    },
                    series: [
                        {
                            dataLabels: {
                                enabled: false,
                            },
                            name: "%",
                            size: "80%",
                            innerSize: "60%",
                            colorByPoint: true,
                            type: "pie",
                            data: selectedObjData,
                        },
                    ],
                }),
            0
        );
        return `<div class="stacked-bar__tooltip-card">
                    <div id="tooltip-chart" style="width: 200px;height: 200px;"></div>
                    <div class="stacked-bar__tooltip-card-content">
                        <h6 class="stacked-bar__tooltip-card-content__title">
                            Projected Allocation
                        </h6>
                        <div class="stacked-bar__tooltip-card-content__list-item">
                            ${equityTxt} ${`${selectedObj.equity}%`}
                        </div>
                        <div class="stacked-bar__tooltip-card-content__list-item">
                            ${fixedIncomeTxt} ${`${selectedObj.fixedIncome}%`}
                        </div>
                        <div class="stacked-bar__tooltip-card-content__list-item">
                            ${cashTxt} ${`${selectedObj.cash}%`}
                        </div>
                    </div>
        </div>`;
    };

    const [chartOptions, setChartOptions] = useState(stackedBarChartOptions);

    useEffect(() => {
        setChartOptions({
            series: defaultSeries,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData, assetType]);

    return (
        <div className="ft-bar-chart">
            <div className="projected-allocation-toggle">
                <FTButton
                    variant={assetType === "equity" ? "contained" : "outlined"}
                    className="bold-button"
                    data-testid={`projected-allocation-toggle-${assetType}`}
                    onClick={() => handleProjectedAllocationToggle("equity")}
                >
                    Equities
                </FTButton>
                <FTButton
                    variant={assetType === "fixedIncome" ? "contained" : "outlined"}
                    className="bold-button"
                    data-testid={`projected-allocation-toggle-${assetType}`}
                    onClick={() => handleProjectedAllocationToggle("fixedIncome")}
                >
                    Fixed Income
                </FTButton>
                <FTButton
                    variant={assetType === "cash" ? "contained" : "outlined"}
                    className="bold-button"
                    data-testid={`projected-allocation-toggle-${assetType}`}
                    onClick={() => handleProjectedAllocationToggle("cash")}
                >
                    Cash
                </FTButton>
                <FTButton
                    variant={assetType === "all" ? "contained" : "outlined"}
                    className="bold-button"
                    data-testid={`projected-allocation-toggle-${assetType}`}
                    onClick={() => handleProjectedAllocationToggle("all")}
                >
                    All
                </FTButton>
            </div>

            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                oneToOne={true}
                updateArgs={[true]}
                aria-label="Projected Allocation interactive chart."
            />

            {/* <div className="ft-legend-container">
                {legendData &&
                    legendData?.map((item: any, index: number) => (
                        <div key={index}>
                            <span>{item.icon}</span>
                            {item.name}
                        </div>
                    ))
                }
            </div> */}
        </div>
    );
};

export default FTStackedBarChart;

import {
	GOE4D_TRANSLATION_URL,
	SPIRE_INDICATIVE_QUOTE_URL,
	SPIRE_PERSONALIZED_QUOTE_CALLBACK,
	SPIRE_PERSONALIZED_QUOTE_PORTAL_URL,
} from "@constants";
import {
	Goe4dRequestDataType,
	SpireIndicativeQuoteType,
	SpireRequestDataType,
} from "@types";
import { uipApiInstance } from "auth/interceptors";

export const getIndicativeQuote = async (
	spireRequestData: SpireRequestDataType
) => {
	return await uipApiInstance({
		method: "POST",
		url: SPIRE_INDICATIVE_QUOTE_URL,
		data: spireRequestData,
	});
};

export const getGoe4dTranslation = async (
	goe4dRequestData: Goe4dRequestDataType,
	clientemail: string
) => {
	try {
		const res = await uipApiInstance({
			method: "POST",
			url: GOE4D_TRANSLATION_URL,
			headers: { clientemail: clientemail, version: "4" },
			data: goe4dRequestData,
		});
		return res;
	} catch (err) {
		console.error(err);
	}
};

export const getPortalUrl = async (
	spireIndicativeQuoteResponse: SpireRequestDataType
) => {
	try {
		const res = await uipApiInstance({
			method: "POST",
			url: SPIRE_PERSONALIZED_QUOTE_PORTAL_URL,
			// headers: { clientemail: clientemail, version: "4" },
			data: spireIndicativeQuoteResponse,
		});
		return res;
	} catch (err) {
		console.error(err);
	}
};

export const personalizedQuoteCallback = async (callbackData: any) => {
	try {
		const res = await uipApiInstance({
			method: "POST",
			url: SPIRE_PERSONALIZED_QUOTE_CALLBACK,
			// headers: { clientemail: clientemail, version: "4" },
			data: callbackData,
		});
		return res;
	} catch (err) {
		console.error(err);
	}
};

import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";

interface SubAssets {
    OCF: number;
    TER: number;
    allocatedValue: number;
    factsheet: string;
    fundPage: string;
    isin: string;
    name: string;
    shareClass: string;
    subAssetClass: string;
}
interface Assets {
    subAssets: SubAssets[];
    totalAllocatedvalue: number;
    type: string;
}
export interface PortfolioDataConfig {
    assets: Assets[];
    attributesHorizon: string;
    portfolioId: number;
    attributesStrategy: string;
    attributesObjective: string;
}

const useGetPortfolio = () => {
    const goe4dTranslation = useAppSelector((state) => state?.goe4dTranslation);
    const marketPortfolios = useAppSelector((state) => state?.marketPortfolios);
    const portfolioDataConfig: PortfolioDataConfig[][] = marketPortfolios?.map(
        (_item: any) => _item?.portfoliosData?.config
    );
    const { body } = goe4dTranslation || {};
    const recommendedPortfolioId = body?.recommendedPortfolioId || 0;
    const portfolioPath = body?.portfolioPath || [];

    const [selectedPortfolioData, setSelectedPortfolioData] = useState<any>([]);

    const getRecommendedPortfolioIdData = (portfolioId: number) => {
        const selectedPortfolio = portfolioDataConfig[0]?.filter(
            (_portData: any) => _portData.portfolioId === portfolioId
        );
        let data = selectedPortfolio?.map((selected: any) => ({
            assetData: selected.assets,
            attributesHorizon: selected["attributesHorizon"],
            attributesObjective: selected["attributesObjective"],
            attributesStrategy: selected["attributesStrategy"],
        }));
        setSelectedPortfolioData(data[0]);
    };

    useEffect(() => {
        if (Object.entries(selectedPortfolioData || {}).length === 0) {
            getRecommendedPortfolioIdData(recommendedPortfolioId);
        }
        // eslint-disable-next-line
    }, [recommendedPortfolioId, selectedPortfolioData]);

    return {
        selectedPortfolioData,
        portfolioDataConfig,
        portfolioPath,
        recommendedPortfolioId,
    };
};

export default useGetPortfolio;

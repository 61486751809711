import { FACTFINDINGPAGE } from "@constants";
import FTButton from "atoms/FTButton";
import FTInput from "atoms/FTInput";
import FTModal from "atoms/FTModal";
import FTNumberInput from "atoms/FTNumberInput";
import FTSwitch from "atoms/FTSwitch";
import { useState } from "react";
import { fetchCurrencySymbol, formatter } from "utils/helper";
import { hasValue, validateOnlyLetters } from "utils/validateInputs";

const AddNewDBPensionsModal = (props: any) => {
    const { idx, showAddNewModal, setShowAddNewModal, displayedProposal, setDisplayedProposal } = props;
    // const { definedBenefitPensions } = displayedProposal?.customData;

    const [showDBPensionError, setDBPensionError] = useState({
        providerErr: "",
        valueErr: "",
    });
    const [dbPensions, setDBPensions] = useState({
        deletable: true,
        inflationAdjusted: true,
        provider: "",
        value: "",
    });

    const handleDBPensionCard = (id: string, val: string) => {
        // let updatedDefinedBenefitPensions = {};
        if (id.includes("provider")) {
            let newError = showDBPensionError.providerErr;
            if (!validateOnlyLetters(val, true)) {
                newError = "Please use alphanumeric characters, number or space.";
                setDBPensionError((prev) => {
                    return { ...prev, providerErr: newError };
                });
            } else {
                setDBPensionError((prev) => {
                    return { ...prev, providerErr: "" };
                });
            }
            // updatedDefinedBenefitPensions = {
            //     ...dbPensions,
            //     provider: val,
            // };
            setDBPensions((prev) => {
                return { ...prev, provider: val };
            });
        } else if (id.includes("value")) {
            let newError = showDBPensionError.valueErr;
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newError = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setDBPensionError((prev) => {
                    return { ...prev, valueErr: newError };
                });
            } else {
                setDBPensionError((prev) => {
                    return { ...prev, valueErr: "" };
                });
            }
            // updatedDefinedBenefitPensions = {
            //     ...dbPensions,
            //     value: validatedOtherValue,
            // };
            setDBPensions((prev) => {
                return { ...prev, value: val };
            });
        }
    };

    const handleDBPensionCardSwitch = (checked: boolean) => {
        // let updateddefinedBenefitPensions = {};
        // updateddefinedBenefitPensions = {
        //     ...definedBenefitPensions,
        //     inflationAdjusted: checked,
        // };
        setDBPensions((prev) => {
            return { ...prev, inflationAdjusted: checked };
        });
    };

    const handleAddDBPensionCard = () => {
        const newDefinedBenefitPensions = {
            deletable: true,
            inflationAdjusted: dbPensions.inflationAdjusted,
            provider: dbPensions.provider,
            value: dbPensions.value,
        };
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.definedBenefitPensions = [
            ...displayedProposal?.customData?.definedBenefitPensions,
            newDefinedBenefitPensions,
        ];
        setDisplayedProposal(updatedProposal);
        setShowAddNewModal({ dbPensionModal: false });
    };

    const checkFieldsStatus = () => {
        const { provider, value } = dbPensions;
        if (!hasValue(provider) && !hasValue(value)) {
            return true;
        }
        return false;
    };

    return (
        <FTModal handleClose={() => setShowAddNewModal({ dbPensionModal: false })} open={showAddNewModal}>
            <section className="add-new-item-modal">
                <div className="content-area">
                    <div className="new-pension-popup">
                        <h5 className="modal-title">Add new pension</h5>
                        <div className="qf-input-text">
                            <FTInput
                                id={`db-pension-card__provider`}
                                data-testid="new-pension-popup__provider"
                                label={FACTFINDINGPAGE.LABEL_CONSTANTS.PROVIDER_NAME}
                                onChange={(e) => handleDBPensionCard(e.target.id, e.target.value)}
                                value={dbPensions?.provider}
                                showErrorOutline={showDBPensionError.providerErr}
                            />
                            {showDBPensionError.providerErr && (
                                <p className="MuiFormHelperText-root">{showDBPensionError.providerErr}</p>
                            )}
                        </div>

                        <div className="qf-input-number">
                            <FTNumberInput
                                id={`db-pension-card__value`}
                                data-testid="new-pension-popup__amount"
                                label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                onChange={(e) => handleDBPensionCard(e.target.id, e.target.value)}
                                value={dbPensions?.value}
                                showErrorOutline={showDBPensionError.valueErr}
                            />
                            {showDBPensionError.valueErr && (
                                <p className="MuiFormHelperText-root">{showDBPensionError.valueErr}</p>
                            )}
                        </div>

                        <FTSwitch
                            label="Inflation Adjusted?"
                            checked={dbPensions?.inflationAdjusted}
                            switchName={`db-pension-card__provider_${idx}_inflationAdjusted`}
                            onChange={(e) => handleDBPensionCardSwitch(e)}
                        />
                    </div>
                </div>
                <div className="buttons-area">
                    <FTButton
                        className="cancel-add-new-btn left-aligned-btn"
                        variant="outlined"
                        aria-disabled="false"
                        aria-label="Cancel"
                        onClick={() => setShowAddNewModal({ dbPensionModal: false })}
                    >
                        Cancel
                    </FTButton>
                    <FTButton
                        className="logout-btn left-aligned-btn"
                        aria-disabled="false"
                        aria-label="Confirm"
                        disabled={checkFieldsStatus()}
                        onClick={() => handleAddDBPensionCard()}
                    >
                        Confirm
                    </FTButton>
                </div>
            </section>
        </FTModal>
    );
};

export default AddNewDBPensionsModal;

import { FTSwitchPropType } from "@types";
import "./styles/toggle.component.scss";
import { FormControlLabel, Switch, SwitchProps, alpha } from "@mui/material";
import styled from "@emotion/styled";

const FTSwitch = ({
	checked,
	switchName,
	label,
	onChange,
	labelPlacement,
}: FTSwitchPropType &
	SwitchProps & {
		labelPlacement?: "bottom" | "top" | "end" | "start" | undefined;
	}) => {
	// const [ isChecked, setIsChecked ] = useState(false)

	const StyledSwitch = styled((props: SwitchProps) => (
		<Switch
			focusVisibleClassName=".Mui-focusVisible"
			disableRipple
			{...props}
		/>
	))(() => ({
		width: 40,
		height: 22,
		padding: 0,
		marginRight: 16,
		marginLeft: labelPlacement === "start" ? 16 : 0, 
		"& .MuiSwitch-switchBase": {
			padding: 0,
			margin: 2,
			transitionDuration: "300ms",
			"&.Mui-checked": {
				transform: "translateX(16px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					backgroundColor: "#3769ff", //theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
					opacity: 1,
					border: 0,
				},
				"&.Mui-disabled + .MuiSwitch-track": {
					opacity: 0.5,
				},
			},
			"&.Mui-focusVisible .MuiSwitch-thumb": {
				color: "#e6e6e6",
				border: "6px solid #e6e6e6",
			},
			"&.Mui-disabled .MuiSwitch-thumb": {
				// color:
				//   theme.palette.mode === 'light'
				//     ? theme.palette.grey[100]
				//     : theme.palette.grey[600],
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				// opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
			},
		},
		"& .MuiSwitch-thumb": {
			boxSizing: "border-box",
			marginTop: 1,
			marginLeft: 3,
			width: 16,
			height: 16,
			"&:hover": {
				borderWidth: 6,
				borderBlockColor: alpha("#e6e6e6", 0.5),
			},
		},
		"& .MuiSwitch-track": {
			borderRadius: 24 / 2,
			height: 22,
			backgroundColor: "#e6e6e6",
			opacity: 1,
		},
	}));
	return (
		<div className="ft-switch" data-testid={switchName}>
			{/* <label className="toggle checked focus" htmlFor={switchName}>
				<span className="slider round">
					<span className="circle">
						<Switch
							id={switchName}
							aria-label={label}
							data-testid={switchName}
							checked={checked}
							color="default"
							className="toggle slider circle"
							inputProps={{ "aria-label": switchName, className: "slider circle" }}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								onChange(event.target.checked);
							}}
						/>
					</span>
				</span>
			</label> */}

			<FormControlLabel
				control={
					<StyledSwitch
						// sx={{ m: 1 }}
						checked={checked}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							// console.log(e.target.checked)
							onChange(e.target.checked);
						}}
					/>
				}
				label={label}
				labelPlacement={labelPlacement || "end"}
			/>
			{/* <span className="toggle-label">{label}</span> */}
		</div>
	);
};

export default FTSwitch;

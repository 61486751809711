import { LAST_UPDATED_ON } from "@constants";
import { Box } from "@mui/material";
import { FTBoxProps } from "@types";
import moment from "moment";
import FTIcon from "./FTIcon";
import FTPopover from "./FTPopover";
import { toHumanReadableDate, formatter } from "utils/helper";
import "./styles/atoms.styles.scss";

const FTBox = (props: FTBoxProps) => {
    const calcWidth = (def: string) => `${def} ${props.halfWidth && "half-width"}`;

    return (
        <section className={calcWidth("ftbox-container")}>
            <article
                className={
                    props.headerTitle === undefined || null || "" ? "display-noheader" : calcWidth("ftbox-header")
                }
            >
                {props.header === "Default" && (
                    <>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: "flex" }}>
                                {props.headerTitle}
                                {props.iconMessage && <FTPopover message={props.iconMessage} />}
                            </Box>
                            <h3>{props.value && formatter.format(props.value)}</h3>
                        </Box>

                        <span>
                            {props.lastUpdatedOn && (
                                <div className="profile-last-update-date">
                                    <p className="profile-last-update-date__title">{LAST_UPDATED_ON}</p>
                                    <h6 className="profile-last-update-date__date">
                                        {moment(props.lastUpdatedOn).format("DD MMMM YYYY")}
                                    </h6>
                                </div>
                            )}
                        </span>
                    </>
                )}

                {props.header === "FindingFactHeader" && (
                    <div className="card-header-line">
                        <FTIcon iconName={props.iconLabel} className="card-header-line__icon" />
                        <div className="card-header__content">
                            <span className="header-title">{`${props.headerTitle} `}</span>
                            {props.isMonthlyOrAnnualLabel && (
                                <span className="ma-label">({`${props.isMonthlyOrAnnualLabel}`})</span>
                            )}
                            {props.lastUpdatedDate && (
                                <span className="last-updatedon">{`As at ${toHumanReadableDate(
                                    props.lastUpdatedDate
                                )}`}</span>
                            )}
                            <h3>{(props.value && formatter.format(props.value)) || formatter.format(0)}</h3>
                        </div>
                    </div>
                )}
                {props.header === "Simple" && <h6>{props.headerTitle}</h6>}
                {props.header === "SimpleWithIcon" && (
                    <h6>
                        <article className="same-line">
                            {props.headerTitle}
                            {props.iconMessage && <FTPopover message={props.iconMessage} />}
                        </article>
                    </h6>
                )}
                {props.header === "SimpleWithRighControls" && (
                    <article className="simple-withcontrols">
                        <h5>{props.headerTitle}</h5>
                        {props.rightControls && props.rightControls()}
                    </article>
                )}
            </article>

            <article className="ftbox-body">{props.body}</article>
        </section>
    );
};

export default FTBox;

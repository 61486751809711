import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import FTModal from "atoms/FTModal";
import { useAppSelector } from "hooks";

const FTResourcesModal = ({
    showResourcesModal,
    setShowResourcesModal,
}: {
    showResourcesModal: boolean;
    setShowResourcesModal: (showResourcesModal: boolean) => void;
}) => {
    const { resourcesContent } = useAppSelector((state) => state?.bloomreachCDN);
    const icons = ["value", "zoom", "growth"];

    const handleLink = (link: any) => {
        window.open(link);
    };

    return (
        <FTModal open={showResourcesModal} handleClose={() => setShowResourcesModal(false)}>
            <div className="support-modal-body resources-modal" data-testid="resources-popup">
                <div className="resources-modal__header">
                    <FTButton
                        className="close-button"
                        id="modal-close-btn"
                        data-testid="modal-close-btn"
                        endIcon={<FTIcon iconName="ft-cross" />}
                        aria-label={"Close the modal"}
                        variant="text"
                        onClick={() => setShowResourcesModal(false)}
                    >
                        Close
                    </FTButton>
                </div>
                <h4 className="resources-modal__title">Resources</h4>
                <div className="resources-modal-content-area">
                    {resourcesContent &&
                        resourcesContent?.map(({ header, subHeader, resourceUrl, imageUrl }, _index) => (
                            <div className="resources-modal__list" key={_index}>
                                <div className="resources-modal__item">
                                    <div className="resources-modal__item__icon">
                                        <FTIcon iconName={`ft-${icons[_index]}`} />
                                        {/* <img src={imageUrl} alt={header} /> */}
                                    </div>
                                    <div className="resources-modal__item__content__container">
                                        <h6 className="resources-modal__item__content__title">{header}</h6>
                                        <div className="resources-modal__item__content__desc">{subHeader}</div>
                                        <div className="resources-modal__item__content__link">
                                            <a
                                                href="/"
                                                onClick={() => handleLink(resourceUrl)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className="resources-modal__item__content__link__container">
                                                    <span>Find out more</span>
                                                    <FTIcon iconName="ft-carrotRight" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </FTModal>
    );
};

export default FTResourcesModal;

import { Modal, ModalProps } from "@mui/material";
import { FTModalProps } from "@types";

const FTModal = (props: FTModalProps & ModalProps) => {
    const { handleClose, open, children, className } = props;
    return (
        <Modal
            open={open}
            className={className}
            onClose={(e, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                    handleClose(e);
                }
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        position: "fixed",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 1040,
                        backgroundColor: "rgba(0,0,0,0.75)",
                    },
                },
            }}
        >
            {children}
        </Modal>
    );
};

export default FTModal;

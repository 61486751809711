import { AnchorHTMLAttributes } from "react";
import "./styles/atoms.styles.scss";

const FTLink = ({
  children,
  href,
}: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a className="link" href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default FTLink;

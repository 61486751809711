import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import FTInputXL from "atoms/FTInputXL";
import FTModal from "atoms/FTModal";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import illustrationNoSearchResult from "assets/icons/raw-icons/illustration-no-results-search.svg";

const FTFaqModal = ({
    showFAQModal,
    handleFaqClose,
}: {
    showFAQModal: boolean;
    handleFaqClose: (showFAQModal: boolean) => void;
}) => {
    const { faqData } = useAppSelector((state) => state?.bloomreachCDN);
    const [selectedFAQ, setSelectedFAQ] = useState<any>(0);
    const [selectedQues, setSelectedQues] = useState<boolean>(false);

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredList, setFilteredList] = useState(faqData);
    const [searchModeOn, setSearchModeOn] = useState(false);
    const [noResultsFound, setNoResultsFound] = useState(false);

    useEffect(() => {
        searchQuery !== "" ? setSearchModeOn(true) : setSearchModeOn(false);
    }, [searchQuery, searchModeOn]);

    useEffect(() => {
        filteredList.length === 0 ? setNoResultsFound(true) : setNoResultsFound(false);
    }, [filteredList, noResultsFound]);

    const handleFAQCategoryClick = (index: number) => {
        setSelectedFAQ(index);
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        // setSearchModeOn(false);
        // setNoResultsFound(false)
    };

    const handleSearch = (e: any) => {
        const query = e.target.value;
        setSearchQuery(query);
        // @ts-ignore
        const filterBasicList = faqData[selectedFAQ]?.items?.filter((list: any) => {
            return e.target.value.trim().length === 0
                ? // @ts-ignore
                  faqData[selectedFAQ]?.items
                : list?.q.toLowerCase().includes(e.target.value);
        });
        setFilteredList(filterBasicList);
        // console.log(filteredList);
    };

    return (
        <FTModal open={showFAQModal} handleClose={() => handleFaqClose(false)}>
            <div data-testid="faq-popup" className="faq-popup">
                <FTButton
                    className="close-button"
                    id="modal-close-btn"
                    data-testid="modal-close-btn"
                    endIcon={<FTIcon iconName="ft-cross" />}
                    aria-label={"Close the modal"}
                    variant="text"
                    onClick={() => handleFaqClose(false)}
                >
                    Close
                </FTButton>
                <h3>Help &amp; Support</h3>

                <div className="search-container">
                    <h2> Browse help </h2>
                    <div className="qf-input-text__no-label">
                        <div className="qf-input-text">
                            <div className="qf-input-text__prefix">
                                <FTIcon iconName="ft-search" />
                            </div>
                            <FTInputXL
                                data-testid="other-wealth-provider__name"
                                type="search"
                                aria-label="search FAQ"
                                placeholder="Search by keyword"
                                autoComplete="no"
                                autoCorrect="off"
                                autoCapitalize="off"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                            {searchModeOn && (
                                <div className="qf-input-text__suffix" onClick={handleClearSearch}>
                                    <FTIcon iconName="ft-cross" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {searchModeOn && (
                    <p>
                        We found <b>{filteredList?.length}</b> results for <b>“{searchQuery}”</b>
                    </p>
                )}

                <div className="split">
                    {!searchModeOn && (
                        <div className="themes">
                            {
                                // Filtering out empty value
                                faqData
                                    ?.filter((item) => item)
                                    ?.map(({ name }, index: number) => (
                                        <div
                                            key={index}
                                            className={index === selectedFAQ ? "selected-theme" : ""}
                                            onClick={() => handleFAQCategoryClick(index)}
                                        >
                                            {name}
                                        </div>
                                    ))
                            }
                        </div>
                    )}

                    {!searchModeOn && (
                        <div className="results-container">
                            <div className="qa-section">
                                {
                                    // @ts-ignore TODO:: check type
                                    faqData[selectedFAQ]?.items.map((_itm: any, _indx: number) => (
                                        <div
                                            key={_indx}
                                            className={selectedQues ? "border-highlight" : ""}
                                            onClick={() => setSelectedQues(!selectedQues)}
                                        >
                                            <div className="question">
                                                <span>
                                                    <span>{_itm.q}</span>
                                                </span>
                                            </div>
                                            {selectedQues && (
                                                <p className={`answer ${selectedQues ? "hidden" : ""}`}>{_itm.a}</p>
                                            )}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )}

                    {searchModeOn && (
                        <div className={!noResultsFound ? "results-container" : ""}>
                            <div className="qa-section">
                                {
                                    // @ts-ignore TODO:: check type
                                    filteredList?.map((_itm: any, _indx: number) => (
                                        <div
                                            key={_indx}
                                            className={selectedQues ? "border-highlight" : ""}
                                            onClick={() => setSelectedQues(!selectedQues)}
                                        >
                                            <div className="question">
                                                <span>
                                                    <span>{_itm.q}</span>
                                                </span>
                                            </div>
                                            {selectedQues && (
                                                <p className={`answer ${selectedQues ? "hidden" : ""}`}>{_itm.a}</p>
                                            )}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )}

                    {searchModeOn && noResultsFound && (
                        <img src={illustrationNoSearchResult} className="no-results-found-icon" alt="" />
                    )}
                    {searchModeOn && noResultsFound && (
                        <div className="no-results-found-text">
                            <h3>Sorry, no results found for this phrase.</h3>
                        </div>
                    )}
                </div>

                <div className="pagination">
                    {/* <qf-button uiStyle="primary-compact"
                               (onClick)="moveBackward()"
                               icon="carrotleft"
                               [style.visibility]="isFirstPage ? 'hidden' : 'visible'">
                    </qf-button> */}

                    {/* <FTIcon className="page-num icon" iconName='ft-carrotleft' /> */}

                    <span
                        className="page-num selected-page"
                        //   *ngFor="let page of pages"
                        //   (click)="selectPage(page.index)"
                        //   [class.selected-page]="page.index === currentPageIndex"
                    >
                        {/* {{ page.label }} */}1
                    </span>

                    {/* <FTIcon className="page-num icon" iconName='ft-carrotRight' /> */}

                    {/* <qf-button uiStyle="primary-compact"
                               (onClick)="moveForward()"
                               icon="carrotRight"
                               [style.visibility]="isLastPage ? 'hidden' : 'visible'">
                    </qf-button> */}
                </div>
            </div>
        </FTModal>
    );
};

export default FTFaqModal;

import { useEffect, useState } from "react";
import FTBox from "atoms/FTBox";
import { useAppSelector } from "hooks";
import { Proposal } from "redux/models";
import { UPDATEPROFILEPAGE } from "@constants";
import { fetchCurrencySymbol, formatter } from "utils/helper";

const ProfileInformationSection = () => {
    const selectedContactAllProposals = useAppSelector((state) => state?.proposals);
    const [proposals, setProposals] = useState<Array<Proposal>>(selectedContactAllProposals);
    const [latestUpdatedProposal, setLatestUpdatedProposal] = useState<Proposal>(proposals[proposals.length - 1]);
    const customData = latestUpdatedProposal?.customData;

    useEffect(
        () => {
            setProposals(selectedContactAllProposals);
            let latestProposal = latestUpdatedProposal;
            for (let i = 0; i < selectedContactAllProposals?.length; i++) {
                if (
                    new Date(selectedContactAllProposals[i]?.__meta?.timestamp) >
                    new Date(latestProposal?.__meta?.timestamp)
                )
                    latestProposal = selectedContactAllProposals[i];
            }
            setLatestUpdatedProposal(latestProposal);
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedContactAllProposals]
    );

    let otherIncomeItems: Array<object> = [],
        statePensionIncome: { value: number } | null = null,
        wealthItems: Array<object> = [],
        definedBenefitPensions: Array<object> = [];

    if (customData && Object.keys(customData)?.length !== 0) {
        otherIncomeItems = customData.otherIncomeItems;
        statePensionIncome = customData.statePensionIncome;
        wealthItems = customData.wealthItems;
        definedBenefitPensions = customData?.definedBenefitPensions;
    }

    const defaultSIIP = wealthItems?.filter((item: any) => {
        return item?.type === "SIPP";
    });

    const otherWealthValues = wealthItems?.filter((item: any) => {
        return item?.type !== "SIPP";
    });

    const lastUpdatedOn = latestUpdatedProposal?.date;

    const renderProfileInformationSection = () => (
        <>
            <div className="proposal-profile-info-container">
                <div className="proposal-profile-info__title">
                    <h6>{UPDATEPROFILEPAGE.DEFAULT_SIPP_VALUE}</h6>
                </div>
                {defaultSIIP?.map((item: any, index: any) => (
                    <div key={index} className="proposal-profile-info__value--list">
                        <div className="proposal-profile-info__value--group">
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">
                                    {`${UPDATEPROFILEPAGE.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                </div>
                                {defaultSIIP ? <div>{formatter.format(item?.value)}</div> : ""}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="proposal-profile-info-container">
                <div className="proposal-profile-info__title">
                    <h6>{UPDATEPROFILEPAGE.OTHER_WEALTH_VALUE}</h6>
                </div>
                {otherWealthValues?.map((item: any, index: any) => (
                    <div key={index} className="proposal-profile-info__value--list">
                        <div className="proposal-profile-info__value--group">
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">{UPDATEPROFILEPAGE.REFERENCE}</div>
                                <div>{item?.name}</div>
                            </div>
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">{UPDATEPROFILEPAGE.TYPE}</div>
                                <div>{item?.type}</div>
                            </div>
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">
                                    {`${UPDATEPROFILEPAGE.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                </div>
                                <div>{formatter.format(item?.value)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="proposal-profile-info-container">
                <div className="proposal-profile-info__title">
                    <h6> {UPDATEPROFILEPAGE.DEFINED_BENEFIT_PENSION_INCOME}</h6>
                </div>
                {definedBenefitPensions?.map(({ provider, value }: any, index: any) => (
                    <div key={index} className="proposal-profile-info__value--list">
                        <div className="proposal-profile-info__value--group">
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">
                                    {UPDATEPROFILEPAGE.PROVIDER_NAME}
                                </div>
                                <div>{provider}</div>
                            </div>
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">
                                    {`${UPDATEPROFILEPAGE.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                </div>
                                <div>{formatter.format(value)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="proposal-profile-info-container">
                <div className="proposal-profile-info__title">
                    <h6>{UPDATEPROFILEPAGE.OTHER_INCOME}</h6>
                </div>
                {otherIncomeItems?.map((item: any, index: any) => (
                    <div className="proposal-profile-info__value--list">
                        <div key={index} className="proposal-profile-info__value--group">
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">{UPDATEPROFILEPAGE.NAME}</div>
                                <div>{item?.name}</div>
                            </div>
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">{UPDATEPROFILEPAGE.TYPE}</div>
                                <div>{item?.type}</div>
                            </div>
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">
                                    {`${UPDATEPROFILEPAGE.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                </div>
                                <div>{formatter.format(item?.value)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="proposal-profile-info-container">
                <div className="proposal-profile-info__title">
                    <h6>{UPDATEPROFILEPAGE.STATE_PENSION}</h6>
                </div>
                {statePensionIncome && statePensionIncome?.value && (
                    <div className="proposal-profile-info__value--list">
                        <div className="proposal-profile-info__value--group">
                            <div className="proposal-profile-info__value">
                                <div className="proposal-profile-info__value-label">
                                    {`${UPDATEPROFILEPAGE.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                </div>
                                <div>{formatter.format(statePensionIncome?.value)}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );

    return (
        <FTBox
            header="Default"
            headerTitle={UPDATEPROFILEPAGE.PROFILE_INFORMATION}
            body={renderProfileInformationSection()}
            lastUpdatedOn={lastUpdatedOn}
        />
    );
};

export default ProfileInformationSection;

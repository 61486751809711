import { CHART_LABELS } from "@constants";
import FTIcon from "atoms/FTIcon";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import more from "highcharts/highcharts-more";
import { useCallback, useEffect, useState } from "react";
import "../../styles/portfolio-allocation-chart.styles.scss";
import { assetClassColors } from "./portfolio-mapper-helpers";
import useGetPortfolio from "hooks/useGetPortfolio";

more(Highcharts); // init module

export interface SubAsset {
    allocatedValue: number;
    subAssetClass: string;
    isin: string;
    name: string;
    factsheet: string;
}
export type AssetType = "Equities" | "Fixed Income" | "Cash";

export interface AssetClass {
    type: AssetType;
    totalAllocatedvalue: number;
    color: string;
    subAssets: SubAsset[];
}

const FTPortfolioAllocationChart = ({ isPdf = false }: { isPdf?: boolean }) => {
    const assetColor = ["#00BFB3", "#1446E1", "#FF9665"];
    const { selectedPortfolioData } = useGetPortfolio();
    const data: AssetClass[] = selectedPortfolioData?.assetData?.map((_itm: any, index: any) => ({
        color: assetColor[index],
        type: _itm.type,
        totalAllocatedvalue: _itm.totalAllocatedvalue,
        subAssets: _itm.subAssets,
    }));

    const getCurrentPortfolioAllocation = (type: AssetType) => {
        const currentPortfolioAllocation = selectedPortfolioData?.assetData?.filter((item: any) => item.type === type);
        // console.log("currentPortfolioAllocation", currentPortfolioAllocation);
        return currentPortfolioAllocation && currentPortfolioAllocation[0]?.totalAllocatedvalue;
    };

    const pieChartOptions: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        chart: {
            // @ts-ignore
            plotBackgroundColor: null,
            // @ts-ignore
            plotBorderWidth: null,
            plotShadow: false,
            height: "100%",
            margin: 0,
            type: "pie",
        },
        title: {
            text: "",
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                size: "100%",
                allowPointSelect: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        enabled: false,
                    },
                },
            },
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
            enabled: false, // to remove accessibilty warning on console
        },
        series: [
            {
                dataLabels: {
                    enabled: false,
                },
                name: "%",
                size: "80%",
                // @ts-ignore
                innerSize: "60%",
                colorByPoint: true,
                type: "pie",
                data: [
                    {
                        name: CHART_LABELS.equities,
                        y: 74.75,
                        color: assetClassColors.equity,
                    },
                    {
                        name: CHART_LABELS.fixedIncome,
                        // sliced: true,
                        // selected: true,
                        y: 25.2,
                        color: assetClassColors.fixedIncome,
                    },
                    {
                        name: CHART_LABELS.cashLabel,
                        y: 0.05,
                        color: assetClassColors.cash,
                    },
                ],
            },
        ],
    };

    const [chartOptions, setChartOptions] = useState(pieChartOptions);

    const updateHighcharts = useCallback(() => {
        setChartOptions((prev) => {
            return {
                ...prev,
                series: [
                    {
                        dataLabels: {
                            enabled: false,
                        },
                        name: "%",
                        size: "80%",
                        innerSize: "60%",
                        colorByPoint: true,
                        type: "pie",
                        data: [
                            {
                                name: CHART_LABELS.equities,
                                y: getCurrentPortfolioAllocation("Equities"),
                                color: assetClassColors.equity,
                            },
                            {
                                name: CHART_LABELS.fixedIncome,
                                y: getCurrentPortfolioAllocation("Fixed Income"),
                                color: assetClassColors.fixedIncome,
                            },
                            {
                                name: CHART_LABELS.cashLabel,
                                y: getCurrentPortfolioAllocation("Cash"),
                                color: assetClassColors.cash,
                            },
                        ],
                    },
                ],
            };
        });
        // eslint-disable-next-line
    }, [
        // eslint-disable-next-line
        getCurrentPortfolioAllocation("Equities"),
        // eslint-disable-next-line
        getCurrentPortfolioAllocation("Fixed Income"),
        // eslint-disable-next-line
        getCurrentPortfolioAllocation("Cash"),
    ]);

    useEffect(() => {
        updateHighcharts();
    }, [updateHighcharts]);

    const [showMore, setShowMore] = useState([
        {
            assetClass: "Equities",
            open: false,
        },
        {
            assetClass: "Fixed Income",
            open: false,
        },
        {
            assetClass: "Cash",
            open: false,
        },
    ]);

    const toggleView = (assetClass: string) => {
        const newState = showMore.map((obj) => {
            if (obj.assetClass === assetClass) {
                return { ...obj, open: !obj.open };
            }
            return obj;
        });
        setShowMore(newState);
    };

    return (
        <div className={`portfolio-allocation-container ${isPdf && "proposal-pdf"}`}>
            <div className="highcharts-chart-container">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    oneToOne={true}
                    updateArgs={[true]}
                    aria-label="Allocation interactive chart."
                />
            </div>

            {isPdf && (
                <div className="recommend-allocation">
                    <h6 className="recommend-allocation__title">Recomended asset allocation</h6>
                    <div className="recommend-allocation__description">
                        Exact allocations subject to dynamic change to align with changing markets and income
                        requirements.
                    </div>
                </div>
            )}

            <div className="assets-info">
                {data &&
                    data?.map(
                        (asset: AssetClass, index: number) =>
                            asset.subAssets.length !== 0 && (
                                <div key={index} className="asset-class">
                                    <h6
                                        className="allocation-header"
                                        style={{ ["--border-color" as string]: asset.color }}
                                    >
                                        <span>{asset.type}</span>
                                        <span>{`${asset.totalAllocatedvalue}%`}</span>
                                    </h6>

                                    <div className="instrument-columns">
                                        <span>Instrument</span>
                                        <span>Category</span>
                                        <span>Weight</span>
                                    </div>

                                    {(showMore[index].assetClass === asset.type && showMore[index].open) || isPdf ? (
                                        <>
                                            {asset.subAssets.map((sub) => (
                                                <div className="instrument" key={sub.isin}>
                                                    <a
                                                        className={`demi-bold`}
                                                        href={sub.factsheet}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {sub.name}
                                                    </a>
                                                    <span className={`demi-bold`}>{sub.subAssetClass}</span>
                                                    <span className={`demi-bold`}>{`${sub.allocatedValue}%`}</span>
                                                </div>
                                            ))}
                                            {!isPdf && (
                                                <span className="toggle-btn" onClick={() => toggleView(asset.type)}>
                                                    See less <FTIcon iconName="ft-arrowUp" />
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {asset.subAssets.slice(0, 3).map((sub) => (
                                                <div className="instrument" key={sub.isin}>
                                                    <a
                                                        className={`demi-bold`}
                                                        href={sub.factsheet}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {sub.name}
                                                    </a>
                                                    <span className={`demi-bold`}>{sub.subAssetClass}</span>
                                                    <span className={`demi-bold`}>{`${sub.allocatedValue}%`}</span>
                                                </div>
                                            ))}
                                            {asset.subAssets.length > 3 && (
                                                <span className="toggle-btn" onClick={() => toggleView(asset.type)}>
                                                    See more <FTIcon iconName="ft-arrowDown" />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </div>
                            )
                    )}
            </div>
        </div>
    );
};

export default FTPortfolioAllocationChart;

import FTPdfModal from "atoms/FTPdfModal";
import PdfHeader from "components/PdfHeader";
import PdfImplementationPlan from "components/PdfImplementaionPlan";
import PdfProposalInfo from "components/PdfProposalInfo/Index";
import { useAppSelector } from "hooks";
import { useRef } from "react";
import { userShortInfo } from "redux/reducer";

const TransactionExecutionPdf = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const { label } = useAppSelector((state) => state?.selectedContact);
    const user = useAppSelector(userShortInfo);
    const { name: selectedProposalName, date: selectedProposalDate } = useAppSelector(
        (state) => state?.selectedProposal
    );
    const divRef = useRef<HTMLDivElement>(null);
    return (
        <FTPdfModal onClose={onClose} open={open} pdfContainerRef={divRef}>
            <div className="transaction-execution-pdf pdf-content-container" ref={divRef}>
                <div className="pdf-content">
                    <PdfHeader
                        proposalPdfTitle=""
                        isProposalPdf={false}
                        title="Transaction Execution Document"
                        clientName={label}
                        qrCodeUrl="https://franklintempletonprod.widen.net/content/lmnf86kmfb/jpeg/QRCode.png"
                    />
                    <PdfProposalInfo
                        proposalDate={selectedProposalDate}
                        proposalName={selectedProposalName}
                        finacialAdvisorName={user?.loggedInUsername || ""}
                    />
                </div>
                <PdfImplementationPlan step={1} />
                <div className="pdf-content implementation-plan__disclaimer"></div>
            </div>
            <div className="transaction-execution-pdf pdf-content-container hidden-pdf-container">
                <div id="hidden-pdf-page" className="pdf-page"></div>
            </div>
        </FTPdfModal>
    );
};

export default TransactionExecutionPdf;

import { FormControl, InputLabel, MenuItem, Select, SelectProps, SvgIcon } from "@mui/material";
import "./styles/atoms.styles.scss";

const FTDropdown = (props: SelectProps & { options: Array<any>; noDataMessage: string }) => {
    const { onOpen, onClose, value, onSelect, options, noDataMessage, onChange, className, id, placeholder, label } =
        props;

    const SelectIcon = (props: any) => (
        <SvgIcon {...props}>
            <svg fill="#3769ff" viewBox="0 0 256 256" id="selectDropdownIcon">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M128,180a3.98881,3.98881,0,0,1-2.82861-1.17139l-80-80.00024a4.00009,4.00009,0,0,1,5.65722-5.65674L128,170.34326l77.17139-77.17163a4.00009,4.00009,0,0,1,5.65722,5.65674l-80,80.00024A3.98881,3.98881,0,0,1,128,180Z"></path>{" "}
                </g>
            </svg>
        </SvgIcon>
    );

    return (
        <article className="select-field">
            <FormControl fullWidth>
                <InputLabel className="selectLabel" id={id}>{label}</InputLabel>
                <Select
                    id={id}
                    data-testid={id}
                    className={className}
                    onOpen={onOpen}
                    onClose={onClose}
                    value={value}
                    onChange={onChange}
                    onSelect={onSelect}
                    // inputProps={{
                    // 	disableUnderline: true,
                    // 	className: className,
                    // 	value: value || "",
                    // }}
                    // variant="standard"
                    placeholder={placeholder}
                    autoComplete="off"
                    label={label}
                    IconComponent={SelectIcon}
                    MenuProps={{
                        PaperProps: {
                            getcontentanchorel: null,
                            style: {
                                maxHeight: 185,
                                // width: 300,
                            },
                        },
                    }}
                    sx={{
                        "&.MuiInputLabel-outlined:hover": {
                            color: "#3769ff"
                        }
                    }}
                >
                    {options?.length > 0 ? (
                        options.map((option: any, idx: number) => (
                            <MenuItem key={idx} value={option.value || option.name}>
                                {option.label || option.name || option.value}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="" disabled>
                            {noDataMessage}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </article>
    );
};

export default FTDropdown;

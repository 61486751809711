import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import Header from "../containers/Header";
import AdvisorLanding from "../pages/AdvisorLanding";
import AdvisorLogin from "../pages/AdvisorLogin";
// import { ErrorBoundary } from "react-error-boundary";;
import CreateProfile from "pages/CreateProfile";
import Home from "pages/Home";
import Proposals from "pages/Proposals";
import ClientCommons from "containers/ClientCommons";
import UpdateProfile from "pages/UpdateProfile";
import FactFinding from "pages/FactFinding";
import ViewProposal from "pages/ViewProposal";
import NextSteps from "pages/NextSteps";

const router = createBrowserRouter(
    createRoutesFromElements(
        // <ErrorBoundary
        // 	FallbackComponent={FTErrorModal}
        // 	onError={(error: Error, info: { componentStack: string }) => {
        // 		console.log(error, info);
        // dispatch(updateErrorModalState({
        //               showErrorModal: true,
        //               error: "ApplicationError",
        //           }));
        // }}
        // >
        <Route path="/" element={<Header />}>
            <Route path="" element={<AdvisorLogin />} />
            <Route path="advisor/landing" element={<AdvisorLanding />} />
            <Route path="profile/create" element={<CreateProfile />} />
            <Route path="" element={<ClientCommons />}>
                <Route path="home" element={<Home />} />
                <Route path="home/fact-finding/:factFindingStep" element={<FactFinding />} />
                <Route path="home/view-proposal" element={<ViewProposal />} />
                <Route path="next-steps" element={<NextSteps />} />
                <Route path="profile" element={<UpdateProfile />} />
                <Route path="proposals" element={<Proposals />} />
            </Route>
            <Route path="*" element={<AdvisorLanding />} />
        </Route>
        // </ErrorBoundary>
    )
);

export default router;

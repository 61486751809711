import { useOktaAuth } from "@okta/okta-react";
import { fetchContacts, fetchMarketPortfolios, fetchOrganizationInfo } from "api";
import FTBox from "atoms/FTBox";
import FTButton from "atoms/FTButton";
import FTDropdown from "atoms/FTDropdown";
import { updateOrgInfoState } from "auth/globalSlice";
import PageHeading from "containers/PageHeading";
import { useAppDispatch, useAppSelector } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { set_market_portfolios, set_selected_contact } from "redux/actions";
import { userShortInfo } from "redux/reducer";
import {
    CREATE_NEW_CLIENT,
    EXISTING_CLIENT_SUBMIT_TEXT,
    LANDING_EXISTING_CLIENT_SETION_TEXT,
    LANDING_HEADING,
    LANDING_NEW_CLIENT_SETION_TEXT,
} from "../../@constants";
import "../styles/advisor-landing.styles.scss";
import ExploreSection from "./ExploreSection";

const AdvisorLanding = () => {
    const { authState } = useOktaAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(userShortInfo);
    const [contacts, setContacts] = useState<any>();
    const [selectedClient, setSelectedClient] = useState("");

    const setOrganizationToStore = useCallback(async () => {
        let organization_res = await fetchOrganizationInfo(user?.organizationName);
        if (organization_res) dispatch(updateOrgInfoState(organization_res));
    }, [dispatch, user]);

    const setMarketPortfoliosToStore = useCallback(async () => {
        let marketPortfolios_res = await fetchMarketPortfolios();
        if (marketPortfolios_res) dispatch(set_market_portfolios(marketPortfolios_res));
    }, [dispatch]);

    const setContactsFromAPI = async () => {
        let contacts_res = await fetchContacts();
        contacts_res = contacts_res?.map((contact: any) => ({
            label: contact.firstName + " " + contact.lastName,
            value: contact._id,
            ...contact,
        }));
        setContacts(contacts_res);
    };

    const routeToCreateClientPage = () => {
        navigate("/profile/create");
    };

    const renderExisitingClientSection = () => {
        const handleClientSelected = (e: any) => setSelectedClient(e.target.value);
        const handleExistingClientSubmit = () => {
            const contact = contacts?.find((contact: any) => contact.value === selectedClient);
            dispatch(set_selected_contact(contact));
            navigate("/home");
        };
        return (
            <>
                <p>{LANDING_EXISTING_CLIENT_SETION_TEXT}</p>
                <FTDropdown
                    id="client-selection-dropdown"
                    data-testid="client-selection-dropdown"
                    label="Select client"
                    noDataMessage="No clients added"
                    options={contacts}
                    onChange={handleClientSelected}
                    value={selectedClient}
                    // placeholder="Select client"
                />
                <FTButton
                    className="left-aligned-btn"
                    onClick={handleExistingClientSubmit}
                    disabled={selectedClient === ""}
                >
                    {EXISTING_CLIENT_SUBMIT_TEXT}
                </FTButton>
            </>
        );
    };

    const renderNewClientSection = () => (
        <>
            <p>{LANDING_NEW_CLIENT_SETION_TEXT}</p>
            <FTButton sx={{ marginTop: "3rem" }} className="left-aligned-btn" onClick={routeToCreateClientPage}>
                {CREATE_NEW_CLIENT}
            </FTButton>
        </>
    );

    useEffect(() => {
        if (authState)
            if (!authState.isAuthenticated) navigate("/?reason=logoutSuccess");
            else {
                setOrganizationToStore();
                setMarketPortfoliosToStore();
                setContactsFromAPI();
            }
    }, [authState, setOrganizationToStore, setMarketPortfoliosToStore, navigate]);

    return (
        <main className="landing-container">
            <PageHeading renderOnPage={"LANDING_PAGE"} />

            <h3 className="landing-heading">{LANDING_HEADING}</h3>
            <section className="main-content">
                {contacts && (
                    <FTBox
                        header="Default"
                        headerTitle="Choose an existing client"
                        body={renderExisitingClientSection()}
                    />
                )}
                <FTBox header="Default" headerTitle="Create a new client" body={renderNewClientSection()} />
            </section>
            <ExploreSection />
        </main>
    );
};

export default AdvisorLanding;

export const HEADER_LINK_HREF =
    "https://content-preview.ftsites.com/en-gb/resources-and-literature/client-use-materials/dynamic-retirement-planner";

export const SIGNIN_HELP = "https://ftext.okta.com/help/login";
// BloomReach URL and End-points
const BLOOMREACH_BASE_URL = "/api/rio/cms";
export const bloomreach_disclaimers = BLOOMREACH_BASE_URL + "/disclaimers";
export const bloomreach_faq = BLOOMREACH_BASE_URL + "/faq";
export const bloomreach_generalcontent = BLOOMREACH_BASE_URL + "/generalcontent";

// Profile - Organization
export const GET_ORGANIZATION_URL = "/api/rio/profile/getorganization";
export const GET_MARKET_PORTFOLIOS_URL = "/api/rio/getmarketportfolios";

// Contacts
export const GET_CONTACTS_URL = "/api/rio/getcontacts";
export const ADD_CONTACT_URL = "/api/rio/addcontact";
export const UPDATE_CONTACT_URL = "/api/rio/updatecontact";

// Auth
export const OKTA_DEVICE_TOKEN_KEY = document?.location?.host + "-device-token";
export const LOGIN = {
    MFA_CHALLENGE: "MFA_CHALLENGE",
    MFA: "MFA",
    SUCCESS: "SUCCESS",
    MFA_REQUIRED: "MFA_REQUIRED",
    OKTA: "OKTA",
};

// Proposals
export const PROPOSALS_URL = "/api/rio/client/";

// Spire
export const SPIRE_INDICATIVE_QUOTE_URL = "/api/rio/spireps/indicativequote";
export const SPIRE_PERSONALIZED_QUOTE_PORTAL_URL = "/api/rio/spireps/personalizedquotes/portalurl";
export const SPIRE_PERSONALIZED_QUOTE_CALLBACK = "/api/rio/spireps/personalizedquotes/callback";

// goe4d
export const GOE4D_TRANSLATION_URL = "/api/rio/goe4d/translation";

// Annuity Drawer Values
export const ANNUITY_RATE = 0.1;
export const ANNUITY_AMOUNT_STEP_SIZE = 100.0;

// FTNumberInput
export const MAX_ALLOWED_NUMBER = 999999999;
export const LONGEST_DISPLAYED_NUMBER = 9999999999;

export const RETIREMENT_AGE = 65;

// Language codes that use decimal comma and dot as thousand separator
export const DECIMAL_COMMA_COUNTRY_LIST = ["nl-NL", "fr-FR", "de-DE", "it-IT", "es-ES", "nl-NL", "pt-PT"];

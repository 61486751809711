import { EXPLORE_SECTION_HEADING } from "../../@constants";
import BlogCard from "./BlogCard";
import PrivacyDisclaimer from "./PrivacyDisclaimer";

const ExploreSection = () => {
	return (
		<>
			<h2 className="explore-heading">{EXPLORE_SECTION_HEADING}</h2>

			<BlogCard />
			<PrivacyDisclaimer />
		</>
	);
};

export default ExploreSection;

import "./styles/ftradial.styles.scss";

type size = "sm" | "md";

interface FTRadialPropTypes {
    dialDegrees: number;
    transformValue?: string;
    size?: size;
}
const FTRadial = ({ dialDegrees, size = "md" }: FTRadialPropTypes) => {
    const transformValue = (dialDegrees: number) => {
        return `rotateZ(${dialDegrees}deg) translateX(-50%)`;
    };

    return (
        <div className={`radial radial-${size}`}>
            <div className="radial-layer-1"></div>
            <div className="radial-layer-2"></div>
            <div className="hand" style={{ transform: transformValue(dialDegrees) }}></div>
        </div>
    );
};

export default FTRadial;

import { FACTFINDINGPAGE } from "@constants";
import FTButton from "atoms/FTButton";
import FTDropdown from "atoms/FTDropdown";
import FTInput from "atoms/FTInput";
import FTModal from "atoms/FTModal";
import FTNumberInput from "atoms/FTNumberInput";
import FTSwitch from "atoms/FTSwitch";
import { useState } from "react";
import { fetchCurrencySymbol, formatter } from "utils/helper";
import { hasValue, validateOnlyLetters } from "utils/validateInputs";

const AddNewOtherIncomeModal = (props: any) => {
    const { idx, showAddNewModal, setShowAddNewModal, displayedProposal, setDisplayedProposal } = props;
    const { otherIncomeItems } = displayedProposal?.customData;

    const [showOtherIncomeError, setOtherIncomeError] = useState({
        nameErr: "",
        typeErr: "",
        valueErr: "",
    });
    const [otherIncome, setOtherIncome] = useState({
        deletable: true,
        name: "",
        type: "",
        value: "",
        inflationAdjusted: true,
    });

    const otherIncomeType = [
        { label: "Annuities", value: "Annuities" },
        { label: "Paid Employment", value: "Paid Employment" },
        { label: "Rental Income", value: "Rental Income" },
        { label: "Others", value: "Others" },
    ];

    const handleOtherIncomeCard = (id: string, val: string) => {
        let updatedOtherIncome = {};
        if (id.includes("name")) {
            let newError = showOtherIncomeError.nameErr;
            if (!validateOnlyLetters(val, true)) {
                newError = "Please use alphanumeric characters, number or space.";
                setOtherIncomeError((prev) => {
                    return { ...prev, nameErr: newError };
                });
            } else {
                setOtherIncomeError((prev) => {
                    return { ...prev, nameErr: "" };
                });
            }
            updatedOtherIncome = {
                ...otherIncome,
                name: val,
            };
            setOtherIncome((prev) => {
                return { ...prev, name: val };
            });
        } else if (id.includes("value")) {
            let newError = showOtherIncomeError.valueErr;
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newError = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setOtherIncomeError((prev) => {
                    return { ...prev, valueErr: newError };
                });
            } else {
                setOtherIncomeError((prev) => {
                    return { ...prev, valueErr: "" };
                });
            }
            updatedOtherIncome = {
                ...otherIncome,
                value: validatedOtherValue,
            };
            setOtherIncome((prev) => {
                return { ...prev, value: val };
            });
        }
    };

    const handleOtherIncomeType = (e: any) => {
        const selectedType = e.target.value;
        let updatedOtherIncomeItems = {};
        updatedOtherIncomeItems = {
            ...otherIncome,
            type: selectedType,
        };
        setOtherIncome((prev) => {
            return { ...prev, type: selectedType };
        });
    };

    const handleOtherIncomeSwitch = (checked: boolean) => {
        let updatedOtherIncome = {};
        updatedOtherIncome = {
            ...otherIncomeItems,
            inflationAdjusted: checked,
        };
        setOtherIncome((prev) => {
            return { ...prev, inflationAdjusted: checked };
        });
    };

    const handleAddOtherIncomeCard = () => {
        const newOtherIncomeItem = {
            deletable: true,
            name: otherIncome.name,
            type: otherIncome.type,
            value: otherIncome.value,
            inflationAdjusted: otherIncome.inflationAdjusted,
        };
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal?.customData?.otherIncomeItems.push(newOtherIncomeItem);
        setDisplayedProposal(updatedProposal);
        setShowAddNewModal({ otherIncomeModal: false });
    };

    const checkFieldsStatus = () => {
        const { name, type, value } = otherIncome;
        if (!hasValue(name) && !hasValue(value) && !hasValue(type)) {
            return true;
        }
        return false;
    };

    return (
        <FTModal handleClose={() => setShowAddNewModal({ otherIncomeModal: false })} open={showAddNewModal}>
            <section className="add-new-item-modal">
                <div className="content-area">
                    <div className="new-pension-popup">
                        <h5 className="modal-title">Add new income</h5>
                        <div className="qf-input-text">
                            <FTInput
                                id={`other-income-provider__name`}
                                data-testid="other-income-provider__name"
                                label={FACTFINDINGPAGE.LABEL_CONSTANTS.PROVIDER_NAME}
                                onChange={(e) => handleOtherIncomeCard(e.target.id, e.target.value)}
                                value={otherIncome?.name}
                                showErrorOutline={showOtherIncomeError.nameErr}
                            />
                            {showOtherIncomeError.nameErr && (
                                <p className="MuiFormHelperText-root">{showOtherIncomeError.nameErr}</p>
                            )}
                        </div>

                        <div className="qf-input-dropdown">
                            <FTDropdown
                                id={`other-income-provider_${idx}_type`}
                                data-testid={`other-income-provider_${idx}_type`}
                                value={otherIncome?.type}
                                options={otherIncomeType}
                                onChange={(e) => handleOtherIncomeType(e)}
                                label={FACTFINDINGPAGE.LABEL_CONSTANTS.TYPE}
                                placeholder={FACTFINDINGPAGE.LABEL_CONSTANTS.TYPE}
                                noDataMessage=""
                            />
                        </div>

                        <div className="qf-input-number">
                            <FTNumberInput
                                id={`other-income-provider__value`}
                                data-testid="new-pension-popup__amount"
                                label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                onChange={(e) => handleOtherIncomeCard(e.target.id, e.target.value)}
                                value={otherIncome?.value}
                                showErrorOutline={showOtherIncomeError.valueErr}
                            />
                            {showOtherIncomeError.valueErr && (
                                <p className="MuiFormHelperText-root">{showOtherIncomeError.valueErr}</p>
                            )}
                        </div>

                        <FTSwitch
                            label="Inflation Adjusted?"
                            checked={otherIncome?.inflationAdjusted}
                            switchName={`db-pension-card__provider_${idx}_inflationAdjusted`}
                            onChange={(e) => handleOtherIncomeSwitch(e)}
                        />
                    </div>
                </div>
                <div className="buttons-area">
                    <FTButton
                        className="cancel-add-new-btn left-aligned-btn"
                        variant="outlined"
                        aria-disabled="false"
                        aria-label="Cancel"
                        onClick={() => setShowAddNewModal({ otherIncomeModal: false })}
                    >
                        Cancel
                    </FTButton>
                    <FTButton
                        className="logout-btn left-aligned-btn"
                        aria-disabled="false"
                        aria-label="Confirm"
                        disabled={checkFieldsStatus()}
                        onClick={() => handleAddOtherIncomeCard()}
                    >
                        Confirm
                    </FTButton>
                </div>
            </section>
        </FTModal>
    );
};

export default AddNewOtherIncomeModal;

import parse from "html-react-parser";
import { useAppSelector } from "hooks";
import { storeLoginDisclaimer } from "redux/reducer";

const DisclaimerContent = () => {
    const loginDisclaimer: any = useAppSelector(storeLoginDisclaimer);
    return (
        <section className="disclaimer-container">
            {loginDisclaimer?.length > 0 ? (
                <>
                    <p>{parse(loginDisclaimer[0])}</p>
                    <p>{parse(loginDisclaimer[1])}</p>
                </>
            ) : (
                <p> Loading ...</p>
            )}
        </section>
    );
};

export default DisclaimerContent;

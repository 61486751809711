import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import FTSwitch from "./FTSwitch";
import "./styles/comparators-btn.styles.scss";
import { useDetectOutsideClick } from "hooks";

const FTChartControlBar = ({ comparators, setComparators }: any) => {
    const {
        ref,
        isComponentVisible: isToggleContainerVisible,
        setIsComponentVisible: setIsToggleContainerVisible,
    } = useDetectOutsideClick(false);
    const { fourPerRule, targetConsumption, hundredPerAnnutisation } = comparators;

    return (
        <>
            <div className="control-bar">
                <div className="comparators-btn">
                    <FTButton
                        data-testid="benchmark-comparisons-proposal-income-chart"
                        aria-label="Benchmark Comparisons Toggles"
                        className="close-button"
                        onClick={() => {
                            setIsToggleContainerVisible(!isToggleContainerVisible);
                        }}
                    >
                        Benchmark Comparisons
                        <FTIcon
                            className={`ft-icon ${isToggleContainerVisible ? "opened" : ""}`}
                            iconName="ft-carrotRight"
                        />
                    </FTButton>

                    {isToggleContainerVisible ? (
                        <div className="comparators-container" ref={ref}>
                            <div className="flex-split">
                                <div>
                                    <FTSwitch
                                        aria-label="4% Rule"
                                        switchName="4% Rule"
                                        label="4% Rule"
                                        data-testid="chart-control-four-percent-rule"
                                        checked={fourPerRule}
                                        onChange={(val) => {
                                            setComparators({
                                                ...comparators,
                                                fourPerRule: val,
                                            });
                                        }}
                                    />
                                    <FTSwitch
                                        aria-label="100% Annuitisation"
                                        switchName="100% Annuitisation"
                                        label="100% Annuitisation"
                                        data-testid="chart-control-full-annuitisation"
                                        checked={hundredPerAnnutisation}
                                        onChange={(val) => {
                                            setComparators({
                                                ...comparators,
                                                hundredPerAnnutisation: val,
                                            });
                                        }}
                                    />
                                </div>

                                <div>
                                    <FTSwitch
                                        aria-label="Target Consumption"
                                        switchName="Target Consumption"
                                        label="Target Consumption"
                                        data-testid="chart-control-target-consumption"
                                        checked={targetConsumption}
                                        onChange={(val) =>
                                            setComparators({
                                                ...comparators,
                                                targetConsumption: val,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {/* <div className="empty-div"></div> */}
            </div>
        </>
    );
};

export default FTChartControlBar;

import { InputProps, MenuItem } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { DropdownProps, InputCustomProps } from "@types";
import { hasValue } from "utils/validateInputs";
import "./styles/atoms.styles.scss";

const FTInput = (props: TextFieldProps & DropdownProps & InputProps & InputCustomProps & { onEnter?: () => void }) => {
    const {
			id,
			label,
			value,
			className,
			select,
			type,
			options,
			showErrorOutline,
			onEnter,
			onChange,
			onBlur,
			onFocus,
			placeholder,
			disabled,
			autoFocus,
		} = props;
    const onKeyDown = (e: any) => {
        // handle Submit if Enter is pressed
        if (hasValue(value) && e.keyCode === 13 && onEnter) onEnter();
    };
    // const onFormattedBlur = (e: any) => {
    //     if(!select) {
    //         let formattedEvent = e;
    //         formattedEvent.target.value = e.target.value.trim()
    //         if(onBlur) onBlur(formattedEvent)
    //         else if(onChange) onChange(formattedEvent)
    //     }
    // }
    // const onFormattedFocus = (e: any) => {
    //     if(!select) {
    //     let formattedEvent = e;
    //     formattedEvent.target.value = e.target.value
    //     if(onFocus) onFocus(formattedEvent)
    //     else if(onChange) onChange(formattedEvent)
    //     }
    // }

    const renderSelectOptions = () => {
        if (select && options) {
            if (options?.length > 0)
                return options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ));
            else
                return (
                    <MenuItem value="" disabled>
                        No clients added
                    </MenuItem>
                );
        }
        return null;
    };

    return (
			<article
				className={
					(select ? "select-field" : "input-field") +
					(showErrorOutline ? " hasErrorOutline" : "")
				}
			>
				<TextField
					data-testid={id}
					variant="standard"
					autoComplete='no'
					autoCorrect="off"
					autoCapitalize="off"
					spellCheck="false"
					onKeyDown={onKeyDown}
					error={Boolean(showErrorOutline)}
					InputProps={{
						disableUnderline: true,
						className: className,
						value: value || "",
					}}
					id={id}
					label={label}
					value={value || ""}
					onChange={onChange}
					// onBlur={onFormattedBlur}
					// onFocus={onFormattedFocus}
					placeholder={placeholder}
					disabled={disabled}
					autoFocus={autoFocus}
          select={select}
					type={type}
				>
					{renderSelectOptions()}
				</TextField>
			</article>
		);
};

export default FTInput;

import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import FTModal from "./FTModal";
import FTIcon from "./FTIcon";
import FTButton from "./FTButton";
import { updateErrorModalState, updateFTModalState } from "auth/globalSlice";
import "./styles/atoms.styles.scss";

const FTErrorModal = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { error, showErrorModal } = useAppSelector((state) => state.global);
    return (
        <FTModal open={showErrorModal || false} handleClose={() => {}}>
            <div className="error-modal-body">
                <FTIcon iconName="ft-error" />
                <h2 className="error-modal-title">Oops, seems like something’s not quite right..</h2>
                <div className="error-modal-desc">
                    {error?.statusCode !== 200 || error?.success === false ? (
                        <span>{error?.name || error?.exc_type || error?.body}</span>
                    ) : (
                        <span>{"Http failure response for " + JSON.stringify(error) + " Unknown Error"}</span>
                    )}
                </div>
                <div className="error-modal-error-id">
                    {error?.statusCode !== 200 || error?.success === false ? (
                        <span>{parse(error?.message || "")}</span>
                    ) : (
                        <span>Error: {JSON.stringify(error) || "Network Error"}</span>
                    )}
                </div>
                {location.pathname === "/home/view-proposal" || location.pathname?.includes("/home/fact-finding") || location.pathname?.includes("/next-steps") ? (
                    <div className="same-line error-dismiss">
                        <span
                            onClick={() => {
                                dispatch(updateFTModalState(false));
                                dispatch(updateErrorModalState({ showErrorModal: false, error: {} }));
                                window.location.reload();
                            }}
                        >
                            Reload
                        </span>
                        <span
                            onClick={() =>
                                dispatch(
                                    updateErrorModalState({
                                        showErrorModal: false,
                                        error: null,
                                    })
                                )
                            }
                        >
                            Dismiss
                        </span>
                    </div>
                ) : (
                    <FTButton
                        variant="outlined"
                        className="left-aligned-btn"
                        onClick={() => {
                            dispatch(updateFTModalState(false));
                            dispatch(updateErrorModalState({ showErrorModal: false, error: {} }));
                            window.location.reload();
                        }}
                    >
                        Reload
                    </FTButton>
                )}
            </div>
        </FTModal>
    );
};

export default FTErrorModal;

// Redux actionType constants
export const ADVISOR_LOGIN = "advisor/login";
export const SET_SELECTED_CONTACT = "set/selectedContact";
export const ADD_CONTENT = "add/content";
export const SET_PROPOSALS = "set/proposals";
export const SET_SELECTED_PROPOSAL = "set/selectedProposal";
export const SET_EDITED_PROPOSAL = "set/editedProposal";
export const SET_ANNUAL_DISPLAYED_PROPOSAL = "set/annualDisplayedProposal";
export const SET_SPIRE_DATA = "set/spireData";
export const SET_GOE4D_DATA = "set/goe4dData";
export const SET_VIEW_LOADING = "set/viewProposalLoading";
export const SET_MARKET_PORTFOLIOS = "set/marketPortfolios";
export const SET_SPIRE_PORTAL_URL = "set/spirePortalURL";
export const SET_SHOW_EXTERNAL_LINK_MODAL = "set/showExternalLinkModal";

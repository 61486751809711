import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import PageHeading from "containers/PageHeading";
import { useNavigate } from "react-router-dom";
import AnnuityQuotation from "./AnnuityQuotation";
import LaunchpadSummary from "./LaunchpadSummary";
import LeftInfoCards from "./LeftInfoCards";
import "../styles/next-steps.styles.scss";
import { useState } from "react";
import TransactionExecutionPdf from "components/TransactionExecutionPdf";

const NextSteps = () => {
    const navigate = useNavigate();
    const handleBackToEdit = () => {
        navigate("/home/view-proposal");
    };
    const [isTransactionPdfOpen, setIsTransactionPdfOpen] = useState(false);
    const renderBreadcrumbs = () => {
        return (
            <div className="launchpad-goto-links">
                <span onClick={handleBackToEdit}>&lt; Back to edit your proposal </span>
                <span onClick={handleSaveExit}>
                    <FTIcon iconName="ft-save2" /> Save &amp; Exit{" "}
                </span>
            </div>
        );
    };
    const handleSaveExit = () => {
        navigate("/home");
    };
    // console.log(isTransactionPdfOpen);
    return (
        <main className="launchpad-container">
            <PageHeading pageHeading="Connecting to platforms" iconName="ft-stagg24x24" />
            <LaunchpadSummary />
            {renderBreadcrumbs()}
            <section className="launchpad-main-content">
                <LeftInfoCards />
                <div>
                    <AnnuityQuotation />
                    <div className="launchpad-right-card">
                        <div>
                            <FTIcon iconName="ft-statePension" />
                        </div>
                        <div>
                            <h6> 2. Download a sheet to set out the fund break-down and allocations </h6>
                            <p> Go to your preferred platform to undertake the trades. </p>
                            <FTButton
                                className="left-aligned-btn"
                                endIcon={<FTIcon iconName="ft-download" />}
                                onClick={() => setIsTransactionPdfOpen(true)}
                            >
                                Download document
                            </FTButton>
                        </div>
                    </div>
                </div>
            </section>
            {isTransactionPdfOpen && (
                <TransactionExecutionPdf open={isTransactionPdfOpen} onClose={() => setIsTransactionPdfOpen(false)} />
            )}
        </main>
    );
};

export default NextSteps;

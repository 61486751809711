import FTIcon from "atoms/FTIcon";
import FTNumberInput from "atoms/FTNumberInput";
import { fetchCurrencySymbol, formatter } from "utils/helper";

const CashDrawer = ({
    handleOtherCashReserve,
    handleFeeCharge,
    otherCashReserve,
    feeCharge,
    cashValue,
    totalCashReserves,
}: {
    isSubmitDisabled: boolean;
    feeCharge: any;
    cashValue: number;
    otherCashReserve: any;
    totalCashReserves: number;
    handleFeeCharge: (value: string) => void;
    handleOtherCashReserve: (value: string) => void;
}) => {
    return (
        <>
            <div className="drawer-content__title-container">
                <h5 className="drawer-content__title"> Total cash reserves </h5>
                <h5 className="drawer-content__totalAmount"> {formatter.format(totalCashReserves)} </h5>
            </div>

            <div data-testid="fees-and-charges-block" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <h6 className="breakdown-block__title"> FEES AND CHARGES </h6>
                </div>
                <div className="fees-charges">
                    <div className="fees-charges-percentage">
                        <div className="other-cash-reserves-input-field">
                            <FTNumberInput
                                id={"Fees and charges (%)"}
                                data-testid="fee-and-charges-input"
                                label={"Fees and charges (%)"}
                                onChange={(e) => handleFeeCharge(e.target.value)}
                                value={feeCharge?.value}
                                aria-label="Fees and charges (%)"
                                showErrorOutline={feeCharge.error}
                            />
                            {feeCharge.error && <p className="MuiFormHelperText-root">{feeCharge.error}</p>}
                        </div>
                        {feeCharge.value && <span className="fees-charges-percentage-symbol">%</span>}
                    </div>
                    <div className="icon-container">
                        <FTIcon iconName="ft-arrowUp" className="ft-icon" />
                    </div>
                    <div className="total-wealth">
                        <div>Cash value</div>
                        <div data-testid="total-wealth__value">{formatter.format(cashValue)}</div>
                    </div>
                </div>
                <p className="demi-bold"> Changing the percentage in fees and charges will impact the cash value </p>
            </div>

            <div data-testid="other-reserve-block" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <h6 className="breakdown-block__title"> OTHER RESERVES </h6>
                </div>

                <div className="other-cash-reserves-input-field">
                    <FTNumberInput
                        id="other-cash-reserves"
                        data-testid="other-cash-reserves"
                        label={`Other cash reserves ${fetchCurrencySymbol}`}
                        onChange={(e) => handleOtherCashReserve(e.target.value)}
                        value={otherCashReserve?.value || 0}
                        aria-label={`Other cash reserves ${fetchCurrencySymbol}`}
                        showErrorOutline={otherCashReserve.error}
                    />
                    {otherCashReserve.error && <p className="MuiFormHelperText-root">{otherCashReserve.error}</p>}
                </div>
            </div>
        </>
    );
};

export default CashDrawer;

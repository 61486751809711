import { uipApiInstance } from "auth/interceptors";
import { ADD_CONTACT_URL, GET_CONTACTS_URL, UPDATE_CONTACT_URL } from "../@constants";
import { CustomerDetailsType } from "@types";

export const fetchContacts = async () => {
    try {
        const res = await uipApiInstance({
            method: "GET",
            url: GET_CONTACTS_URL,
        });
        return res?.data?.contacts || [];
    } catch (err) {
        console.error(err);
    }
};

export const addContact = async (customerDetails: CustomerDetailsType) => {
    try {
        const res = await uipApiInstance({
            method: "POST",
            url: ADD_CONTACT_URL,
            data: customerDetails,
        });
        return res?.data?.contact || {};
    } catch (err) {
        console.error(err);
    }
};

export const updateContact = async (customerDetails: CustomerDetailsType) => {
    try {
        const res = await uipApiInstance({
            method: "POST",
            url: UPDATE_CONTACT_URL,
            data: customerDetails,
        });
        return res?.data || {};
    } catch (err) {
        console.error(err);
    }
};

import PropTypes from "prop-types";
import { FTIconProps } from "../@types";
import "./styles/atoms.styles.scss";

const FTIcon = ({ iconName, className }: FTIconProps) => {
	return <i className={`ft ${iconName} ${className || ""}`}></i>;
};
export default FTIcon;

FTIcon.propTypes = {
	icon: PropTypes.string,
	size: PropTypes.string,
	color: PropTypes.string,
};

import { SIDE_NAV_SEGEMNTS, customData } from "@constants";
import { useOktaAuth } from "@okta/okta-react";
import { addProposal, fetchProposals } from "api/proposals-api";
import { updateErrorModalState, updateFTModalState, updateGlobalLoaderState } from "auth/globalSlice";
import NewProposal from "components/NewProposal";
import SideNav from "components/SideNav";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    set_annual_displayed_proposal,
    set_displayed_proposal,
    set_goe4d_data,
    set_proposals,
    set_selected_proposal,
    set_spire_data,
    set_spire_portal_url,
} from "redux/actions";
import { hasValue } from "utils/validateInputs";

const ClientCommons = () => {
    const { authState } = useOktaAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { showFTModal } = useAppSelector((state) => state.global);
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const user = useAppSelector((state) => state.auth.user);
    const proposals = useAppSelector((state) => state.proposals);
    const isActive = (routeTo: string) => location.pathname.includes(routeTo);
    const handleSegmentClicked = (routeTo: string) => {
        dispatch(set_selected_proposal({}));
        dispatch(set_displayed_proposal({}));
        dispatch(set_annual_displayed_proposal({}));
        dispatch(set_spire_data({}));
        dispatch(set_goe4d_data({}));
        dispatch(set_spire_portal_url(""));
        navigate(routeTo);
    };
    const [addProposalLoading, setAddProposalLoading] = useState<boolean>(false);

    const prepareProposalData = (newProposalName: string) => ({
        customData: {
            ...customData,
            wealthItems:
                selectedContact?.wealthItems && selectedContact?.wealthItems?.length > 0
                    ? selectedContact?.wealthItems
                    : customData.wealthItems,
        },
        date: new Date().toISOString(),
        expiryDate: new Date(0).toISOString(),
        name: newProposalName,
        state: "Active",
        type: null,
        userId: user.userId,
        proposalId: "",
    });

    const prepareCopyProposalData = (newProposalName: string) => ({
        ...selectedProposal,
        name: newProposalName,
        proposalId: "",
        date: new Date().toJSON(),
    });

    const isOnViewProposal = () => location.pathname === "/home/view-proposal";

    const handleAddProposal = async (newProposalName: string, newProposalNameError: boolean) => {
        if (hasValue(newProposalName.trim()) && !newProposalNameError && selectedContact) {
            setAddProposalLoading(true);
            const data = isOnViewProposal()
                ? prepareCopyProposalData(newProposalName)
                : prepareProposalData(newProposalName);
            dispatch(updateGlobalLoaderState(true));
            const res = await addProposal(data, selectedContact.contactId || selectedContact._id);
            if (res?.success) {
                // set selected proposal to store
                dispatch(set_selected_proposal(res?.proposal));
                // update proposals in store
                dispatch(set_proposals([...proposals, res?.proposal]));
                // close FT Modal
                dispatch(updateFTModalState(false));
                // set loading false
                setAddProposalLoading(false);
                // route to /fact-finding/${proposal.customData.factFindingStep}
                if (isOnViewProposal()) navigate("/home/view-proposal");
                else
                    navigate(
                        `/home/fact-finding/${res.proposal?.customData?.meta?.factFindingStep.toLowerCase() || ""}`
                    );
            } else {
                // close FT Modal
                dispatch(updateFTModalState(false));
                // close error modal
                dispatch(
                    updateErrorModalState({
                        showErrorModal: true,
                        error: res,
                    })
                );
                // loading false
                setAddProposalLoading(false);
            }
            dispatch(updateGlobalLoaderState(false));
        }
    };

    useEffect(
        () => {
            dispatch(set_spire_data({}));
            dispatch(set_goe4d_data({}));
            dispatch(set_spire_portal_url(""));
            if (authState) {
                if (!authState.isAuthenticated) navigate("/?reason=logoutSuccess");
                else if (Object.values(selectedContact)?.length === 0) navigate("/advisor/landing");
                else
                    (async () => {
                        const proposals = await fetchProposals(selectedContact.contactId || selectedContact._id);
                        dispatch(set_proposals(proposals));
                    })();
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    );

    return (
        <>
            <SideNav segments={SIDE_NAV_SEGEMNTS} handleSegmentClicked={handleSegmentClicked} isActive={isActive} />
            {showFTModal && (
                <NewProposal handleAddProposal={handleAddProposal} addProposalLoading={addProposalLoading} />
            )}
            <Outlet />
        </>
    );
};

export default ClientCommons;

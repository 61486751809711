import { useEffect, useState } from "react";
import AnnuitiesTab from "./AnnuitiesTab";
import IncomeWealthTab from "./IncomeWealthTab";
import PortfolioTab from "./PortfolioTab";
import TalkingPoints from "./TalkingPoints";
import "../styles/ft-tabs.styles.scss";

const FTTabs = () => {
	const [toggleState, setToggleState] = useState(1);
	const [isProposedIncomeActive, setProposeIncomeActive] = useState<Boolean>(true);

	const toggleTab = (index: number) => {
		setToggleState(index);
	};

	useEffect(() => {
		if (toggleState === 2 || toggleState === 3) {
			setProposeIncomeActive(false);
		}
	}, [toggleState, setProposeIncomeActive])

	return (
		<div className="tab-container ft-tabs">
			<div className="tab-nav ft-tabs__ui-style-standard qf-util-bg-transparent">
				<ul className="nav nav-tabs">
					<li
						className={toggleState === 1 ? "nav-item active" : "nav-item"}
						onClick={() => toggleTab(1)}
					>
						<a className="nav-link active" id="">
							<span>Income &amp; Wealth</span>
						</a>
					</li>
					<li
						className={toggleState === 2 ? "nav-item active" : "nav-item"}
						onClick={() => toggleTab(2)}
					>
						<a className="nav-link" id="">
							<span>Portfolio</span>
						</a>
					</li>
					<li
						className={toggleState === 3 ? "nav-item active" : "nav-item"}
						onClick={() => toggleTab(3)}
					>
						<a className="nav-link" id="">
							<span>Annuities</span>
						</a>
					</li>
				</ul>
			</div>

			<div className="tab-content">
				{toggleState === 1 ? (
					<div
						role="tabpanel"
						aria-label="Income & Wealth content"
						className={toggleState === 1 ? "tab-pane active" : "tab-pane"}
					>
						<IncomeWealthTab />
					</div>
				) : (
					""
				)}
				{toggleState === 2 ? (
					<div
						role="tabpanel"
						aria-label="Portfolio content"
						className={toggleState === 2 ? "tab-pane active" : "tab-pane"}
					>
						<PortfolioTab />
					</div>
				) : (
					""
				)}
				{toggleState === 3 ? (
					<div
						role="tabpanel"
						aria-label="Annuities content"
						className={toggleState === 3 ? "tab-pane active" : "tab-pane"}
					>
						<AnnuitiesTab />
					</div>
				) : (
					""
				)}
			</div>

			<TalkingPoints isProposedIncomeActive={isProposedIncomeActive} />
		</div>
	);
};

export default FTTabs;

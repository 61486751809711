import { Tooltip } from "@mui/material";
import { FTPopoverProps, popoverUiStyleClasses } from "../@types";
import FTIcon from "./FTIcon";
import "./styles/ftpopover.styles.scss";

const FTPopover = ({ message, type = "standard" }: FTPopoverProps) => {
    return (
        <span className="ftpopover">
            <span className={popoverUiStyleClasses[type]}>
                <Tooltip arrow title={message} placement="top">
                    <span className="ft-popover__icon">
                        <FTIcon iconName="ft-info" />
                    </span>
                </Tooltip>
            </span>
        </span>
    );
};

export default FTPopover;

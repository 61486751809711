import { HEALTH_SNAPSHOT_OBJ } from "@constants";
import FTIcon from "atoms/FTIcon";
import { useAppSelector } from "hooks";
import { formatter, getSIPPValue, toHumanReadableDate } from "utils/helper";

const LaunchpadSummary = () => {
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const orgInfo = useAppSelector((state) => state.global.orgInfo);
    const selectedContact = useAppSelector((state) => state.selectedContact);

    return (
        <section className="launchpad-summary">
            <section className="launchpad-flex-split">
                <section className="launchpad-summary-left-side">
                    <h5 className="demi-bold">Your client’s retirement income </h5>
                    <section className="launchpad-flex-split">
                        <section className="launchpad-retirement-income-container">
                            <article className="launchpad-flex-split launchpad-retirement-income-prop">
                                <span>Platform</span>
                                <span>{orgInfo?.name || ""}</span>
                            </article>
                            <section className="launchpad-flex-split launchpad-retirement-income-prop">
                                <span> Health Profile </span>
                                <span>{HEALTH_SNAPSHOT_OBJ[selectedContact?.healthStatus].title}</span>
                            </section>
                        </section>
                        <section className="launchpad-retirement-income-container">
                            <section className="launchpad-flex-split launchpad-retirement-income-prop">
                                <span>SIPP Value</span>
                                <span>
                                    {formatter.format(getSIPPValue(selectedProposal?.customData?.wealthItems || []))}
                                </span>
                            </section>
                            <section className="launchpad-flex-split launchpad-retirement-income-prop">
                                <span> Indicative Portfolio </span>
                                <span>{`Model Portfolio ${selectedProposal?.customData?.recommendedPortfolioId}`}</span>
                            </section>
                        </section>
                        <section className="launchpad-retirement-income-container">
                            <section className="launchpad-flex-split launchpad-retirement-income-prop">
                                <span>Generated on</span>
                                <span>
                                    {toHumanReadableDate(selectedProposal?.customData?.meta?.lastAccessTime) || "-"}
                                </span>
                            </section>
                            <section className="launchpad-flex-split launchpad-retirement-income-prop">
                                <span>Last Calculation</span>
                                <span>{toHumanReadableDate(selectedProposal?.date) || "-"}</span>
                            </section>
                        </section>
                    </section>
                </section>
                <section className="launchpad-next-step-icon">
                    <FTIcon iconName="ft-value" />
                </section>
            </section>
        </section>
    );
};

export default LaunchpadSummary;

import { WealthItem } from "@types";
import moment from "moment";
import store from "../redux/store";

const currentState = store.getState();

export const scrollToTop = () =>
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });

export const scrollToHeader = () =>
    window.scrollTo({
        top: 95,
        behavior: "smooth",
    });

// Fetching currency specs from org data
const locale = currentState?.global?.orgInfo?.settings?.locale || "nl-NL";
const currency = currentState?.global?.orgInfo?.settings?.currencySymbol || "EUR";

export const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
});

// get currency symbol
export const getCurrencySymbol = (locale: string, currency: string) =>
    (0)
        .toLocaleString(locale, { style: "currency", currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
        .replace(/\d/g, "")
        .trim();

export const fetchCurrencySymbol = `(${getCurrencySymbol(locale, currency)})`;
export const fetchCurrencySymbolWithoutParenthesis = `${getCurrencySymbol(locale, currency)}`;

// Calulating age considering leap years and current year bithdate
export const calcAge = (birthdate: string) =>
    Math.floor((Date.now() - new Date(birthdate).getTime()) / (1000 * 60 * 60 * 24 * 365.25)) + " years";

export const calculateTotalInvestmentValue = (wealthItems: Array<WealthItem>) => {
    return wealthItems?.reduce((total, wealthItem) => Number(total) + Number(wealthItem.value || 0), 0) || 0;
};

export const calculateTotalExpenditureValue = (expenditures: Array<any>) => {
    const total = Object.keys(expenditures || {}).reduce(
        (total, expenditure: any) => total + parseFloat(expenditures[expenditure] || 0),
        0
    );
    return total;
};

export const calculateTotalExpenditureValueFormatted = (expenditures: Array<any>) => {
    const total = Object.keys(expenditures || {}).reduce(
        (total, expenditure: any) => total + parseFloat(expenditures[expenditure] || 0),
        0
    );
    return formatter.format(total);
};

export const formatAmount = (text: string) => parseFloat(text.replace("£", "").replace(",", "").trim());

export const toHumanReadableDate = (text: number | string) => moment(text).format("DD MMMM YYYY");

export const formatInputDateToISOString = (date: string) => {
    let dateArray = date?.split("/");
    dateArray = [dateArray[2], dateArray[1], dateArray[0]];
    return dateArray.join("-").concat("T00:00:00.000Z");
};

export const calculateCurrentAge = (dateOfBirth: string) =>
    new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
// remove .set('month', 0).set('date', 1)
export const toGoe4DDate = (text: number | string) => moment(text).set("month", 0).set("date", 1).format("DD-MM-YYYY");

export const calculateCurrentDate = () => moment(new Date()).set("month", 0).set("date", 1).format("DD-MM-YYYY");

export const calculateEndDate = (dateOfBirth: string, lifeSpan: number) => {
    const dobObj = moment(dateOfBirth).format("DD-MM-YYYY");
    // console.log(moment(dobObj).add(lifeSpan - 1, "years").month("January").date(1).format("DD-MM-YYYY"))
    return moment(dobObj)
        .add(lifeSpan - 1, "years")
        .set("month", 0)
        .set("date", 1)
        .format("DD-MM-YYYY");
};
// remove .set('month', 1).set('date', 1)

export const getSIPPValue = (wealthItems: Array<WealthItem>) => {
    return (wealthItems && wealthItems[0]?.value) || 0;
};

export const calculateSumOfAllOtherWealthValues = (wealthItems: Array<WealthItem>) => {
    return wealthItems?.reduce((total, wealthItem) => total + Number(wealthItem.value), 0.0) || 0;
};

export const removeNonNumerics = (num: string) => parseFloat(num.replace(/\D/g, "")) || 0.0;
export const numberToThousandSeparated = (num: string) => num.replace(/[^\d.]/g, "");

export const isValidNumber = (num: string) => {
    if (/[^\d.]/g.test(num)) {
        return num;
    }
};

export const calculateTotalExpense = (expenditures: any) => {
    const total = expenditures && Object.values(expenditures).reduce((sum, val) => Number(sum) + Number(val), 0);
    return Number(total);
};

export const calculateSumValues = (definedBenefitPensions: Array<any>) => {
    return definedBenefitPensions?.reduce((total, dbPension) => total + Number(dbPension.value), 0) || 0;
};

export const calculateSumValuesWithoutInflation = (definedBenefitPensions: Array<any>) => {
    return (
        definedBenefitPensions
            ?.filter((x) => !x.inflationAdjusted)
            .reduce((total, dbPension) => total + Number(dbPension.value), 0) || 0
    );
};

export const calculateSumValuesWithInflation = (definedBenefitPensions: Array<any>) => {
    const dbInflationAdjusted = definedBenefitPensions?.filter((db) => db.inflationAdjusted);
    return dbInflationAdjusted?.reduce((sum, db) => sum + Number(db.value || 0), 0);
};

export const calculateTotalOtherIncome = (otherIncomeItems: Array<any>) => {
    return otherIncomeItems?.reduce((total, otherIncome) => total + Number(otherIncome.value), 0) || 0;
};

export const calculateOtherWealthValues = (wealthItems: Array<any>) => {
    const otherWealthValues = wealthItems?.filter((item: any) => {
        return item.type !== "SIPP";
    });
    return otherWealthValues?.reduce((total, otherIncome) => total + Number(otherIncome.value), 0) || 0;
};

export function riskToDegrees(risk: string) {
    switch (risk) {
        case "Conservative":
            return -85;
        case "Moderate":
            return 0;
        case "Aggressive":
            return 85;
        default:
            return 0;
    }
}

export function floatFix(value: number) {
    return value ? Number(Number(value).toFixed(2)) : 0.0;
}

export const getMonthlyValue = (val: number) => {
    // console.log("mm", val)
    return val ? floatFix(val / 12) : val;
};

export const getAnnualValue = (val: number) => {
    return val ? floatFix(val * 12) : val;
};

export const toPercent = (floatingNumber: number): number => {
    return floatingNumber * 100;
};

// Income

export const totalIncome = (dBPensions: Array<any>, otherIncome: Array<any>, statePension: number) =>
    calculateSumValues(dBPensions) + calculateTotalOtherIncome(otherIncome) + Number(statePension);

export const formatWrapEllipsis = (text: string) => (text?.length > 15 ? text?.substring(0, 15) + "..." : text);

export const randomize = (value: number) => {
    const plusOrMinus = Math.floor(Math.random() * 10) % 2;
    let randomAdjustedValue = plusOrMinus
        ? value - value * Math.random() * 0.005 - 0.025
        : value + value * Math.random() * 0.005 + 0.025;
    return Number(randomAdjustedValue.toFixed(2));
};

import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import FTModal from "atoms/FTModal";
import { useAppSelector } from "hooks";

const FTContactUsModal = ({
    showContactUsModal,
    setShowContactUsModal,
}: {
    showContactUsModal: boolean;
    setShowContactUsModal: (showContactUsModal: boolean) => void;
}) => {
    const { immediate_assistance, contacts } = useAppSelector((state) => state?.bloomreachCDN?.contactInfo);
    const handleLink = (link: any) => {
        window.open(link);
    };
    const returnInnerHTML = (link: any) => {
        return link.innerHTML();
    };

    return (
        <FTModal open={showContactUsModal} handleClose={() => setShowContactUsModal(false)}>
            <div className="support-modal-body">
                <div data-testid="contact-us-popup">
                    <FTButton
                        className="close-button"
                        id="modal-close-btn"
                        data-testid="modal-close-btn"
                        endIcon={<FTIcon iconName="ft-cross" />}
                        aria-label={"Close the modal"}
                        variant="text"
                        onClick={() => setShowContactUsModal(false)}
                    >
                        Close
                    </FTButton>
                    <div className="contents">
                        <h2>Contact Us</h2>
                        <h4>Technical queries</h4>
                        <p>Connect with our support team:</p>
                        <h5 className="info-phone">{immediate_assistance?.phone}</h5>
                        <h5
                            className="info-mail"
                            onClick={() => handleLink(returnInnerHTML(immediate_assistance.email))}
                            dangerouslySetInnerHTML={{ __html: immediate_assistance.email }}
                        ></h5>
                        <hr />
                        <h4>Need help with Retirement Income Optimiser?</h4>
                        <p>
                            The UK retirement team are always excited to get your feedback and suggestions,or to help
                            with queries. Feel free to reach out to:
                        </p>

                        <div className="contents-details">
                            {contacts &&
                                contacts?.map(({ name, title, description, imageUrl, email, linkedInUrl }, _index) => (
                                    <div className="split">
                                        <div>
                                            <div className="advisor-icon">
                                                <img src={imageUrl} alt={name} />
                                            </div>
                                            <div className="advisor-details">
                                                <h6>{name}</h6>
                                                <div className="title">{title}</div>
                                                <div className="advisor-desc">{description}</div>
                                            </div>
                                        </div>

                                        <div className="contact-details">
                                            <span>Connect with me:</span>
                                            <a
                                                href="/"
                                                onClick={() => handleLink(`${"mailto:" + email}`)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FTIcon iconName="ft-email" className="icon icon--email" />
                                            </a>
                                            <a
                                                href="/"
                                                onClick={() => handleLink(linkedInUrl)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FTIcon iconName="ft-linked-in" className="icon icon--linked-in" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </FTModal>
    );
};

export default FTContactUsModal;

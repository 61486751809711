import { QuestionsProps, SideNavProps, FTModalContentTypes, HealthSnapshotDataProps, StepTitle } from "@types";

// label constants
// Header
export const HEADER_TEXT = "Want to learn more about the tool? ";
export const HEADER_LINK = "Find out more >";
export const LAST_UPDATED_ON = "Last updated on";
export const HEADER_DROPDOWN = {
    HEADING: "Support",
    OPTIONS: ["FAQ's", "Contact Us", "Resources"],
};
export const LOGOUT_TEXT = "Logout";
export const ADVISOR_HOME_BUTTON_TEXT = "Adviser Home";

// Login Page
export const LOGINPAGE = {
    STATIC_CONTENT: {
        HEADING: "See the bigger picture",
        BODY: "For many of your clients, retirement is now a reality. Using our dynamic new tool with advanced analytics will enable you to see the bigger picture, giving you all the information and knowledge you need to create a great income strategy.",
        FIG1: "Dedicated to Income",
        FIG2: "Hyper-Personalised",
        FIG3: "Expand your client base",
    },
    MIDDLE_CONTENT: {
        LEFT_SECTION: {
            HEADING: "Recommend a better retirement",
            BODY: "Use Retirement Income Optimiser to help your clients generate superior potential value from their finances, without running out of cash.",
            FIND_OUT_LINK: "https://www.franklintempleton.co.uk/about-us/defined-contribution-pensions",
        },
        RIGHT_SECTION: {
            HEADING: "Contact us to find out more",
            SUBHEADING_LINK_LABEL: "Read the FAQ",
            SUBHEADING_TEXT: " or get in touch with us:",
            CONTACT_NUMBER: "020 7073 8500",
            EMAIL_ID: "rethinking.retirement@franklintempleton.com",
        },
    },
};
export const DISCLAIMER_INFO = "This website is intended for the residents of the United Kingdom";
export const SITEMAP_ADDRESS =
    "Franklin Templeton Investments<br />Management Limited<br />Cannon Place<br />78 Cannon Street<br />LONDON EC4N 6HL";
export const SITEMAP_COLUMNS: Array<[string, Array<[string, string]>]> = [
    [
        "Support",
        [
            ["FAQs", "#"],
            ["Contact Us", "https://www.franklintempleton.co.uk/about-us/contact-us#retirement"],
        ],
    ],
    [
        "About Us",
        [
            ["Company", "https://www.franklintempleton.co.uk/about-us/our-company/index"],
            ["Retirement Team", "https://www.franklintempleton.co.uk/about-us/contact-us#retirement"],
        ],
    ],
    [
        "Our Products",
        [
            [
                "Pension Drawdown",
                "https://content-preview.ftsites.com/en-gb/resources-and-literature/client-use-materials/defined-contribution-pensions",
            ],
            ["Investment Products", "https://www.franklintempleton.co.uk/our-funds/capabilities/index"],
        ],
    ],
];
export const SITEMAP_LINKS = [
    ["Careers", "https://www.franklintempletoncareers.com/?title=careers"],
    ["Terms of use", "https://www.franklintempleton.co.uk/footer/terms-of-use"],
    ["Anti Corruption", "https://www.franklintempleton.co.uk/footer/anti-corruption"],
    ["Privacy and Cookie Notice", "https://www.franklintempleton.co.uk/footer/privacy-cookies-notice-settings"],
    ["Important Legal Information", "https://www.franklintempleton.co.uk/footer/important-legal-information"],
    ["Support", "https://www.franklintempleton.co.uk/footer/support"],
    ["Modern Slavery Statement", "https://www.franklintempleton.co.uk/footer/modern-slavery-statement"],
    ["Security Policy", "https://www.franklintempleton.co.uk/footer/security-policy"],
];
export const SOCIAL_LINKS_LABEL = "Connect with us:";
export const FOOTER_TEXT = "Copyright © 2022 Franklin Templeton Investments. All Rights Reserved.";
export const SIGN_IN_TEXT = "Sign In";
export const NEED_HELP_SIGNING_IN = "Need help signing in?";
export const FORGOT_PASSWORD = "Forgot password?";
export const HELP = "Help";
export const SIGNIN_ERROR_USERNAME = "Please enter a username";
export const SIGNIN_ERROR_PASSWORD = "Please enter a password";
export const SIGNIN_ERROR_MESSAGE1 = "We found some errors. Please review the form and make corrections.";
export const SIGNIN_ERROR_MESSAGE2 = "Unable to sign in";

// Landing
export const EXPLORE_SECTION_HEADING = "Explore our views on retirement planning";
export const LANDING_HEADING = "This is step one of your journey, let's get started...";
export const LANDING_EXISTING_CLIENT_SETION_TEXT = "Please select the appropriate client from the list below:";
export const LANDING_NEW_CLIENT_SETION_TEXT =
    "In just a few simple steps, we can begin your client's journey towards a more rewarding and sustainable retirement...";

// Create profile
export const CREATE_NEW_CLIENT = "Create New Client";

export const CREATEPROFILEPAGE = {
    CREATE_A_NEW_CLIENT_PROFILE: "Create a new client profile",
    BACK_TO_ADVISER_HOME: "Back to Adviser Home",
    INPUT_LABELS: {
        FIRST_NAME: "First Name",
        LAST_NAME: "Last Name",
        DOB: "DOB",
        POST_CODE: "Postcode",
        YES: "Yes",
        NO: "No",
        PLESE_TYPE_HERE: "Please type here",
        IS_RETIRED: "Does your client have any guaranteed income sources?",
        IS_RETIRED_TOOLTIP: "Does your client have any guaranteed income sources?",
        HAS_OUTSTANDING_DEBTS: "Does your client have outstanding debts?",
        HAS_OUTSTANDING_DEBTS_TOOLTIP: "Does your client have any guaranteed income sources?",
    },
    WARNING_MESSAGES: {
        DOB_POST_WARN:
            "Please bear in mind that you won’t be able to edit or update the DOB and postcode after your client's profile has been created.",
    },
    HEALTH_SNAPSHOT: {
        TITLE: "HEALTH SNAPSHOT",
        HEALTH_SNAPSHOT_TOOLTIP:
            "In order to assess your client's longevity and annuity requirements, we need to understand their health.",
    },
    PERSONAL_INFORMATION: "PERSONAL INFORMATION",
    PROFILE_SAVED_SUCCESSFULLY: "✓ Profile saved successfully.",
    BTN_CREATE_NEW_PROFILE: "Create new profile",
    BTN_CREATE_LOADING_TEXT: "Saving...",
    FORM_VALIDATION_ERRORS: {
        PLEASE_ENTER_A_VALID_DAY_MONTH_AND_YEAR: "Please enter a valid day, month and year",
        PLEASE_ENTER_A_VALID_POSTCODE: "Please enter a valid postcode",
        MUST_BE_VALID_UK_POSTCODE_INCLUDING_THE_SPACE: "Must be valid UK postcode (including the 'space')",
        PLEASE_USE_ALPHANUMERIC_CHARACTERS_NUMBER_OR_SPACE: "Please use alphanumeric characters, number or space.",
    },
};

export const UPDATEPROFILEPAGE = {
    BTN_UPDATE: "Update",
    PROFILE_INFORMATION: "PROFILE INFORMATION",
    DEFAULT_SIPP_VALUE: "DEFAULT SIPP VALUE",
    AMOUNT_IN_POUND: "Amount",
    OTHER_WEALTH_VALUE: "OTHER WEALTH VALUE",
    DEFINED_BENEFIT_PENSION_INCOME: "DEFINED BENEFIT PENSION INCOME",
    PROVIDER_NAME: "Provider Name",
    OTHER_INCOME: "OTHER INCOME",
    STATE_PENSION: "STATE PENSION",
    UPDATE_YOUR_CLIENT_PROFILE: " let’s update your client’s profile",
    REFERENCE: "Reference",
    TYPE: "Type",
    NAME: "Name",
};

export const QUESTIONS: Array<QuestionsProps> = [
    {
        id: "isRetired",
        defaultValue: true,
        question: "Does your client have any guaranteed income sources?",
        warningMessage:
            "Retirement Income Optimiser seeks to show the optimal retirement plan for above state pension age, it uses live data to provide a projection which can be enacted immediately.",
        popOverMessage:
            "Expectation is that your client has reached or is about to reach state pension age and has taken or is about to take all other pensions. It does not include open-ended pensions or future guaranteed incomes.",
    },
    {
        id: "hasOutstandingDebts",
        defaultValue: false,
        question: "Does your client have outstanding debts?",
        warningMessage:
            "Retirement Income Optimiser takes into account the assets of a client, from pensions and savings. It does not currently seek to provide debt management advice.",
        popOverMessage:
            "The Retirement Income Optimiser uses live data to provide an income projection, and takes into account the assets of your client, from pension and savings. It also seeks to show the optimal retirement plan for people above state pension age. Please note, it does not currently seek to provide debt management advice.",
    },
];
export const EXISTING_CLIENT_SUBMIT_TEXT = "Let's Go";

// Home
export const SIDE_NAV_SEGEMNTS: SideNavProps["segments"] = [
    {
        label: "Client Home",
        iconName: "ft-home",
        routeTo: "/home",
        datatestid: "side-nav-link-home",
    },
    {
        label: "Profile",
        iconName: "ft-profile",
        routeTo: "/profile",
        datatestid: "side-nav-link-profile",
    },
    {
        label: "Proposals",
        iconName: "ft-folderInactive",
        routeTo: "/proposals",
        datatestid: "side-nav-link-proposals",
    },
];

export const USER_SUMMARY = {
    UPDATE_PROFILE_BTN: "Update profile",
};

export const STEPS_SUMMARY = {
    HEADING: "It's quick and easy...",
    STEPS_LIST: [
        {
            stepNum: "STEP 1",
            iconName: "ft-drawerTotalWealth",
            stepName: StepTitle.wealth,
            className: "step-1",
        },
        {
            stepNum: "STEP 2",
            iconName: "ft-drawerBenefitsIncome",
            stepName: StepTitle.benefitsIncome,
            className: "step-2",
        },
        {
            stepNum: "STEP 3",
            iconName: "ft-drawerExpenditure",
            stepName: StepTitle.expenditure,
            className: "step-3",
        },
        {
            stepNum: "STEP 4",
            iconName: "ft-drawerRisk",
            stepName: StepTitle.risk,
            className: "step-4",
        },
        {
            stepNum: "STEP 5",
            iconName: "ft-basket",
            stepName: StepTitle.viewProposal,
            className: "step-5",
        },
    ],
    NEW_PROPOSALS_BTN: "Start New Proposal",
};

export const PROPOSALS_SECTION = {
    HEADING: "Recent Proposals",
    INFO_MESSAGE:
        "Please keep in mind the proposals you create are only kept for 90 days from the last time you accessed them. If you want to keep them for longer please review the proposal and download as a PDF.",
    NO_PROPOSALS_MSG: "You don’t have any proposals added yet.",
    ADD_PROPOSALS_BTN: "+ Add new proposal",
};

export const HEALTH_SNAPSHOT_OBJ: any = {
    AboveAverage: {
        id: "health-card__option--aboveaverage",
        iconName: "ft-healthy",
        title: "Good Health",
        content: "Your client is fit and healthy through regular exercise and healthy eating.",
        value: "AboveAverage",
    },
    Average: {
        id: "health-card__option--average",
        iconName: "ft-average-health",
        title: "Average Health - Non-Smoker",
        content:
            "Your client’s health is generally okay. They have given up smoking and despite not being overweight are being monitored for conditions such as high blood pressure and cholesterol.",
        value: "Average",
    },
    BelowAverage: {
        id: "health-card__option--belowaverage",
        iconName: "ft-smoker",
        title: "Average Health - Smoker",
        content:
            "Your client’s health is being monitored and managed with regular tests and they are taking prescription medicine/tablets.",
        value: "BelowAverage",
    },
};

export const HEALTH_SNAPSHOT_DATA: Array<HealthSnapshotDataProps> = Object.values(HEALTH_SNAPSHOT_OBJ);

// New Proposal
export const FT_MODAL_CONTENT: FTModalContentTypes = {
    NEW_PROPOSAL: {
        VALUE: "new_proposal",
        HEADING: "NAME YOUR NEW PROPOSAL",
        CANCEL_BTN: "Cancel",
        LETSGO_BTN: "Let's Go",
        PLACEHOLDER: "Type name here",
        ERROR_TEXT: "Please use only alphanumeric characters or space.",
        ALREADY_EXISTS_ERROR_TEXT: "A proposal with this name already exists.",
    },
    DELETE_PROPOSAL: {
        VALUE: "delete_proposal",
        HEADING: "Delete Proposal?",
        BODY: "Are you sure you want to delete",
        CANCEL_BTN: "Cancel",
        CONFIRM_BTN: "Confirm",
        DELETE_LABEL: "Delete",
    },
    DELETE_ITEM: {
        VALUE: "delete_item",
        HEADING: "Delete Item?",
        BODY: "Are you sure you want to delete",
        CANCEL_BTN: "Cancel",
        CONFIRM_BTN: "Confirm",
        DELETE_LABEL: "Delete",
    },
    SESSION_TIMEOUT: { VALUE: "session_timeout", HEADING: "" },
};

export const customData = {
    annuity: {
        content:
            "Your client’s health is generally okay. They have given up smoking and despite not being overweight are being monitored for conditions such as high blood pressure and cholesterol.",
        icon: "average-health",
        name: "Average Health - Non-Smoker",
        quote: 0.029,
        value: "Average",
    },
    cashPotAmount: null,
    totalCashReserveValue: 0,
    definedBenefitPensions: [],
    expenditures: {
        foodAndDrink: 0,
        personal: 0,
        housing: 0,
        clothingAndFootwear: 0,
        transport: 0,
        socialAndCultural: 0,
    },
    expendituresMeta: {
        isCouple: false,
        isInLondon: false,
        lifestyle: null,
    },
    isMonthlyOn: true,
    isIncludeAnnuities: true,
    meta: {
        completedSteps: [],
        factFindingStep: "Wealth",
        lastAccessTime: new Date().toISOString(),
    },
    otherIncomeItems: [],
    riskProvider: null,
    riskScore: null,
    statePensionIncome: {
        inflationAdjusted: true,
        value: null,
    },
    wealthItems: [
        {
            deletable: false,
            name: "SIPP Value",
            readonly: false,
            type: "SIPP",
            value: null,
        },
    ],
    feeRate: null,
    riskAversion: null,
    portfolioName: "",
    quotations: [],
    goalProbability: 0,
    recommendedPortfolioId: 0,
    allocationAnnuity: 0,
    recommendedConsumption: [],
    additionalPayoutAnnuity: 0,
    probabilityOfSuccess: 0,
    totalIncreaseInAnnuity: 0,
    annuityQuoteAmount: 0,
    includeAnnuities: true,
};

export const FACTFINDINGPAGE = {
    FFSUMMARYCARD: {
        TOTAL_EXPENSES: "TOTAL EXPENSES",
        MONTHLY: "Monthly",
        ANNUAL: "Annual",
        BREAKDOWN: "Breakdown",
        REGULAR_EXPENSES: "Regular Expenses",
        LIFE_EVENTS: "Life Events",
        INCOMES: "Incomes",
        EXPENSES: "Expenses",
        STEP: "Step",
    },
    LABEL_CONSTANTS: {
        AMOUNT_IN_POUND: "Amount",
        REFERENCE: "Reference",
        DELETE: "Delete",
        ADD_NEW: "+ Add New",
        PROVIDER_NAME: "Provider Name",
        TYPE: "Type",
    },
};

// Risk Suitability
export const RISK_SUITABILITY = {
    RISK_INTRO:
        "Too much light can ruin a great picture. Likewise, a negative risk exposure could limit your client's ability to deliver over the long term. Retirement Income Optimiser has been designed to help your client balance risk with an achievable income plan.",
    SENSITIVITY: {
        HEADING: "Sensitivity To Annual Increases / Decreases In Income",
        CONTENT:
            "Annuities can be a valuable component of a retirement plan, as they deliver a secure income stream in all market conditions. In blending an annuity and active investments, a plan can be resilient and offer lower volatility in income payments, whilst maintaining ways to address inflation. The sustainability of the plan can be increased, although the lifetime value which can be drawn down, may be lower. How would you describe your clients' need for income security over the lifetime of the plan?",
        OPTIONS: [
            {
                iconName: "ft-dynamic",
                title: "Dynamic",
                content:
                    "Open to fluctuations in the drawdown value which can be taken, from year to year, if it helps deliver a sustainable plan.",
                value: "Dynamic",
            },
            {
                iconName: "ft-flexible",
                title: "Flexible",
                content:
                    "Would like to see the effect of dynamic allocations on income, but prefers consistent draw down.",
                value: "Flexible",
            },
            {
                iconName: "ft-secure",
                title: "Secure",
                content: "Prefers a smooth, predictable income.",
                value: "Secure",
            },
        ],
    },
    ATTITUDE: {
        HEADING: "What Is Your Client's Attitude To Risk?",
        ICON_MESSAGE:
            "Regarding investment selections we incorporate into the proposal, it's important for us to understand your client's Attitude To Risk (ATR).",
        CONTENT: "Select the risk provider used with your client",
        PROVIDER_LABEL: `If your provider is not shown use "Other"`,
        PROFILE_LABEL: "Please select your client's risk profile",
    },
};

export const CHART_LABELS = {
    fixedLabel: "Fixed Expenses",
    discretionaryLabel: "Discretionary Expenses",
    annuityIncomeLabel: "Annuity Income",
    dbPensionLabel: "DB Pension",
    statePensionLabel: "State Pension",
    otherIncomeLabel: "Other Income",
    incomeGapLabel: "Income Gap",
    achievableIncomeLabel: "Achievable Income",
    amountLabel: "Amount",
    lifeEventsLabel: "Life Events",
    badScenarioLabel: "Bad Scenario",
    goodScenarioLabel: "Good Scenario",
    lifeEventLabel: "Life Event",
    ageLabel: "Age",
    potentialRangeLabel: "Potential Range",
    drawdownFromWealth: "Drawdown from wealth",
    targetConsumptionLabel: "Target Consumption",
    fullAnnuitizationLabel: "100% Annuitisation",
    fourPercentRuleLabel: "4% Rule",
    predefinedAllocationLabel: "Predefined Allocation",
    investableWealthLabel: "Investable Wealth",
    riskLabel: "Static Risk Based Portfolio",
    fixedIncome: "Fixed Income",
    equities: "Equities",
    equity: "Equity",
    cashLabel: "Cash",
    totalWealth: "Total Wealth",
    bonds: "Bonds",
};

export const ANNUITY_AMOUNT_CHANGE = {
    INCREASE: "increase",
    DECREASE: "decrease",
};

import React from "react";
import "./pdfProposalInfo.style.scss";
import moment from "moment";

interface PdfProposalInfProps {
    proposalName: string;
    proposalDate: string;
    finacialAdvisorName: string;
    isProposalPdf?: boolean;
}

const PdfProposalInfo: React.FC<PdfProposalInfProps> = ({
    proposalDate,
    proposalName,
    finacialAdvisorName,
    isProposalPdf,
}) => {
    return (
        <div className={`${isProposalPdf ? "pdf-content" : "transaction-pdf"} pdf-proposal-info`}>
            <div className="pdf-proposal-info__advisor-info">
                <h6 className="pdf-proposal-info__advisor-info__name__placeholder">
                    Financial advisor:
                    <span className="pdf-proposal-info__advisor-info__name">{finacialAdvisorName}</span>
                </h6>
            </div>
            <div className="pdf-proposal-info__proposal-info">
                <div className="pdf-proposal-info__proposal-info__name__container">
                    <h6 className="pdf-proposal-info__proposal-info__name__placeholder">
                        Proposal name:
                        <span className="pdf-proposal-info__proposal-info__name">{proposalName}</span>
                    </h6>
                </div>
                <div className="pdf-proposal-info__proposal-info__create-date__container">
                    <h6 className="pdf-proposal-info__proposal-info__create-date__placeholder">
                        Proposal generated date:{" "}
                        <span className="pdf-proposal-info__proposal-info__create-date">
                            {moment(proposalDate).format("Do MMMM YYYY")}
                        </span>
                    </h6>
                </div>
            </div>
        </div>
    );
};

export default PdfProposalInfo;

import parse from "html-react-parser";
import { useAppSelector } from "hooks";
import { storePrivacyDisclaimer } from "redux/reducer";
import { Box, Skeleton } from "@mui/material";

const PrivacyDisclaimer = () => {
    const privacyDisclaimerData: any = useAppSelector(storePrivacyDisclaimer);
    return privacyDisclaimerData?.length > 0 ? (
        <div className="privacy-disclaimer">{parse(privacyDisclaimerData[0])}</div>
    ) : (
        <>
            <Skeleton />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton width="24%" />
                <Skeleton width="35%" />
                <Skeleton width="39%" />
            </Box>
            <Skeleton />
        </>
    );
};

export default PrivacyDisclaimer;

import { FindingFactSummaryCardPropTypes, StepRoute } from "@types";
import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import {
    calculateOtherWealthValues,
    calculateSumOfAllOtherWealthValues,
    calculateSumValues,
    calculateTotalExpense,
    calculateTotalOtherIncome,
    formatter,
    getSIPPValue,
    totalIncome,
} from "utils/helper";
import "../styles/fact-finding.styles.scss";
import { hasValue } from "utils/validateInputs";
import { useSelectedProposal } from "hooks";

const FindingFactSummaryCard = ({
    factFindingStep,
    handleNextClicked,
    handleSaveExitClicked,
}: FindingFactSummaryCardPropTypes) => {
    const {
        isMonthlyOn,
        riskScore,
        definedBenefitPensions,
        expenditures,
        otherIncomeItems,
        statePensionIncome,
        wealthItems,
        riskAversion,
        riskProvider,
        expendituresMeta,
    } = useSelectedProposal();
    const statePensionIncomeValue = Number(statePensionIncome?.value);

    const renderFFSummaryCard: any = {
        wealth: {
            title: "TOTAL INVESTABLE WEALTH",
            iconLabel: "drawerRisk",
            value: formatter.format(calculateSumOfAllOtherWealthValues(wealthItems) || 0),
            sections: [
                {
                    title: "Breakdown",
                    rows: [
                        {
                            key: "Default SIPP value",
                            value: formatter.format(getSIPPValue(wealthItems) || 0),
                            id: "summary-sipp",
                        },
                        {
                            key: "Other Wealth Value",
                            value: formatter.format(calculateOtherWealthValues(wealthItems) || 0),
                            id: "summary-total-other-wealth",
                        },
                    ],
                },
            ],
        },
        "benefits-and-income": {
            title: "TOTAL INCOME",
            iconLabel: "drawerRisk",
            value: formatter.format(
                totalIncome(definedBenefitPensions, otherIncomeItems, statePensionIncomeValue) || 0
            ),
            sections: [
                {
                    title: "Breakdown",
                    rows: [
                        {
                            key: "Total defined benefit pension income",
                            value: formatter.format(calculateSumValues(definedBenefitPensions) || 0),
                            id: "fact-finding-summary__total-db-pension",
                        },
                        {
                            key: "Total other income",
                            value: formatter.format(calculateTotalOtherIncome(otherIncomeItems) || 0),
                            id: "fact-finding-summary__total-other-income",
                        },
                        {
                            key: "Social security / State pension",
                            value: formatter.format(statePensionIncomeValue || 0),
                            id: "fact-finding-summary__state-pension",
                        },
                    ],
                },
            ],
        },
        expenditure: {
            title: "TOTAL EXPENSES",
            iconLabel: "handMoney",
            value: formatter.format(calculateTotalExpense(expenditures) || 0),
            sections: [
                {
                    title: "Breakdown",
                    rows: [
                        {
                            key: "Regular Expenses",
                            value: formatter.format(calculateTotalExpense(expenditures) || 0),
                            id: "fact-finding-summary__total-regular-expenses",
                        },
                    ],
                },
            ],
        },
        risk: {
            title: "RISK SUITABILITY",
            iconLabel: "handMoney",
            value: riskScore,
            sections: [
                {
                    title: "Breakdown",
                    rows: [
                        {
                            key: "Income Sensitivity",
                            value: riskAversion,
                        },
                    ],
                },
            ],
        },
    };

    const selectedFFStep = factFindingStep && renderFFSummaryCard[factFindingStep];

    const isSaveExitDisabled = () => {
        switch (factFindingStep) {
            case StepRoute.wealth:
                // wealth
                if (wealthItems?.find((item: any) => !hasValue(item.name) || !hasValue(item.value))) return true;
                break;
            case StepRoute.benefitsIncome:
                // income
                if (definedBenefitPensions?.find((item: any) => !hasValue(item.provider))) return true;
                if (otherIncomeItems?.find((item: any) => !hasValue(item.name))) return true;
                if (
                    definedBenefitPensions?.length === 0 &&
                    otherIncomeItems?.length === 0 &&
                    !hasValue(statePensionIncome?.value)
                )
                    return true;
                break;
            case StepRoute.expenditure:
                // expenditure
                if (
                    Object.values(expenditures || {})?.find((item) => !hasValue(item)) ||
                    !hasValue(expendituresMeta?.lifestyle)
                )
                    return true;
                break;
            case StepRoute.risk:
                // risk
                if (!hasValue(riskAversion) || !hasValue(riskProvider) || !hasValue(riskScore)) return true;
                break;
            default:
                return false;
        }
        return false;
    };
    const isNextDisabled = () => {
        switch (factFindingStep) {
            case StepRoute.wealth:
                // wealth
                if (
                    wealthItems?.find(
                        (item: any) => !hasValue(item?.name) || !hasValue(item?.value) || !hasValue(item?.type)
                    )
                )
                    return true;
                break;
            case StepRoute.benefitsIncome:
                // income
                if (definedBenefitPensions?.find((item: any) => !hasValue(item?.provider) || !hasValue(item?.value)))
                    return true;
                if (
                    otherIncomeItems?.find(
                        (item: any) => !hasValue(item?.name) || !hasValue(item?.value) || !hasValue(item?.type)
                    )
                )
                    return true;
                if (
                    definedBenefitPensions?.length === 0 &&
                    otherIncomeItems?.length === 0 &&
                    !hasValue(statePensionIncome?.value)
                )
                    return true;
                break;
            case StepRoute.expenditure:
                // expenditure
                if (
                    Object.values(expenditures || {})?.find((item) => !hasValue(item)) ||
                    !hasValue(expendituresMeta?.lifestyle)
                )
                    return true;
                break;
            case StepRoute.risk:
                // risk
                if (!hasValue(riskAversion) || !hasValue(riskProvider) || !hasValue(riskScore)) return true;
                break;
            default:
                return false;
        }
    };

    return (
        <div className="ff-controls">
            <div className="fact-finding-summary-card" data-testid="fact-finding-summary-card">
                <div className="ff-card__header">
                    <div className="card-header-line">
                        <FTIcon className="card-header-line__icon" iconName={`ft-${selectedFFStep?.iconLabel}`} />
                        <div className="card-header__content">
                            <p>{selectedFFStep?.title}</p>
                            {factFindingStep === "risk" || factFindingStep === "wealth" ? (
                                ""
                            ) : (
                                <span>{isMonthlyOn ? "Monthly" : "Annual"}</span>
                            )}
                            <h4 className="demi-bold">{selectedFFStep?.value}</h4>
                        </div>
                    </div>
                </div>

                <div className="ff-card__body">
                    {selectedFFStep?.sections?.map((item: any, index: any) => (
                        <div key={index} className="review-breakdown">
                            <div className="review-breakdown-title demi-bold">{item.title}</div>
                            <div data-testid={item.title}>
                                {item.rows.map((row: any, idx: any) => (
                                    <div key={idx} className="label-value__label">
                                        <div className="label-value__label">{row.key}</div>
                                        <div className="label-value__value">{row.value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="fact-finding-control">
                <FTButton
                    id="save-exit-btn"
                    data-testid="save-exit-btn"
                    endIcon={<FTIcon iconName="ft-save2" />}
                    onClick={handleSaveExitClicked}
                    aria-label={"Save &amp; Exit "}
                    className="save-next-button"
                    variant="outlined"
                    disabled={isSaveExitDisabled()}
                >
                    Save &amp; Exit
                </FTButton>

                <FTButton
                    id="next-step-btn"
                    data-testid="next-step-btn"
                    endIcon={<FTIcon iconName="ft-arrow" />}
                    onClick={handleNextClicked}
                    aria-label={"Next Step"}
                    className="next-step-btn"
                    disabled={isNextDisabled()}
                >
                    Next Step
                </FTButton>
            </div>
        </div>
    );
};

export default FindingFactSummaryCard;

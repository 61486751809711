import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { FACTFINDINGPAGE } from "@constants";
import { fetchBloomreachGeneralContent } from "api";
import FTBox from "atoms/FTBox";
import FTButton from "atoms/FTButton";
import FTDeleteConfirmationDialog from "atoms/FTDeleteConfirmationDialog";
import FTIcon from "atoms/FTIcon";
import FTInput from "atoms/FTInput";
import FTNumberInput from "atoms/FTNumberInput";
import FTSwitch from "atoms/FTSwitch";
import PageHeading from "containers/PageHeading";
import { calculateSumValues, calculateTotalOtherIncome, fetchCurrencySymbol, formatter } from "utils/helper";
import { hasValue, validateOnlyLetters } from "utils/validateInputs";
import { useAppSelector, useSelectedProposal } from "hooks";
import FTDropdown from "atoms/FTDropdown";

const Income = (props: any) => {
    const { setDisplayedProposal } = useSelectedProposal();
    const displayedProposal = useAppSelector((state) => state.displayedProposal);
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const { definedBenefitPensions, otherIncomeItems, statePensionIncome, isMonthlyOn } =
        displayedProposal?.customData || {};
    const [showDBPensionProviderError, setDBPensionProviderError] = useState<Array<string | undefined>>([]);
    const [showDBPensionValueError, setDBPensionValueError] = useState<Array<string | undefined>>([]);
    const [showOtherIncomeProviderError, setOtherIncomeProviderError] = useState<Array<string | undefined>>([]);
    const [showOtherIncomeValueError, setOtherIncomeValueError] = useState<Array<string | undefined>>([]);
    const [statePensionIncomeError, setStatePensionIncomeError] = useState<string | undefined>();
    const [statePensionIncomeToggle, setStatePensionIncomeToggle] = useState(
        hasValue(selectedProposal?.customData?.statePensionIncome?.value)
    );
    const [content, setContent] = useState([]);

    const [showOtherIncomeProviderDeleteModal, setOtherIncomeProviderDeleteModal] = useState<boolean>(false);
    const [showDBIncomeProviderDeleteModal, setDBIncomeProviderDeleteModal] = useState<boolean>(false);
    const [selectedItemToDelete, setSelectedItemToDelete] = useState<{
        itemID: number;
        itemName: string;
    }>();

    const otherIncomeType = [
        { label: "Annuities", value: "Annuities" },
        { label: "Paid Employment", value: "Paid Employment" },
        { label: "Rental Income", value: "Rental Income" },
        { label: "Others", value: "Others" },
    ];

    const fetchedStatePensionContent = async () => {
        const fetchedContent = await fetchBloomreachGeneralContent();
        setContent(fetchedContent?.statePensionData);
    };
    useEffect(() => {
        fetchedStatePensionContent();
    }, []);

    const handleStatePensionToggleChange = (checked: boolean) => {
        // console.log(checked)
        setStatePensionIncomeToggle(checked);
        if (!checked) {
            let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
            updatedProposal.customData.statePensionIncome.value = null;
            setDisplayedProposal(updatedProposal);
        }
    };

    const handleAddDBPensionCard = () => {
        const newDefinedBenefitPension = {
            deletable: true,
            inflationAdjusted: true,
            provider: "",
            value: null,
        };
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.definedBenefitPensions = [...definedBenefitPensions, newDefinedBenefitPension];
        setDisplayedProposal(updatedProposal);
    };

    const handleAddOtherIncomeItem = () => {
        const newOtherIncomeItem = {
            deletable: true,
            name: "",
            value: null,
            type: "",
            inflationAdjusted: true,
        };
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.otherIncomeItems = [...otherIncomeItems, newOtherIncomeItem];
        setDisplayedProposal(updatedProposal);
    };

    const handleDeleteDBPensionCard = (idx: number) => {
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData?.definedBenefitPensions?.splice(idx, 1);
        let newErrors = [...showDBPensionProviderError];
        newErrors.splice(idx, 1);
        setDBPensionProviderError(newErrors);
        newErrors = [...showDBPensionValueError];
        newErrors.splice(idx, 1);
        setDBPensionValueError(newErrors);
        setDisplayedProposal(changedProposal);
        setDBIncomeProviderDeleteModal(false);
    };

    const handleDBIncomeItemModal = (idx: number, itemName: string) => {
        if (itemName?.trim() === "") {
            handleDeleteDBPensionCard(idx);
        } else {
            setDBIncomeProviderDeleteModal(true);
            setSelectedItemToDelete({ itemID: idx, itemName: itemName });
        }
    };

    const handleDeleteOtherIncomeItemModal = (idx: number, itemName: string) => {
        if (itemName?.trim() === "") {
            handleDeleteOtherIncomeItem(idx);
        } else {
            setOtherIncomeProviderDeleteModal(true);
            setSelectedItemToDelete({ itemID: idx, itemName: itemName });
        }
    };

    const handleDeleteOtherIncomeItem = (idx: number | undefined) => {
        if (typeof idx === "number") {
            let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
            changedProposal?.customData?.otherIncomeItems.splice(idx, 1);
            let newErrors = [...showOtherIncomeProviderError];
            newErrors.splice(idx, 1);
            setOtherIncomeProviderError(newErrors);
            newErrors = [...showOtherIncomeValueError];
            newErrors.splice(idx, 1);
            setOtherIncomeValueError(newErrors);
            setDisplayedProposal(changedProposal);
            setOtherIncomeProviderDeleteModal(false);
        }
    };

    const handleDBPensionCard = (id: string, val: string, idx: number) => {
        let updatedDefinedBenefitPensions = {};
        if (id.includes("name")) {
            let newErrors = [...showDBPensionProviderError];
            if (val !== "" && val.trim() === "") {
                newErrors[idx] = "Name cannot be empty.";
                setDBPensionProviderError(newErrors);
            } else if (!validateOnlyLetters(val, true)) {
                newErrors[idx] = "Please use alphanumeric characters, number or space.";
                setDBPensionProviderError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setDBPensionProviderError(newErrors);
            }
            updatedDefinedBenefitPensions = {
                ...definedBenefitPensions[idx],
                provider: val,
            };
        } else if (id.includes("value")) {
            let newErrors = [...showDBPensionValueError];
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setDBPensionValueError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setDBPensionValueError(newErrors);
            }
            updatedDefinedBenefitPensions = {
                ...definedBenefitPensions[idx],
                value: validatedOtherValue || null,
            };
        }
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.definedBenefitPensions[idx] = updatedDefinedBenefitPensions;
        setDisplayedProposal(changedProposal);
    };

    const handleOtherIncomeProvider = (id: string, val: string, idx: number) => {
        let updatedOtherIncomeItems = {};
        if (id.includes("name")) {
            let newErrors = [...showOtherIncomeProviderError];
            if (val !== "" && val.trim() === "") {
                newErrors[idx] = "Name cannot be empty.";
                setDBPensionProviderError(newErrors);
            } else if (!validateOnlyLetters(val, true)) {
                newErrors[idx] = "Please use alphanumeric characters, number or space.";
                setOtherIncomeProviderError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setOtherIncomeProviderError(newErrors);
            }
            updatedOtherIncomeItems = {
                ...displayedProposal?.customData?.otherIncomeItems[idx],
                name: val,
            };
        } else if (id.includes("value")) {
            let newErrors = [...showOtherIncomeValueError];
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setOtherIncomeValueError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setOtherIncomeValueError(newErrors);
            }
            updatedOtherIncomeItems = {
                ...displayedProposal?.customData?.otherIncomeItems[idx],
                value: validatedOtherValue || null,
            };
        }
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.otherIncomeItems[idx] = updatedOtherIncomeItems;
        setDisplayedProposal(changedProposal);
    };

    const handleOtherIncomeType = (e: any, idx: number) => {
        const selectedType = e.target.value;
        let updatedOtherIncomeItems = {};
        updatedOtherIncomeItems = {
            ...otherIncomeItems[idx],
            type: selectedType,
        };
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.otherIncomeItems[idx] = updatedOtherIncomeItems;
        setDisplayedProposal(changedProposal);
    };

    const handleDBPensionCardSwitch = (checked: boolean, idx: number) => {
        let updateddefinedBenefitPensions = {};
        updateddefinedBenefitPensions = {
            ...definedBenefitPensions[idx],
            inflationAdjusted: checked,
        };
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.definedBenefitPensions[idx] = updateddefinedBenefitPensions;
        setDisplayedProposal(changedProposal);
    };

    const handleOtherIncomeProviderSwitch = (checked: boolean, idx: number) => {
        let updatedOtherIncomeItems = {};
        updatedOtherIncomeItems = {
            ...otherIncomeItems[idx],
            inflationAdjusted: checked,
        };
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.otherIncomeItems[idx] = updatedOtherIncomeItems;
        setDisplayedProposal(changedProposal);
    };

    const handleStatePensionCardAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const validatedOtherValue = e.target.value;
        if (Number(validatedOtherValue) > 10000000)
            setStatePensionIncomeError(`Please enter a value less than or equal to ${formatter.format(10000000)}`);
        else setStatePensionIncomeError(undefined);
        const updatedStatePensionAmount = {
            ...statePensionIncome,
            inflationAdjusted: true,
            value: validatedOtherValue,
        };
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.statePensionIncome = updatedStatePensionAmount;
        setDisplayedProposal(changedProposal);
    };

    const renderTotalDBIncome = () => {
        return (
            <div className="db-pension-card">
                {definedBenefitPensions?.map((item: any, idx: number) => (
                    <div key={idx} className="input-group">
                        <div className="column left-input-group">
                            <div className="qf-input-text__input-box-container">
                                <FTInput
                                    id={`db-pension-card__provider_${idx}_name`}
                                    data-testid={`db-pension-card__provider_${idx}_name`}
                                    label={FACTFINDINGPAGE.LABEL_CONSTANTS.PROVIDER_NAME}
                                    showErrorOutline={showDBPensionProviderError[idx]}
                                    onChange={(e) => handleDBPensionCard(e.target.id, e.target.value, idx)}
                                    value={item?.provider}
                                />
                                {showDBPensionProviderError[idx] && (
                                    <p className="MuiFormHelperText-root">{showDBPensionProviderError[idx]}</p>
                                )}
                            </div>
                            <div className="qf-input-text__input-box-container">
                                <FTNumberInput
                                    id={`db-pension-card__provider_${idx}_value`}
                                    data-testid={`db-pension-card__provider_${idx}_value`}
                                    label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                    onChange={(e) => handleDBPensionCard(e.target.id, e.target.value, idx)}
                                    value={item?.value || ""}
                                    showErrorOutline={showDBPensionValueError[idx]}
                                />
                                {showDBPensionValueError[idx] && (
                                    <p className="MuiFormHelperText-root">{showDBPensionValueError[idx]}</p>
                                )}
                            </div>
                        </div>

                        <div className="column middle-input-group">
                            <FTSwitch
                                label="Inflation Adjusted?"
                                checked={item?.inflationAdjusted}
                                switchName={`db-pension-card__provider_${idx}_inflationAdjusted`}
                                onChange={(checked) => handleDBPensionCardSwitch(checked, idx)}
                            />
                        </div>

                        <div className="column right-input-group">
                            <FTButton
                                variant="text"
                                className="button__text-variant"
                                startIcon={<FTIcon iconName="ft-cross" className="text-variant-button-icon" />}
                                data-testid={`db-pension-card_${idx}_delete-btn`}
                                onClick={() => handleDBIncomeItemModal(idx, item?.provider)}
                            >
                                {FACTFINDINGPAGE.LABEL_CONSTANTS.DELETE}
                            </FTButton>
                        </div>
                    </div>
                ))}

                <FTButton
                    className="left-aligned-btn"
                    data-testid="db-pension-card__add-btn"
                    aria-label={FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                    onClick={handleAddDBPensionCard}
                >
                    {FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                </FTButton>

                {showDBIncomeProviderDeleteModal && (
                    <FTDeleteConfirmationDialog
                        idx={selectedItemToDelete?.itemID}
                        itemName={selectedItemToDelete?.itemName}
                        showDeleteModal={showDBIncomeProviderDeleteModal}
                        setShowDeleteModal={setDBIncomeProviderDeleteModal}
                        handleItemDelete={handleDeleteDBPensionCard}
                    />
                )}
            </div>
        );
    };

    const renderTotalOtherIncome = () => {
        return (
            <div className="other-income-provider">
                {displayedProposal?.customData?.otherIncomeItems?.map((item: any, idx: number) => (
                    <div key={idx} className="input-group">
                        <div className="column left-input-group">
                            <div className="qf-input-text__input-box-container">
                                <FTInput
                                    id={`other-income-provider_${idx}_name`}
                                    data-testid={`other-income-provider_${idx}_name`}
                                    label={FACTFINDINGPAGE.LABEL_CONSTANTS.PROVIDER_NAME}
                                    onChange={(e) => handleOtherIncomeProvider(e.target.id, e.target.value, idx)}
                                    value={item?.name}
                                    showErrorOutline={showOtherIncomeProviderError[idx]}
                                />
                                {showOtherIncomeProviderError[idx] && (
                                    <p className="MuiFormHelperText-root">{showOtherIncomeProviderError[idx]}</p>
                                )}
                            </div>
                            <div className="qf-input-text__input-box-container">
                                <FTNumberInput
                                    id={`other-income-provider_${idx}_value`}
                                    data-testid={`other-income-provider_${idx}_value`}
                                    label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                    onChange={(e) => handleOtherIncomeProvider(e.target.id, e.target.value, idx)}
                                    value={item?.value || ""}
                                    showErrorOutline={showOtherIncomeValueError[idx]}
                                />
                                {showOtherIncomeValueError[idx] && (
                                    <p className="MuiFormHelperText-root">{showOtherIncomeValueError[idx]}</p>
                                )}
                            </div>
                        </div>

                        <div className="column middle-input-group">
                            <FTDropdown
                                id={`other-income-provider_${idx}_type`}
                                data-testid={`other-income-provider_${idx}_type`}
                                value={item?.type}
                                options={otherIncomeType}
                                onChange={(e) => handleOtherIncomeType(e, idx)}
                                label={FACTFINDINGPAGE.LABEL_CONSTANTS.TYPE}
                                placeholder={FACTFINDINGPAGE.LABEL_CONSTANTS.TYPE}
                                noDataMessage=""
                            />

                            <FTSwitch
                                label="Inflation Adjusted?"
                                checked={item?.inflationAdjusted}
                                switchName={`other-income-provider_${idx}_inflationAdjusted`}
                                onChange={(checked) => handleOtherIncomeProviderSwitch(checked, idx)}
                            />
                        </div>

                        <div className="column right-input-group">
                            <FTButton
                                variant="text"
                                className="button__text-variant"
                                startIcon={<FTIcon iconName="ft-cross" className="text-variant-button-icon" />}
                                data-testid={`other-income-provider_${idx}_delete-btn`}
                                onClick={() => handleDeleteOtherIncomeItemModal(idx, item?.name)}
                            >
                                {FACTFINDINGPAGE.LABEL_CONSTANTS.DELETE}
                            </FTButton>
                        </div>
                    </div>
                ))}
                <FTButton
                    className="left-aligned-btn"
                    data-testid="other-income-provider__add-btn"
                    aria-label={FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                    onClick={handleAddOtherIncomeItem}
                >
                    {FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                </FTButton>

                {showOtherIncomeProviderDeleteModal && (
                    <FTDeleteConfirmationDialog
                        idx={selectedItemToDelete?.itemID}
                        itemName={selectedItemToDelete?.itemName}
                        showDeleteModal={showOtherIncomeProviderDeleteModal}
                        setShowDeleteModal={setOtherIncomeProviderDeleteModal}
                        handleItemDelete={handleDeleteOtherIncomeItem}
                    />
                )}
            </div>
        );
    };

    const renderStatePensionAndBenefitIncome = () => {
        return (
            <>
                <div className="toggle-section">
                    <FTSwitch
                        switchName="statePensionInflationAdjusted"
                        data-testid="statePensionInflationAdjusted"
                        aria-label="statePensionInflationAdjusted"
                        label="Are you including a state pension?"
                        checked={statePensionIncomeToggle}
                        onChange={(checked) => handleStatePensionToggleChange(checked)}
                    />
                </div>

                {statePensionIncomeToggle && (
                    <div className="card-content">
                        <p className="bold"> How much state pension will your client get? </p>
                        <div>{content && parse(content[0] || "")}</div>

                        <div className="state-pension-card__amount-container">
                            <FTNumberInput
                                id="statePensionIncome_value"
                                label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                onChange={handleStatePensionCardAmount}
                                value={statePensionIncome?.value || ""}
                                showErrorOutline={statePensionIncomeError}
                                data-testid="state-pension-card__amount"
                            />
                            {statePensionIncomeError && (
                                <p className="MuiFormHelperText-root">{statePensionIncomeError}</p>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <div>
            <PageHeading stepCount={2} pageHeading={"Income – see it through a new lens"} />
            <FTBox
                header="FindingFactHeader"
                headerTitle="Total defined benefit pension income"
                iconLabel="ft-dcPension"
                value={calculateSumValues(definedBenefitPensions)}
                isMonthlyOrAnnualLabel={isMonthlyOn ? "Monthly" : "Annual"}
                body={renderTotalDBIncome()}
            />
            <FTBox
                header="FindingFactHeader"
                headerTitle="Total other income"
                iconLabel="ft-pound-arrows"
                value={calculateTotalOtherIncome(otherIncomeItems)}
                isMonthlyOrAnnualLabel={isMonthlyOn ? "Monthly" : "Annual"}
                body={renderTotalOtherIncome()}
            />
            <FTBox
                header="FindingFactHeader"
                headerTitle="Social security / state pension"
                iconLabel="ft-statePension"
                value={statePensionIncome?.value || 0}
                isMonthlyOrAnnualLabel={isMonthlyOn ? "Monthly" : "Annual"}
                body={renderStatePensionAndBenefitIncome()}
            />
        </div>
    );
};

export default Income;

export const assetClassColors = {
    equity: "#00BFB3",
    fixedIncome: "#1446E1",
    cash: "#FF9665",
};

export interface AmountAtAge {
    age: number;
    amount: number;
}

export interface AllocationAtAge {
    age: number;
    equity: number;
    cash: number;
    fixedIncome: number;
}

export enum AssetClassType {
    none = "None",
    consumption = "Consumption",
    annuity = "Annuity",
    equity = "Equity",
    fixedIncome = "Fixed Income",
    cash = "Cash",
}

export enum MarketScenario {
    none = "None",
    good = "Good",
    average = "Average",
    bad = "Bad",
}

export enum ValueMeasure {
    none = "none",
    percentage = "Percentage",
    amount = "Amount",
}

export class ConsumptionProbabilityModel {
    age = 0;
    assetClass = "";
    value = 0;
    valueMeasure = "";
}

export type ProjectedAllocationSelectionType = "all" | "equity" | "fixedIncome" | "cash";

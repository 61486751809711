import moment from "moment";
import { CREATEPROFILEPAGE } from "../@constants";

const UKPostCodeRegexExp = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? \d[A-Z]{2}|GIR ?0A{2})$/i;
const NameRegexExp = /^[a-zA-Z0-9 ]*$/i;

export const hasValue = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
};

export const validateBirthdate = (value: string, ageLimit: number, dateFormat = "DD/MM/YYYY"): string => {
    const date = moment(value, dateFormat, true);

    if (!date.isValid() && hasValue(value)) {
        return CREATEPROFILEPAGE.FORM_VALIDATION_ERRORS.PLEASE_ENTER_A_VALID_DAY_MONTH_AND_YEAR;
    }

    if (moment().diff(date, "years") < 65 || moment().diff(date, "years") >= ageLimit) {
        return `Client needs to be 65 to ${ageLimit} for this tool`;
    }
    return "";
};

export const validateOnlyLetters = (value: string, isAcceptNumber: boolean = true): boolean => {
    if (!/^[a-zA-Z0-9][ a-zA-Z0-9]*$/.test(value)) {
        return false; // this.TEXT_WITH_NUMBER_INVALID_MSG
    }
    if (!isAcceptNumber && !/^[a-zA-Z][ a-zA-Z]*$/.test(value)) {
        return false; // this.TEXT_INVALID_MSG
    }

    return NameRegexExp.test(value);
};

export const validatePostcode = (value: string | undefined): boolean => {
    if (value === undefined) return false;
    return UKPostCodeRegexExp.test(value);
};

export const removeExtraSpaces = (value: string) => value.trim().split(/ +/).join(" ");

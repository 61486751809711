import FTFaqModal from "components/Support/FTFaqModal";
import { HEADER_LINK, LOGINPAGE } from "../../@constants";
import Link from "../../atoms/FTLink";
import { useState } from "react";

const MiddleContent = () => {
    const [showFAQModal, setShowFAQModal] = useState(false);

    const handleFaqClose = () => {
        setShowFAQModal(!showFAQModal);
    };

    return (
        <section className="middle-content">
            <article className="left-section">
                <h4>{LOGINPAGE.MIDDLE_CONTENT.LEFT_SECTION.HEADING}</h4>
                <p>{LOGINPAGE.MIDDLE_CONTENT.LEFT_SECTION.BODY}</p>
                <Link href="#">{HEADER_LINK}</Link>
            </article>
            <article className="right-section">
                <h4>{LOGINPAGE.MIDDLE_CONTENT.RIGHT_SECTION.HEADING}</h4>
                <p>
                    <span className="link" onClick={() => setShowFAQModal(true)}>
                        {LOGINPAGE.MIDDLE_CONTENT.RIGHT_SECTION.SUBHEADING_LINK_LABEL}
                    </span>
                    {showFAQModal && <FTFaqModal showFAQModal={showFAQModal} handleFaqClose={handleFaqClose} />}
                    {LOGINPAGE.MIDDLE_CONTENT.RIGHT_SECTION.SUBHEADING_TEXT}
                </p>
                <h4>{LOGINPAGE.MIDDLE_CONTENT.RIGHT_SECTION.CONTACT_NUMBER}</h4>
                <h4>
                    <a href={`mailto:${LOGINPAGE.MIDDLE_CONTENT.RIGHT_SECTION.EMAIL_ID}`}>
                        {LOGINPAGE.MIDDLE_CONTENT.RIGHT_SECTION.EMAIL_ID}
                    </a>
                </h4>
            </article>
        </section>
    );
};

export default MiddleContent;

import { useAppDispatch } from "hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { set_show_external_link_modal } from "redux/actions";
import { v4 as uuidv4 } from "uuid";
import { OKTA_DEVICE_TOKEN_KEY } from "./@constants";
import router from "./router";

function App() {
	const { i18n } = useTranslation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		// Check if device token is set and set a new one if its not set per domain
		const deviceToken = localStorage.getItem(OKTA_DEVICE_TOKEN_KEY);

		if (!deviceToken) {
			const uuid = uuidv4();

			localStorage.setItem(OKTA_DEVICE_TOKEN_KEY, uuid.substring(0, 32));
		}
        const handleExternalLinkClicked = (event: any) => {
					event.preventDefault();
					if (event.target.tagName === 'A' && event.target.getAttribute('target') === '_blank' && new URL(event.target.href).origin !== window.location.origin) {
						dispatch(set_show_external_link_modal(event.target.href));
					}
				}
		window.addEventListener("click", handleExternalLinkClicked);
		return () => window.removeEventListener("click", handleExternalLinkClicked);

	}, [dispatch, i18n]);
	return <RouterProvider router={router} />;
}

export default App;

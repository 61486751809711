import { FTMessageLiteProps } from "@types";
import FTIcon from "./FTIcon";

const FTMessageLite = ({ message, type }: FTMessageLiteProps) => {
    return (
        <section className={`ft-message-lite ${type}`}>
            <FTIcon iconName={`ft-${type}`} />
            <p>{message}</p>
        </section>
    );
};

export default FTMessageLite;

import { useAppDispatch } from "hooks";
import { updateFTModalState } from "auth/globalSlice";
import FTButton from "atoms/FTButton";
import { FT_MODAL_CONTENT, PROPOSALS_SECTION } from "@constants";
import illustrationNoDataImage from "assets/images/illustration-no-data.svg";
import "pages/styles/home.styles.scss";

const NoProposalsSection = () => {
	const dispatch = useAppDispatch();
	return (
		<section className="no-proposals-section">
			<img alt="no-proposals-img" src={illustrationNoDataImage} />
			<article className="no-proposals-content">
				<h3>{PROPOSALS_SECTION.NO_PROPOSALS_MSG}</h3>
				<FTButton
					className="left-aligned-btn"
					onClick={() =>
						dispatch(updateFTModalState(FT_MODAL_CONTENT.NEW_PROPOSAL.VALUE))
					}
				>
					{PROPOSALS_SECTION.ADD_PROPOSALS_BTN}
				</FTButton>
			</article>
		</section>
	);
};

export default NoProposalsSection;

import { FTSelectCardProps } from "@types";
import FTIcon from "./FTIcon";
import "./styles/selectable-card.styles.scss";

function FTSelectCard({ title, content, iconName, value, onSelect, isSelected }: FTSelectCardProps) {
    return (
        <div
            className={isSelected ? "_card-button active" : "_card-button"}
            data-testid={`select-card-${value}`}
            onClick={onSelect}
            data-value={value}
        >
            <div className="ft-card__ui-style-standard">
                <div className="select-card-body">
                    <div className="select-card-title">
                        <FTIcon iconName={iconName} />
                        <h6 className="select-card-title demi-bold">{title}</h6>
                    </div>
                    <p className="select-card-content small-text">{content}</p>
                </div>
            </div>
        </div>
    );
}

export default FTSelectCard;

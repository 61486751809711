import { useAppSelector } from "hooks";
import { useEffect } from "react";
import { calculateSumOfAllOtherWealthValues, calculateTotalExpense, formatter, getMonthlyValue } from "utils/helper";

const LeftInfoCards = () => {
    const selectedProposal = useAppSelector((state) => state?.selectedProposal);
    const { allocationAnnuity, recommendedConsumption } = selectedProposal?.customData;
    const annualDisplayedProposal = useAppSelector((state) => state.annualDisplayedProposal);
    const { expenditures, riskScore, wealthItems, includeAnnuities } = annualDisplayedProposal?.customData;
    const { healthStatus } = useAppSelector((state) => state?.selectedContact);
    const { healthPersonaPercentage } = useAppSelector((state) => state?.global.orgInfo?.settings);
    const { annuityQuoteAmount } = useAppSelector((state) => state?.selectedProposal?.customData);
    const { goodHealth, nonSmoker, smoker } = healthPersonaPercentage;

    const calculateAnnuityQuotePercent = () => {
        if (healthStatus === "AboveAverage") {
            return (goodHealth * 100).toFixed(2);
        } else if (healthStatus === "Average") {
            return (nonSmoker * 100).toFixed(2);
        } else if (healthStatus === "BelowAverage") {
            return (smoker * 100).toFixed(2);
        }
    };

    const annuityRate = allocationAnnuity === 0 ? 0 : calculateAnnuityQuotePercent();
    // const proposedAnnuityAmount = allocationAnnuity; // AEX-745 new flow
    const annuityQuotePercent = annuityRate;

    useEffect(() => {
        calculateAnnuityQuotePercent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allocationAnnuity, annuityQuoteAmount, wealthItems, includeAnnuities]);

    return (
        <div>
            <div className="launchpad-left-card">
                <h6>Consumption</h6>
                <div className="launchpad-key-value-line">
                    <span>Target annual expenditure</span>
                    <span>{formatter.format(Number(calculateTotalExpense(expenditures)) || 0)}</span>
                </div>
                <div className="launchpad-key-value-line">
                    <span>Recommended monthly income</span>
                    <span>
                        {recommendedConsumption
                            ? formatter.format(getMonthlyValue(recommendedConsumption[0]) || 0)
                            : null}
                    </span>
                </div>
            </div>
            <div className="launchpad-left-card">
                <h6>Portfolio</h6>
                <div className="launchpad-key-value-line">
                    <span> Investment account value </span>
                    <span>{formatter.format(Number(calculateSumOfAllOtherWealthValues(wealthItems)) || 0)}</span>
                </div>
                <div className="launchpad-key-value-line">
                    <span>Risk suitability</span>
                    <span>{riskScore}</span>
                </div>
            </div>
            <div className="launchpad-left-card">
                <h6>Annuities</h6>
                <div className="launchpad-key-value-line">
                    <span>Annuity quote</span>
                    <span>{allocationAnnuity === 0 || !includeAnnuities ? "0%" : `${annuityQuotePercent}%`}</span>
                </div>
                <div className="launchpad-key-value-line">
                    <span>Proposed increase in annuity</span>
                    <span>{formatter.format(!includeAnnuities ? 0 : allocationAnnuity)}</span>
                </div>
            </div>
        </div>
    );
};

export default LeftInfoCards;

import parse from "html-react-parser";
import { useState } from "react";
import { SITEMAP_ADDRESS, SITEMAP_COLUMNS, SITEMAP_LINKS, SOCIAL_LINKS_LABEL } from "../../@constants";
import Link from "../../atoms/FTLink";
import FTFaqModal from "components/Support/FTFaqModal";

const SiteMap = () => {
    const [showFAQModal, setShowFAQModal] = useState(false);
    const handleFaqClose = () => {
        setShowFAQModal(false);
    };

    return (
        <>
            <section className="sitemap-container">
                <article className="sitemap-columns">{parse(SITEMAP_ADDRESS)}</article>
                {SITEMAP_COLUMNS.map((col, index) => (
                    <article key={index} className="sitemap-columns">
                        <h6 className="sitemap-columns-heading">{col[0]}</h6>
                        {col[1]?.map((footer_links, key) => {
                            if (footer_links[0] === "FAQs")
                                return (
                                    <span key={key} className="link" onClick={() => setShowFAQModal(true)}>
                                        {footer_links[0]}
                                    </span>
                                );
                            return (
                                <Link key={key} href={footer_links[1]}>
                                    {footer_links[0]}
                                </Link>
                            );
                        })}
                    </article>
                ))}
            </section>
            <section className="sitemap-links">
                {SITEMAP_LINKS.map(([label, url], index) => (
                    <Link key={index} href={url}>
                        {label}
                    </Link>
                ))}
                <p>{SOCIAL_LINKS_LABEL}</p>
            </section>
            {showFAQModal && <FTFaqModal showFAQModal={showFAQModal} handleFaqClose={handleFaqClose} />}
        </>
    );
};

export default SiteMap;

import FTButton from "./FTButton";
import FTIcon from "./FTIcon";
import FTModal from "./FTModal";

const FTLogoutModal = ({
	showLogoutModal,
	setShowLogoutModal,
  handleLogout,
}: {
	showLogoutModal: boolean;
	setShowLogoutModal: (showLogoutModal: boolean) => void;
  handleLogout: () => void;
}) => {
	return (
		<FTModal
			open={showLogoutModal}
			handleClose={() => setShowLogoutModal(false)}
		>
			<div className="error-modal-body">
				<div className="flex-container">
					<div className="content-area">
						<div
							data-testid="logout-modal"
							className="logout-popup logout-modal__header"
						>
							<div className="logout-modal__header__icon">
								<FTIcon iconName="ft-logout" />
							</div>
							<h2 className="logout-modal__header__title"> Log out </h2>
							<div className="logout-modal__header__desc">
								{" "}
								Are you sure you want to log out?{" "}
							</div>
						</div>
					</div>
					<div className="buttons-area">
						<FTButton
							className="cancel-logout-btn left-aligned-btn"
              variant="outlined"
							aria-disabled="false"
							aria-label=""
              onClick={() => setShowLogoutModal(false)}
						>
							Cancel
						</FTButton>
						<FTButton
							className="logout-btn left-aligned-btn"
							aria-disabled="false"
							aria-label=""
              onClick={handleLogout}
						>
							Log out
						</FTButton>
					</div>
				</div>
			</div>
		</FTModal>
	);
};

export default FTLogoutModal;

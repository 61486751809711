import { FT_MODAL_CONTENT, HEALTH_SNAPSHOT_OBJ, PROPOSALS_SECTION, STEPS_SUMMARY, USER_SUMMARY } from "@constants";
import FTBox from "atoms/FTBox";
import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import FTMessageLite from "atoms/FTMessageLite";
import { updateFTModalState } from "auth/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import "pages/styles/home.styles.scss";
import { useNavigate } from "react-router-dom";
import { calcAge } from "utils/helper";
import ProposalsSection from "./ProposalsSection";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const orgInfo = useAppSelector((state) => state.global.orgInfo);

    const fullNameLabel = `${selectedContact.firstName} ${selectedContact.lastName}`;

    const renderUserSummary = () => (
        <section>
            <article className="user-summary-body">
                <div className="info-line">
                    <FTIcon className="info-line-icon" iconName="ft-cog" />
                    <span className="info-line-label">PLATFORM</span>
                    <span className="info-line-data">{orgInfo?.name || ""}</span>
                </div>
                <div className="info-line">
                    <FTIcon className="info-line-icon" iconName="ft-profile" />
                    <span className="info-line-label">AGE</span>
                    <span className="info-line-data">{calcAge(selectedContact?.birthdate)}</span>
                </div>
                <div className="info-line">
                    <FTIcon
                        className="info-line-icon"
                        iconName={HEALTH_SNAPSHOT_OBJ[selectedContact?.healthStatus].iconName}
                    />
                    <span className="info-line-label">HEALTH PROFILE</span>
                    <span className="info-line-data">{HEALTH_SNAPSHOT_OBJ[selectedContact?.healthStatus].title}</span>
                </div>
            </article>
            <article className="user-summary-footer">
                <FTButton
                    className="bold-button left-aligned-btn"
                    variant="outlined"
                    onClick={() => navigate("/profile")}
                >
                    <span className="btn-text-icon">
                        {USER_SUMMARY.UPDATE_PROFILE_BTN}
                        <FTIcon className="info-line-icon" iconName="ft-edit" />
                    </span>
                </FTButton>
            </article>
        </section>
    );

    const renderStepsSummary = () => (
        <section>
            <h4>{STEPS_SUMMARY.HEADING}</h4>
            <div className="steps-list">
                {STEPS_SUMMARY.STEPS_LIST.map((step, idx) => (
                    <div key={idx} className={step.className}>
                        <FTIcon className="floating-steps icon" iconName={step.iconName} />
                        <article>
                            <div className="step-num">{step.stepNum}</div>
                            <div className="step-name demi-bold">{step.stepName}</div>
                        </article>
                    </div>
                ))}
            </div>
            <FTButton
                className="bold-button left-aligned-btn"
                onClick={() => dispatch(updateFTModalState(FT_MODAL_CONTENT.NEW_PROPOSAL.VALUE))}
            >
                <span className="btn-text-icon">
                    {STEPS_SUMMARY.NEW_PROPOSALS_BTN}
                    <FTIcon iconName="ft-arrow" />
                </span>
            </FTButton>
        </section>
    );

    return (
        <main>
            <section className="summary-section">
                <FTBox header="Default" halfWidth headerTitle={fullNameLabel} body={renderUserSummary()} />
                <FTBox header="Default" halfWidth body={renderStepsSummary()} />
            </section>
            <section className="proposals-section">
                <h3>{PROPOSALS_SECTION.HEADING}</h3>
                <FTMessageLite message={PROPOSALS_SECTION.INFO_MESSAGE} type="info" />
                <ProposalsSection showLastThree={true} />
            </section>
        </main>
    );
};

export default Home;

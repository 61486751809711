import { FACTFINDINGPAGE } from "@constants";
import { Box } from "@mui/material";
import FTBox from "atoms/FTBox";
import FTButton from "atoms/FTButton";
import FTDeleteConfirmationDialog from "atoms/FTDeleteConfirmationDialog";
import FTIcon from "atoms/FTIcon";
import FTInput from "atoms/FTInput";
import FTNumberInput from "atoms/FTNumberInput";
import PageHeading from "containers/PageHeading";
import { useState } from "react";
import { useSelectedProposal } from "hooks";
import { fetchCurrencySymbol, formatter, getSIPPValue } from "utils/helper";
import { hasValue, validateOnlyLetters } from "utils/validateInputs";
import FTDropdown from "atoms/FTDropdown";

const Wealth = () => {
    const { displayedProposal, annualDisplayedProposal, setDisplayedProposal } = useSelectedProposal();
    const [showSIPPError, setShowSIPPError] = useState<string | undefined>();
    const [showOtherRefError, setShowOtherRefError] = useState<Array<string | undefined>>([]);
    const [showOtherValueError, setShowOtherValueError] = useState<Array<string | undefined>>([]);
    const [showOtherWealthDeleteModal, setOtherWealthDeleteModal] = useState<boolean>(false);
    const [selectedItemToDelete, setSelectedItemToDelete] = useState<{
        itemID: number;
        itemName: string;
    }>();

    const lastUpdatedDate = displayedProposal?.customData?.meta?.lastAccessTime;

    const otherWealthType = [
        { label: "Pension", value: "Pension" },
        { label: "Investment", value: "Investment" },
        { label: "Cash", value: "Cash" },
        { label: "Others", value: "Others" },
    ];

    const calculateTotalOtherWealth = () => {
        let total = displayedProposal?.customData?.wealthItems?.reduce((sum: number, item: any) => {
            if (item.type !== "SIPP") return sum + Number(item?.value);
            return sum;
        }, 0);
        return total;
    };

    const handleOtherValues = (id: string, val: string, idx: number) => {
        idx++;
        let changedWealthItem = {};
        if (id.includes("ref")) {
            let newErrors = [...showOtherRefError];
            if (!validateOnlyLetters(val, true)) {
                newErrors[idx] = "Please use alphanumeric characters, number or space.";
                setShowOtherRefError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setShowOtherRefError(newErrors);
            }
            changedWealthItem = {
                ...annualDisplayedProposal?.customData?.wealthItems[idx],
                name: val,
            };
        } else if (id.includes("type")) {
            changedWealthItem = {
                ...annualDisplayedProposal?.customData?.wealthItems[idx],
                type: val,
            };
        } else if (id.includes("value")) {
            let newErrors = [...showOtherValueError];
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setShowOtherValueError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setShowOtherValueError(newErrors);
            }
            changedWealthItem = {
                ...annualDisplayedProposal?.customData?.wealthItems[idx],
                value: validatedOtherValue,
            };
        }
        let changedProposal = JSON.parse(JSON.stringify(annualDisplayedProposal));
        changedProposal.customData.wealthItems[idx] = changedWealthItem;
        setDisplayedProposal(changedProposal);
    };

    const handleWealthIncomeType = (e: any, idx: number) => {
        idx++;
        const selectedType = e.target.value;
        let updatedWealthIncomeItems = {};
        updatedWealthIncomeItems = {
            ...annualDisplayedProposal?.customData?.wealthItems[idx],
            type: selectedType,
        };
        let changedProposal = JSON.parse(JSON.stringify(annualDisplayedProposal));
        changedProposal.customData.wealthItems[idx] = updatedWealthIncomeItems;
        setDisplayedProposal(changedProposal);
    };

    const handleAddOtherWealthItem = () => {
        const newWealthItem = {
            deletable: true,
            name: "",
            readonly: false,
            type: "",
            value: null,
        };
        let changedProposal = JSON.parse(JSON.stringify(annualDisplayedProposal));
        changedProposal.customData.wealthItems.push(newWealthItem);
        setDisplayedProposal(changedProposal);
    };

    const handleOtherWealthDeleteModal = (idx: number, itemName: string) => {
        setOtherWealthDeleteModal(true);
        setSelectedItemToDelete({ itemID: idx, itemName: itemName });
    };

    const handleDeleteOtherWealthItem = (idx: number) => {
        idx++;
        let changedProposal = JSON.parse(JSON.stringify(annualDisplayedProposal));
        changedProposal.customData.wealthItems.splice(idx, 1);
        let newErrors = [...showOtherRefError];
        newErrors.splice(idx - 1, 1);
        setShowOtherRefError(newErrors);
        newErrors = [...showOtherValueError];
        newErrors.splice(idx - 1, 1);
        setShowOtherValueError(newErrors);
        setDisplayedProposal(changedProposal);
        setOtherWealthDeleteModal(false);
    };

    const renderOtherValues = () => {
        return (
            <div className="other-wealth-value">
                {annualDisplayedProposal?.customData?.wealthItems?.slice(1).map((item: any, idx: number) => (
                    <div key={idx} className="grouped-input-box">
                        <div className="column left">
                            <div className="qf-input-text__input-box-container">
                                <FTInput
                                    id={`other-wealth_${idx}_ref`}
                                    label={FACTFINDINGPAGE.LABEL_CONSTANTS.REFERENCE}
                                    onChange={(e) => handleOtherValues(e.target.id, e.target.value, idx)}
                                    value={item?.name}
                                    data-testid={`other-wealth_${idx}_ref`}
                                />
                                {showOtherRefError[idx + 1] && (
                                    <p className="MuiFormHelperText-root">{showOtherRefError[idx + 1]}</p>
                                )}
                            </div>
                            <FTDropdown
                                id={`other-wealth_${idx}_type`}
                                label="Type"
                                options={otherWealthType}
                                onChange={(e) => handleWealthIncomeType(e, idx)}
                                value={item?.type}
                                placeholder="Type"
                                data-testid={`other-wealth_${idx}_type`}
                                noDataMessage=""
                            />
                            <div className="qf-input-text__input-box-container">
                                <FTNumberInput
                                    id={`other-wealth_${idx}_value`}
                                    data-testid={`other-wealth_${idx}_value`}
                                    label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                    onChange={(e) => handleOtherValues(e.target.id, e.target.value, idx)}
                                    value={item?.value}
                                />
                                {showOtherValueError[idx + 1] && (
                                    <p className="MuiFormHelperText-root">{showOtherValueError[idx + 1]}</p>
                                )}
                            </div>
                        </div>
                        <div className="column right">
                            <FTButton
                                variant="text"
                                className="button__text-variant"
                                startIcon={<FTIcon iconName="ft-cross" className="text-variant-button-icon" />}
                                data-testid="other-wealth__delete-btn"
                                onClick={() =>
                                    hasValue(item?.name)
                                        ? handleOtherWealthDeleteModal(idx, item?.name)
                                        : handleDeleteOtherWealthItem(idx)
                                }
                            >
                                {FACTFINDINGPAGE.LABEL_CONSTANTS.DELETE}
                            </FTButton>
                        </div>
                    </div>
                ))}
                <FTButton
                    className="left-aligned-btn"
                    data-testid="add-other-wealth-type-btn"
                    aria-label={FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                    onClick={handleAddOtherWealthItem}
                >
                    {FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                </FTButton>

                {showOtherWealthDeleteModal && (
                    <FTDeleteConfirmationDialog
                        idx={selectedItemToDelete?.itemID}
                        itemName={selectedItemToDelete?.itemName}
                        showDeleteModal={showOtherWealthDeleteModal}
                        setShowDeleteModal={setOtherWealthDeleteModal}
                        handleItemDelete={handleDeleteOtherWealthItem}
                    />
                )}
            </div>
        );
    };

    const handleSIPPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) > 10000000)
            setShowSIPPError(`Please enter a value less than or equal to ${formatter.format(10000000)}`);
        else setShowSIPPError(undefined);
        const changedWealthItem = {
            ...displayedProposal?.customData?.wealthItems[0],
            value: e.target.value,
        };
        let changedProposal = {
            ...displayedProposal,
            customData: {
                ...displayedProposal?.customData,
                wealthItems: [changedWealthItem, ...displayedProposal?.customData?.wealthItems?.slice(1)],
            },
        };
        setDisplayedProposal(changedProposal);
    };

    const renderDefaultSIPP = () => {
        return (
            <Box sx={{ maxWidth: 200 }}>
                <FTNumberInput
                    id="SIPPValue"
                    label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                    onChange={handleSIPPChange}
                    value={getSIPPValue(displayedProposal?.customData?.wealthItems)}
                    showErrorOutline={showSIPPError}
                    data-testid="defaultSIPP-wealth"
                />
                {showSIPPError && <p className="MuiFormHelperText-root">{showSIPPError}</p>}
            </Box>
        );
    };

    return (
        <div>
            <PageHeading stepCount={1} pageHeading={"Focus on wealth"} />
            <FTBox
                header="FindingFactHeader"
                headerTitle="DEFAULT SIPP VALUE"
                iconLabel="ft-value"
                value={getSIPPValue(displayedProposal?.customData?.wealthItems)}
                body={renderDefaultSIPP()}
                lastUpdatedDate={lastUpdatedDate}
            />
            <FTBox
                header="FindingFactHeader"
                headerTitle="OTHER WEALTH VALUE"
                iconLabel="ft-handMoney"
                value={calculateTotalOtherWealth()}
                body={renderOtherValues()}
                lastUpdatedDate={lastUpdatedDate}
            />
        </div>
    );
};

export default Wealth;

import Link from "atoms/FTLink";

const RegisterSection = () => {
  return (
    <section className="register-section">
      <p> Don't have an account? </p>
      <Link href="https://www.google.com">Register</Link> as a new user
    </section>
  );
};

export default RegisterSection;

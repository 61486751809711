import { FACTFINDINGPAGE } from "@constants";
import FTButton from "atoms/FTButton";
import FTDropdown from "atoms/FTDropdown";
import FTInput from "atoms/FTInput";
import FTModal from "atoms/FTModal";
import FTNumberInput from "atoms/FTNumberInput";
import { useState } from "react";
import { fetchCurrencySymbol, formatter } from "utils/helper";
import { hasValue, validateOnlyLetters } from "utils/validateInputs";

const AddNewOtherWealthModal = (props: any) => {
    const { idx, showAddNewModal, setShowAddNewModal, displayedProposal, setDisplayedProposal } = props;
    const { wealthItems } = displayedProposal?.customData;

    const [showOtherWealthError, setOtherWealthError] = useState({
        nameErr: "",
        typeErr: "",
        valueErr: "",
    });
    const [otherWealth, setOtherWealth] = useState({
        deletable: true,
        name: "",
        type: "",
        value: "",
        readonly: false,
    });

    const otherWealthType = [
        { label: "Pension", value: "Pension" },
        { label: "Investment", value: "Investment" },
        { label: "Cash", value: "Cash" },
        { label: "Others", value: "Others" },
    ];

    const handleOtherWealthCard = (id: string, val: string) => {
        let updatedOtherWealth = {};
        if (id.includes("name")) {
            let newError = showOtherWealthError.nameErr;
            if (!validateOnlyLetters(val, true)) {
                newError = "Please use alphanumeric characters, number or space.";
                setOtherWealthError((prev) => {
                    return { ...prev, nameErr: newError };
                });
            } else {
                setOtherWealthError((prev) => {
                    return { ...prev, nameErr: "" };
                });
            }
            updatedOtherWealth = {
                ...otherWealth,
                name: val,
            };
            setOtherWealth((prev) => {
                return { ...prev, name: val };
            });
        } else if (id.includes("value")) {
            let newError = showOtherWealthError.valueErr;
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newError = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setOtherWealthError((prev) => {
                    return { ...prev, valueErr: newError };
                });
            } else {
                setOtherWealthError((prev) => {
                    return { ...prev, valueErr: "" };
                });
            }
            updatedOtherWealth = {
                ...otherWealth,
                value: validatedOtherValue,
            };
            setOtherWealth((prev) => {
                return { ...prev, value: val };
            });
        }
    };

    const handleOtherWealthType = (e: any) => {
        const selectedType = e.target.value;
        let updatedOtherWealthItem = {};
        updatedOtherWealthItem = {
            ...otherWealth,
            type: selectedType,
        };
        setOtherWealth((prev) => {
            return { ...prev, type: selectedType };
        });
    };

    const handleAddOtherWealthCard = () => {
        const newOtherWealthItem = {
            deletable: true,
            name: otherWealth.name,
            type: otherWealth.type,
            value: otherWealth.value,
        };
        let updatedProposal = {
            ...displayedProposal,
            customData: {
                ...displayedProposal?.customData,
                wealthItems: wealthItems,
            },
        };
        updatedProposal?.customData?.wealthItems.push(newOtherWealthItem);
        setDisplayedProposal(updatedProposal);
        setShowAddNewModal({ otherWealthModal: false });
    };

    const checkFieldsStatus = () => {
        const { name, type, value } = otherWealth;
        if (!hasValue(name) && !hasValue(value) && !hasValue(type)) {
            return true;
        }
        return false;
    };

    return (
        <FTModal handleClose={() => setShowAddNewModal({ otherWealthModal: false })} open={showAddNewModal}>
            <section className="add-new-item-modal">
                <div className="content-area">
                    <div className="new-pension-popup">
                        <h5 className="modal-title">Add new wealth</h5>
                        <div className="qf-input-text">
                            <FTInput
                                id={`other-wealth-provider__name`}
                                data-testid="other-wealth-provider__name"
                                label={FACTFINDINGPAGE.LABEL_CONSTANTS.PROVIDER_NAME}
                                onChange={(e) => handleOtherWealthCard(e.target.id, e.target.value)}
                                value={otherWealth?.name}
                                showErrorOutline={showOtherWealthError.nameErr}
                            />
                            {showOtherWealthError.nameErr && (
                                <p className="MuiFormHelperText-root">{showOtherWealthError.nameErr}</p>
                            )}
                        </div>

                        <div className="qf-input-dropdown">
                            <FTDropdown
                                id={`other-wealth-provider_${idx}_type`}
                                data-testid={`other-wealth-provider_${idx}_type`}
                                value={otherWealth?.type}
                                options={otherWealthType}
                                onChange={(e) => handleOtherWealthType(e)}
                                label={FACTFINDINGPAGE.LABEL_CONSTANTS.TYPE}
                                placeholder={FACTFINDINGPAGE.LABEL_CONSTANTS.TYPE}
                                noDataMessage=""
                            />
                        </div>

                        <div className="qf-input-number">
                            <FTNumberInput
                                id={`other-wealth-provider__value`}
                                data-testid="new-other-wealth-popup__amount"
                                label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                onChange={(e) => handleOtherWealthCard(e.target.id, e.target.value)}
                                value={otherWealth?.value}
                                showErrorOutline={showOtherWealthError.valueErr}
                            />
                            {showOtherWealthError.valueErr && (
                                <p className="MuiFormHelperText-root">{showOtherWealthError.valueErr}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="buttons-area">
                    <FTButton
                        className="cancel-add-new-btn left-aligned-btn"
                        variant="outlined"
                        aria-disabled="false"
                        aria-label="Cancel"
                        onClick={() => setShowAddNewModal({ otherWealthModal: false })}
                    >
                        Cancel
                    </FTButton>
                    <FTButton
                        className="logout-btn left-aligned-btn"
                        aria-disabled="false"
                        aria-label="Confirm"
                        disabled={checkFieldsStatus()}
                        onClick={() => handleAddOtherWealthCard()}
                    >
                        Confirm
                    </FTButton>
                </div>
            </section>
        </FTModal>
    );
};

export default AddNewOtherWealthModal;

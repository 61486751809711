import { GET_ORGANIZATION_URL, GET_MARKET_PORTFOLIOS_URL } from "@constants";
import { uipApiInstance } from "auth/interceptors";

export const fetchOrganizationInfo = async (organizationName: string) => {
  try {
      const res = await uipApiInstance({
          method: "POST",
          url: GET_ORGANIZATION_URL,
          data: { organizationName },
      });
      return res?.data?.organization || {};
  } catch (err) {
      console.error(err);
  }
};

export const fetchMarketPortfolios = async () => {
    try {
        const res = await uipApiInstance({
            method: "GET",
            url: GET_MARKET_PORTFOLIOS_URL,
        });
        return res?.data?.marketportfolios || [];
    } catch (err) {
        console.error(err);
    }
  };
  

import PropTypes from "prop-types";
import { FTMessageBarProps, messageTypeClasses } from "../@types";
import FTIcon from "./FTIcon";
import "./styles/message-bar.styles.scss";

const FTMessageBar = ({
	type = "warning",
	message,
	title,
    onClose,
}: FTMessageBarProps) => {
	return (
		<div className={"message-bar " + messageTypeClasses[type]}>
			<div className="message-bar__container">
				<div className="message-bar__icon-container">
					<span className="message-bar__icon">
						<FTIcon iconName="ft-confirm" />
					</span>
				</div>
				<div className="message-bar__msg-container">
					<div
						aria-hidden="true"
						className={
							messageTypeClasses[type] === "message--warning"
								? "message-bar__display-hidden"
								: "message-bar__title demi-bold"
						}
					>
						{title}
					</div>
					<div className="message-bar__msg">{message}</div>
				</div>
				<div
					className={
						messageTypeClasses[type] === "message--warning"
							? "message-bar__display-hidden"
							: "message-bar__close-button-container"
					}
				>
					<div className="message-bar__close-button" onClick={onClose}>
						{/* <span className="message-bar__icon"> */}
							<FTIcon iconName="ft-x-switch" />
						{/* </span> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FTMessageBar;

FTMessageBar.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	type: PropTypes.string,
};

import React from "react";
import "./pdfHeader.style.scss";
interface PdfHeaderProps {
    isProposalPdf: boolean;
    title?: string;
    proposalPdfTitle?: string;
    clientName: string;
    qrCodeUrl?: string;
}
const PdfHeader: React.FC<PdfHeaderProps> = ({ isProposalPdf, title, clientName, qrCodeUrl }) => {
    return (
        <div className={`${isProposalPdf ? "pdf-content" : "transaction-pdf"} pdf-header`}>
            <div className="pdf-header__container">
                <h5 className="pdf-header__title"> {title}</h5>
                <span>for {clientName}</span>
            </div>

            {isProposalPdf && (
                <div className="pdf-header__qr-code-container">
                    <div className="pdf-header__support-content-container">
                        <div className="pdf-header__support-content">
                            The QR code allows you to connect to our support team
                        </div>
                        <div className="pdf-header__contact">
                            Contact Support at Franklin Templeton: 8878 0888
                            {}
                        </div>
                    </div>
                    <div className="pdf-header__qr-code__img">
                        <img src={qrCodeUrl} alt="Qr Code" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PdfHeader;

import { UPDATEPROFILEPAGE } from "@constants";
import PageHeadingView from "components/PageHeading";
import moment from "moment";
import { useAppSelector } from "hooks";
import { userShortInfo } from "redux/reducer";

const PageHeading = (props: any) => {
    const { pageHeading, renderOnPage, iconName } = props;
    const user = useAppSelector(userShortInfo);
    const pageHeadingMapper: any = {
        LANDING_PAGE: {
            pageHeading: `Welcome ${user.loggedInUsername}`,
            iconName: "ft-finger",
            rightSideContent: (
                <div className="ft-split__last">
                    You last logged in on
                    <br />
                    <strong>
                        {moment(user?.lastLoggedInDateTime).format("DD MMMM YYYY")} at&nbsp;
                        {moment(user?.lastLoggedInDateTime).format("HH:mm")}
                    </strong>
                </div>
            ),
        },
        UPDATE_PAGE: {
            pageHeading: `${user.loggedInUsername}, ${UPDATEPROFILEPAGE.UPDATE_YOUR_CLIENT_PROFILE}`,
            iconName: "ft-update-profile",
        },
        CREATE_PROFILE_PAGE: {
            iconName: "ft-new-client",
        },
    };

    return (
        <PageHeadingView
            pageHeading={pageHeading || pageHeadingMapper[renderOnPage]?.pageHeading}
            rightSideContent={pageHeadingMapper[renderOnPage]?.rightSideContent}
            iconName={iconName || pageHeadingMapper[renderOnPage]?.iconName}
            {...props}
        />
    );
};

export default PageHeading;

import { LoginValues } from "@types";
import FTButton from "../../atoms/FTButton";
import FTCheckbox from "../../atoms/FTCheckbox";
import FTInput from "../../atoms/FTInput";
import Link from "../../atoms/FTLink";
import {
    FORGOT_PASSWORD,
    HELP,
    SIGNIN_HELP,
    NEED_HELP_SIGNING_IN,
    SIGNIN_ERROR_PASSWORD,
    SIGNIN_ERROR_USERNAME,
    SIGN_IN_TEXT,
} from "../../@constants";
import RegisterSection from "./RegisterSection";
import "./signin.styles.scss";

const SignInView = (props: {
    loginValues: LoginValues;
    setLoginValues: (loginValues: LoginValues) => void;
    needHelpClicked: boolean;
    setNeedHelpClicked: (needHelpClicked: boolean) => void;
    handleSignIn: (loginValues: LoginValues) => void;
    isSignInLoading: boolean;
    usernameError: boolean;
    passwordError: boolean;
    signInError: string;
    onFieldsChange: (e: any) => void;
    onFieldsBlur: (e: any) => void;
    onEnter?: () => void;
}) => {
    const {
        loginValues,
        setLoginValues,
        needHelpClicked,
        setNeedHelpClicked,
        handleSignIn,
        isSignInLoading,
        usernameError,
        passwordError,
        signInError,
        onFieldsChange,
        onFieldsBlur,
        onEnter,
    } = props;
    // controller code at containers/SignIn
    return (
        <div className="signin-container">
            <section className="signin-input-block">
                <h2>{SIGN_IN_TEXT}</h2>
                {signInError !== "" && <p className="error-message">{signInError}</p>}
                <FTInput
                    data-testid="username"
                    id="username"
                    type="text"
                    label="Username"
                    className="bold-field"
                    disabled={isSignInLoading}
                    onChange={onFieldsChange}
                    value={loginValues.username}
                    onEnter={onEnter}
                    onBlur={onFieldsBlur}
                />
                {usernameError && <p className="error-message">{SIGNIN_ERROR_USERNAME}</p>}
                <FTInput
                    data-testid="password"
                    id="password"
                    type="password"
                    label="Password"
                    className="bold-field"
                    disabled={isSignInLoading}
                    onChange={onFieldsChange}
                    value={loginValues.password}
                    onEnter={onEnter}
                    onBlur={onFieldsBlur}
                />
                {passwordError && <p className="error-message">{SIGNIN_ERROR_PASSWORD}</p>}
                <FTCheckbox
                    label="Remember me"
                    checked={loginValues.rememberMe}
                    onChange={(e, checked) => setLoginValues({ ...loginValues, rememberMe: checked })}
                />
                <FTButton className="bold-button" onClick={() => handleSignIn(loginValues)} disabled={isSignInLoading}>
                    {SIGN_IN_TEXT}
                </FTButton>
                <div className="help-container">
                    <article onClick={() => setNeedHelpClicked(!needHelpClicked)}>
                        <span className="link">{NEED_HELP_SIGNING_IN}</span>
                    </article>
                    {needHelpClicked && (
                        <>
                            <Link href="#">{FORGOT_PASSWORD}</Link>
                            <Link href={SIGNIN_HELP}>{HELP}</Link>
                        </>
                    )}
                </div>
            </section>
            <RegisterSection />
        </div>
    );
};

export default SignInView;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PersonalInformationView from "components/PersonalInformation";
import { removeExtraSpaces, validateBirthdate, validateOnlyLetters, validatePostcode } from "utils/validateInputs";
import { useAppSelector } from "hooks";

const PersonalInformation = ({ customerDetails, setCustomerDetails, inputFieldError, setInputFieldError }: any) => {
    const location = useLocation();
    const orgInfo = useAppSelector(state => state.global.orgInfo)
    const [isOnUpdatePage, setIsOnUpdatePage] = useState<boolean>(false);

    useEffect(() => {
        location.pathname === "/profile" ? setIsOnUpdatePage(true) : setIsOnUpdatePage(false);
    }, [location.pathname]);

    const handleInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerDetails({
            ...customerDetails,
            [e.target.id]: e.target.value,
        });
    };

    const handleInputFieldOnBlur = (e: any) => {
        const { value, id } = e.target;
        const sanitizedText = id === "birthdate" ? value.trim().split(/ +/).join("") : removeExtraSpaces(value);
        switch (id) {
            case "firstName":
                setCustomerDetails({
                    ...customerDetails,
                    [id]: sanitizedText,
                });
                setInputFieldError({
                    ...inputFieldError,
                    firstNameError: sanitizedText !== "" && !validateOnlyLetters(sanitizedText),
                });
                break;
            case "lastName":
                setInputFieldError({
                    ...inputFieldError,
                    lastNameError: sanitizedText !== "" && !validateOnlyLetters(sanitizedText),
                });
                setCustomerDetails({
                    ...customerDetails,
                    [id]: sanitizedText,
                });
                break;
            case "birthdate":
                setCustomerDetails({
                    ...customerDetails,
                    [id]: sanitizedText,
                });
                setInputFieldError({
                    ...inputFieldError,
                    birthdateError: validateBirthdate(sanitizedText, orgInfo?.settings?.ageLimit),
                });
                break;
            case "postcode":
                setInputFieldError({
                    ...inputFieldError,
                    postcodeError: sanitizedText !== "" && !validatePostcode(sanitizedText),
                });
                setCustomerDetails({
                    ...customerDetails,
                    [id]: sanitizedText.toUpperCase().trim(),
                });
                break;

            default:
                break;
        }
    };

    const handleQuestionaireChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerDetails({
            ...customerDetails,
            [event.target.name]: event.target.value === "true",
        });
    };

    return (
        <PersonalInformationView
            customerDetails={customerDetails}
            setCustomerDetails={setCustomerDetails}
            handleInputField={handleInputField}
            inputFieldError={inputFieldError}
            isOnUpdatePage={isOnUpdatePage}
            handleQuestionaireChange={handleQuestionaireChange}
            handleInputFieldOnBlur={handleInputFieldOnBlur}
        />
    );
};

export default PersonalInformation;

import { createAction } from "@reduxjs/toolkit";
import {
    SET_SELECTED_CONTACT,
    ADD_CONTENT,
    SET_PROPOSALS,
    SET_SELECTED_PROPOSAL,
    SET_SPIRE_DATA,
    SET_GOE4D_DATA,
    SET_EDITED_PROPOSAL,
    SET_ANNUAL_DISPLAYED_PROPOSAL,
    SET_VIEW_LOADING,
    SET_MARKET_PORTFOLIOS,
    SET_SPIRE_PORTAL_URL,
    SET_SHOW_EXTERNAL_LINK_MODAL,
} from "../@constants";

export const set_selected_contact = createAction(SET_SELECTED_CONTACT, (contact) => ({
    payload: contact,
}));

export const add_content = createAction(ADD_CONTENT, (content) => ({
    payload: content,
}));

export const set_proposals = createAction(SET_PROPOSALS, (content) => ({
    payload: content,
}));

export const set_selected_proposal = createAction(SET_SELECTED_PROPOSAL, (content) => ({
    payload: content,
}));

export const set_displayed_proposal = createAction(SET_EDITED_PROPOSAL, (content) => ({
    payload: content,
}));

export const set_annual_displayed_proposal = createAction(SET_ANNUAL_DISPLAYED_PROPOSAL, (content) => ({
    payload: content,
}));

export const set_spire_data = createAction(SET_SPIRE_DATA, (content) => ({
    payload: content,
}));

export const set_goe4d_data = createAction(SET_GOE4D_DATA, (content) => ({
    payload: content,
}));

export const set_view_loading = createAction(SET_VIEW_LOADING, (content) => ({
    payload: content,
}));

export const set_market_portfolios = createAction(SET_MARKET_PORTFOLIOS, (content) => ({
    payload: content,
}));

export const set_spire_portal_url = createAction(SET_SPIRE_PORTAL_URL, (content) => ({
    payload: content,
}));

export const set_show_external_link_modal = createAction(SET_SHOW_EXTERNAL_LINK_MODAL, (content) => ({
    payload: content,
}));
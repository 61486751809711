import { FTSelectableCardProps } from "@types";
import { useEffect, useState } from "react";
import FTIcon from "./FTIcon";
import "./styles/selectable-card.styles.scss";

function FTSelectableCard({
    title,
    content,
    iconName,
    datatestid,
    value,
    setCustomerDetails,
    customerDetails,
}: FTSelectableCardProps) {
    const [isCardSelected, setIsCardSelected] = useState<boolean>(false);
    const { healthStatus } = customerDetails;

    const handleCardSelection = () => {
        setIsCardSelected(!isCardSelected);
        if (value === healthStatus) {
            setCustomerDetails((prev: any) => {
                return { ...prev, healthStatus: "" };
            });
        } else {
            setCustomerDetails((prev: any) => {
                return { ...prev, healthStatus: value };
            });
        }
    };

    useEffect(() => {
        if (healthStatus === value) {
            setIsCardSelected(true);
        } else {
            setIsCardSelected(false);
        }
    }, [healthStatus, value]);

    return (
        <div
            className={isCardSelected ? "_card-button active" : "_card-button"}
            data-testid={datatestid}
            onClick={handleCardSelection}
        >
            <div className="ft-card__ui-style-standard">
                <div className="ft-card__body">
                    <div className="ft-icon">
                        <FTIcon iconName={iconName} />
                    </div>
                    <div>
                        <div className="health-profile-selection__title">{title}</div>
                        <div className="health-profile-selection__content">{content}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FTSelectableCard;

import { CHART_LABELS, FT_MODAL_CONTENT } from "@constants";
import FTBox from "atoms/FTBox";
import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import FTLink from "atoms/FTLink";
import { updateFTModalState } from "auth/globalSlice";
import { useAppDispatch, useAppSelector, useSelectedProposal } from "hooks";
import { useNavigate } from "react-router-dom";
import { calculateTotalExpenditureValue, formatter, getMonthlyValue, totalIncome } from "utils/helper";
import IncomeGapHighchart from "./IncomeGapHighchart";
import SuccessProbability from "./SuccessProbability";
import { useState } from "react";
import ProposalPdf from "components/ProposalPdf";

const AnnualProposalDetails = ({ drawerActions }: { drawerActions: any }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [previewPdf, setPreviewPdf] = useState(false);
    const { expenditures, definedBenefitPensions, otherIncomeItems, statePensionIncome } = useAppSelector(
        (state) => state?.selectedProposal?.customData
    );
    const { isMonthlyOn } = useSelectedProposal();

    const statePensionAmount = Number(statePensionIncome?.value);
    const totalExpenditureYearly = calculateTotalExpenditureValue(expenditures);
    const totalIncomeYearly = Number(totalIncome(definedBenefitPensions, otherIncomeItems, statePensionAmount));
    const totalExpenditureMonthly = getMonthlyValue(totalExpenditureYearly);
    const totalIncomeMonthly = getMonthlyValue(totalIncomeYearly);

    const handleViewFinalProposal = () => {
        navigate("/next-steps");
    };

    const isRightControldsDisabled = () => {
        if (drawerActions?.isDrawerVisible) return true;
        return false;
    };
    const renderRightControls = () => {
        return (
            <div className="header-right-controls-set">
                <div
                    data-testid="save-as-btn"
                    className={isRightControldsDisabled() ? "disabled" : ""}
                    aria-label=""
                    aria-disabled="false"
                    onClick={() => dispatch(updateFTModalState(FT_MODAL_CONTENT.NEW_PROPOSAL.VALUE))}
                >
                    <span className="small-text">
                        <FTIcon iconName="ft-add" />
                        Save as
                    </span>
                </div>
                <div
                    data-testid="download-pdf-btn"
                    aria-label=""
                    className={isRightControldsDisabled() ? "disabled" : ""}
                    aria-disabled="false"
                >
                    <span className="small-text" onClick={() => setPreviewPdf(true)}>
                        <FTIcon iconName="ft-download" />
                        Download PDF
                    </span>
                </div>
            </div>
        );
    };
    const renderViewProposalDetails = () => {
        return (
            <div className="flex-split">
                <div className="first-col">
                    <span className="demi-bold">Client Results</span>
                    <span>
                        Your client has indicated the need for sum of{" "}
                        <b>
                            {isMonthlyOn
                                ? formatter.format(totalExpenditureMonthly)
                                : formatter.format(totalExpenditureYearly)}
                        </b>
                        {isMonthlyOn ? " per month " : " per year "} in order to address their expenditure plans.
                    </span>
                    <span>
                        Given they currently have{" "}
                        <b>
                            {isMonthlyOn ? formatter.format(totalIncomeMonthly) : formatter.format(totalIncomeYearly)}
                        </b>{" "}
                        {isMonthlyOn ? " per month " : " per year "} in income from all existing sources and those
                        identified in fact-finding, it indicates a gap meeting their lifestyle spending plans.
                    </span>
                    <FTLink href="https://www.google.com">Explore ways to improve this further</FTLink>
                    <FTButton className="left-aligned-btn same-line" onClick={handleViewFinalProposal}>
                        <FTIcon iconName="ft-eyeReveal" />
                        {" View Final Proposal "}
                    </FTButton>
                </div>
                <div className="second-col">
                    <span className="demi-bold">{CHART_LABELS.incomeGapLabel}</span>
                    <IncomeGapHighchart />
                    <div className="legend-container">
                        <div className="legend-container__left-side">
                            <div className="legend__item">
                                <div
                                    className="legend__item__pointer"
                                    style={{ "--series-color": "#FF9665" } as React.CSSProperties}
                                ></div>
                                <div className="legend__item__name">{CHART_LABELS.fixedLabel}</div>
                            </div>
                            <div className="legend__item">
                                <div
                                    className="legend__item__pointer"
                                    style={{ "--series-color": "#ffaec9" } as React.CSSProperties}
                                ></div>
                                <div className="legend__item__name">{CHART_LABELS.discretionaryLabel}</div>
                            </div>
                        </div>
                        <div className="legend-container__right-side">
                            <div className="legend__item">
                                <div
                                    className="legend__item__pointer"
                                    style={{ "--series-color": "#72DBD5" } as React.CSSProperties}
                                ></div>
                                <div className="legend__item__name">{CHART_LABELS.dbPensionLabel}</div>
                            </div>
                            <div className="legend__item">
                                <div
                                    className="legend__item__pointer"
                                    style={{ "--series-color": "#8760EF" } as React.CSSProperties}
                                ></div>
                                <div className="legend__item__name">{CHART_LABELS.statePensionLabel}</div>
                            </div>
                            <div className="legend__item">
                                <div
                                    className="legend__item__pointer"
                                    style={{ "--series-color": "#F0B4F9" } as React.CSSProperties}
                                ></div>
                                <div className="legend__item__name">{CHART_LABELS.otherIncomeLabel}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third-col">
                    <SuccessProbability />
                </div>
            </div>
        );
    };

    return (
        <>
            <FTBox
                header="SimpleWithRighControls"
                headerTitle="Annual Proposal Details"
                body={renderViewProposalDetails()}
                rightControls={renderRightControls}
            />
            {previewPdf && <ProposalPdf open={previewPdf} onClose={() => setPreviewPdf(false)} />}
        </>
    );
};

export default AnnualProposalDetails;

import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { Contact, BloomreachCDN } from "./models";
import {
    set_selected_contact,
    add_content,
    set_proposals,
    set_selected_proposal,
    set_spire_data,
    set_goe4d_data,
    set_displayed_proposal,
    set_annual_displayed_proposal,
    set_view_loading,
    set_market_portfolios,
    set_spire_portal_url,
    set_show_external_link_modal,
} from "./actions";
import globalReducer from "../auth/globalSlice";
import authReducer from "../auth/authSlice";
import { RootState } from "./store";
import { Proposal } from "./models";
import { SpireIndicativeQuoteType } from "@types";

const initialState = {
    bloomreachCDN: {} as BloomreachCDN,
    selectedContact: {} as Contact,
    proposals: [] as Array<Proposal>,
    selectedProposal: {} as Proposal,
    displayedProposal: {} as Proposal,
    annualDisplayedProposal: {} as Proposal,
    spireIndicativeQuote: {} as SpireIndicativeQuoteType,
    goe4dTranslation: {} as any, // Goe4dTranslationType, TODO @types LN 505
    viewProposalLoading: false,
    marketPortfolios: [],
    spirePortalURL: "",
    showExternalLinkModal: "",
};

const selectedContactReducer = createReducer(initialState.selectedContact, (builder) => {
    builder
        .addCase(set_selected_contact, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const proposalsReducer = createReducer(initialState.proposals, (builder) => {
    builder
        .addCase(set_proposals, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const selectedProposalReducer = createReducer(initialState.selectedProposal, (builder) => {
    builder
        .addCase(set_selected_proposal, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const displayedProposalReducer = createReducer(initialState.displayedProposal, (builder) => {
    builder
        .addCase(set_displayed_proposal, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const annualDisplayedProposalReducer = createReducer(initialState.annualDisplayedProposal, (builder) => {
    builder
        .addCase(set_annual_displayed_proposal, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const bloomreachCDNReducer = createReducer(initialState.bloomreachCDN, (builder) => {
    builder
        .addCase(add_content, (state, action) => ({
            ...state,
            ...action.payload,
        }))
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const spireIndicativeQuoteReducer = createReducer(initialState.spireIndicativeQuote, (builder) => {
    builder
        .addCase(set_spire_data, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const goe4dTranslationReducer = createReducer(initialState.goe4dTranslation, (builder) => {
    builder
        .addCase(set_goe4d_data, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const viewProposalLoadingReducer = createReducer(initialState.viewProposalLoading, (builder) => {
    builder
        .addCase(set_view_loading, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const marketPortfoliosReducer = createReducer(initialState.marketPortfolios, (builder) => {
    builder
        .addCase(set_market_portfolios, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const spirePortalURLReducer = createReducer(initialState.spirePortalURL, (builder) => {
    builder
        .addCase(set_spire_portal_url, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const showExternalLinkModalReducer = createReducer(initialState.showExternalLinkModal, (builder) => {
    builder
        .addCase(set_show_external_link_modal, (_, action) => action.payload)
        // add more reducer cases here
        .addDefaultCase((state) => state);
});

const reducer = combineReducers({
    selectedContact: selectedContactReducer,
    proposals: proposalsReducer,
    selectedProposal: selectedProposalReducer,
    displayedProposal: displayedProposalReducer,
    annualDisplayedProposal: annualDisplayedProposalReducer,
    bloomreachCDN: bloomreachCDNReducer,
    spireIndicativeQuote: spireIndicativeQuoteReducer,
    goe4dTranslation: goe4dTranslationReducer,
    viewProposalLoading: viewProposalLoadingReducer,
    marketPortfolios: marketPortfoliosReducer,
    spirePortalURL: spirePortalURLReducer,
    showExternalLinkModal: showExternalLinkModalReducer,
    global: globalReducer,
    auth: authReducer,
    // users: userManagementReducer,
    // translations: translationsReducer,
});

export default reducer;

// export data

export const storeLoginDisclaimer = (state: RootState) => state?.bloomreachCDN?.findingFactDisclaimers?.loginDisclaimer;
export const storePrivacyDisclaimer = (state: RootState) =>
    state?.bloomreachCDN?.findingFactDisclaimers?.PrivacyDisclaimer;
export const userShortInfo = (state: RootState) => state?.auth?.user;

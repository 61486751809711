import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function FTGlobalLoader({ open, position }: { open: boolean, position?: string }) {
    return (
        <div>
            <Backdrop
                sx={{
                    position: position || "fixed",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1040,
                    backgroundColor: "rgba(0,0,0,0.75)",
                }}
                open={open}
            >
                <CircularProgress sx={{ width: '60px !important', height: '60px !important' }} />
            </Backdrop>
        </div>
    );
}

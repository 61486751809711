import { useAppSelector, useSelectedProposal } from "hooks";
import FTButton from "atoms/FTButton";
import { FACTFINDINGPAGE } from "@constants";

const MonthlyAnnualToggle = () => {
	const annualDisplayedProposal = useAppSelector(state => state.annualDisplayedProposal);
	const { isMonthlyOn, adjustValuesIfIsMonthlyOn, setOnlyDisplayedProposal } = useSelectedProposal();
	const toggleIsMonthlyOn = () => {
		// console.log(isMonthlyOn)
		setOnlyDisplayedProposal(adjustValuesIfIsMonthlyOn(!isMonthlyOn, annualDisplayedProposal));
	};

	return (
		<div className="period-buttons">
			<FTButton
				className="monthly-toggle"
				variant={isMonthlyOn ? "contained" : "outlined"}
				onClick={toggleIsMonthlyOn}
				aria-disabled="false"
				aria-label="Monthly"
			>
				{FACTFINDINGPAGE.FFSUMMARYCARD.MONTHLY}
			</FTButton>
			<FTButton
				className="annual-toggle"
				variant={!isMonthlyOn ? "contained" : "outlined"}
				onClick={toggleIsMonthlyOn}
				aria-disabled="false"
				aria-label="Annual"
			>
				{FACTFINDINGPAGE.FFSUMMARYCARD.ANNUAL}
			</FTButton>
		</div>
	);
};

export default MonthlyAnnualToggle;

import { useAppDispatch, useAppSelector } from "hooks";
import { set_show_external_link_modal } from "redux/actions";
import { hasValue } from "utils/validateInputs";
import FTButton from "./FTButton";
import FTModal from "./FTModal";
import "./styles/redirect-link-confirm-modal.styles.scss";

const FTExternalLinkModal = () => {
	const dispatch = useAppDispatch();
	const showExternalLinkModal = useAppSelector(
		(state) => state.showExternalLinkModal
	);
	return (
		<FTModal
			open={hasValue(showExternalLinkModal)}
			handleClose={() => dispatch(set_show_external_link_modal(""))}
		>
			<div className="error-modal-body">
				<div className="flex-container">
					<div className="content-area">
						<div
							data-testid="redirect-outside-popup"
							className="redirect-outside-popup"
						>
							<h3 className="logout-modal__header__title">
								{" "}
								Leaving Franklin Templeton{" "}
							</h3>
							<p>
								Clicking OK below will take you to an independent site.
								Information and services provided on this independent site are
								not reviewed by, guaranteed by, or endorsed by Franklin
								Templeton or its affiliates. Please keep in mind that this
								independent site's terms and conditions, privacy and security
								policies, or other legal information may be different from those
								of Franklin Templeton's site. Franklin Templeton is not liable
								for any direct or indirect technical or system issues,
								consequences or damages arising from your use of this
								independent website.
							</p>
						</div>
						<div className="redirect-outside-popup-buttons">
							<FTButton
								className="cancel-logout-btn"
								variant="outlined"
								aria-disabled="false"
								aria-label=""
								onClick={() => dispatch(set_show_external_link_modal(""))}
							>
								Cancel
							</FTButton>
							<FTButton
								className="logout-btn"
								aria-disabled="false"
								aria-label=""
								onClick={() => {
                  dispatch(set_show_external_link_modal(""));
                  window.open(showExternalLinkModal);
                }}
							>
								OK
							</FTButton>
						</div>
					</div>
				</div>
			</div>
		</FTModal>
	);
};

export default FTExternalLinkModal;

import { FACTFINDINGPAGE } from "@constants";
import FTButton from "atoms/FTButton";
import FTDeleteConfirmationDialog from "atoms/FTDeleteConfirmationDialog";
import FTIcon from "atoms/FTIcon";
import FTNumberInput from "atoms/FTNumberInput";
import { useSelectedProposal } from "hooks";
import { useEffect, useState } from "react";
import {
    calculateOtherWealthValues,
    calculateSumOfAllOtherWealthValues,
    fetchCurrencySymbol,
    formatter,
    getSIPPValue,
    toHumanReadableDate,
} from "utils/helper";
import { hasValue } from "utils/validateInputs";
import AddNewOtherWealthModal from "./AddNewOtherWealthModal";

const WealthDrawer = ({
    isSubmitDisabled,
    setSubmitDisabled,
}: {
    isSubmitDisabled: boolean;
    setSubmitDisabled: (err: boolean) => void;
}) => {
    const {
        displayedProposal,
        setDisplayedProposal,
        wealthItems,
        // meta: { lastAccessTime },
    } = useSelectedProposal();
    const [showSIPPError, setShowSIPPError] = useState<string | undefined>();
    const [showOtherValueError, setShowOtherValueError] = useState<Array<string | undefined>>([]);
    const [showOtherWealthDeleteModal, setOtherWealthDeleteModal] = useState<boolean>(false);
    const [selectedItemToDelete, setSelectedItemToDelete] = useState<{
        itemID: number;
        itemName: string;
    }>();
    const [showAddNewItemModal, setAddNewItemModal] = useState({
        otherWealthModal: false,
    });

    useEffect(() => {
        // disableClosingDrawer
        if (
            hasValue(showSIPPError) ||
            showOtherValueError.includes(`Please enter a value less than or equal to ${formatter.format(10000000)}`)
        ) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [showOtherValueError, showSIPPError, setSubmitDisabled]);

    const handleSIPPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) > 10000000)
            setShowSIPPError(`Please enter a value less than or equal to ${formatter.format(10000000)}`);
        else setShowSIPPError(undefined);
        const changedWealthItem = {
            ...displayedProposal?.customData?.wealthItems[0],
            value: e.target.value,
        };
        let changedProposal = {
            ...displayedProposal,
            customData: {
                ...displayedProposal?.customData,
                wealthItems: [changedWealthItem, ...displayedProposal?.customData?.wealthItems?.slice(1)],
            },
        };
        setDisplayedProposal(changedProposal);
    };

    const handleOtherValues = (id: string, val: string, idx: number) => {
        idx++;
        let changedWealthItem = {};
        if (id.includes("value")) {
            let newErrors = [...showOtherValueError];
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setShowOtherValueError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setShowOtherValueError(newErrors);
            }
            changedWealthItem = {
                ...wealthItems[idx],
                value: validatedOtherValue,
            };
        }
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.wealthItems[idx] = changedWealthItem;
        setDisplayedProposal(changedProposal);
    };

    const handleOtherWealthDeleteModal = (idx: number, itemName: string) => {
        setOtherWealthDeleteModal(true);
        setSelectedItemToDelete({ itemID: idx, itemName: itemName });
    };

    const handleDeleteOtherWealthItem = (idx: number) => {
        idx++;
        let changedProposal = {
            ...displayedProposal,
            customData: {
                ...displayedProposal?.customData,
                wealthItems: displayedProposal?.customData?.wealthItems,
            },
        };
        changedProposal.customData.wealthItems.splice(idx, 1);
        let newErrors = [...showOtherValueError];
        newErrors.splice(idx - 1, 1);
        setShowOtherValueError(newErrors);
        setDisplayedProposal(changedProposal);
        setOtherWealthDeleteModal(false);
    };

    const handleAddNewOtherWealthModal = () => {
        setAddNewItemModal((prev) => {
            return { ...prev, otherWealthModal: true };
        });
    };

    return (
        <>
            <div className="drawer-content__title-container">
                <h5 className="drawer-content__title"> Total investable wealth </h5>
                <h5 className="drawer-content__totalAmount">
                    {" "}
                    {formatter.format(calculateSumOfAllOtherWealthValues(wealthItems))}{" "}
                </h5>
            </div>

            <div data-testid="wealth-drawer__sipp__container" className="breakdown-block">
                <div className="sipp__title__container breakdown-block__title-container">
                    <h6 className="sipp__title breakdown-block__title"> DEFAULT SIPP VALUE </h6>
                    <div className="breakdown-block__sub-title">
                        {" "}
                        Total: {formatter.format(getSIPPValue(wealthItems))}{" "}
                    </div>
                </div>
                <div className="as-of"> As at {toHumanReadableDate(new Date().toISOString())} </div>
                <div className="sipp__input-group">
                    <div className="delete-group">
                        <div className="name"> Provider Name: SIPP Value </div>
                        <div className="input-flex-container">
                            <div className="qf-input-number">
                                <FTNumberInput
                                    id="SIPPValue"
                                    label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                    onChange={handleSIPPChange}
                                    value={getSIPPValue(wealthItems)}
                                    showErrorOutline={showSIPPError}
                                    data-testid="defaultSIPP-wealth"
                                />
                                {showSIPPError && <p className="MuiFormHelperText-root">{showSIPPError}</p>}
                            </div>
                            <FTButton
                                variant="text"
                                className="button__text-variant"
                                startIcon={<FTIcon iconName="ft-cross" className="text-variant-button-icon" />}
                                data-testid="drawer-item__delete-btn"
                                // onClick={() => handleOtherWealthDeleteModal(idx, item?.name)}
                            >
                                {FACTFINDINGPAGE.LABEL_CONSTANTS.DELETE}
                            </FTButton>
                        </div>
                        <div className="drawer-input-group--sub-text"> Type: SIPP </div>
                    </div>
                </div>
            </div>

            <div data-testid="wealth-drawer__other-wealth__container" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <h6 className="breakdown-block__title"> OTHER WEALTH VALUE </h6>
                    <div className="breakdown-block__sub-title">
                        {" "}
                        Total: {formatter.format(calculateOtherWealthValues(wealthItems))}{" "}
                    </div>
                </div>
                <p className="as-of"> As at {toHumanReadableDate(new Date().toISOString())} </p>

                {wealthItems?.slice(1).map((item: any, idx: number) => (
                    <div key={idx} className="ft-drawer-input-group">
                        <div className="delete-group">
                            <div className="name"> Provider Name: {item?.name} </div>
                            <div className="input-flex-container">
                                <div className="qf-input-number">
                                    <FTNumberInput
                                        id={`other-wealth_${idx}_value`}
                                        data-testid={`other-wealth_${idx}_value`}
                                        label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                        onChange={(e) => handleOtherValues(e.target.id, e.target.value, idx)}
                                        value={item?.value}
                                    />
                                    {showOtherValueError[idx + 1] && (
                                        <p className="MuiFormHelperText-root">{showOtherValueError[idx + 1]}</p>
                                    )}
                                </div>
                                <FTButton
                                    variant="text"
                                    className="button__text-variant"
                                    startIcon={<FTIcon iconName="ft-cross" className="text-variant-button-icon" />}
                                    data-testid="drawer-item__delete-btn"
                                    onClick={() => handleOtherWealthDeleteModal(idx, item?.name)}
                                >
                                    {FACTFINDINGPAGE.LABEL_CONSTANTS.DELETE}
                                </FTButton>
                            </div>
                            <div className="drawer-input-group--sub-text">Type: {item?.type}</div>
                        </div>
                    </div>
                ))}
                <FTButton
                    className="left-aligned-btn"
                    data-testid="add-new-other-wealth-btn"
                    aria-label={FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                    onClick={handleAddNewOtherWealthModal}
                >
                    {FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                </FTButton>

                {showOtherWealthDeleteModal && (
                    <FTDeleteConfirmationDialog
                        idx={selectedItemToDelete?.itemID}
                        itemName={selectedItemToDelete?.itemName}
                        showDeleteModal={showOtherWealthDeleteModal}
                        setShowDeleteModal={setOtherWealthDeleteModal}
                        handleItemDelete={handleDeleteOtherWealthItem}
                    />
                )}

                {showAddNewItemModal.otherWealthModal && (
                    <AddNewOtherWealthModal
                        displayedProposal={displayedProposal}
                        setDisplayedProposal={setDisplayedProposal}
                        showAddNewModal={showAddNewItemModal.otherWealthModal}
                        setShowAddNewModal={setAddNewItemModal}
                        handleOtherValues={handleOtherValues}
                    />
                )}
            </div>
        </>
    );
};

export default WealthDrawer;

import { CREATEPROFILEPAGE, UPDATEPROFILEPAGE } from "@constants";
import { useOktaAuth } from "@okta/okta-react";
import { updateContact } from "api";
import FTButton from "atoms/FTButton";
import ClientCommons from "containers/ClientCommons";
import HealthSnapshot from "containers/HealthSnapshot";
import PageHeading from "containers/PageHeading";
import PersonalInformation from "containers/PersonalInformation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set_selected_contact } from "redux/actions";
import { useAppSelector } from "hooks";
import ProfileInformationSection from "./ProfileInformation";
import "../styles/client-update-profile.styles.scss";

const UpdateProfile = () => {
    const { authState } = useOktaAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedContact = useAppSelector((state) => state.selectedContact);
    const { _id: contactId } = selectedContact;
    const [customerDetails, setCustomerDetails] = useState(selectedContact);
    const [isUpdateProfileLoading, setIsUpdateProfileLoading] = useState<boolean | string>(false);
    const [inputFieldError, setInputFieldError] = useState({
        firstNameError: false,
        lastNameError: false,
    });

    useEffect(() => {
        if (authState)
            if (!authState.isAuthenticated) navigate("/?reason=logoutSuccess");
            else if (Object.values(selectedContact)?.length === 0) navigate("/advisor/landing");
    }, [authState, selectedContact, navigate]);

    const handleUpdateNewClientBtn = async () => {
        try {
            setIsUpdateProfileLoading(true);
            const response = await updateContact({
                ...customerDetails,
                contactId: contactId,
            });
            setIsUpdateProfileLoading(false);
            console.log("Server response", response);
            if (response?.success) {
                const responseData = response?.contact;
                dispatch(set_selected_contact(responseData));
                navigate("/home?reason=updateClientProfileSuccess");
            }
        } catch (err) {
            // TODO:: Handle Error senario
            console.error("Update client error", err);
            // we can show the modal when there is error, until we can show alert
            // update: error component is added and can be used here
        }
    };

    const checkFieldsStatus = () => {
        const { healthStatus, firstName, lastName } = customerDetails;
        const { firstNameError, lastNameError } = inputFieldError;
        if (healthStatus === "" || firstName === "" || lastName === "" || firstNameError || lastNameError) {
            return true;
        }
        return false;
    };

    return (
        <>
            <ClientCommons />
            <main className="ft-page-container ft-page-container-update">
                <PageHeading renderOnPage="UPDATE_PAGE" />

                <PersonalInformation
                    setCustomerDetails={setCustomerDetails}
                    customerDetails={customerDetails}
                    inputFieldError={inputFieldError}
                    setInputFieldError={setInputFieldError}
                />

                <HealthSnapshot setCustomerDetails={setCustomerDetails} customerDetails={customerDetails} />

                <ProfileInformationSection />

                <FTButton
                    data-testid="update-client-profile-btn"
                    className="ft-button"
                    onClick={handleUpdateNewClientBtn}
                    disabled={checkFieldsStatus() || Boolean(isUpdateProfileLoading)}
                >
                    {isUpdateProfileLoading ? CREATEPROFILEPAGE.BTN_CREATE_LOADING_TEXT : UPDATEPROFILEPAGE.BTN_UPDATE}
                    {}
                </FTButton>
            </main>
        </>
    );
};

export default UpdateProfile;

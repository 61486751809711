import { FACTFINDINGPAGE } from "@constants";
import FTButton from "atoms/FTButton";
import FTDeleteConfirmationDialog from "atoms/FTDeleteConfirmationDialog";
import FTIcon from "atoms/FTIcon";
import FTNumberInput from "atoms/FTNumberInput";
import { useEffect, useState } from "react";
import { calculateSumValues, calculateTotalOtherIncome, fetchCurrencySymbol, formatter } from "utils/helper";
import AddNewDBPensionsModal from "./AddNewDBPensionsModal";
import AddNewOtherIncomeModal from "./AddNewOtherIncomeModal";
import { hasValue } from "utils/validateInputs";
import { useSelectedProposal } from "hooks";

const IncomeDrawer = ({
    isSubmitDisabled,
    setSubmitDisabled,
    totalIncome,
}: {
    isSubmitDisabled: boolean;
    setSubmitDisabled: (err: boolean) => void;
    totalIncome: number;
}) => {
    const { displayedProposal, setDisplayedProposal } = useSelectedProposal();
    const { definedBenefitPensions, otherIncomeItems, statePensionIncome, isMonthlyOn } = useSelectedProposal();

    const [showDBPensionProviderError, setDBPensionProviderError] = useState<Array<string | undefined>>([]);
    const [showDBPensionValueError, setDBPensionValueError] = useState<Array<string | undefined>>([]);
    const [showDBIncomeProviderDeleteModal, setDBIncomeProviderDeleteModal] = useState<boolean>(false);

    const [showOtherIncomeProviderError, setOtherIncomeProviderError] = useState<Array<string | undefined>>([]);
    const [showOtherIncomeValueError, setOtherIncomeValueError] = useState<Array<string | undefined>>([]);
    const [showOtherIncomeProviderDeleteModal, setOtherIncomeProviderDeleteModal] = useState<boolean>(false);

    const [statePensionIncomeError, setStatePensionIncomeError] = useState<string | undefined>();
    const [statePensionIncomeAmount, setStatePensionIncomeAmount] = useState(statePensionIncome?.value);

    const [selectedItemToDelete, setSelectedItemToDelete] = useState<{
        itemID: number;
        itemName: string;
    }>();

    const [showAddNewItemModal, setAddNewItemModal] = useState({
        dbPensionModal: false,
        otherIncomeModal: false,
    });

    useEffect(() => {
        // disableClosingDrawer
        if (
            hasValue(statePensionIncomeError) ||
            showDBPensionValueError.includes(
                `Please enter a value less than or equal to ${formatter.format(10000000)}`
            ) ||
            showOtherIncomeValueError.includes(
                `Please enter a value less than or equal to ${formatter.format(10000000)}`
            )
        ) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
        // console.log("showOtherIncomeValueError", showOtherIncomeValueError)
    }, [statePensionIncomeError, setSubmitDisabled, showDBPensionValueError, showOtherIncomeValueError]);

    const handleStatePensionCardAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const validatedOtherValue = e.target.value;
        if (Number(validatedOtherValue) > 10000000)
            setStatePensionIncomeError(`Please enter a value less than or equal to ${formatter.format(10000000)}`);
        else setStatePensionIncomeError(undefined);
        const updatedStatePensionAmount = {
            ...statePensionIncome,
            inflationAdjusted: true,
            value: validatedOtherValue,
        };
        setStatePensionIncomeAmount(validatedOtherValue);
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.statePensionIncome = updatedStatePensionAmount;
        setDisplayedProposal(changedProposal);
    };

    const getStatePensionIncomeAmount = () => {
        return statePensionIncomeAmount;
    };

    const handleAddNewDBPensionModal = () => {
        setAddNewItemModal((prev) => {
            return { ...prev, dbPensionModal: true };
        });
    };

    const handleAddNewOtherIncomeModal = () => {
        setAddNewItemModal((prev) => {
            return { ...prev, otherIncomeModal: true };
        });
    };

    const handleDeleteDBPensionModal = (idx: number, itemName: string) => {
        setDBIncomeProviderDeleteModal(true);
        setSelectedItemToDelete({ itemID: idx, itemName: itemName });
    };

    const handleDeleteOtherIncomeModal = (idx: number, itemName: string) => {
        setOtherIncomeProviderDeleteModal(true);
        setSelectedItemToDelete({ itemID: idx, itemName: itemName });
    };

    const handleDBPensionCard = (id: string, val: string, idx: number) => {
        let updatedDefinedBenefitPensions = {};
        if (id.includes("value")) {
            let newErrors = [...showDBPensionValueError];
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setDBPensionValueError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setDBPensionValueError(newErrors);
            }
            updatedDefinedBenefitPensions = {
                ...definedBenefitPensions[idx],
                value: validatedOtherValue || null,
            };
        }
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.definedBenefitPensions[idx] = updatedDefinedBenefitPensions;
        setDisplayedProposal(changedProposal);
    };

    const handleOtherIncomeCard = (id: string, val: string, idx: number) => {
        let updatedOtherIncomeItems = {};
        if (id.includes("value")) {
            let newErrors = [...showOtherIncomeValueError];
            const validatedOtherValue = val;
            if (Number(validatedOtherValue) > 10000000) {
                newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
                setOtherIncomeValueError(newErrors);
            } else {
                newErrors[idx] = undefined;
                setOtherIncomeValueError(newErrors);
            }
            updatedOtherIncomeItems = {
                ...otherIncomeItems[idx],
                value: validatedOtherValue || null,
            };
        }
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.otherIncomeItems[idx] = updatedOtherIncomeItems;
        setDisplayedProposal(changedProposal);
    };

    const handleDeleteDBPensionItem = (idx: number) => {
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData?.definedBenefitPensions?.splice(idx, 1);
        let newErrors = [...showDBPensionProviderError];
        newErrors.splice(idx, 1);
        setDBPensionProviderError(newErrors);
        newErrors = [...showDBPensionValueError];
        newErrors.splice(idx, 1);
        setDBPensionValueError(newErrors);
        setDisplayedProposal(changedProposal);
        setDBIncomeProviderDeleteModal(false);
    };

    const handleDeleteOtherIncomeItem = (idx: number | undefined) => {
        if (typeof idx === "number") {
            let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
            changedProposal?.customData?.otherIncomeItems.splice(idx, 1);
            let newErrors = [...showOtherIncomeProviderError];
            newErrors.splice(idx, 1);
            setOtherIncomeProviderError(newErrors);
            newErrors = [...showOtherIncomeValueError];
            newErrors.splice(idx, 1);
            setOtherIncomeValueError(newErrors);
            setDisplayedProposal(changedProposal);
            setOtherIncomeProviderDeleteModal(false);
        }
    };

    const totalDefinedBenefitPensions = calculateSumValues(definedBenefitPensions);
    const totalOtherIncome = calculateTotalOtherIncome(otherIncomeItems);

    return (
        <>
            <div className="drawer-content__title-container">
                <h5 className="drawer-content__title">
                    Total income <span className="monthly-annual-label">{isMonthlyOn ? "(Monthly)" : "(Annual)"}</span>
                </h5>
                <h5 className="drawer-content__totalAmount">{formatter.format(totalIncome)}</h5>
            </div>

            <div data-testid="income-drawer__db-pension__container" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <div className="breakdown-block__title">
                        {" "}
                        DEFINED BENEFIT PENSION{" "}
                        <span className="monthly-annual-label">{isMonthlyOn ? "(Monthly)" : "(Annual)"}</span>
                    </div>
                    <div className="breakdown-block__sub-title">
                        Total: {formatter.format(totalDefinedBenefitPensions)}
                    </div>
                </div>
                {definedBenefitPensions?.map((item: any, idx: number) => (
                    <div key={idx} className="ft-drawer-input-group">
                        <div className="delete-group">
                            <div className="name"> Provider Name: {item?.provider} </div>
                            <div className="input-flex-container">
                                <div className="qf-input-number">
                                    <FTNumberInput
                                        id={`db-pension-card__provider_${idx}_value`}
                                        data-testid={`db-pension-card__provider_${idx}_value`}
                                        label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                        onChange={(e) => handleDBPensionCard(e.target.id, e.target.value, idx)}
                                        value={item?.value}
                                    />
                                    {showDBPensionValueError[idx + 1] && (
                                        <p className="MuiFormHelperText-root">{showDBPensionValueError[idx + 1]}</p>
                                    )}
                                </div>
                                <FTButton
                                    variant="text"
                                    className="button__text-variant"
                                    startIcon={<FTIcon iconName="ft-cross" className="text-variant-button-icon" />}
                                    data-testid="drawer-item__delete-btn"
                                    onClick={() => handleDeleteDBPensionModal(idx, item?.provider)}
                                >
                                    {FACTFINDINGPAGE.LABEL_CONSTANTS.DELETE}
                                </FTButton>
                            </div>
                            <div className="drawer-input-group--sub-text">
                                {" "}
                                Inflation: {item?.inflationAdjusted ? "Adjusted" : "Not adjusted"}{" "}
                            </div>
                        </div>
                    </div>
                ))}
                <FTButton
                    className="left-aligned-btn"
                    data-testid="add-db-pension-btn"
                    aria-label={FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                    onClick={handleAddNewDBPensionModal}
                >
                    {FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                </FTButton>

                {showAddNewItemModal.dbPensionModal && (
                    <AddNewDBPensionsModal
                        displayedProposal={displayedProposal}
                        setDisplayedProposal={setDisplayedProposal}
                        showAddNewModal={showAddNewItemModal.dbPensionModal}
                        setShowAddNewModal={setAddNewItemModal}
                        handleDBPensionCard={handleDBPensionCard}
                    />
                )}

                {showDBIncomeProviderDeleteModal && (
                    <FTDeleteConfirmationDialog
                        idx={selectedItemToDelete?.itemID}
                        itemName={selectedItemToDelete?.itemName}
                        showDeleteModal={showDBIncomeProviderDeleteModal}
                        setShowDeleteModal={setDBIncomeProviderDeleteModal}
                        handleItemDelete={handleDeleteDBPensionItem}
                    />
                )}
            </div>

            <div data-testid="income-drawer__other-income__container" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <div className="breakdown-block__title">
                        OTHER INCOME
                        <span className="monthly-annual-label">{isMonthlyOn ? "(Monthly)" : "(Annual)"}</span>
                    </div>
                    <div className="breakdown-block__sub-title">Total: {formatter.format(totalOtherIncome)}</div>
                </div>

                {otherIncomeItems?.map((item: any, idx: number) => (
                    <div key={idx} className="ft-drawer-input-group">
                        <div className="delete-group">
                            <div className="name"> Provider Name: {item?.name} </div>
                            <div className="input-flex-container">
                                <div className="qf-input-number">
                                    <FTNumberInput
                                        id={`other-income__provider_${idx}_value`}
                                        data-testid={`db-pension-card__provider_${idx}_value`}
                                        label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                                        onChange={(e) => handleOtherIncomeCard(e.target.id, e.target.value, idx)}
                                        value={item?.value}
                                    />
                                    {showDBPensionValueError[idx + 1] && (
                                        <p className="MuiFormHelperText-root">{showDBPensionValueError[idx + 1]}</p>
                                    )}
                                </div>
                                <FTButton
                                    variant="text"
                                    className="button__text-variant"
                                    startIcon={<FTIcon iconName="ft-cross" className="text-variant-button-icon" />}
                                    data-testid="drawer-item__delete-btn"
                                    onClick={() => handleDeleteOtherIncomeModal(idx, item?.name)}
                                >
                                    {FACTFINDINGPAGE.LABEL_CONSTANTS.DELETE}
                                </FTButton>
                            </div>
                            <div className="drawer-input-group--sub-text"> Type: {item?.type} </div>
                            <div className="drawer-input-group--sub-text">
                                {" "}
                                Inflation: {item?.inflationAdjusted ? "Adjusted" : "Not adjusted"}{" "}
                            </div>
                        </div>
                    </div>
                ))}

                <FTButton
                    className="left-aligned-btn"
                    data-testid="add-db-pension-btn"
                    aria-label={FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                    onClick={handleAddNewOtherIncomeModal}
                >
                    {FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                </FTButton>

                {showAddNewItemModal.otherIncomeModal && (
                    <AddNewOtherIncomeModal
                        displayedProposal={displayedProposal}
                        setDisplayedProposal={setDisplayedProposal}
                        showAddNewModal={showAddNewItemModal.otherIncomeModal}
                        setShowAddNewModal={setAddNewItemModal}
                        handleOtherIncomeCard={handleOtherIncomeCard}
                    />
                )}

                {showOtherIncomeProviderDeleteModal && (
                    <FTDeleteConfirmationDialog
                        idx={selectedItemToDelete?.itemID}
                        itemName={selectedItemToDelete?.itemName}
                        showDeleteModal={showOtherIncomeProviderDeleteModal}
                        setShowDeleteModal={setOtherIncomeProviderDeleteModal}
                        handleItemDelete={handleDeleteOtherIncomeItem}
                    />
                )}
            </div>

            <div data-testid="income-drawer__state-pension__container" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <div className="breakdown-block__title">
                        SOCIAL SECURITY / STATE PENSION
                        <span className="monthly-annual-label">{isMonthlyOn ? "(Monthly)" : "(Annual)"}</span>
                    </div>
                </div>

                <div data-testid="state-pension-card__amount-container" className="qf-input-number">
                    <FTNumberInput
                        id="statePensionIncome_value"
                        label={`${FACTFINDINGPAGE.LABEL_CONSTANTS.AMOUNT_IN_POUND} ${fetchCurrencySymbol}`}
                        onChange={handleStatePensionCardAmount}
                        value={getStatePensionIncomeAmount()}
                        showErrorOutline={statePensionIncomeError}
                        data-testid="state-pension-card__amount"
                    />
                    {statePensionIncomeError && <p className="MuiFormHelperText-root">{statePensionIncomeError}</p>}
                </div>
            </div>
        </>
    );
};

export default IncomeDrawer;

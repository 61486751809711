import { CHART_LABELS } from "@constants";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "hooks";
import {
    calculateSumValues,
    calculateTotalExpense,
    calculateTotalOtherIncome,
    formatter,
    totalIncome,
} from "utils/helper";
HighchartsMore(Highcharts); // init module

const IncomeGapHighchart = () => {
    const { definedBenefitPensions, expenditures, otherIncomeItems, statePensionIncome } = useAppSelector(
        (state) => state?.selectedProposal?.customData
    );
    const fixedExpenseFactor = useAppSelector((state) => state?.global?.orgInfo?.settings?.fixedExpenseFactor);

    const totalExpenditure = calculateTotalExpense(expenditures);
    const fixedRatioValue = fixedExpenseFactor * totalExpenditure;
    const discretionaryRatioValue = (1 - fixedExpenseFactor) * totalExpenditure;

    const totalDBPension = calculateSumValues(definedBenefitPensions);
    const totalOtherIncome = calculateTotalOtherIncome(otherIncomeItems);
    const totalStatePension = parseFloat(statePensionIncome?.value);

    const totalAccIncome = totalIncome(definedBenefitPensions, otherIncomeItems, statePensionIncome.value);

    const incomeGapChartOptions = {
        chart: {
            backgroundColor: "transparent",
            spacingTop: 0,
            marginTop: 0,
            height: "196",
            plotShadow: false,
        },
        title: {
            text: "",
        },
        xAxis: {
            minPadding: 0,
            maxPadding: 0,
            categories: [formatter.format(totalExpenditure), "", formatter.format(totalAccIncome)],
            labels: {
                style: {
                    color: "black",
                    fontSize: "11px",
                },
                rotation: 0,
                allowOverlap: true,
                padding: 0,
                step: 2,
            },
        },
        credits: {
            enabled: false,
        },
        yAxis: {
            visible: false,
            minPadding: 0,
            maxPadding: 0,
        },
        legend: {
            enabled: false,
            align: "center",
            verticalAlign: "bottom",
            layout: "horizontal",
        },
        tooltip: {
            borderWidth: 1,
            formatter: function () {
                const getIncomeGapValue = (totalExpenditure: number, totalAccIncome: number) => {
                    return totalAccIncome - totalExpenditure;
                };
                // @ts-ignore
                const _this = this as Highcharts.PointLabelObject;
                const displayValue =
                    _this.color !== "#000" ? _this.y : getIncomeGapValue(totalExpenditure, totalAccIncome);
                return `${_this.series.name}: ${_this.y && displayValue && formatter.format(displayValue)}`;
            },
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                },
                pointWidth: 55,
                stacking: "normal",
            },
        },
        series: [
            {
                name: CHART_LABELS.fixedLabel,
                type: "column",
                color: "#FF9665",
                data: [{ y: 0 }, 0, 0],
            },
            {
                name: CHART_LABELS.discretionaryLabel,
                type: "column",
                color: "#ffaec9",
                data: [{ y: 0 }, 0, 0],
            },
            {
                name: CHART_LABELS.dbPensionLabel,
                type: "column",
                color: "#72DBD5",
                data: [0, 0, { y: 0 }],
            },
            {
                type: "column",
                name: CHART_LABELS.statePensionLabel,
                color: "#8760EF",
                data: [0, 0, { y: 0 }],
            },
            {
                type: "column",
                name: CHART_LABELS.otherIncomeLabel,
                color: "#F0B4F9",
                data: [0, 0, { y: 0 }],
            },
            {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        // @ts-ignore
                        const _this = this as Highcharts.PointLabelObject;
                        const {
                            point: { low },
                            y,
                        } = _this;
                        const diff = totalAccIncome - totalExpenditure;

                        if (low === y) {
                            return formatter.format(diff);
                        }
                        return "";
                    },
                    style: {
                        fontWeight: "normal",
                    },
                },
                color: "#000",
                type: "errorbar",
                name: CHART_LABELS.incomeGapLabel,
                data: [[], [totalAccIncome, totalExpenditure], []],
            },
        ],
    };

    const [chartOptions, setChartOptions] = useState(incomeGapChartOptions);

    const updateHighcharts = useCallback(() => {
        setChartOptions((prev) => {
            return {
                ...prev,
                tooltip: {
                    borderWidth: 1,
                    formatter: function () {
                        const getIncomeGapValue = (totalExpenditure: number, totalAccIncome: number) => {
                            return totalAccIncome - totalExpenditure;
                        };
                        // @ts-ignore
                        const _this = this as Highcharts.PointLabelObject;
                        const displayValue =
                            _this.color !== "#000" ? _this.y : getIncomeGapValue(totalExpenditure, totalAccIncome);
                        return `${_this.series.name}: ${_this.y && displayValue && formatter.format(displayValue)}`;
                    },
                },
                series: [
                    {
                        name: CHART_LABELS.fixedLabel,
                        type: "column",
                        color: "#FF9665",
                        data: [{ y: fixedRatioValue }, 0, 0],
                    },
                    {
                        name: CHART_LABELS.discretionaryLabel,
                        type: "column",
                        color: "#ffaec9",
                        data: [{ y: discretionaryRatioValue }, 0, 0],
                    },
                    {
                        name: CHART_LABELS.dbPensionLabel,
                        type: "column",
                        color: "#72DBD5",
                        data: [0, 0, { y: totalDBPension }],
                    },
                    {
                        type: "column",
                        name: CHART_LABELS.statePensionLabel,
                        color: "#8760EF",
                        data: [0, 0, { y: totalStatePension }],
                    },
                    {
                        type: "column",
                        name: CHART_LABELS.otherIncomeLabel,
                        color: "#F0B4F9",
                        data: [0, 0, { y: totalOtherIncome }],
                    },
                    {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                // @ts-ignore
                                const _this = this as Highcharts.PointLabelObject;
                                const {
                                    point: { low },
                                    y,
                                } = _this;
                                const diff = totalAccIncome - totalExpenditure;

                                if (low === y) {
                                    return formatter.format(diff);
                                }
                                return "";
                            },
                            style: {
                                fontWeight: "normal",
                            },
                        },
                        color: "#000",
                        type: "errorbar",
                        name: CHART_LABELS.incomeGapLabel,
                        data: [[], [totalAccIncome, totalExpenditure], []],
                    },
                ],
            };
        });
    }, [
        totalAccIncome,
        totalDBPension,
        totalExpenditure,
        totalOtherIncome,
        totalStatePension,
        discretionaryRatioValue,
        fixedRatioValue,
    ]);
    useEffect(() => {
        updateHighcharts();
    }, [totalExpenditure, totalStatePension, totalDBPension, totalOtherIncome, updateHighcharts]);

    // const legendData: any[] = useMemo(() => [], []);
    // useEffect(() => {
    //     chartOptions.series.forEach((s) => {
    //         if (s.name !== CHART_LABELS.incomeGapLabel) {
    //             legendData.push({
    //                 name: s.name,
    //                 // @ts-ignore
    //                 color: s.color,
    //             });
    //         }
    //     });
    // }, [chartOptions.series, legendData]);

    return (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} aria-label="Risk profile interactive chart." />
    );
};

export default IncomeGapHighchart;

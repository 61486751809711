import { PROPOSALS_URL } from "@constants";
import { Proposal } from "redux/models";
import { uipApiInstance } from "auth/interceptors";

export const fetchProposals = async (id: string) => {
	try {
		const res = await uipApiInstance({
			method: "GET",
			url: PROPOSALS_URL + `${id}/getclientproposals`,
		});
		return res?.data?.proposals || [];
	} catch (err) {
		console.error(err);
	}
};

export const getProposal = async (clientId: string, proposalId: string) => {
	try {
		const res = await uipApiInstance({
			method: "POST",
			url: PROPOSALS_URL + `${clientId}/getproposal`,
			data: { proposalId },
		});
		return res?.data?.proposal || [];
	} catch (err) {
		console.error(err);
	}
};

export const addProposal = async (data: Proposal, id: string) => {
	try {
		const res = await uipApiInstance({
			method: "POST",
			url: PROPOSALS_URL + `${id}/addproposal`,
			data: data,
		});
		return res.data;
	} catch (err) {
		console.error(err);
	}
};

export const updateProposal = async (data: Proposal, id: string) => {
	try {
		const res = await uipApiInstance({
			method: "POST",
			url: PROPOSALS_URL + `${id}/updateproposal`,
			data: data,
		});
		return res.data;
	} catch (err) {
		console.error(err);
	}
};

export const deleteProposal = async (contatId: string, proposalId: string) => {
	try {
		const res = await uipApiInstance({
			method: "POST",
			url: PROPOSALS_URL + `${contatId}/deleteproposal`,
			data: { proposalId: proposalId },
		});
		return res.data;
	} catch (err) {
		console.error(err);
	}
};

import FTBox from "atoms/FTBox";
import FTSelectableCard from "atoms/FTSelectableCard";
import { CREATEPROFILEPAGE, HEALTH_SNAPSHOT_DATA } from "../../@constants";
import "./health-snapshot.styles.scss";

const HealthSnapshotView = ({ setCustomerDetails, customerDetails }: any) => {
    return (
        <>
            <FTBox
                header="Default"
                headerTitle={CREATEPROFILEPAGE.HEALTH_SNAPSHOT.TITLE}
                iconMessage={CREATEPROFILEPAGE.HEALTH_SNAPSHOT.HEALTH_SNAPSHOT_TOOLTIP}
                body={
                    <>
                        <div className="health-snapshot">
                            <div className="health-snapshot__cards-wrapper">
                                {HEALTH_SNAPSHOT_DATA?.map(({ id, iconName, title, content, value }) => (
                                    <FTSelectableCard
                                        key={id}
                                        data-testid={id}
                                        iconName={iconName}
                                        title={title}
                                        content={content}
                                        setCustomerDetails={setCustomerDetails}
                                        value={value}
                                        customerDetails={customerDetails}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                }
            />
        </>
    );
};

export default HealthSnapshotView;

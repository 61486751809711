/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable no-sequences */
import { createSlice, createAsyncThunk, PayloadAction, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { OktaAuth } from "@okta/okta-auth-js";
import { v4 as uuidv4 } from "uuid";

import { OKTA_DEVICE_TOKEN_KEY } from "../@constants";
import { uipApiInstance } from "./interceptors";

type InitialState = {
    isAuthenticated: boolean;
    user: any;
    loading: boolean;
    isSendingMFA: boolean;
    error?: null | undefined | any;
    showSessionExpiredModal: boolean;
    sendingForgotPassword?: boolean;
};

const initialState: InitialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    isSendingMFA: false,
    error: null,
    showSessionExpiredModal: false,
    sendingForgotPassword: false,
};

const getAndSaveTokens = async (oktaAuth: OktaAuth, dispatch: any, tenantId: string) => {
    const res = await oktaAuth.token.getWithoutPrompt({
        responseType: ["token", "id_token"],
        sessionToken: transaction.sessionToken,
        scopes: ["openid", "email", "profile", "phone"],
    });

    const userInfoRes: any = await axios({
        method: "GET",
        baseURL: process.env.REACT_APP_UIP_API_URL,
        url: "/api/rio/loginuserinfo",
        withCredentials: false,
        headers: {
            Authorization: "Bearer " + res.tokens?.accessToken?.accessToken,
        },
    });
    const userInfo = {
        lastLoggedInDateTime: userInfoRes?.data?.lastLogin,
        loggedInUsername: userInfoRes?.data?.name,
        userRole: userInfoRes?.data?.role,
        userId: res.tokens?.idToken?.claims?.sub,
        userEmail: res.tokens?.idToken?.claims?.email,
        accessToken: res.tokens?.accessToken?.accessToken,
        accessTokenExpiration: res.tokens?.accessToken?.expiresAt,
        idToken: res.tokens?.idToken?.idToken,
        tenant: userInfoRes?.data?.tenant,
        freemium: userInfoRes?.data?.freemium,
        organizationName: userInfoRes?.data?.organizationName,
        acceptedEulaVersion: userInfoRes?.data.acceptedEulaVersion,
        acceptedUserAgreementVersion: userInfoRes?.data.acceptedUserAgreementVersion,
    };

    await oktaAuth.tokenManager.setTokens(res.tokens);

    // const eula = orgInfo?.payload?.orgInfo?.eula;
    // const userAgreement = orgInfo?.payload?.orgInfo?.userAgreement;
    return { ...userInfo };
};

type UserInfo = { oktaAuth: OktaAuth; username: string; password: string };

let transaction: any;
let factor: any;

export const authenticateUser = createAsyncThunk(
    "authState/authenticateUser",
    async ({ oktaAuth, username, password }: UserInfo, { rejectWithValue, dispatch, getState }) => {
        try {
            const state: any = getState();

            const tenantId = state?.global?.globalConfig?.subdomain;

            const deviceTokenFromLocalStorage = await localStorage.getItem(OKTA_DEVICE_TOKEN_KEY);
            transaction = await oktaAuth.signInWithCredentials({
                username,
                password,
                context: {
                    deviceToken: deviceTokenFromLocalStorage || "",
                },
            });
            // If status = "MFA_REQUIRED"
            if (transaction?.status === "MFA_REQUIRED" || transaction?.status === "MFA_ENROLL") {
                // Send MFA code to preferred MFA option
                factor = transaction?.factors?.find?.(
                    (fac: any) => fac?.provider === "OKTA" && fac?.factorType === "email"
                );
                // eslint-disable-next-line require-atomic-updates
                if (transaction?.status === "MFA_REQUIRED") {
                    transaction = await factor?.verify();
                }

                if (transaction?.status === "MFA_ENROLL") {
                    transaction = await factor?.enroll();
                }
            }
            let user: any = {};
            if (transaction?.status === "SUCCESS") {
                user = await getAndSaveTokens(oktaAuth, dispatch, tenantId);
            }

            if (!user) {
                return rejectWithValue("User not allowed to access this application");
            }

            return { status: transaction?.status, user };
        } catch (error) {
            console.log(error);
            return rejectWithValue(error);
        }
    }
);

export const resendMFA = createAsyncThunk("authState/resendMFA", async (_, { dispatch, rejectWithValue }) => {
    try {
        const resendMFATransaction = await transaction.resend("email");

        transaction.sessionToken = resendMFATransaction.sessionToken;
        return true;
    } catch (error) {
        console.log(error);
        return rejectWithValue(error);
    }
});

type ConfirmMFAAndLoginType = {
    oktaAuth: OktaAuth;
    code: string;
    rememberDevice?: boolean;
};

let mfaTransaction = null;

export const confirmMFAAndLogin = createAsyncThunk(
    "authState/confirmMFA",
    async ({ oktaAuth, code, rememberDevice }: ConfirmMFAAndLoginType, { rejectWithValue, dispatch, getState }) => {
        try {
            // const state: any = getState();

            const tenantId = "default"; //state?.global?.globalConfig?.subdomain;

            if (transaction.status === "MFA_ENROLL_ACTIVATE") {
                mfaTransaction = await transaction.activate({
                    passCode: code,
                    rememberDevice,
                });
            } else {
                mfaTransaction = await transaction.verify({
                    passCode: code,
                    rememberDevice,
                });
            }

            // Reset device token if remember device is not selected
            if (!rememberDevice) {
                localStorage.removeItem(OKTA_DEVICE_TOKEN_KEY);

                const uuid = uuidv4();

                localStorage.setItem(OKTA_DEVICE_TOKEN_KEY, uuid.substring(0, 32));
            }

            transaction.sessionToken = mfaTransaction.sessionToken;

            console.log("verify", mfaTransaction);

            const user: any = await getAndSaveTokens(oktaAuth, dispatch, tenantId);

            if (!user) {
                return rejectWithValue("User not allowed to access this application");
            }

            return { status: mfaTransaction.status, user };
        } catch (error) {
            console.log(error);
            // dispatch(logEventToBackEnd("CONFIRM_MFA_API_ERROR"));
            return rejectWithValue(error);
        }
    }
);

type ForgotPassowrdType = { oktaAuth: OktaAuth; email: string };

export const forgotPassword = createAsyncThunk(
    "authState/forgotPassword",
    async ({ oktaAuth, email }: ForgotPassowrdType, { rejectWithValue, dispatch }) => {
        try {
            transaction = await oktaAuth.forgotPassword({
                username: email,
                factorType: "EMAIL",
            });

            return true;
        } catch (error) {
            console.log(error);
            // dispatch(logEventToBackEnd("FORGOT_PASSWORD_API_ERROR"));
            return rejectWithValue(error);
        }
    }
);

export const sendForgotPasswordLink = createAsyncThunk(
    "authState/sendForgotPasswordLink",
    async ({ email }: any, { rejectWithValue, dispatch }) => {
        try {
            const response = await uipApiInstance({
                method: "POST",
                url: "/forgotpassword",
                withCredentials: false,
                data: {
                    email,
                },
            });

            return response.data?.success;
        } catch (error) {
            console.log(error);
            // dispatch(logEventToBackEnd("FORGOT_PASSWORD_API_ERROR"));
            return rejectWithValue(error);
        }
    }
);

export const logout = createAction("authState/logout");

const authSlice = createSlice({
    name: "authState",
    initialState,
    reducers: {
        toggleSessionExpiredModal: (state, action: PayloadAction<any>) => {
            state.showSessionExpiredModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, (state) => ({
            ...initialState,
            showSessionExpiredModal: state.showSessionExpiredModal,
        }));
        builder.addCase(authenticateUser.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(authenticateUser.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = Boolean(action.payload.user && Object.keys(action.payload.user)?.length);
                state.user = action.payload.user;
                state.error = "";
            }),
            builder.addCase(authenticateUser.rejected, (state, action) => {
                state.loading = false;
                state.user = {};
                state.error = action.error;
            });
        builder.addCase(resendMFA.pending, (state) => {
            state.isSendingMFA = true;
        });
        builder.addCase(resendMFA.fulfilled, (state) => {
            state.isSendingMFA = false;
            state.error = "";
        }),
            builder.addCase(resendMFA.rejected, (state, action) => {
                state.isSendingMFA = false;
                state.error = action.payload;
            });
        builder.addCase(confirmMFAAndLogin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(confirmMFAAndLogin.fulfilled, (state, action) => {
            state.loading = false;
            state.isAuthenticated = Boolean(action.payload.user && Object.keys(action.payload.user)?.length);
            state.user = action.payload.user;
            state.error = "";
        }),
            builder.addCase(confirmMFAAndLogin.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.user = {};
                state.error = action.error;
            });
        builder.addCase(forgotPassword.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(forgotPassword.fulfilled, (state) => {
            state.loading = false;
            state.error = "";
        }),
            builder.addCase(forgotPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder.addCase(sendForgotPasswordLink.pending, (state) => {
            state.sendingForgotPassword = true;
        });
        builder.addCase(sendForgotPasswordLink.fulfilled, (state) => {
            state.sendingForgotPassword = false;
            state.error = "";
        }),
            builder.addCase(sendForgotPasswordLink.rejected, (state, action) => {
                state.sendingForgotPassword = false;
                state.error = action.error;
            });
    },
});

export const { toggleSessionExpiredModal } = authSlice.actions;

export default authSlice.reducer;

import { FT_MODAL_CONTENT } from "@constants";
import FTButton from "./FTButton";
import FTIcon from "./FTIcon";
import FTModal from "./FTModal";

const FTDeleteConfirmationDialog = (props: any) => {
    const { handleItemDelete, idx, itemName, showDeleteModal, setShowDeleteModal } = props;

    return (
        <FTModal handleClose={() => setShowDeleteModal(false)} open={showDeleteModal}>
            <section className="delete-proposal-container">
                <div className="modal-title">
                    <FTIcon iconName="ft ft-remove" />
                    <h6 className="demi-bold">{FT_MODAL_CONTENT.DELETE_ITEM.HEADING}</h6>
                </div>
                <div className="modal-confirm-content">{`${FT_MODAL_CONTENT.DELETE_ITEM.BODY} "${itemName}"?`}</div>
                <article className="delete-proposal-footer">
                    <FTButton variant="outlined" className="left-aligned-btn" onClick={() => setShowDeleteModal(false)}>
                        {FT_MODAL_CONTENT.DELETE_ITEM.CANCEL_BTN}
                    </FTButton>
                    <FTButton className="left-aligned-btn" onClick={() => handleItemDelete(idx)}>
                        {FT_MODAL_CONTENT.DELETE_ITEM.CONFIRM_BTN}
                    </FTButton>
                </article>
            </section>
        </FTModal>
    );
};

export default FTDeleteConfirmationDialog;

import FTSwitch from "atoms/FTSwitch";
import { useAppSelector, useSelectedProposal } from "hooks";
import { round } from "lodash-es";
import { useState } from "react";
import { calculateTotalExpenditureValue, floatFix, formatter, getMonthlyValue } from "utils/helper";

const TalkingPoints = ({ isProposedIncomeActive }: { isProposedIncomeActive: Boolean }) => {
    const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    const fundedness = goe4dTranslation?.body?.fundedness;
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const { label, firstName, lastName } = useAppSelector((state) => state.selectedContact);
    const { annualDisplayedProposal, annuityQuoteAmount } = useSelectedProposal();
    const { definedBenefitPensions, otherIncomeItems, expenditures, statePensionIncome } =
        annualDisplayedProposal?.customData || selectedProposal?.customData;

    const clientName = label || `${firstName} ${lastName}`;
    const [isTalkingPointsOpen, setTalkingPointsOpen] = useState(false);

    const totalIncomeNotional = () => {
        return floatFix(
            [...otherIncomeItems, ...definedBenefitPensions, statePensionIncome]
                .filter((income) => !income.inflationAdjusted)
                .reduce((acc, curr) => acc + curr.value, 0)
        );
    };

    const totalIncomeReal = () => {
        return floatFix(
            [...otherIncomeItems, ...definedBenefitPensions, statePensionIncome]
                .filter((income) => income.inflationAdjusted)
                .reduce((acc, curr) => acc + curr.value, 0)
        );
    };

    const getTotalIncomeYearly = () => floatFix(totalIncomeNotional() + totalIncomeReal());
    const totalExpenditureYearly = calculateTotalExpenditureValue(expenditures);

    // Overfunded (probability > target probability)
    const renderOverfundedFundedness = () => {
        return `
      The illustrated solution sets out a sustainable income in retirement, through the use of a bespoke portfolio and cashflow arrangements. This is the calculated income value which can enable ${clientName} to have confidence in drawing a regular income for life. <br/> <br/>
      The proposed plan is likely to exceed ${clientName} expectations for retirement. Several assumptions have been made which are set out in the downloadable PDF, which we recommend you review carefully.
    `;
    };

    // Funded (probability = target probability)
    const renderFundedFundedness = () => {
        return `
    The illustrated solution sets out a reasonably sustainable income in retirement, through the use of a portfolio and 
    cashflow arrangements.This is the calculated income value which can enable ${clientName} to have reasonable confidence
    in drawing a regular income for life, mindful of any shocks or shifts in their plan. <br/> <br/>

    The proposed plan is likely to meet ${clientName} expectations for retirement. Several assumptions have been made which are set out in the downloadable PDF, which we recommend you review carefully.
    `;
    };

    // Underfunded (probability < target probability)
    const renderUnderfundedFundedness = () => {
        const currentYear = new Date().getFullYear().toString();
        const totalExpenditureMonthly = getMonthlyValue(totalExpenditureYearly);
        const incomeGapYearly =
            totalExpenditureYearly - getTotalIncomeYearly() - round(annuityQuoteAmount * 12, 2) || 0;
        const incomeGapMonthly = formatter.format(getMonthlyValue(incomeGapYearly));

        return `
    The plan shown here is less than ${clientName} has indicated they were looking for. <br/> <br/>

    The illustrated solution sets out a sustainable income in retirement, using a bespoke portfolio and cashflow arrangements. 
    This is the calculated income value which can enable ${clientName} to have confidence in drawing a regular income for life.  <br/> <br/>

    In the plan shown, ${clientName} has indicated a need for <b>${formatter.format(
            totalExpenditureMonthly
        )}</b> per month during ${currentYear}.
    This results in an estimated shortfall of <b>${incomeGapMonthly}</b> per month. <br/> <br/>

    Several assumptions have been made which are set out in the downloadable PDF, which we recommend you review carefully. <br/> <br/>
    If ${clientName} wishes to draw <b>${formatter.format(
            totalExpenditureMonthly
        )}</b> per month, there is a high likelihood that the wealth will demise during their lifetime and alternative arrangements will be needed.`;
    };

    const onTalkingPointToggle = (checked: boolean) => {
        setTalkingPointsOpen(checked);
    };

    const fundnessMapping = (fundedness: string): any => {
        if (fundedness === "OverFunded") {
            return renderOverfundedFundedness();
        } else if (fundedness === "Funded") {
            return renderFundedFundedness();
        } else if (fundedness === "UnderFunded") {
            return renderUnderfundedFundedness();
        }
    };

    return (
        <>
            {isProposedIncomeActive && (
                <div className={`talking-point-container ${isTalkingPointsOpen ? "show-content" : ""}`}>
                    <div className="talking-point__toggle">
                        <FTSwitch
                            switchName="Talking points"
                            label="Talking points"
                            checked={isTalkingPointsOpen}
                            onChange={(checked) => onTalkingPointToggle(checked)}
                        />
                        <div className={`talking-point__content ${isTalkingPointsOpen ? "show" : ""}`}>
                            <h6 className="talking-point__content__title">Proposed Income</h6>
                            <div
                                dangerouslySetInnerHTML={{ __html: fundnessMapping(fundedness) }}
                                className="talking-point__content__description"
                            ></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TalkingPoints;

import React from "react";
import "./pdfImplementationPlan.style.scss";
import { calculateSumOfAllOtherWealthValues, formatter } from "utils/helper";
import { useAppSelector, useSelectedProposal } from "hooks";
import useGetPortfolio from "hooks/useGetPortfolio";

interface PdfImplementationPlanProps {
    step: number;
}

const PdfImplementationPlan: React.FC<PdfImplementationPlanProps> = ({ step }) => {
    const { wealthItems } = useSelectedProposal();
    const investmentAccountValue = calculateSumOfAllOtherWealthValues(wealthItems);

    const { portfolioDataConfig, recommendedPortfolioId } = useGetPortfolio();

    const spireIndicativeQuote = useAppSelector((state) => state.spireIndicativeQuote);
    const proposedAnnuityIncreaseAmount = spireIndicativeQuote?.illustrationDetails?.premiumValue || 0;
    const recommendedPortfolioData = portfolioDataConfig[0].filter(
        ({ portfolioId }) => portfolioId === recommendedPortfolioId
    );
    // console.log(recommendedPortfolioId);
    return (
        <>
            <div className="pdf-content implementation-plan">
                <div className="steps__title-container">
                    <div className="steps__title__icon  floating sm">
                        <div className="steps__title__icon__number">{step}</div>
                    </div>
                    <h5 className="steps__title">
                        <span className="steps__title__label">Implementaion Plan</span>
                    </h5>
                </div>
                <div className="data-table__row header">
                    <div>FUND NAME</div>
                    <div>ASSET CLASS</div>
                    <div>SEDOL/ISLIN</div>
                    <div>OCF</div>
                    <div>% of plan</div>
                    <div>VALUE</div>
                </div>
            </div>
            <div className="pdf-content">
                {recommendedPortfolioData.map(({ assets }) => {
                    return assets.map(({ subAssets }) => {
                        return subAssets.map(({ name, subAssetClass, isin, OCF, allocatedValue }, index) => {
                            return (
                                <div className="data-table__row body" key={index}>
                                    <div>{name}</div>
                                    <div>{subAssetClass}</div>

                                    <div>{isin}</div>

                                    <div>{OCF}</div>

                                    <div>{allocatedValue} %</div>
                                    <div>{formatter.format(investmentAccountValue * (allocatedValue / 100))}</div>
                                </div>
                            );
                        });
                    });
                })}
            </div>
            <div className="pdf-content">
                <div className="data-table__row footer proposal-pdf">
                    <div>
                        <h6 className="implementation-plan__footer-bold-label">Total Portfolio Investment</h6>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="implementation-plan__footer-bold-label">100%</div>
                    <div className="implementation-plan__footer-bold-label align-right">
                        {formatter.format(investmentAccountValue)}
                    </div>
                </div>
            </div>
            <div className="pdf-content">
                <div className="data-table__row footer">
                    <div>
                        <h6 className="implementation-plan__footer-label">SLI value to Purchase</h6>
                    </div>
                    <div className="implementation-plan__footer-bold-label align-right">
                        {formatter.format(proposedAnnuityIncreaseAmount)}
                    </div>
                </div>
            </div>
            <div className="pdf-content">
                <div className="data-table__row footer">
                    <div>
                        <h6 className="implementation-plan__footer-bold-label">Total Amount Purchased</h6>
                    </div>
                    <div className="implementation-plan__footer-bold-label align-right">
                        {formatter.format(investmentAccountValue - proposedAnnuityIncreaseAmount)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PdfImplementationPlan;

import { FactFindingStep, StepRoute, StepTitle } from "@types";

export const factFindingStepsObject: any = {
	[StepRoute.wealth]: {
		title: StepTitle.wealth,
		completed: false,
		active: false,
		iconClass: "ft-drawerTotalWealth",
		routeName: "wealth",
		nextRoute: "benefits-and-income",
	},
	[StepRoute.benefitsIncome]: {
		title: StepTitle.benefitsIncome,
		completed: true,
		active: false,
		iconClass: "ft-drawerBenefitsIncome",
		routeName: "benefits-and-income",
		nextRoute: "expenditure",
	},
	[StepRoute.expenditure]: {
		title: StepTitle.expenditure,
		completed: false,
		active: true,
		iconClass: "ft-drawerExpenditure",
		routeName: "expenditure",
		nextRoute: "risk",
	},
	[StepRoute.risk]: {
		title: StepTitle.risk,
		completed: false,
		active: false,
		iconClass: "ft-drawerRisk",
		routeName: "risk",
		nextRoute: "view-proposal",
	},
	[StepRoute.viewProposal]: {
		title: StepTitle.viewProposal,
		completed: false,
		active: false,
		iconClass: "ft-basket",
		routeName: "view-proposal",
	},
};

export const factFindingSteps: FactFindingStep[] = Object.values(
	factFindingStepsObject
);

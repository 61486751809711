import FTDropdown from "atoms/FTDropdown";
import { useAppSelector, useSelectedProposal } from "hooks";
import { useState } from "react";
import RiskDrawerHighchart from "./RiskDrawerHighchart";

const RiskDrawer = () => {
    const { displayedProposal, setDisplayedProposal } = useSelectedProposal();
    const riskMapping = useAppSelector((state) => state?.global?.orgInfo?.settings?.riskMapping);
    const { riskScore, riskProvider } = displayedProposal?.customData;
    const [riskAversion, setRiskAversion] = useState(displayedProposal?.customData?.riskAversion);
    const [selectedRiskProfile, setSelectedRiskProfile] = useState(riskScore);

    const riskScoreOptions = riskMapping[riskProvider].map((risk: string) => ({
        label: risk,
        value: risk,
        isSelected: risk === riskScore,
    }));
    const riskSensitivityOptions = [
        { label: "Dynamic", value: "Dynamic" },
        { label: "Flexible", value: "Flexible" },
        { label: "Secure", value: "Secure" },
    ];

    const handleRiskProfile = (e: any) => {
        const riskProfileSelected = e.target.value;
        setSelectedRiskProfile(riskProfileSelected);

        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.riskScore = riskProfileSelected;
        setDisplayedProposal(updatedProposal);
    };

    const handleRiskSensitivity = (e: any) => {
        const selectedRiskSensitivity = e.target.value;
        setRiskAversion(selectedRiskSensitivity);

        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.riskAversion = selectedRiskSensitivity;
        setDisplayedProposal(updatedProposal);
    };

    return (
        <>
            <div className="drawer-content__title-container">
                <h5 className="drawer-content__title"> Attitude to risk </h5>
                <h5 className="drawer-content__totalAmount"> {riskScore && riskScore} </h5>
            </div>

            <div data-testid="risk-sensitivity-block" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <h6 className="breakdown-block__title"> SENSITIVITY TO ANNUAL INCREASES / DECREASES IN INCOME </h6>
                </div>
                <div className="qf-input-dropdown">
                    <FTDropdown
                        id="risk-sensitivity"
                        // label="Sensitivity"
                        options={riskSensitivityOptions}
                        onChange={handleRiskSensitivity}
                        // placeholder="Sensitivity"
                        value={riskAversion && riskAversion}
                        aria-label={`Dropdown selection: ${riskAversion}`}
                        noDataMessage=""
                    />
                </div>
            </div>

            <div data-testid="risk-provider-block" className="breakdown-block">
                <div className="breakdown-block__title-container">
                    <h6 className="breakdown-block__title"> Risk profiling </h6>
                </div>
                <div className="risk-provider__content">
                    <div className="risk-provider__label">{`Risk Provider: ${riskProvider}`}</div>
                    <div className="risk-provider__container">
                        <div className="qf-input-dropdown">
                            <FTDropdown
                                id="risk-profile"
                                // label="Risk Profile"
                                // placeholder="Risk Profile"
                                onChange={handleRiskProfile}
                                options={riskScoreOptions}
                                value={selectedRiskProfile}
                                aria-label={`Dropdown selection: ${selectedRiskProfile}`}
                                noDataMessage=""
                            />
                        </div>
                        <div className="highcharts-container">
                            <RiskDrawerHighchart selectedRiskProfile={selectedRiskProfile} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RiskDrawer;

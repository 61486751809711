import { useOktaAuth } from "@okta/okta-react";
import { CustomerDetailsType } from "@types";
import { addContact } from "api";
import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import HealthSnapshot from "containers/HealthSnapshot";
import PageHeading from "containers/PageHeading";
import PersonalInformation from "containers/PersonalInformation";
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { set_proposals, set_selected_contact } from "redux/actions";
import { CREATEPROFILEPAGE } from "../../@constants";
import "../styles/client-update-profile.styles.scss";
import { formatInputDateToISOString } from "utils/helper";

const initialData = {
    contactId: "",
    state: "Active",
    email: "",
    firstName: "",
    lastName: "",
    birthdate: "",
    healthStatus: "",
    jobTitle: "",
    organizationId: "",
    addresses: [],
    phones: [],
    wealthItems: [],
    isRetired: true,
    hasOutstandingDebts: false,
    postcode: "",
};

const CreateProfile = () => {
    const { authState } = useOktaAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [customerDetails, setCustomerDetails] = useState<CustomerDetailsType>(initialData);
    const [hasFieldsError, setHasFieldsError] = useState<boolean | string>(false);
    const [inputFieldError, setInputFieldError] = useState({
        firstNameError: false,
        lastNameError: false,
        birthdateError: "",
        postcodeError: false,
    });

    const routeToLandingPage = () => {
        navigate("/advisor/landing");
    };

    const routeToHome = () => {
        navigate("/home");
    };

    const handleCreateNewClientBtn = async () => {
        // console.log(formatInputDateToISOString(customerDetails.birthdate));
        try {
            setHasFieldsError(true);
            const response = await addContact({
                ...customerDetails,
                birthdate: formatInputDateToISOString(customerDetails.birthdate),
            });

            if (response?.contactId) {
                setHasFieldsError(response?.contactId);
                dispatch(set_selected_contact(response));
                dispatch(set_proposals([]));
                routeToHome();
            }
        } catch (err) {
            // TODO:: Handle Error senario
            console.error("Create client error", err);
            // we can show the modal when there is error, until we can show alert
        }
    };

    const checkFieldsStatus = () => {
        const { healthStatus, firstName, lastName, birthdate, postcode } = customerDetails;
        const { firstNameError, lastNameError, postcodeError, birthdateError } = inputFieldError;
        if (
            healthStatus === "" ||
            firstName === "" ||
            lastName === "" ||
            postcode === "" ||
            birthdate === "" ||
            firstNameError ||
            lastNameError ||
            postcodeError ||
            birthdateError
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (authState && !authState?.isAuthenticated) navigate("/?reason=logoutSuccess");
    }, [authState, navigate]);

    return (
        <main className="ft-page-container">
            <PageHeading iconName="ft-new-client" pageHeading={CREATEPROFILEPAGE.CREATE_A_NEW_CLIENT_PROFILE} />

            <div className="middle-container create-client-page">
                <div className="middle__control">
                    <FTIcon iconName="ft-carrotleft" />{" "}
                    <span className="link" onClick={routeToLandingPage}>
                        {CREATEPROFILEPAGE.BACK_TO_ADVISER_HOME}
                    </span>
                </div>

                <PersonalInformation
                    setCustomerDetails={setCustomerDetails}
                    customerDetails={customerDetails}
                    inputFieldError={inputFieldError}
                    setInputFieldError={setInputFieldError}
                />
                <HealthSnapshot setCustomerDetails={setCustomerDetails} customerDetails={customerDetails} />
            </div>

            <div className="bottom-container ft-page-block">
                {typeof hasFieldsError === "string" && (
                    <span className="_contact-saved">{CREATEPROFILEPAGE.PROFILE_SAVED_SUCCESSFULLY}</span>
                )}
                <FTButton
                    data-testid="create-new-profile-btn"
                    className="ft-button"
                    onClick={handleCreateNewClientBtn}
                    disabled={checkFieldsStatus() || Boolean(hasFieldsError)}
                >
                    {hasFieldsError
                        ? CREATEPROFILEPAGE.BTN_CREATE_LOADING_TEXT
                        : CREATEPROFILEPAGE.BTN_CREATE_NEW_PROFILE}
                </FTButton>
            </div>
        </main>
    );
};

export default CreateProfile;

import { ButtonProps } from "@mui/material";
import "./styles/atoms.styles.scss";

const FTKnobButton = (props: ButtonProps) => {
    const { className, children, disabled } = props;
    return (
        <article className={`knob-button-container ${className || ""}`} {...props}>
            <span className={disabled ? "disabled-cursor" : ""}>
                <span className={className}>
                    {children}
                </span>
            </span>
        </article>
    );
};

export default FTKnobButton;

import { PageHeadingProps } from "@types";
import FTIcon from "atoms/FTIcon";
import "./page-heading.component.scss";

function PageHeadingView({ iconName, pageHeading, rightSideContent, stepCount }: PageHeadingProps) {
    return (
        <div className="page-headings">
            <div className="ft-split">
                <div className="ft-split__first">
                    {stepCount && (
                        <div className="page-headings__step page-headings__icon__text">
                            <span>Step &nbsp;{stepCount}</span>
                        </div>
                    )}
                    {iconName && (
                        <div className="page-headings__icon floating sm">
                            <FTIcon iconName={iconName} />
                        </div>
                    )}

                    <div className="page-headings__title">
                        <h6>{pageHeading}</h6>
                    </div>
                </div>

                {rightSideContent && rightSideContent}
            </div>
        </div>
    );
}

export default PageHeadingView;

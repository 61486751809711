import { uipApiInstance } from "auth/interceptors";
import { bloomreach_disclaimers, bloomreach_generalcontent, bloomreach_faq } from "../@constants";
import { ContactInfo, EconomicAssumption, FaqTheme, ResourceContent } from "@types";
// import { disclaimers_mock_data } from "../constants/mock-data";

export const fetchBloomreachDisclaimerContent = async () => {
    try {
        const response = await uipApiInstance({
            method: "GET",
            url: bloomreach_disclaimers,
            withCredentials: false,
        });
        // const response = { data: disclaimers_mock_data };
        const disclaimer: any = Object.values(response.data.content)?.filter(
            (item: any) => item.displayName === "Disclaimers" && item.json
        );
        const disclaimerPolicies = JSON.parse(disclaimer[0].json).disclaimers;
        // Finding Fact Page Disclaimer
        const findingFactDisclaimers: any = {};
        disclaimerPolicies.forEach((disclaimerPolicy: any) => {
            findingFactDisclaimers[disclaimerPolicy.name_lvl_1[0].name] =
                disclaimerPolicy.name_lvl_1[0].name_lvl_2[0].disclaimer_desc;
        });
        // View Proposl Drawer Disclaimer
        const viewProposalDisclaimers: any = {};
        disclaimerPolicies?.filter((disclaimer: any) => {
            if (disclaimer?.page_name === "View Proposal") {
                let disclaimerArr = disclaimer.name_lvl_1;
                return disclaimerArr.forEach((vp_disclaimer: any) => {
                    viewProposalDisclaimers[vp_disclaimer.name] = vp_disclaimer.name_lvl_2[0].disclaimer_desc;
                });
            }
            return disclaimerPolicies;
        });

        const disclaimerData: object = {
            findingFactDisclaimers,
            viewProposalDisclaimers,
        };
        return disclaimerData;
    } catch (err) {
        console.error(err);
    }
};

export const fetchBloomreachGeneralContent = async () => {
    try {
        const response = await uipApiInstance({
            method: "GET",
            url: bloomreach_generalcontent,
            withCredentials: false,
        });
        // const response = { data: generalcontent_mock_data };
        const generalContentData: any = Object.values(response.data.content)?.filter(
            (item: any) => item.displayName === "GeneralContent" && item.json
        );
        const blogData = await JSON.parse(generalContentData[0].json).retirementArticles;
        const contactInfo: ContactInfo = await JSON.parse(generalContentData[0].json).contactInfo;
        const resourcesContent: ResourceContent = await JSON.parse(generalContentData[0].json).resources;
        const economicAssumptions: EconomicAssumption = await JSON.parse(generalContentData[0].json)
            .economicAssumptionsData;
        const statePensionDescription = await JSON.parse(generalContentData[0].json).descriptions?.[1].name_lvl_1[0]
            .name_lvl_2[0].disclaimer_desc;
        const viewProposalDescription = await JSON.parse(generalContentData[0].json).descriptions?.[0].name_lvl_1;
        let viewProposalDesc: any = {};
        viewProposalDescription.forEach((vp_desc: any) => {
            viewProposalDesc[vp_desc.name] = vp_desc.name_lvl_2[0].disclaimer_desc;
        });

        const bloomreachGeneralContent = {
            blogData,
            statePensionData: statePensionDescription,
            viewProposalGraphDescription: viewProposalDesc,
            contactInfo,
            resourcesContent,
            economicAssumptions,
        };
        return bloomreachGeneralContent;
    } catch (err) {
        console.error(err);
    }
};

export const fetchBloomreachFaqData = async () => {
    try {
        const response = await uipApiInstance({
            method: "GET",
            url: bloomreach_faq,
            withCredentials: false,
        });
        // const response = { data: faq_mock_data };
        const faq: any = Object.values(response.data.content)?.filter(
            (item: any) => item.displayName === "FAQ" && item.json
        );

        const faqDataRoot: FaqTheme = await JSON.parse(faq[0].json);
        // .faqData
        return faqDataRoot;
    } catch (err) {
        console.error(err);
    }
};

import { FactFindingStep } from "@types";
// import { calculateSumOfAllWealthItems, formatter } from "utils/helper";

export const drawersStepObject: any = {
    wealth: {
        title: "Wealth",
        // subTitle: formatter.format(calculateSumOfAllWealthItems(wealthItems)),
        drawerTitle: "TOTAL WEALTH",
        iconClass: "ft-drawerTotalWealth",
        slug: "wealth",
    },
    benefitsIncome: {
        title: "Income",
        subTitle: "totalIncome | formatAmount:currency",
        drawerTitle: "TOTAL INCOME",
        iconClass: "ft-drawerBenefitsIncome",
        slug: "benefitsIncome",
    },
    expenditure: {
        title: "Expenditure",
        // drawerTitle: "TOTAL INCOME",
        subTitle: "expenditureAmount | formatAmount:currency",
        iconClass: "ft-drawerExpenditure",
        slug: "expenditure",
    },
    risk: {
        title: "Risk Suitability",
        subTitle: "riskScore",
        drawerTitle: "TOTAL INCOME",
        iconClass: "ft-drawerRisk",
        slug: "risk",
    },
    annuity: {
        title: "Annuity",
        subTitle: "requestedIncomeLabel",
        drawerTitle: "TOTAL INCOME",
        incomeRate: "requestedIncome",
        premiumValue: "premiumValue",
        iconClass: "ft-drawerAnnuity",
        slug: "annuity",
    },
    cashReserves: {
        title: "Cash Reserves",
        subTitle: "totalCashReserves | formatAmount:currency",
        iconClass: "ft-handMoney",
        slug: "cashReserves",
    },
};

export const drawersSteps: FactFindingStep[] = Object.values(drawersStepObject);

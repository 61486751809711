import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import FTModal from "atoms/FTModal";
import { updateErrorModalState } from "auth/globalSlice";
import { set_proposals, set_selected_proposal } from "redux/actions";
import { Proposal } from "redux/models";
import {
    calculateTotalExpenditureValueFormatted,
    calculateTotalInvestmentValue,
    floatFix,
    formatWrapEllipsis,
    formatter,
    toHumanReadableDate,
} from "utils/helper";
import { deleteProposal, fetchProposals } from "api";
import { FT_MODAL_CONTENT } from "@constants";
import "pages/styles/home.styles.scss";
import NoProposalsSection from "./NoProposalsSection";

const ProposalsSection = ({ showLastThree }: { showLastThree?: boolean }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const proposals = useAppSelector((state) => state.proposals);

    const getGoalProbability = (goalProbability: number) => {
        return floatFix(goalProbability * 100) + "%";
    };

    const proposalCardRows = {
        annuity: {
            label: "TARGET EXPENDITURE ",
            val: (customData: any) =>
                calculateTotalExpenditureValueFormatted(customData?.expenditures || {}) || formatter.format(0),
        },
        growth: {
            label: "PROBABILITY OF SUCCESS ",
            val: (customData: any) => getGoalProbability(customData?.goalProbability),
        },
        drawerAnnuity: {
            label: "INVESTMENT ACCOUNT VALUE ",
            val: (customData: any) =>
                formatter.format(calculateTotalInvestmentValue(customData?.wealthItems) || 0) || "",
        },
        value: {
            label: "INDICATIVE PORTFOLIO ",
            val: (customData: any) => customData?.recommendedPortfolioId,
        },
        drawerRisk: {
            label: "PROPOSED INCREASE IN ANNUITY ",
            val: (customData: any) => formatter.format(customData?.proposedIncreaseInAnnuity),
        },
        zoom: {
            label: "RISK SUITABILITY ",
            val: (customData: any) => customData?.riskScore || "-",
        },
    };
    const [displayedProposals, setDisplayedProposals] = useState<Array<Proposal>>(proposals);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedProposalToDelete, setSelectedProposalToDelete] = useState<{
        proposalId: string | undefined;
        name: string;
    } | null>();
    const [deleteProposalLoading, setDeleteProposalLoading] = useState<boolean>(false);
    const handleDeleteModal = (proposalId: string | undefined, name: string) => {
        setShowDeleteModal(true);
        setSelectedProposalToDelete({ proposalId: proposalId, name: name });
    };
    const handleViewProposal = (proposal: Proposal) => {
        proposal?.customData?.meta?.factFindingStep === "view-proposal"
            ? navigate("/home/view-proposal")
            : navigate(`/home/fact-finding/${proposal?.customData?.meta?.factFindingStep?.toLowerCase() || ""}`);
    };
    const deleteProposalFromDOM = () => {
        const updatedProposals = [...proposals].filter(
            (proposal: Proposal) => proposal?.proposalId !== selectedProposalToDelete?.proposalId
        );
        dispatch(set_proposals(updatedProposals));
    };
    const handleDeleteProposal = async () => {
        setDeleteProposalLoading(true);
        deleteProposalFromDOM();
        setDeleteProposalLoading(false);
        setShowDeleteModal(false);
        const res = await deleteProposal(
            selectedContact.contactId || selectedContact._id,
            selectedProposalToDelete?.proposalId || ""
        );
        if (res?.success) {
            const proposals = await fetchProposals(selectedContact.contactId || selectedContact._id);
            dispatch(set_proposals(proposals));
        } else {
            dispatch(
                updateErrorModalState({
                    showErrorModal: true,
                    error: res,
                })
            );
        }
        // setDeleteProposalLoading(false);
    };
    useEffect(() => {
        if (Object.values(selectedContact)?.length === 0) navigate("/advisor/landing");
        else if (proposals?.length > 0) {
            let recentProposals: any = [...proposals];
            recentProposals = recentProposals.sort(
                (proposal1: any, proposal2: any) =>
                    new Date(proposal1?.customData?.meta?.lastAccessTime).valueOf() -
                    new Date(proposal2?.customData?.meta?.lastAccessTime).valueOf()
            );
            // setDisplayedProposals(recentProposals?.slice(-3)?.reverse());
            setDisplayedProposals(showLastThree ? recentProposals?.slice(-3)?.reverse() : recentProposals?.reverse());
        } else setDisplayedProposals(proposals);
    }, [showLastThree, proposals, selectedContact, navigate]);

    return proposals && proposals?.length !== 0 ? (
        <section className="proposal-cards-list">
            {displayedProposals?.map((proposal: Proposal, idx: number) => (
                <div key={idx} className="proposal-card-container">
                    <section className="proposal-card">
                        <article className="header">
                            <span
                                className="delete-proposal-btn"
                                onClick={() => handleDeleteModal(proposal.proposalId || proposal._id, proposal.name)}
                            >
                                <span>{FT_MODAL_CONTENT.DELETE_PROPOSAL.DELETE_LABEL}</span>
                                <FTIcon iconName="ft-cross" />
                            </span>
                            <h6 className="demi-bold long-text-wrap-ellipsis">{proposal.name}</h6>
                            <span className="date-span">Generated {toHumanReadableDate(proposal?.date)}</span>
                            {proposal?.customData?.meta?.factFindingStep === "view-proposal" ? (
                                <span _ngcontent-umm-c533="" className="completion-state">
                                    <FTIcon iconName="ft-proposal-complete" /> Proposal complete
                                </span>
                            ) : (
                                <span _ngcontent-umm-c533="" className="completion-state">
                                    <FTIcon iconName="ft-proposal-in-progress" /> Proposal in progress
                                </span>
                            )}
                        </article>
                        <article className="body">
                            {Object.values(proposalCardRows).map((row, i) => (
                                <div key={i} className="row">
                                    <div className="label">
                                        <FTIcon iconName={`ft - ${Object.keys(proposalCardRows)[i]} `} />
                                        <span className="label-name">{row.label}</span>
                                    </div>
                                    <div className="value">
                                        <span className="demi-bold">
                                            {typeof row.val === "function" ? row.val(proposal?.customData) : ""}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </article>
                        <FTButton
                            className="parent-width-btn"
                            onClick={() => {
                                // set proposal to store
                                dispatch(set_selected_proposal(proposal));
                                handleViewProposal(proposal);
                            }}
                        >
                            {proposal?.customData?.meta?.factFindingStep === "view-proposal"
                                ? "VIEW PROPOSAL"
                                : "RESUME PROPOSAL"}
                        </FTButton>
                    </section>
                </div>
            ))}
            {showDeleteModal && (
                <FTModal handleClose={() => setShowDeleteModal(false)} open={showDeleteModal}>
                    <section className="delete-proposal-container">
                        <div className="modal-title">
                            <FTIcon iconName="ft ft-remove" />
                            <h6 className="demi-bold">{FT_MODAL_CONTENT.DELETE_PROPOSAL.HEADING}</h6>
                        </div>
                        <div className="modal-confirm-content">
                            {`${FT_MODAL_CONTENT.DELETE_PROPOSAL.BODY} "${formatWrapEllipsis(
                                selectedProposalToDelete?.name || ""
                            )}"?`}
                        </div>
                        <article className="delete-proposal-footer">
                            <FTButton
                                className="left-aligned-btn"
                                variant="outlined"
                                onClick={() => setShowDeleteModal(false)}
                                disabled={deleteProposalLoading}
                            >
                                {FT_MODAL_CONTENT.DELETE_PROPOSAL.CANCEL_BTN}
                            </FTButton>
                            <FTButton
                                className="left-aligned-btn"
                                disabled={deleteProposalLoading}
                                onClick={() => handleDeleteProposal()}
                            >
                                {deleteProposalLoading ? "Loading..." : FT_MODAL_CONTENT.DELETE_PROPOSAL.CONFIRM_BTN}
                            </FTButton>
                        </article>
                    </section>
                </FTModal>
            )}
        </section>
    ) : (
        <NoProposalsSection />
    );
};

export default ProposalsSection;

import { Box } from "@mui/material";
import { RiskScore } from "@types";
import FTPopover from "atoms/FTPopover";
import FTRadial from "atoms/FTRadial";
import { useAppSelector, useSelectedProposal } from "hooks";
import { calculateCurrentAge, calculateSumOfAllOtherWealthValues, formatter, riskToDegrees } from "utils/helper";
import FTPortfolioAllocationChart from "./FTPortfolioAllocationChart";
import FTStackedBarChart from "./FTStackedBarChart";
import { AllocationAtAge } from "./portfolio-mapper-helpers";
import useGetPortfolio from "hooks/useGetPortfolio";
// import { goe4dTranslation } from "@constants/mock-data";

const PortfolioTab = () => {
    const { riskScore, wealthItems } = useSelectedProposal();

    const { viewProposalGraphDescription, viewProposalDisclaimers } = useAppSelector((state) => state.bloomreachCDN);
    const { projectedGIDescription } = viewProposalGraphDescription || {};
    const { mpGraphDisclaimer, portfolioTabDisclaimer } = viewProposalDisclaimers || [];

    const { birthdate } = useAppSelector((state) => state?.selectedContact);
    const lifeSpan = useAppSelector((state) => state?.global.orgInfo?.settings?.lifeSpan);

    const { selectedPortfolioData, portfolioDataConfig, portfolioPath, recommendedPortfolioId } = useGetPortfolio();

    const investmentAccountValue = calculateSumOfAllOtherWealthValues(wealthItems);
    const riskSuitability = riskScore;

    const renderInvestmentAccountValue = (investmentAccountValue: number) => {
        return (
            <div className="qf-label-value">
                <div className="qf-label-value__label">Investment account value</div>
                <div className="qf-label-value__value">{formatter.format(investmentAccountValue)}</div>
            </div>
        );
    };

    const renderRiskSuitabilityValue = (riskSuitability: RiskScore) => {
        return (
            <div className="qf-label-value">
                <div className="qf-label-value__label">Risk suitability</div>
                <div className="qf-label-value__value">{riskSuitability}</div>
            </div>
        );
    };

    const getRecommendedPortfolioId = () => recommendedPortfolioId;

    let projectedAllocatedData: any = [];
    let concatedPortfoliPath: any = [recommendedPortfolioId, ...portfolioPath];

    concatedPortfoliPath?.forEach((model: any) => {
        portfolioDataConfig[0]?.forEach((_item: any) => {
            if (_item.portfolioId === model) {
                let val = {};
                _item.assets?.forEach((i: any) =>
                    Object.assign(val, {
                        [i.type]: i.totalAllocatedvalue,
                    })
                );
                projectedAllocatedData.push(val);
            }
        });
    });

    let currentAge = calculateCurrentAge(birthdate);
    const ageArray: any = [];
    for (let age = currentAge; age <= lifeSpan; age++) {
        ageArray.push(age);
    }

    const calculateProjectedAllocationData = (ageArray: [], projectedAllocatedData: []) => {
        let projectedAllocationData: Array<AllocationAtAge> = projectedAllocatedData?.map(
            (item: any, index: number) => {
                return {
                    age: ageArray[index],
                    equity: item["Equities"],
                    fixedIncome: item["Fixed Income"],
                    cash: item["Cash"],
                };
            }
        );
        return projectedAllocationData;
    };

    return (
        <>
            <div className="portfolio-allocation-body-container">
                <div className="portfolio-allocation-description">
                    <div className="portfolio-allocation-header">
                        <h5>Portfolio </h5>
                    </div>
                    <div className="portfolio-allocation__investment-info">
                        <div data-testid="portfolio-allocation__investment-account-value">
                            {renderInvestmentAccountValue(investmentAccountValue)}
                        </div>
                        <div data-testid="portfolio-allocation__risk-suitability-value">
                            {renderRiskSuitabilityValue(riskSuitability)}
                        </div>
                    </div>
                    <div className="portfolio-allocation-name">
                        <h5>{`Model Portfolio ${getRecommendedPortfolioId()}`}</h5>
                    </div>
                    <p className="bold"> Investment objective </p>
                    {selectedPortfolioData?.attributesObjective}

                    <p className="bold"> Investment horizon </p>
                    {selectedPortfolioData?.attributesHorizon}
                </div>
                <div className="portfolio-allocation-detail">
                    <div className="risk-radial-container">
                        <p className="bold"> Client's attitude to risk </p>

                        <div className="risk-radial__chart">
                            <FTRadial size="sm" dialDegrees={riskToDegrees(riskScore)} />
                            <div className="risk-radial__risk-name">{riskScore}</div>
                        </div>
                    </div>
                    <div className="portfolio-allocation-link-container">
                        <span className="icon pdf-icon"></span>
                        <a target="_blank" href="http://api.ft.com/xxxxxxxx" rel="noreferrer">
                            Portfolio Factsheet
                        </a>
                    </div>
                </div>
            </div>

            <br />
            <br />

            <div className="chart-content-container">
                <div className="chart-content__content">
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <h5> Allocation </h5>
                        <FTPopover
                            type="secondary"
                            message="See the breakdown of the potential investments which will enable your client to achieve the preferred retirement plan"
                        />
                    </Box>
                </div>
            </div>

            <FTPortfolioAllocationChart />
            <p className="disclaimer">{mpGraphDisclaimer && mpGraphDisclaimer}</p>

            <br />

            <div className="chart-content-container">
                <div className="chart-content__content">
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <h5> Projected Allocation </h5>
                        <FTPopover
                            type="secondary"
                            message="The projected allocation is an indication of the future investment mix. It does not set out the underlying funds. These allocations will be subject to change"
                        />
                    </Box>
                </div>
                <div className="chart-content__description">
                    <p className="disclaimer">{projectedGIDescription && projectedGIDescription}</p>
                </div>
            </div>

            <FTStackedBarChart chartData={calculateProjectedAllocationData(ageArray, projectedAllocatedData)} />
            <p className="disclaimer">{portfolioTabDisclaimer && portfolioTabDisclaimer}</p>
        </>
    );
};

export default PortfolioTab;

import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { fetchBloomreachGeneralContent } from "api";
import { useEffect, useState } from "react";

const BlogCard = () => {
    const [content, setContent] = useState([]);
    const fetchBlogContent = async () => {
        const fetchedContent = await fetchBloomreachGeneralContent();
        setContent(fetchedContent?.blogData);
    };
    useEffect(() => {
        fetchBlogContent();
    }, []);
    return (
        <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12 }}>
            {content?.map(({ imageTileURL, articleURL, header, body }, index) => (
                <Grid item xs={4} sm={4} md={4} key={index}>
                    <Card className="explore-heading-cards" elevation={0} sx={{ borderRadius: 0 }}>
                        <CardActionArea
                            component="a"
                            href={articleURL}
                            target="_blank"
                            // onClick={() => console.log("CardActionArea clicked")} // can be use for tracking
                            disableRipple
                        >
                            <CardMedia
                                component="img"
                                height="256"
                                image={imageTileURL}
                                alt={header}
                                sx={{ paddingBottom: "1rem" }}
                            />
                            <CardContent sx={{ padding: "1rem 0" }}>
                                <div className="_article-content">
                                    <h6>{header}</h6>
                                </div>
                                <Typography variant="body2" className="_article-footer">
                                    {body}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default BlogCard;

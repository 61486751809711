import "./proposal-pdf.styles.scss";
import FTIcon from "atoms/FTIcon";
import PdfHeader from "components/PdfHeader";
import PdfProposalInfo from "components/PdfProposalInfo/Index";
import PdfConsumptionChart from "components/PdfConsumptionChart";
import PdfImplementationPlan from "components/PdfImplementaionPlan";
import FTPortfolioAllocationChart from "pages/ViewProposal/PortfolioTab/FTPortfolioAllocationChart";
import SuccessProbability from "pages/ViewProposal/SuccessProbability";
import {
    calculateSumOfAllOtherWealthValues,
    calculateTotalExpenditureValue,
    calculateTotalExpense,
    formatter,
    getMonthlyValue,
    totalIncome,
} from "utils/helper";
import { useAppSelector, useSelectedProposal } from "hooks";
import useGetPortfolio from "hooks/useGetPortfolio";
import moment from "moment";
import { Key, useEffect, useRef, useState } from "react";
import FTPdfModal from "atoms/FTPdfModal";
import { userShortInfo } from "redux/reducer";

// const MockMiscStringsState = {
//     economicAssumptions: [
//         {
//             name: "Rulebook Numerical Assumptions",
//             items: [
//                 {
//                     assumption: "Risk Aversion Parameters",
//                     assumptionDesc:
//                         "State either conservative, moderate or aggressive and provide a definition for each.",
//                 },
//                 {
//                     assumption: "Longevity Tables",
//                     assumptionDesc: "Source and date to be provided",
//                 },
//                 {
//                     assumption: "Annuity Quotes by health level and age",
//                     assumptionDesc: "Values and expiry date",
//                 },
//             ],
//         },
//         {
//             name: "Capital Market Assumptions & Benchmarks",
//             nameDesc:
//                 "Retirement Income Optimiser incorporates historic and forward looking capital market data into the retirement income planning methodology, which have been utilised to create the proposal shown in this document. Outcomes are monitored against three strategic investment themes or asset characteristics, as shown below, alongside the foundation capital market benchmarks. * The capital market assumptions are based upon the insights of the investment manager and may not reflect the FRC standard assumptions for pension prediction reporting. This document is not a pension statement and is for the use of professionals only",
//             items: [
//                 {
//                     assumption: "Growth / Risk Asset – asset providing potential for long term capital appreciation",
//                     assumptionDesc: "Defined as 80% MSCI ACWI GBP: 20% FTSE All Share GBP",
//                 },
//                 {
//                     assumption:
//                         "Defensive Asset – asset providing short term risk reduction and diversification to the growth/risk asset",
//                     assumptionDesc: "Defined as Bloomberg Barclays Global Aggregate GBP (hedged)",
//                 },
//                 {
//                     assumption:
//                         "Cash – used within portfolios below the tangency mix of the growth and defensive assets",
//                     assumptionDesc: "Return expectation zero, volatility expectation zero.",
//                 },
//                 {
//                     assumption: "Model Portfolio",
//                     assumptionDesc:
//                         "Each of the model portfolios are reviewed against a composite blend of the 2 foundation benchmarks in proportion to their stated long-term SAA (e.g Portfolio 5 - 40% MSCI ACWI GBP; 10% FTSE All Share Composite; 50% BBbg Global Agg GBP (Hedged)).",
//                 },
//                 {
//                     assumption: "Bequest Parameter",
//                     assumptionDesc: "++ Bloomreach content to be added",
//                 },
//                 {
//                     assumption: "Time Preference Parameter",
//                     assumptionDesc: "++ Bloomreach content to be added",
//                 },
//             ],
//         },
//         {
//             name: "Calculation Engine Numerical Assumptions",
//             items: [
//                 {
//                     assumption: "Annuity quote for comparators",
//                     assumptionDesc: "++ Bloomreach content to be added",
//                 },
//                 {
//                     assumption: "Inflation Forecast",
//                     assumptionDesc: "++ Bloomreach content to be added",
//                 },
//                 {
//                     assumption: "Annuity Quotes for Projections",
//                     assumptionDesc: "++ Bloomreach content to be added",
//                 },
//                 {
//                     assumption: "Minimum annuitization allowed",
//                     assumptionDesc: "++ Bloomreach content to be added",
//                 },
//                 {
//                     assumption: "# of Paths in Projections",
//                     assumptionDesc: "500",
//                 },
//             ],
//         },
//     ],
// };

const ProposalPdf = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const {
        wealthItems,
        expenditures,
        isMonthlyOn,
        definedBenefitPensions,
        otherIncomeItems,
        statePensionIncome,
        feeRate,
        cashPotAmount,
        lifeEvents,
        riskAversion,
        riskProvider,
        riskScore,
        isIncludeAnnuities,
        expendituresMeta,
    } = useSelectedProposal();

    const {
        name: selectedProposalName,
        date: selectedProposalDate,
        customData,
    } = useAppSelector((state) => state?.selectedProposal);
    const user = useAppSelector(userShortInfo);

    const { label, birthdate, postcode } = useAppSelector((state) => state?.selectedContact);

    const { selectedPortfolioData } = useGetPortfolio();

    const goe4dTranslation = useAppSelector((state) => state?.goe4dTranslation);
    const { economicAssumptions } = useAppSelector((state) => state?.bloomreachCDN);
    const feeStructure = useAppSelector((state) => state?.global?.orgInfo?.feeStructure);
    const spireIndicativeQuote = useAppSelector((state) => state.spireIndicativeQuote);

    const [feeCharge, setFeeCharge] = useState<{
        value: string | number;
        error: string;
    }>({ value: feeRate * 100, error: "" });
    const [cashValue, setCashValue] = useState(0);
    const totalExpenditureYearly = calculateTotalExpenditureValue(expenditures);
    const monthlyRecommendedIncome = formatter.format(
        getMonthlyValue(goe4dTranslation?.body?.recommendedConsumption[0]) || 0
    );
    const annualRecommendIncome = formatter.format(goe4dTranslation?.body?.recommendedConsumption[0] || 0);
    const totalExpenditure = calculateTotalExpense(expenditures);

    const proposedAnnuityIncreaseAmount = spireIndicativeQuote?.illustrationDetails?.premiumValue || 0;
    const totalAccIncome = totalIncome(definedBenefitPensions, otherIncomeItems, statePensionIncome.value);
    const cashReservesRate = useAppSelector((state) => state?.global?.orgInfo?.settings?.cashReservesRate);
    const totalWealth = Number(calculateSumOfAllOtherWealthValues(wealthItems));
    const cashReserves = totalWealth * Number(feeRate);
    const totalCashReserves = Number(cashReservesRate * totalWealth) + Number(cashReserves);

    useEffect(() => {
        if (feeRate === null) {
            if (totalWealth < feeStructure[0].to) {
                setFeeCharge({
                    error: "",
                    value: (feeStructure[0].fee * 100)?.toFixed(2),
                });
            } else if (totalWealth < feeStructure[1].to) {
                setFeeCharge({
                    error: "",
                    value: (feeStructure[1].fee * 100)?.toFixed(2),
                });
            } else if (totalWealth < feeStructure[2].to) {
                setFeeCharge({
                    error: "",
                    value: (feeStructure[2].fee * 100)?.toFixed(2),
                });
            }
        }
    }, [totalWealth, feeStructure, feeRate, cashValue]);

    useEffect(() => {
        setCashValue((totalWealth * +feeCharge.value) / 100);
    }, [feeCharge, totalWealth]);
    const divRef = useRef<HTMLDivElement>(null);

    return (
        <FTPdfModal onClose={onClose} open={open} pdfContainerRef={divRef}>
            <div className="proposal-pdf pdf-content-container" ref={divRef}>
                <div className="proposal-pdf__body">
                    <div className="pdf-page">
                        <PdfHeader
                            title="Retirement Income Optimiser"
                            isProposalPdf={true}
                            clientName={label}
                            qrCodeUrl="https://franklintempletonprod.widen.net/content/lmnf86kmfb/jpeg/QRCode.png"
                        />
                        <PdfProposalInfo
                            proposalDate={selectedProposalDate}
                            proposalName={selectedProposalName}
                            finacialAdvisorName={user?.loggedInUsername}
                            isProposalPdf={true}
                        />
                        <SuccessProbability isPdf={true} />
                        <div className="pdf-content proposal-tab-container">
                            <div className="proposal-tab">
                                <h4 className="proposal-tab__title">Target income for next 12-months</h4>
                                <h6 className="proposal-tab__value">
                                    {isMonthlyOn ? monthlyRecommendedIncome : annualRecommendIncome}
                                </h6>
                            </div>
                            <div className="proposal-tab">
                                <h4 className="proposal-tab__title">Portfolio Value</h4>
                                <h6 className="proposal-tab__value">{formatter.format(totalWealth)}</h6>
                            </div>
                            <div className="proposal-tab">
                                <h4 className="proposal-tab__title">Additional Aunnity Income (per annum)</h4>
                                <h6 className="proposal-tab__value">
                                    {formatter.format(proposedAnnuityIncreaseAmount)}
                                </h6>
                            </div>
                        </div>
                        <div className="pdf-content retirement-income-optimiser-container">
                            <div className="retirement-income-optimiser__content">
                                <p>Retirement Income Optimiser</p>
                                <h1>A dynamic approach to sustainable income</h1>
                                <div>
                                    We have taken into account your client’s total wealth, health, life event
                                    expenditure and income to create a totally personalised plan designed to meet their
                                    income requirements for years to come. We believe the Retirement Income Optimiser’s
                                    advanced technology offers the prospect of a superior income to traditional pensions
                                    over the long term, plus the flexibility to alter arrangements as circumstances
                                    inevitably change.
                                </div>
                            </div>
                            <div className="retirement-income-optimiser__steps">
                                <div className="retirement-income-optimiser__steps-container">
                                    <div className="retirement-income-optimiser__steps-left-step">
                                        <div className="retirement-income-optimiser__step step2">
                                            <h2 className="bold">2</h2>
                                            <div className="retirement-income-optimiser__step__title">
                                                Advanced analytics
                                            </div>
                                            <p>
                                                are used to dynamically optimise income through changing needs and
                                                markets
                                            </p>
                                        </div>
                                    </div>
                                    <div className="retirement-income-optimiser__steps-right-step">
                                        <div className="retirement-income-optimiser__step step1">
                                            <h2 className="bold">1</h2>
                                            <div className="retirement-income-optimiser__step__title">
                                                Total personalisation
                                            </div>
                                            <p>to your client’s specific needs and circumstances</p>
                                        </div>
                                        <div className="retirement-income-optimiser__step step3">
                                            <h2 className="bold">3</h2>
                                            <div className="retirement-income-optimiser__step__title">
                                                Increase engagement
                                            </div>
                                            <p>
                                                and promote richer interaction with your client to create a better
                                                income solution
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pdf-content summary">
                            <div className="steps__title-container">
                                <div className="steps__title__icon  floating sm">
                                    <div className="steps__title__icon__number">1</div>
                                </div>
                                <h5 className="steps__title">
                                    <span className="steps__title__label">Summary</span>
                                </h5>
                            </div>
                            <div>
                                This is where it all comes into focus. Please look below to see how your client’s target
                                income can be realistically achieved by the dynamic combination of assets at your
                                command. In short, the outcome is a better income
                            </div>
                            <div className="summary__expenditure-level">
                                <h4 className="summary__expenditure-level__label">Expenditure level required:</h4>
                                <h4 className="summary__expenditure-level__value">
                                    {formatter.format(calculateTotalExpense(expenditures) || 0)}
                                </h4>
                            </div>
                            <div className="summary__annual-income">
                                <h6 className="summary__annual-income__label">
                                    How your client’s annual income is calculated:
                                </h6>
                                <div className="summary__annual-income__card-holder">
                                    <div className="summary__annual-income__card">
                                        <div className="summary__annual-income__container">
                                            <FTIcon iconName={"ft-handMoney"} className="proposal-pdf-icon" />
                                            <h6 className="summary__annual-income__card__value">
                                                {formatter.format(totalAccIncome)}
                                            </h6>
                                            <div className="summary__annual-income__card__label">Existing Income</div>
                                        </div>
                                    </div>
                                    <div className="summary__annual-income__operator">+</div>
                                    <div className="summary__annual-income__card">
                                        <div className="summary__annual-income__container">
                                            <FTIcon iconName={"ft-handMoney"} className="proposal-pdf-icon" />
                                            <h6 className="summary__annual-income__card__value">
                                                {formatter.format(proposedAnnuityIncreaseAmount)}
                                            </h6>
                                            <div className="summary__annual-income__card__label">
                                                New annuity income
                                            </div>
                                        </div>
                                    </div>
                                    <div className="summary__annual-income__operator">+</div>
                                    <div className="summary__annual-income__card">
                                        <div className="summary__annual-income__container">
                                            <FTIcon iconName={"ft-statePension"} className="proposal-pdf-icon" />
                                            <h6 className="summary__annual-income__card__value">
                                                {formatter.format(0 - totalExpenditure)}
                                            </h6>
                                            <div className="summary__annual-income__card__label">Drawdown</div>
                                        </div>
                                    </div>
                                    <div className="summary__annual-income__operator">=</div>
                                    <div className="summary__annual-income__card total">
                                        <div className="summary__annual-income__container">
                                            <FTIcon iconName={"ft-drawerAnnuity"} className="proposal-pdf-icon" />
                                            <h6 className="summary__annual-income__card__value">
                                                {formatter.format(totalAccIncome - totalExpenditure)}
                                            </h6>
                                            <div>Proposed income</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pdf-content">
                            <div className="steps__title-container">
                                <div className="steps__title__icon  floating sm">
                                    <div className="steps__title__icon__number">2</div>
                                </div>
                                <h5 className="steps__title">
                                    <span className="steps__title__label">Outcomes Review</span>
                                </h5>
                            </div>
                            <PdfConsumptionChart />
                        </div>
                        <div className="pdf-content">
                            <div className="portfolio-description-container">
                                <h5 className="portfolio-description__title">Model Portfolio</h5>
                                <div className="portfolio-investment-objective">
                                    <h6>Investment objective</h6>
                                    <div>{selectedPortfolioData?.attributesObjective}</div>
                                </div>
                                <div className="portfolio-investment-strategy">
                                    <h6>Investment horizon</h6>
                                    <div>{selectedPortfolioData?.attributesHorizon}</div>
                                </div>
                            </div>
                            <FTPortfolioAllocationChart isPdf={true} />
                        </div>
                        <PdfImplementationPlan step={3} />
                        <div className="pdf-content implementation-plan__description">
                            The following table sets out the indicative costs and charges associated with the retirement
                            drawdown plan, as calculated using the Retirement Income Optimiser. These are based upon the
                            inputs provided by the adviser and the product and fund charges, based upon the Model
                            Portfolio 5 model portfolio allocation, as at 13 September 2022. Costs and charges reduce
                            the level of income that a client may achieve from the plan. It is therefore an important
                            aspect when considering the options. The estimate is as follows:
                            {/* {implementationPlanDescription} */}
                        </div>
                        <div className="pdf-content charges-table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Funds Charge</th>
                                        <th colSpan={2}>Advise Charges</th>
                                        <th colSpan={2}>Platform Charges</th>
                                        <th colSpan={2}>Other Charges</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="charges-item__name">Initial Charge</div>
                                        </td>
                                        <td className="charges-item__value">
                                            0.6%
                                            {/* {initialCharge | qfPercent} */}
                                        </td>
                                        <td>
                                            <div className="charges-item__name">Annual Review</div>
                                        </td>
                                        <td className="charges-item__value">
                                            0.4%
                                            {/* {adviceCharges | qfPercent} */}
                                        </td>
                                        <td>
                                            <div className="charges-item__name">Embark Platform</div>
                                        </td>
                                        <td className="charges-item__value">
                                            1.0%
                                            {/* {feeRateAdjustment | qfPercent} */}
                                        </td>
                                        <td>
                                            <div className="charges-item__name">-</div>
                                        </td>
                                        <td className="charges-item__value">
                                            0.0%
                                            {/* {otherCharges | qfPercent} */}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={7} className="total-changes-label">
                                            Total Charges
                                        </td>
                                        <td className="total-changes-value charges-item__value">
                                            2.0%
                                            {/* {totalChargeRate | qfPercent} */}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="pdf-content implementation-plan__disclaimer">
                            Assumption: Platform fee is a fixed fee, applied to all wealth assets. The fund costs are
                            derived from the fact-sheets of the underlying funds, based upon the latest valuation.
                            Further details can be obtained from{" "}
                            <a href="http://www.franklintempletonglobal.com">
                                http://www.franklintempletonglobal.com
                                <br />
                                <br />
                            </a>{" "}
                            As a 'distributor' of our products, you need to provide your clients with details of all the
                            costs & charges they’ll incur as result of making their investment. This will include
                            product and fund charges, any additional services charges and your advice charges. It is the
                            responsibility of the Adviser to ensure the accurate costs & charges inputs have been
                            uploaded in order to determine an accurate plan which reflects the client.
                            <br />
                            <br />
                            Separate fact sheets exist for each underlying product in the plan. The assumption used in
                            the plan, include growth rates. The growth rate provided in this document is an indication
                            and is not guaranteed. Actual costs and charges may depend on the performance of the Fund.
                            The total charges deducted for any Fund will have an impact on the investment return your
                            client might get. Whilst performance can’t be guaranteed, we can give you examples of how
                            the charges will affect what they might get back.
                            <br />
                            <br />
                            This illustration assumes that costs occur evenly throughout the year, charges are paid out
                            of the investment evenly throughout the year, and that a gross growth rate (as shown in the
                            assumptions below) applies. The figures are estimates and may change.
                        </div>
                        <div className="pdf-content economic-assumption__container">
                            <h5 className="pdf-content economic-assumption__title">Economic assumption</h5>
                            {economicAssumptions?.map(({ name, nameDesc, items }, index) => (
                                <div className="statement-facts__session" key={index}>
                                    <div className="pdf-content">
                                        <h6 className="statement-facts__name">{name}</h6>
                                        <div className="statement-facts__description">{nameDesc}</div>
                                    </div>
                                    <div className="statement-facts__table">
                                        {items.map(({ assumption, assumptionDesc }, index) => (
                                            <div className="pdf-content statement-facts__row" key={index}>
                                                <div className="statement-facts__label">{assumption}</div>
                                                <div className="statement-facts__value">{assumptionDesc}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="pdf-content statement-facts-container">
                            <h5 className="statement-facts__title">Statement of Facts</h5>
                            <div className="pdf-content statement-facts__description">
                                The section below summarises the personal details on which the Retirement Income
                                Optimiser proposal has been based
                            </div>
                            <div className="statement-facts__session">
                                <h6 className="pdf-content statement-facts__name">1. Personal Information</h6>
                                <div className="statement-facts__table">
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">What is your client’s age?</div>
                                        <div className="statement-facts__value">
                                            {moment().diff(moment(birthdate), "years") || "-"}
                                        </div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">What is your client’s postcode?</div>
                                        <div className="statement-facts__value">{postcode}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="statement-facts__session">
                                <h6 className="pdf-content statement-facts__name">2. Wealth</h6>
                                <div className="statement-facts__table">
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            How much does your client have to invest?
                                        </div>
                                        <div className="statement-facts__value">{formatter.format(totalWealth)}</div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is the breakdown of this wealth?
                                        </div>
                                        <div className="statement-facts__value">
                                            <ul>
                                                {wealthItems?.map(
                                                    (
                                                        {
                                                            name,
                                                            value,
                                                        }: {
                                                            name: string;
                                                            value: number;
                                                        },
                                                        index: Key
                                                    ) => (
                                                        <li className="statement-facts__value__list-item" key={index}>
                                                            {name}: {formatter.format(value)}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="statement-facts__session">
                                <h6 className="pdf-content statement-facts__name">3. Income</h6>
                                <div className="statement-facts__table">
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            How much does your client receive as income?
                                        </div>
                                        <div className="statement-facts__value">{formatter.format(totalAccIncome)}</div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is the breakdown of this income?
                                        </div>
                                        <div className="statement-facts__value">
                                            <div>
                                                <ul>
                                                    {definedBenefitPensions?.map(
                                                        (
                                                            {
                                                                provider,
                                                                value,
                                                            }: {
                                                                provider: string;
                                                                value: number;
                                                            },
                                                            index: Key
                                                        ) => (
                                                            <li
                                                                className="statement-facts__value__list-item"
                                                                key={index}
                                                            >{`${provider}, Defined Benefit Pension: ${formatter.format(
                                                                value
                                                            )}`}</li>
                                                        )
                                                    )}
                                                    {otherIncomeItems?.map(
                                                        (
                                                            {
                                                                name,
                                                                type,
                                                                value,
                                                            }: {
                                                                name: string;
                                                                type: string;
                                                                value: number;
                                                            },
                                                            index: Key
                                                        ) => (
                                                            <li
                                                                className="statement-facts__value__list-item"
                                                                key={index}
                                                            >{`${name}, ${type}: ${formatter.format(value)}`}</li>
                                                        )
                                                    )}
                                                    {statePensionIncome?.value && (
                                                        <li className="statement-facts__value__list-item">
                                                            State Pension Amount:{" "}
                                                            {formatter.format(customData.statePensionIncome.value)}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="statement-facts__session">
                                <h6 className="pdf-content statement-facts__name">4. Expenditure</h6>
                                <div className="statement-facts__table">
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            Apply uplift factor (recommended for HNW clients)
                                        </div>
                                        <div className="statement-facts__value">{expendituresMeta?.isInLondon}</div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            Will your client be supporting a partner who has no income when they retire?
                                        </div>
                                        <div className="statement-facts__value">{expendituresMeta?.isCouple}</div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What retirement lifestyle choice was selected?
                                        </div>
                                        <div className="statement-facts__value">
                                            {expendituresMeta?.lifestyle || ""}
                                        </div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is the total sum of expected expenditure?
                                        </div>
                                        <div className="statement-facts__value">
                                            {formatter.format(totalExpenditureYearly)}
                                        </div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is the breakdown of this expenditure value?
                                        </div>
                                        <div className="statement-facts__value">
                                            {expenditures && (
                                                <ul>
                                                    <li className="statement-facts__value__list-item">
                                                        Food And Drink:{" "}
                                                        {formatter.format(expenditures?.clothingAndFootwear)}
                                                    </li>
                                                    <li className="statement-facts__value__list-item">
                                                        Clothing And Footwear:{" "}
                                                        {formatter.format(expenditures?.clothingAndFootwear)}
                                                    </li>
                                                    <li className="statement-facts__value__list-item">
                                                        Housing costs and bills:{" "}
                                                        {formatter.format(expenditures?.housing)}
                                                    </li>
                                                    <li className="statement-facts__value__list-item">
                                                        Personal goods and services:{" "}
                                                        {formatter.format(expenditures?.personal)}
                                                    </li>
                                                    <li className="statement-facts__value__list-item">
                                                        Transport: {formatter.format(expenditures?.transport)}
                                                    </li>
                                                    <li className="statement-facts__value__list-item">
                                                        Social, cultural and holidays:{" "}
                                                        {formatter.format(expenditures?.socialAndCultural)}
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            Does your client have any life events?
                                        </div>
                                        <div className="statement-facts__value">
                                            {lifeEvents ? (
                                                <ul>
                                                    {lifeEvents?.map(
                                                        (
                                                            {
                                                                name,
                                                                age,
                                                                value,
                                                                type,
                                                            }: {
                                                                name: string;
                                                                age: number;
                                                                type: string;
                                                                value: number;
                                                            },
                                                            index: Key
                                                        ) => (
                                                            <li
                                                                className="statement-facts__value__list-item"
                                                                key={index}
                                                            >{`${name}, At age ${age}, ${type}: ${value}`}</li>
                                                        )
                                                    )}
                                                </ul>
                                            ) : (
                                                <span>No</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="statement-facts__session">
                                <h6 className="pdf-content statement-facts__name">5. Risk Suitability</h6>
                                <div className="statement-facts__table">
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is the sensitivity to annual increases/decreases in income?
                                        </div>
                                        <div className="statement-facts__value">{riskAversion}</div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is your client’s attitude to risk?
                                        </div>
                                        <div className="statement-facts__value">
                                            For risk provider {riskProvider}, your client risk score is {riskScore}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="statement-facts__session">
                                <h6 className="pdf-content statement-facts__name">6. Annuity</h6>
                                <div className="statement-facts__table">
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            Has your client opted to get a recommendation that includes an annuity?
                                        </div>
                                        <div className="statement-facts__value">{isIncludeAnnuities}</div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is your client\'s health profile?
                                        </div>
                                        <div>
                                            <div className="statement-facts__value">
                                                Health Name
                                                {/* healthProfile.name */}
                                            </div>
                                            <div className="statement-facts__value">
                                                Health Content
                                                {/* healthProfile.content */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="statement-facts__session">
                                <h6 className="pdf-content statement-facts__name">7. Cash Reserves</h6>
                                <div className="statement-facts__table">
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What is the total value of the cash reserves your client will hold?
                                        </div>
                                        <div className="statement-facts__value">
                                            {formatter.format(totalCashReserves)}
                                        </div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What are the fees and annual charges for your client?
                                        </div>
                                        <div className="statement-facts__value">
                                            {`${feeRate} which equates to a cash value of ${formatter.format(
                                                cashValue
                                            )}`}
                                        </div>
                                    </div>
                                    <div className="pdf-content statement-facts__row">
                                        <div className="statement-facts__label">
                                            What additional cash reserves does a client want to hold?
                                        </div>
                                        <div className="statement-facts__value">{cashPotAmount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pdf-content">
                            <p className="reminder">
                                *This material is intended to be of general interest only and should not be construed as
                                individual investment advice or a recommendation or solicitation to buy, sell or hold
                                any security or to adopt any investment strategy. It does not constitute legal or tax
                                advice. Your client should carefully consider a fund's investment goals, risks, charges
                                and expenses before investing.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="proposal-pdf pdf-content-container hidden-pdf-container">
                <div id="hidden-pdf-page" className="pdf-page"></div>
            </div>
        </FTPdfModal>
    );
};

export default ProposalPdf;

import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { updateFTModalState } from "auth/globalSlice";
import FTButton from "atoms/FTButton";
import FTModal from "atoms/FTModal";
import FTInputXL from "atoms/FTInputXL";
import {
	hasValue,
	removeExtraSpaces,
	validateOnlyLetters,
} from "utils/validateInputs";
import { FT_MODAL_CONTENT } from "@constants";
import "./new-proposal.styles.scss";

const NewProposal = ({
	handleAddProposal,
	addProposalLoading,
}: {
	handleAddProposal: (
		newProposalName: string,
		newProposalNameError: boolean
	) => void;
	addProposalLoading: boolean;
}) => {
	const dispatch = useAppDispatch();
	const { showFTModal } = useAppSelector((state) => state.global);
	const proposals = useAppSelector((state) => state.proposals);
	const [newProposalName, setNewProposalName] = useState<string>("");
	const [alreadyExistsError, setAlreadyExistsError] = useState<boolean>(false);
	const [newProposalNameError, setNewProposalNameError] =
		useState<boolean>(false);
	const isNewProposalModalOpen = () =>
		showFTModal === FT_MODAL_CONTENT.NEW_PROPOSAL.VALUE;
	const alreadyExists = (value: string) => {
		return Boolean(
			proposals?.find(({ name }: { name: string }) => name === value)
		);
	};
	const handleFTModalClose = () => {
		setNewProposalName("");
		setNewProposalNameError(false);
		dispatch(updateFTModalState(false));
	};
	const handleNewProposalNameChange = (value: string) => {
		setNewProposalName(value);
		setNewProposalNameError(
			hasValue(value.trim()) && !validateOnlyLetters(value, true)
		);
		setAlreadyExistsError(hasValue(value.trim()) && alreadyExists(value));
	};
	const handleNewProposalNameBlur = (value: string) => {
		handleNewProposalNameChange(
			hasValue(value.trim()) ? removeExtraSpaces(value.trim()) : ""
		);
	};

	return (
		<FTModal open={isNewProposalModalOpen()} handleClose={handleFTModalClose}>
			<section className="new-proposal-container">
				<h6 className="demi-bold">{FT_MODAL_CONTENT.NEW_PROPOSAL.HEADING}</h6>
				<FTInputXL
					placeholder={FT_MODAL_CONTENT.NEW_PROPOSAL.PLACEHOLDER}
					autoFocus
					error={newProposalNameError}
					value={newProposalName}
					onChange={(e) => handleNewProposalNameChange(e.target.value)}
					onBlur={(e) => handleNewProposalNameBlur(e.target.value)}
					onEnter={() =>
						!(!hasValue(newProposalName) ||
							newProposalNameError ||
							alreadyExistsError ||
							addProposalLoading) &&
						handleAddProposal(newProposalName, newProposalNameError)
					}
					disabled={addProposalLoading}
				/>
				{newProposalNameError && (
					<p className="MuiFormHelperText-root">
						{FT_MODAL_CONTENT.NEW_PROPOSAL.ERROR_TEXT}
					</p>
				)}
				{alreadyExistsError && (
					<p className="MuiFormHelperText-root">
						{FT_MODAL_CONTENT.NEW_PROPOSAL.ALREADY_EXISTS_ERROR_TEXT}
					</p>
				)}
				<article className="new-proposal-footer">
					<FTButton
						className="left-aligned-btn"
						variant="outlined"
						onClick={handleFTModalClose}
						disabled={addProposalLoading}
					>
						{FT_MODAL_CONTENT.NEW_PROPOSAL.CANCEL_BTN}
					</FTButton>
					<FTButton
						className="left-aligned-btn"
						disabled={
							!hasValue(newProposalName) ||
							newProposalNameError ||
							alreadyExistsError ||
							addProposalLoading
						}
						onClick={() =>
							!(!hasValue(newProposalName) ||
							newProposalNameError ||
							alreadyExistsError ||
							addProposalLoading) && handleAddProposal(newProposalName, newProposalNameError)
						}
					>
						{addProposalLoading
							? "Loading..."
							: FT_MODAL_CONTENT.NEW_PROPOSAL.LETSGO_BTN}
					</FTButton>
				</article>
			</section>
		</FTModal>
	);
};

export default NewProposal;

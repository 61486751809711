import { FACTFINDINGPAGE } from "@constants";
import { factFindingSteps, factFindingStepsObject } from "@constants/finding-facts-constants";
import FTIcon from "atoms/FTIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import MonthlyAnnualToggle from "./MonthlyAnnualToggle";
import { StepTitle } from "@types";

const FactFindingSteps = (props: any) => {
    const { factFindingStep } = props;
    const navigate = useNavigate();
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const completedSteps = selectedProposal?.customData?.meta?.completedSteps;

    const routeToSelectedStep = (routeName: string) => {
        const factFindPageAbsPath = "/home/fact-finding/";
        navigate(`${factFindPageAbsPath}${routeName}`);
    };

    const renderStepsClasses = (step: any) => {
        const allSteps = Object.values(StepTitle);
        let updatedCompletedSteps: Array<string> = [];
        for (var i = 0; i < allSteps.length; i++) {
            updatedCompletedSteps.push(allSteps[i]);
            if (allSteps[i] === factFindingStepsObject[factFindingStep]?.title) break;
        }
        if (factFindingStep === step?.routeName) {
            // if (completedSteps?.length < 1) {
            //     return 'step active disabled'
            // }
            return "step active";
        } else if (updatedCompletedSteps?.includes(step.title)) {
            return "step completed";
        } else if (factFindingStep !== step?.title && !updatedCompletedSteps?.includes(step.title)) {
            return "step uncompleted disabled";
        }
    };

    return (
        <section className="step-container">
            <MonthlyAnnualToggle />

            <div className="fact-finding-steps">
                {factFindingSteps?.map((step: any, index: any) => (
                    <div
                        id={step.title}
                        onClick={() => routeToSelectedStep(step.routeName)}
                        key={index}
                        className={renderStepsClasses(step)}
                    >
                        <span className="icon step-icon">
                            <FTIcon iconName={completedSteps?.includes(step.title) ? "ft-checkmark" : step.iconClass} />
                        </span>
                        <div className="step-description">
                            <span className="step-index">
                                {FACTFINDINGPAGE.FFSUMMARYCARD.STEP} {index + 1}{" "}
                            </span>
                            <span>{step.title}</span>
                        </div>
                        {completedSteps?.includes(step.title) && (
                            <span className="icon-edit">
                                <FTIcon iconName="ft-edit" />
                            </span>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default FactFindingSteps;

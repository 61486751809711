import { FactFindingPageContainerPropTypes } from "@types";
import { useAppSelector, useSelectedProposal } from "hooks";
import Expenditure from "./Expenditure";
import Income from "./Income";
import RiskSuitability from "./RiskSuitability";
import Wealth from "./Wealth";

const FactFindingPageContainer = ({
    factFindingStep,
}: FactFindingPageContainerPropTypes) => {
    const bloomreachCDN = useAppSelector((state) => state?.bloomreachCDN);
    const { displayedProposal, setDisplayedProposal } = useSelectedProposal()
    const {
        findingFactDisclaimers: {
            IncomeFactFindDisclaimer,
            riskFactFindDisclaimer,
            wealthFactFindDisclaimer,
            expenditureFactFindDisclaimer,
        },
    } = bloomreachCDN;

    const renderFFSelectedPage: any = {
        wealth: {
            component: <Wealth />,
            disclaimerContent: wealthFactFindDisclaimer,
        },
        "benefits-and-income": {
            component: <Income />,
            disclaimerContent: IncomeFactFindDisclaimer,
        },
        expenditure: {
            component: (
                <Expenditure />
            ),
            disclaimerContent: expenditureFactFindDisclaimer,
        },
        risk: {
            component: (
                <RiskSuitability displayedProposal={displayedProposal} setDisplayedProposal={setDisplayedProposal} />
            ),
            disclaimerContent: riskFactFindDisclaimer,
        },
    };

    return (
        <div className="ft-page-block ft-page-block__x-padded ft-page-block__y-padded">
            {factFindingStep && renderFFSelectedPage[factFindingStep]?.component}

            <div className="_review-legal-content">
                <p>{factFindingStep && renderFFSelectedPage[factFindingStep]?.disclaimerContent}</p>
            </div>
        </div>
    );
};

export default FactFindingPageContainer;

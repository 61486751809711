import { FT_MODAL_CONTENT, PROPOSALS_SECTION } from "@constants";
import FTButton from "atoms/FTButton";
import FTMessageLite from "atoms/FTMessageLite";
import { updateFTModalState } from "auth/globalSlice";
import PageHeading from "containers/PageHeading";
import { useAppDispatch, useAppSelector } from "hooks";
import ProposalsSection from "pages/Home/ProposalsSection";
import { useEffect, useState } from "react";
import "../styles/proposals.styles.scss";

const Proposals = () => {
    const dispatch = useAppDispatch();
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const [showFloatingHeader, setShowFloatingHeader] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // setShowFloatingHeader (css: .shown) if scroll bar (Y) is not at the top (0)
            setShowFloatingHeader(window.scrollY !== 0);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <main className="proposals-container">
            <div className={`floating-header ${showFloatingHeader && "shown"}`}>
                <h5 className="floating-header__client-name long-text-wrap-ellipsis demi-bold">
                    {selectedContact?.label}
                </h5>
                <FTButton
                    className="primary-compact"
                    aria-disabled="false"
                    aria-label="Start New Proposal"
                    onClick={() => dispatch(updateFTModalState(FT_MODAL_CONTENT.NEW_PROPOSAL.VALUE))}
                >
                    <div className="qf-button__button-content">Start New Proposal</div>
                </FTButton>
            </div>
            <section className="proposals-header">
                <PageHeading pageHeading="Currently active proposals" iconName="ft-proposals-1" />
                <FTButton
                    className="bold-button"
                    onClick={() => dispatch(updateFTModalState(FT_MODAL_CONTENT.NEW_PROPOSAL.VALUE))}
                >
                    Start New Proposal
                </FTButton>
            </section>
            <FTMessageLite message={PROPOSALS_SECTION.INFO_MESSAGE} type="info" />
            <ProposalsSection />
        </main>
    );
};

export default Proposals;

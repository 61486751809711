import "./pdfConsumptionChart.style.scss";
import { Box } from "@mui/material";
import { useAppSelector } from "hooks";
import ConsumptionChart from "pages/ViewProposal/IncomeWealthTab/ConsumptionChart";
import WealthChart from "pages/ViewProposal/IncomeWealthTab/WealthChart";

const PdfConsumptionChart = () => {
    const { viewProposalGraphDescription, viewProposalDisclaimers } = useAppSelector((state) => state.bloomreachCDN);
    const { proposedIncomeGraphDescription } = viewProposalGraphDescription || {};
    const { consumptionTabDisclaimer } = viewProposalDisclaimers || [];
    return (
        <div className="pdf-content">
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <h5> Proposal Income </h5>
            </Box>
            <div className="line-chart-container">
                <div className="line-chart">
                    <ConsumptionChart isPdf={true} />
                </div>
                <div className="line-chart-description">
                    <h5>A better lifestyle is achievable</h5>
                    <div>{proposedIncomeGraphDescription && proposedIncomeGraphDescription}</div>
                </div>
            </div>
            <br />
            <div className="line-chart-container">
                <div className="line-chart">
                    <WealthChart isPdf={true} />
                </div>
                <div className="line-chart-description">
                    <h5>Preserving Wealth</h5>
                    <div>{consumptionTabDisclaimer && consumptionTabDisclaimer}</div>
                </div>
            </div>
            <br />
        </div>
    );
};

export default PdfConsumptionChart;

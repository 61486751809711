import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { sum } from "lodash";
import { Proposal } from "redux/models";

export class PdfExportService {
    pdfExportStrategy: PdfExportStrategy;

    constructor(strategy: PdfExportStrategy) {
        this.pdfExportStrategy = strategy;
    }
    public setStrategy(strategy: PdfExportStrategy) {
        this.pdfExportStrategy = strategy;
    }
}

interface PdfPageSetting {
    // Total Height of the elements inside the current page
    totalContentHeight: number;

    // What element is inside a the current page
    currPageElements: Element[];

    // Vertical start point in the result img
    verticalOffset: number;

    // Setting for table which exceed content height
    exceedPageTableSetting: ExceedPageTableSetting | null;
}

interface ExceedPageTableSetting {
    elementIndex: number;
    tableHeight: number;
    rowIndexes: number[];
    tablePosition: "" | "start" | "end";
}

interface PdfExportConfig {
    doc: jsPDF;
    pageWidth: number;
    contents: Element[];
    pageCounterInFooter: boolean;
    fileName: string;
    addHeader(): void;
    addFooter(content: string): void;
    a4Height: number;
    a4Padding: number;
}
export interface ProfileState {
    selectedContact: {
        displayName: string;
    };
}
export interface PdfExportStrategy {
    readonly footerHeight: number;
    readonly headerHeight: number;
    export(nativeElement: HTMLElement): Promise<void>;
}

export class TransactionExecutionPdf implements PdfExportStrategy {
    readonly footerHeight = 36;
    readonly headerHeight = 0;
    readonly a4Height = 842;
    readonly a4Padding = 24;

    doc: jsPDF;

    constructor(private _displayName: string, private _selectedProposal: Proposal, private _disclaimer: string) {
        this.doc = new jsPDF("p", "pt", "a4");
    }

    async export(nativeElement: HTMLElement): Promise<void> {
        // Get all content inside the pdf. Cannot just use nativeElement content because it might
        // has a page break problem.
        const domContents = Array.from(nativeElement.querySelectorAll(".pdf-content"));
        const config: PdfExportConfig = {
            doc: this.doc,
            pageWidth: this._pageWidth,
            pageCounterInFooter: false,
            contents: domContents,
            fileName: `TransactionExecutionPdf_${moment().format("YYYYMMDD")}_${this._displayName}.pdf`,
            addHeader: this._addHeader,
            addFooter: this._addFooter,
            a4Height: this.a4Height,
            a4Padding: this.a4Padding,
        };
        await exportPdf(config);
    }

    private _addHeader = () => {
        this.doc.setFillColor("#fff");
        this.doc.rect(0, -1, this._pageWidth, this.a4Padding, "F");
    };

    private _addFooter = (): void => {
        // const { tradeExecutionAnnuityPDF, tradeExecutionNoAnnuityPDF } = this._disclaimersState;
        const { isIncludeAnnuities } = this._selectedProposal.customData;
        const footerPosition = this.a4Height - this.footerHeight;
        this.doc.setFontSize(7);
        this.doc.setFillColor("#fff");
        this.doc.rect(0, footerPosition, this._pageWidth, this.a4Padding, "F");
        this.doc.setTextColor("#212121");
        this.doc.text(
            isIncludeAnnuities ? this._disclaimer : this._disclaimer,
            16,
            this.a4Height - this.footerHeight + 15,
            { maxWidth: this._pageWidth - 16 - 16 }
        );
    };

    private get _pageWidth() {
        return this.doc.internal.pageSize.width;
    }
}

export class ProposalPdf implements PdfExportStrategy {
    readonly footerHeight = 16;
    readonly headerHeight = 0;
    readonly a4Height = 842;
    readonly a4Padding = 24;

    doc: jsPDF;

    constructor(private _profileState: ProfileState) {
        this.doc = new jsPDF("p", "pt", "a4");
    }

    async export(nativeElement: HTMLElement): Promise<void> {
        const { selectedContact } = this._profileState;
        // Get all content inside the pdf. Cannot just use nativeElement content because it might
        // has a page break problem.
        const domContents = Array.from(nativeElement.querySelectorAll(".pdf-content"));
        // console.log(domContents);
        const config: PdfExportConfig = {
            doc: this.doc,
            pageWidth: this._pageWidth,
            pageCounterInFooter: true,
            contents: domContents,
            fileName: `ProposalPdf_${moment().format("YYYYMMDD")}_${selectedContact.displayName}.pdf`,
            addHeader: this._addHeader,
            addFooter: this._addFooter,
            a4Height: this.a4Height,
            a4Padding: this.a4Padding,
        };
        await exportPdf(config);
    }

    private _addHeader = () => {
        this.doc.setFillColor("#fff");
        this.doc.rect(0, -1, this._pageWidth, this.a4Padding, "F");
    };

    private _addFooter = (content: string) => {
        const footerPosition = this.a4Height - this.a4Padding + 12;
        this.doc.setFillColor("#fff");
        this.doc.rect(0, footerPosition, this._pageWidth, this.a4Padding, "F");
        this.doc.setTextColor("#C9C9C9");
        this.doc.setFontSize(14);
        this.doc.text(content, this._pageWidth - 20, footerPosition + this.a4Padding / 2 - 5, {
            align: "right",
            maxWidth: this._pageWidth - 16 - 16,
        });
    };

    private get _pageWidth() {
        return this.doc.internal.pageSize.width;
    }
}

async function exportPdf(config: PdfExportConfig) {
    const pageSettings = calculatePages(config);
    await generatePdf(pageSettings, config);
}

function calculatePages({ contents, a4Padding, a4Height, pageWidth }: PdfExportConfig) {
    const pageHeight = a4Height - a4Padding * 2;

    const getHeight = (el: Element) => pageWidth * ((el as HTMLElement).offsetHeight / (el as HTMLElement).offsetWidth);

    const isElementDataTable = (el: Element) => el.className.includes("data-table-container");

    const resetCalculationProps = () => {
        exceedPageTableSetting = null;
        currPageElements = [];
        totalContentHeightInPage = 0;
        verticalOffset = 0;
    };

    const handleTableThatDoesntFit = (el: Element) => {
        const tableRows = el.querySelectorAll("tr");
        const getTablePosition = (indexes: number[]) => (indexes.includes(0) ? "start" : "");

        let dataTableHeight = 0;
        let rowIndexes: number[] = [];

        tableRows.forEach((dataRow, index) => {
            dataTableHeight += getHeight(dataRow);

            if (totalContentHeightInPage + dataTableHeight > pageHeight) {
                const tableSetting: ExceedPageTableSetting = {
                    elementIndex: currPageElements.length,
                    tableHeight: dataTableHeight,
                    rowIndexes,
                    tablePosition: getTablePosition(rowIndexes),
                };

                pageSettings.push({
                    // Calculate for the height for elements before the data table.
                    totalContentHeight: sum(currPageElements.map((el) => getHeight(el))) + dataTableHeight,
                    // Putting the previous elements and the data table into the settings
                    currPageElements: [...currPageElements, el],
                    verticalOffset: verticalOffset + (pageSettings.length === 0 ? 0 : a4Padding),
                    exceedPageTableSetting: tableSetting,
                });

                resetCalculationProps();
                dataTableHeight = 0;
                rowIndexes = [];
            }
            rowIndexes.push(index);
        });

        // Setting for the part of table needs to carry over to the next page.
        exceedPageTableSetting = {
            elementIndex: currPageElements.length,
            tableHeight: dataTableHeight,
            rowIndexes,
            tablePosition: "end",
        };
    };

    const addPageSetting = () => {
        let totalContentHeight = sum(currPageElements.map((el) => getHeight(el)));

        if (exceedPageTableSetting) {
            const elHeights = currPageElements.filter((el) => !isElementDataTable(el)).map(getHeight);
            totalContentHeight = sum(elHeights) + exceedPageTableSetting.tableHeight;
        }

        pageSettings.push({
            totalContentHeight,
            currPageElements,
            verticalOffset: verticalOffset + (pageSettings.length === 0 ? 0 : a4Padding),
            exceedPageTableSetting,
        });
    };

    const pageSettings: PdfPageSetting[] = [];

    let exceedPageTableSetting: ExceedPageTableSetting | null = null;

    // Total Height of the elements inside the current page
    let totalContentHeightInPage = 0;

    // Vertical start point in the result img
    let verticalOffset = 0;

    // What element is inside a the current page
    let currPageElements: Element[] = [];

    for (let i = 0; i < contents.length; i++) {
        const elHeight = getHeight(contents[i]);

        // 1. Check if current element can fit into a a4 page with elements in currPageElements
        // 2. Check if element's height higher than a4 content height.
        // 3. If the current element is carry over from last page the checking for page height needs to add the
        // carried over element height so that when exporting the code would know where to begin the page.
        // Needs to check if the first element already exceed the height of a4 page.
        if (totalContentHeightInPage + elHeight > pageHeight && i !== 0) {
            // Handle data table setting for not overlapping with other content.
            if (
                isElementDataTable(contents[i]) &&
                elHeight > pageHeight - totalContentHeightInPage &&
                !exceedPageTableSetting
            ) {
                handleTableThatDoesntFit(contents[i]);
            } else {
                addPageSetting();
                resetCalculationProps();
            }
        }

        currPageElements.push(contents[i]);

        // Check if  height is exceed a4 empty space
        // totalContentHeightInPage +=
        //   isElementDataTable(contents[i]) && exceedPageTableSetting
        //     ? exceedPageTableSetting?.tableHeight
        //     : elHeight;

        // Add page for non data table element which higher than a4 page hight
        if (elHeight > pageHeight && !isElementDataTable(contents[i])) {
            verticalOffset = 0;
            // keep adding paging until the content height is shorter than a4 page
            while (elHeight + verticalOffset > pageHeight) {
                let pageVerticalOffset = verticalOffset;

                if (pageVerticalOffset === 0 && pageSettings.length > 0) {
                    pageVerticalOffset = a4Padding;
                }

                pageSettings.push({
                    totalContentHeight: elHeight,
                    currPageElements: [contents[i]],
                    // If this is the first element then the padding for top should be 0
                    verticalOffset: pageVerticalOffset,
                    exceedPageTableSetting: null,
                });

                // Giving the where to begin the page
                // If this is the first element then top offset should be 0
                verticalOffset -= pageHeight + (pageSettings.length === 0 ? 0 : a4Padding);
            }
            // Height of what's left out of the page just added into the pageSetting.
            totalContentHeightInPage = elHeight + verticalOffset;
        }
    }
    addPageSetting();
    return pageSettings;
}

async function generatePdf(
    pageSettings: PdfPageSetting[],
    { pageCounterInFooter, doc, fileName, pageWidth, addHeader, addFooter }: PdfExportConfig
) {
    // Hidden element for displaying pdf content in each page
    const div = document.getElementById("hidden-pdf-page");

    // Start exporting pdf
    for (let i = 0; i < pageSettings.length; i++) {
        const footerContent = pageCounterInFooter ? `${i + 1}/${pageSettings.length}` : "";

        if (i > 0) {
            doc.addPage();
        }

        pageSettings[i].currPageElements.forEach((el, index) => {
            const clone = el.cloneNode(true) as Element;

            // Handling what row needs to be in the page.
            if (pageSettings[i].exceedPageTableSetting?.elementIndex === index) {
                const tableSetting = pageSettings[i]?.exceedPageTableSetting;
                clone.querySelectorAll("tr").forEach((row, index) => {
                    if (!tableSetting?.rowIndexes.includes(index)) {
                        row.remove();
                    }
                });
                switch (tableSetting?.tablePosition) {
                    case "start":
                        clone.querySelector("tfoot")?.remove();
                        break;
                    case "end":
                        clone.querySelector("thead")?.remove();
                        break;
                    default:
                        clone.querySelector("tfoot")?.remove();
                        clone.querySelector("thead")?.remove();
                }
            }
            div?.appendChild(clone);
        });

        const elem = await html2canvas(div as HTMLElement, {
            scale: 2.5,
            useCORS: true,
        });
        const imgData = elem.toDataURL("image/jpeg");

        doc.addImage(imgData, "jpeg", 0, pageSettings[i].verticalOffset, pageWidth, pageSettings[i].totalContentHeight);

        if (i > 0) {
            addHeader();
        }
        addFooter(footerContent);

        // Clear html content for next page
        if (div?.innerHTML) div.innerHTML = "";
    }
    // console.log(div);
    doc.save(fileName);
}

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from "immer";

import { logout } from "../auth/authSlice";
import { uipApiInstance } from "./interceptors";

setAutoFreeze(false);
type InitialState = {
    loading: boolean;
    globalLoader: boolean;
    error?: null | undefined | any;
    showErrorModal?: boolean;
    globalConfig?: any;
    showFTModal: string | false;
    orgInfo: any;
};

const initialState: InitialState = {
    loading: false,
    globalLoader: false,
    error: {},
    showErrorModal: false,
    globalConfig: {},
    showFTModal: false,
    orgInfo: {},
};

// export const logEventToBackEnd = createAsyncThunk("globalState/logEventToBackEnd", async (action: string) => {
//     const response = await uipApiInstance({
//         method: "POST",
//         url: "/api/advisorportal/actionsaudit",
//         data: {
//             action,
//         },
//     });

//     return response;
// });

// export const getTenantBasedConfig = createAsyncThunk(
//     "globalState/getTenantBasedConfig",
//     async ({ tenantId, i18n }: { tenantId: string; i18n: any }, { rejectWithValue, dispatch }) => {
//         try {
//             const response = await axios({
//                 method: "GET",
//                 baseURL: process.env.REACT_APP_UIP_API_URL,
//                 url: `/api/advisorportal/gettenantinfo?subdomain=${tenantId}`,
//                 withCredentials: false,
//             });

//             const tenantConfigs = response.data.tenantConfig?.sort((a: any, b: any) => {
//                 const convertToTime = (date: any) => moment(date).valueOf();

//                 return convertToTime(b?.__meta?.timestamp) - convertToTime(a?.__meta?.timestamp);
//             });

//             if (tenantConfigs?.[0]?.language) {
//                 i18n.changeLanguage(tenantConfigs?.[0]?.language?.[0]);
//             } else {
//                 i18n.changeLanguage(tenantConfigs?.[0]?.subdomain === "highpoint" ? "es" : "en");
//             }

//             return tenantConfigs?.[0];
//         } catch (err) {
//             console.log(err);
//             dispatch(logEventToBackEnd("GET_TENANT_INFO_API_ERROR"));
//             return rejectWithValue(err);
//         }
//     }
// );

export const updateTenantInfo = createAsyncThunk(
    "globalState/updateTenantInfo",
    async (requestedPayload: any, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: "POST",
                url: `/api/advisorportal/updatetenantinfo`,
                withCredentials: false,
                data: requestedPayload,
            });

            return response?.data;
        } catch (err) {
            console.log(err);
            // dispatch(logEventToBackEnd("UPDATE_TENANT_INFO_API_ERROR"));
            return rejectWithValue(err);
        }
    }
);

export const getCSRFToken = createAsyncThunk("globalState/getCSRFToken", async () => {
    const response = await uipApiInstance({
        method: "GET",
        url: "/getCSRFToken",
    });
    uipApiInstance.defaults.headers.post["X-CSRF-Token"] = response.data.CSRFToken;
});

const globalSlice = createSlice({
    name: "globalState",
    initialState,
    reducers: {
        updateErrorModalState: (state, action: PayloadAction<any>) => {
            state.showErrorModal = action.payload.showErrorModal;
            state.error = action.payload.error;
        },
        updateLogo: (state, action: PayloadAction<any>) => {
            state.globalConfig.logo = action.payload;
        },
        updateSubdomain: (state, action: PayloadAction<any>) => {
            state.globalConfig.subdomain = action.payload;
        },
        updateFTModalState: (state, action: PayloadAction<any>) => {
            state.showFTModal = action.payload;
        },
        updateGlobalLoaderState: (state, action: PayloadAction<any>) => {
            state.globalLoader = action.payload;
        },
        updateOrgInfoState: (state, action: PayloadAction<any>) => {
            state.orgInfo = action.payload;
        },
        resetGlobalReducer: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(logout, (state) => ({
            ...initialState,
            globalConfig: state.globalConfig,
        }));
    },
});

export const {
    updateErrorModalState,
    updateGlobalLoaderState,
    updateOrgInfoState,
    updateLogo,
    updateSubdomain,
    resetGlobalReducer,
    updateFTModalState,
} = globalSlice.actions;

export default globalSlice.reducer;

import { Menu, MenuItem } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import FTIcon from "atoms/FTIcon";
import { useState } from "react";
import {
    ADVISOR_HOME_BUTTON_TEXT,
    HEADER_DROPDOWN,
    HEADER_LINK,
    HEADER_LINK_HREF,
    HEADER_TEXT,
    LOGOUT_TEXT,
} from "../../@constants";
import FTLogo from "../../assets/logos/ft-logo.png";
import Link from "../../atoms/FTLink";
import "./header.styles.scss";

const HeaderView = ({
    handleLogout,
    handleFaq,
    handleContactUs,
    handleResources,
    isOnLanding,
    routeToLanding,
    showClientAndProposalInfo,
}: {
    handleLogout: () => void;
    handleFaq: () => void;
    handleContactUs: () => void;
    handleResources: () => void;
    isOnLanding: () => boolean;
    routeToLanding: () => void;
    showClientAndProposalInfo: { contactName: string; proposalName?: string } | undefined;
}) => {
    const { authState } = useOktaAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderClientAndProposalInfo = () => {
        return (
            showClientAndProposalInfo && (
                <div className="client-info">
                    <div className="demi-bold long-text-wrap-ellipsis">{showClientAndProposalInfo.contactName}</div>
                    {showClientAndProposalInfo?.proposalName !== "" && (
                        <span className="long-text-wrap-ellipsis">
                            Proposal name: {showClientAndProposalInfo.proposalName}{" "}
                        </span>
                    )}
                </div>
            )
        );
    };

    const renderAuthenticatedSection = () => (
        <article className={`header-authenticated ${showClientAndProposalInfo ? "content-justified" : ""}`}>
            {renderClientAndProposalInfo()}
            <div className="header-navigation-links">
                <div
                    className={anchorEl ? "header-buttons active-border" : "header-buttons"}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
                    data-testid="support-btn"
                    id="support-btn"
                >
                    <span>{HEADER_DROPDOWN.HEADING}</span>
                    <FTIcon className={anchorEl ? "upside-down small" : "small"} iconName="ft-caret-down" />
                </div>
                <Menu
                    MenuListProps={{ className: "header-buttons dropdownmenu" }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    style={{ borderRadius: 0 }}
                >
                    <MenuItem>
                        <span className="link" onClick={handleFaq}>
                            {"FAQ's"}
                            <FTIcon className="small" iconName="ft-carrotRight" />
                        </span>
                    </MenuItem>
                    <MenuItem>
                        <span className="link" onClick={handleContactUs}>
                            {"Contact Us"}
                            <FTIcon className="small" iconName="ft-carrotRight" />
                        </span>
                    </MenuItem>
                    <MenuItem>
                        <span className="link" onClick={handleResources}>
                            {"Resources"}
                            <FTIcon className="small" iconName="ft-carrotRight" />
                        </span>
                    </MenuItem>
                </Menu>
                <div
                    data-testid="home-btn"
                    className={isOnLanding() ? "header-buttons is-active" : "header-buttons"}
                    onClick={routeToLanding}
                >
                    <FTIcon iconName="ft-home" />
                    <span>{ADVISOR_HOME_BUTTON_TEXT}</span>
                </div>
                <div data-testid="logout-popup-btn" className="header-buttons" onClick={handleLogout}>
                    <FTIcon iconName="ft-logout" />
                    <span>{LOGOUT_TEXT}</span>
                </div>
            </div>
        </article>
    );

    const renderUnauthenticatedSection = () => (
        <article className="header-unauthenticated">
            <p>
                {HEADER_TEXT}
                <Link href={HEADER_LINK_HREF}>{HEADER_LINK}</Link>
            </p>
        </article>
    );
    // controller code at containers/Header
    return (
        <section className="header-container">
            <img className="ft-logo" alt="ft-logo" src={FTLogo} onClick={() => window.location.reload()} />
            {authState?.isAuthenticated ? renderAuthenticatedSection() : renderUnauthenticatedSection()}
        </section>
    );
};

export default HeaderView;

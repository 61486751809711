import { OktaAuth } from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth({
  issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: "/",
  pkce: false,
  tokenManager: {
    storage: "sessionStorage",
  },
  cookies: {
    sameSite: "lax",
  },
});

export default oktaAuth;

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CheckboxProps } from "@types";
import "./styles/atoms.styles.scss";

const FTCheckbox = ({ label, onChange, checked }: CheckboxProps) => {
  return (
    <article className="checkbox-container">
      <FormControlLabel
        control={<Checkbox size="small" checked={checked} />}
        label={label}
        onChange={onChange}
      />
    </article>
  );
};

export default FTCheckbox;

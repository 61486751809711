import { RISK_SUITABILITY } from "@constants";
import FTBox from "atoms/FTBox";
import FTDropdown from "atoms/FTDropdown";
import FTRadial from "atoms/FTRadial";
import FTSelectCard from "atoms/FTSelectCard";
import PageHeading from "containers/PageHeading";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { Proposal } from "redux/models";
import { riskToDegrees } from "utils/helper";

const RiskSuitability = ({
    displayedProposal,
    setDisplayedProposal,
}: {
    displayedProposal: Proposal;
    setDisplayedProposal: (displayedProposal: Proposal) => void;
}) => {
    const riskData = useAppSelector((state) => state?.global?.orgInfo?.settings?.riskMapping);

    type riskProfileType = {
        label: string;
        value: string;
    };

    const [selectedRiskProvider, setSelectedRiskProvider] = useState(displayedProposal?.customData?.riskProvider || "");
    const [selectedRiskProfile, setSelectedRiskProfile] = useState(displayedProposal?.customData?.riskScore || "");
    const [riskProfile, setRiskProfile] = useState(riskData || []);

    const riskProviderList =
        riskData &&
        Object.keys(riskData).map((key) => ({
            label: key,
            value: key,
        }));

    const handleRiskProvider = (e: any) => {
        const riskProviderSelected: string = e.target.value;
        setSelectedRiskProvider(riskProviderSelected);
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.riskProvider = riskProviderSelected;
        setDisplayedProposal(updatedProposal);
        const riskDataofRiskProvider = riskData[riskProviderSelected];
        let filteredRiskProfile = riskDataofRiskProvider?.map((profile: riskProfileType) => ({
            label: profile,
            value: profile,
        }));
        setRiskProfile(filteredRiskProfile);
        setSelectedRiskProfile("");
    };

    const handleRiskProfile = (e: any) => {
        const riskProfileSelected = e.target.value;
        setSelectedRiskProfile(riskProfileSelected);

        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.riskScore = riskProfileSelected;
        setDisplayedProposal(updatedProposal);
    };

    const handleRiskSelected = (e: any) => {
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.riskAversion = e.currentTarget.getAttribute("data-value");
        setDisplayedProposal(updatedProposal);
    };

    const checkIsSelected = (value: string) => {
        return displayedProposal?.customData?.riskAversion === value;
    };
    useEffect(() => {
        if (displayedProposal?.customData?.riskProvider && displayedProposal?.customData?.riskScore) {
            const riskDataofRiskProvider = riskData[displayedProposal?.customData?.riskProvider];
            let filteredRiskProfile = riskDataofRiskProvider?.map((profile: riskProfileType) => ({
                label: profile,
                value: profile,
            }));
            setRiskProfile(filteredRiskProfile);
            setSelectedRiskProfile(displayedProposal?.customData?.riskScore);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <PageHeading stepCount={4} pageHeading={"Risk exposure"} />
            <p>{RISK_SUITABILITY.RISK_INTRO}</p>
            <FTBox
                header="Simple"
                headerTitle={RISK_SUITABILITY.SENSITIVITY.HEADING}
                body={
                    <section>
                        <p>{RISK_SUITABILITY.SENSITIVITY.CONTENT}</p>
                        <div className="health-snapshot__cards-wrapper">
                            {RISK_SUITABILITY?.SENSITIVITY?.OPTIONS?.map(({ iconName, title, content, value }, idx) => (
                                <FTSelectCard
                                    key={idx}
                                    iconName={iconName}
                                    title={title}
                                    content={content}
                                    value={value}
                                    onSelect={handleRiskSelected}
                                    isSelected={checkIsSelected(value)}
                                />
                            ))}
                        </div>
                    </section>
                }
            />
            <FTBox
                header="SimpleWithIcon"
                headerTitle={RISK_SUITABILITY.ATTITUDE.HEADING}
                iconMessage={RISK_SUITABILITY.ATTITUDE.ICON_MESSAGE}
                body={
                    <div className="suitability-risk-container">
                        <div className="_risk-selection-area">
                            <span>{RISK_SUITABILITY.ATTITUDE.CONTENT}</span> <br />
                            <span className="small-text">{RISK_SUITABILITY.ATTITUDE.PROVIDER_LABEL}</span>
                            <div className="risk-provider-dropdown">
                                <FTDropdown
                                    id="risk-provider"
                                    label="Risk Provider"
                                    options={riskProviderList}
                                    onChange={handleRiskProvider}
                                    placeholder="Risk Provider"
                                    value={selectedRiskProvider}
                                    noDataMessage=""
                                />
                            </div>
                            <span className="risk-profile-label">{RISK_SUITABILITY.ATTITUDE.PROFILE_LABEL}</span>
                            <div className="risk-provider-dropdown">
                                <FTDropdown
                                    id="risk-profile"
                                    label="Risk Profile"
                                    options={riskProfile}
                                    onChange={handleRiskProfile}
                                    placeholder="Risk Profile"
                                    value={selectedRiskProfile}
                                    noDataMessage=""
                                />
                            </div>
                        </div>

                        <div className="_risk-radial">
                            <FTRadial size="md" dialDegrees={riskToDegrees(selectedRiskProfile)} />
                            <h6>{selectedRiskProfile}</h6>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default RiskSuitability;

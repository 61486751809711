import { RiskScore } from "@types";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";

const RiskDrawerHighchart = (props: any) => {
    const { selectedRiskProfile } = props;
    // const [updateFlag, setUpdateFlag] = useState(false);
    // const riskProfileChartRef = useRef<HighchartsReact.RefObject>(null);
    const riskScoreChartOptions = {
        credits: {
            enabled: false,
        },
        chart: {
            plotBackgroundColor: null, //
            plotBorderWidth: null, //
            plotShadow: false,
            backgroundColor: "transparent",
            height: "70px",
            margin: 0,
            spacing: [0, 0, 0, 0],
        },
        title: {
            text: null, //
        },
        xAxis: {
            minPadding: 0,
            maxPadding: 0,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                        fontWeight: "bold",
                        color: "white",
                    },
                },
                borderWidth: 0,
                startAngle: -90,
                endAngle: 90,
                center: ["50%", "80%"],
                size: "106%",
                allowPointSelect: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        enabled: false,
                    },
                },
            },
        },
        series: [
            {
                type: "pie",
                innerSize: "60%",
                data: [
                    {
                        name: "",
                        y: 0,
                        color: "rgb(0, 122, 204)",
                    },
                    {
                        name: "",
                        y: 0,
                        color: "rgba(0, 122, 204, 0.3)",
                    },
                ],
            },
        ],
    };
    const [chartOptions, setChartOptions] = useState(riskScoreChartOptions);
    const chartDegree = {
        Conservative: 10,
        Moderate: 50,
        Aggressive: 90,
    };

    const updateHighcharts = (riskScore: RiskScore) => {
        // const chartData = chartOptions.series && (chartOptions.series[0] as any).data;
        const probability = chartDegree[riskScore] ?? 0;
        // chartData[0].y = probability,
        // chartData[1].y = 100 - probability,
        setChartOptions((prev) => {
            return {
                ...prev,
                series: [
                    {
                        type: "pie",
                        innerSize: "60%",
                        data: [
                            {
                                name: "",
                                y: probability,
                                color: "rgb(0, 122, 204)",
                            },
                            {
                                name: "",
                                y: 100 - probability,
                                color: "rgba(0, 122, 204, 0.3)",
                            },
                        ],
                    },
                ],
            };
        });
        // setUpdateFlag(true);
    };
    useEffect(() => {
        updateHighcharts(selectedRiskProfile);
    }, [selectedRiskProfile,]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            // ref={riskProfileChartRef}
            // updateArgs={[updateFlag]}
            aria-label="Risk profile interactive chart."
        />
    );
};

export default RiskDrawerHighchart;

import { useOktaAuth } from "@okta/okta-react";
import { LoginValues } from "@types";
import { authenticateUser } from "auth/authSlice";
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { set_view_loading } from "redux/actions";
import { LOGIN, SIGNIN_ERROR_MESSAGE1, SIGNIN_ERROR_MESSAGE2 } from "../@constants";
import SignInView from "../components/SignIn";

const SignIn = (props: {}) => {
    const { oktaAuth } = useOktaAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loginValues, setLoginValues] = useState<LoginValues>({
        username: "",
        password: "",
        rememberMe: false,
    });
    const [needHelpClicked, setNeedHelpClicked] = useState<boolean>(false);
    const [isSignInLoading, setisSignInLoading] = useState<boolean>(false);
    const [usernameError, setUsernameError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [signInError, setSignInError] = useState<string>("");
    const validateLoginValues = () => {
        if (loginValues.username.trim() !== "" && loginValues.password.trim() !== "") {
            setSignInError("");
            setUsernameError(false);
            setPasswordError(false);
            return true;
        } else {
            if (loginValues.username.trim() === "") setUsernameError(true);
            if (loginValues.password.trim() === "") setPasswordError(true);
            setSignInError(SIGNIN_ERROR_MESSAGE1);
            return false;
        }
    };
    const handleSignIn = async (loginValues: LoginValues) => {
        if (validateLoginValues()) {
            dispatch(set_view_loading(true));
            setisSignInLoading(true);
            const res: any = await dispatch(authenticateUser({ oktaAuth, ...loginValues }));

            if (res.payload.status === LOGIN.SUCCESS) {
                setSignInError("");
                setUsernameError(false);
                setPasswordError(false);
                //Login to remember username
                loginValues?.rememberMe
                    ? localStorage.setItem("RIO_APP_REMEMBERED_EMAIL", loginValues?.username)
                    : localStorage.removeItem("RIO_APP_REMEMBERED_EMAIL");
                navigate("/advisor/landing");
            } else {
                setSignInError(SIGNIN_ERROR_MESSAGE2);
            }
            dispatch(set_view_loading(false));
            setisSignInLoading(false);
        }
    };
    const onFieldsBlur = (e: any) => {
        if (e.target.id === "username") {
            if (e.target.value.trim() === "") setUsernameError(true);
            else setUsernameError(false);
        }
        if (e.target.id === "password") {
            if (e.target.value.trim() === "") setPasswordError(true);
            else setPasswordError(false);
        }
    }
    const onFieldsChange = (e: any) => {
        if (e.target.id === "username") {
            if (e.target.value.trim() !== "") setUsernameError(false);
        }
        if (e.target.id === "password") {
            if (e.target.value.trim() !== "") setPasswordError(false);
        }
        setLoginValues({
            ...loginValues,
            [e.target.id]: e.target.value.trim(),
        });
    };

    useEffect(() => {
        (async () => {
            const email = await localStorage.getItem("RIO_APP_REMEMBERED_EMAIL");
            if (email)
                setLoginValues((prevState) => ({
                    ...prevState,
                    username: email,
                    rememberMe: true,
                }));
        })();
    }, []);

    return (
        <SignInView
            {...props}
            loginValues={loginValues}
            setLoginValues={setLoginValues}
            needHelpClicked={needHelpClicked}
            setNeedHelpClicked={setNeedHelpClicked}
            handleSignIn={handleSignIn}
            isSignInLoading={isSignInLoading}
            usernameError={usernameError}
            passwordError={passwordError}
            signInError={signInError}
            onFieldsChange={onFieldsChange}
            onFieldsBlur={onFieldsBlur}
            onEnter={() => handleSignIn(loginValues)}
        />
    );
    // view code at components/SignIn/index.tsx
};

export default SignIn;

import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { QuestionaireCardProps } from "@types";
import FTMessageBar from "atoms/FTMessageBar";
import FTPopover from "atoms/FTPopover";
import "./questionaire-card.styles.scss";

function QuestionaireCard({
    questionID,
    question,
    popOverMessage,
    warningMessage,
    handleQuestionaireChange,
    showWarningBox,
    defaultValue,
    customerDetails,
}: QuestionaireCardProps) {
    return (
        <Box sx={{ flex: "1" }}>
            <div key={questionID} className="ft-card__ui-style-standard">
                <div className="ft-card__body">
                    <div className="input-toggle">{question}</div>
                    <RadioGroup
                        row
                        aria-labelledby={questionID}
                        name={questionID}
                        onChange={handleQuestionaireChange}
                        value={questionID ? customerDetails[questionID] : false}
                        defaultValue={defaultValue}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>

                    <div className="tooltip-container">
                        <FTPopover type="secondary" message={popOverMessage} />
                    </div>
                </div>
            </div>
            {showWarningBox && <FTMessageBar message={warningMessage} />}
        </Box>
    );
}

export default QuestionaireCard;

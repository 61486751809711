import { useEffect, useRef, useState } from "react";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../redux/store";

// To be used throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// helper hooks
/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useDetectOutsideClick = (initialIsVisible: boolean) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        if (isComponentVisible) {
            document.addEventListener("click", handleClickOutside, true);
        } else {
            setIsComponentVisible(false);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [isComponentVisible, handleClickOutside]);

    return { ref, isComponentVisible, setIsComponentVisible };
};

// export RIO custom hooks
export * from "./useGoe4d";
export * from "./useSelectedProposal";
export * from "./useSpire";

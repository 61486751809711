import React from "react";

export interface CheckboxProps {
    label: string;
    checked: boolean;
    onChange: ((event: React.SyntheticEvent<Element, Event>, checked: boolean) => void) | undefined;
}

export interface LoginValues {
    username: string;
    password: string;
    rememberMe: boolean;
}

export type FTBoxProps = {
    body: React.ReactNode;
    halfWidth?: boolean;
    rightControls?: () => React.ReactNode;
} & (FTBoxDefaultHeaderTypes | FindingFactHeaderTypes);

interface FTBoxDefaultHeaderTypes {
    header: "Default" | "Simple" | "SimpleWithIcon" | "SimpleWithRighControls";
    halfWidth?: boolean;
    headerTitle?: string;
    iconMessage?: string;
    lastUpdatedOn?: number | string;
    value?: number;
}

interface FindingFactHeaderTypes {
    header: "FindingFactHeader";
    headerTitle: string;
    iconLabel: string;
    value?: number;
    lastUpdatedDate?: number | string;
    isMonthlyOrAnnualLabel?: "Monthly" | "Annual";
}

// FT Icon Props
type IconSize = "small" | "medium" | "large";
type IconColor = "Blue" | "White" | "Grey" | "Black";
export interface FTIconProps {
    iconName: string;
    color?: IconColor;
    size?: IconSize;
    className?: string;
}

// FTMessageBar Props
type MessageType = "default" | "warning" | "error" | "success";
export interface FTMessageBarProps {
    type?: MessageType;
    title?: string;
    message: string;
    onClose?: () => void;
}

export const messageTypeClasses: Record<MessageType, string> = {
    default: "message--success",
    warning: "message--warning",
    error: "message--fail",
    success: "message--success",
};

// FTPopover Props
type UIStyle = "standard" | "secondary";
export const popoverUiStyleClasses: Record<UIStyle, string> = {
    standard: "ft-popover__ui-style-standard",
    secondary: "ft-popover__ui-style-secondary",
};
export interface FTPopoverProps {
    message: string | JSX.Element;
    placement?: string;
    type?: UIStyle;
}

// FTSelectableCard Props

export interface FTSelectableCardProps {
    title: string;
    content: string;
    iconName: string;
    datatestid?: string;
    setCustomerDetails: (customerDetails: object) => void;
    customerDetails: { healthStatus: string };
    // checkbox event is not optimum since the element behaves like radio button
    handleCardSelection?: ((event: React.SyntheticEvent<Element, Event>, checked: boolean) => void) | undefined;
    value: string;
}

// FTSelectCard Props

export interface FTSelectCardProps {
    title: string;
    content: string;
    iconName: string;
    onSelect: (e: any) => void;
    value: string;
    isSelected: boolean;
}

// CustomerDetailsType
export interface CustomerDetailsType {
    contactId: string;
    state: string;
    email: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    healthStatus: string;
    jobTitle: string;
    organizationId: string;
    addresses: Array<string>;
    phones: Array<string>;
    wealthItems: Array<string>;
    isRetired: boolean;
    hasOutstandingDebts: boolean;
    postcode: string;
    fullNameLabel?: string;
}

// PageHeading Props

export interface PageHeadingProps {
    iconName: string;
    renderOnPage: string;
    pageHeading?: React.ReactNode;
    rightSideContent: React.ReactNode;
    stepCount: number;
}

// Dropdown props
export interface DropdownProps {
    options?: { label: string; value: string }[];
    isHavingError?: string;
}

// Input Box Custom Props
export interface InputCustomProps {
    showErrorOutline?: string;
}

// QuestionaireCard Props
export interface QuestionaireCardProps {
    questionID?: keyof CustomerDetailsType;
    question: string;
    popOverMessage: string;
    setCustomerDetails: (customerDetails: object) => void;
    customerDetails: CustomerDetailsType;
    showWarningBox: string | boolean | string[] | undefined;
    handleQuestionaireChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;

    defaultValue?: boolean;
    warningMessage: string;
}

export interface SideNavProps {
    segments: Array<{
        label: string;
        iconName: string;
        routeTo: string;
        datatestid: string;
    }>;
    handleSegmentClicked: (routeTo: string) => void;
    isActive: (routeTo: string) => boolean;
}

export interface QuestionsProps {
    id: keyof CustomerDetailsType;
    defaultValue: boolean;
    question: string;
    warningMessage: string;
    popOverMessage: string;
}

export interface HealthSnapshotDataProps {
    id: string;
    iconName: string;
    title: string;
    content: string;
    value: string;
}

export interface FTMessageLiteProps {
    message: string;
    type: "info" | "warning";
}

export interface FTModalProps {
    handleClose: (e: object) => void;
}

export interface FTModalProps {
    handleClose: (e: object) => void;
}

// New Proposal

type FTModalContentTypesValues = "new_proposal" | "session_timeout" | "delete_proposal" | "delete_item";

export interface FTModalContentTypes {
    NEW_PROPOSAL: {
        VALUE: FTModalContentTypesValues;
        HEADING: string;
        CANCEL_BTN: string;
        LETSGO_BTN: string;
        PLACEHOLDER: string;
        ERROR_TEXT: string;
        ALREADY_EXISTS_ERROR_TEXT: string;
    };
    DELETE_PROPOSAL: {
        VALUE: FTModalContentTypesValues;
        HEADING: string;
        BODY: string;
        CANCEL_BTN: string;
        CONFIRM_BTN: string;
        DELETE_LABEL: string;
    };
    DELETE_ITEM: {
        VALUE: FTModalContentTypesValues;
        HEADING: string;
        BODY: string;
        CANCEL_BTN: string;
        CONFIRM_BTN: string;
        DELETE_LABEL: string;
    };
    SESSION_TIMEOUT: { VALUE: FTModalContentTypesValues; HEADING: string };
}

// Wealth

export type WealthType = "Pension" | "Investment" | "Cash" | "Other" | "SIPP";
export interface WealthItem {
    value: number;
    name: string;
    type: WealthType;
    deletable: boolean;
    readonly: boolean;
}

// Income

export type IncomeType = "Annuities" | "Paid Employment" | "Rental Income" | "Others";

export interface BaseIncomeItem {
    value: number;
    inflationAdjusted: boolean;
}

export interface OtherIncomeItem extends BaseIncomeItem {
    name: string;
    type: IncomeType;
    deletable: boolean;
}

export interface definedBenefitPensionsItem extends BaseIncomeItem {
    provider: string;
    deletable: boolean;
}

export type StatePensionItem = BaseIncomeItem;

// RISK

export type RiskProvider = "Defacto" | "Distribution Technology" | "Other";
export type RiskScore = "Conservative" | "Moderate" | "Aggressive";
export type RiskAversion = "Dynamic" | "Flexible" | "Secure";
export type ProjectedAllocationSelectionType = "all" | "equity" | "bond" | "cash";

// Finding Fact Summary
export interface SummaryData {
    title: string;
    iconLabel: string;
    value: string;
    sections: SectionList[];
}

export interface SectionList {
    title: string;
    rows: RowsData[];
}

export interface RowsData {
    key: string;
    value: string;
    class: string;
}

export interface FactFindingStep {
    title: StepTitle;
    completed: boolean;
    active: boolean;
    iconClass: string;
    routeName: string;
    nextRoute?: string;
}

export interface FactFindingPageHeading {
    stepCount: number;
    title: string;
}

export enum StepTitle {
    wealth = "Wealth",
    benefitsIncome = "Income",
    expenditure = "Expenditure",
    risk = "Risk Suitability",
    viewProposal = "Finish",
    launchpadInit = "LaunchpadInit",
    launchpadQuoteSelect = "LaunchpadQuoteSelect",
}

export enum StepRoute {
    wealth = "wealth",
    benefitsIncome = "benefits-and-income",
    expenditure = "expenditure",
    risk = "risk",
    viewProposal = "view-proposal",
}

export interface Step {
    Wealth: "wealth";
    Income: "benefitsIncome";
    Expenditure: "expenditure";
    RiskSuitability: "risk";
    Finish: "viewProposal";
}

export enum StepEnum {
    Wealth = "wealth",
    Income = "benefitsIncome",
    Expenditure = "expenditure",
    RiskSuitability = "risk",
    Finish = "viewProposal",
}

// Expenditures Page

export type Lifestyle = "Minimum" | "Moderate" | "Comfortable";

export type Expenditure =
    | "foodAndDrink"
    | "personal"
    | "housing"
    | "clothingAndFootwear"
    | "transport"
    | "socialAndCultural";

export type ExpendituresObj = { [key in Expenditure]?: number };

export interface ExpendituresMeta {
    isCouple: boolean;
    isInLondon: boolean;
    lifestyle: string | null;
}

export interface Lifestyles {
    "Min / Couple": number;
    "Mod / Couple": number;
    "Comf / Couple": number;
    "Min / Single": number;
    "Mod / Single": number;
    "Comf / Single": number;
}
export interface ExpenditureTypes {
    "A + B": Lifestyles; // Food and drink.
    C: Lifestyles; // Clothing and footwear.
    "D + E": Lifestyles; // Housing costs and bills.
    F: Lifestyles; // Personal goods and services (inc health).
    G: Lifestyles; // Transport.
    H: Lifestyles; // Social, cultural and holidays.
}

export interface ExpenditureDefaults {
    "Annual / London": ExpenditureTypes;
    "Annual / Non London": ExpenditureTypes;
    "Monthly / London": ExpenditureTypes;
    "Monthly / Non London": ExpenditureTypes;
}

export type LifeEventEvent =
    | "Future Planned Income"
    | "Disposal of Asset"
    | "Significant Purchases"
    | "Helping Relatives & Family"
    | "Health and Well-being Costs"
    | "Other";
export type LifeEventType = "Expenditure" | "Income";

export interface LifeEvent {
    event: LifeEventEvent;
    type: LifeEventType;
    name: string;
    age: number;
    value: number;
}

// Fact Finding Page Container
export interface FactFindingPageContainerPropTypes {
    factFindingStep: string | undefined;
}

export interface FindingFactSummaryCardPropTypes {
    factFindingStep: string | undefined;
    handleNextClicked: () => void;
    handleSaveExitClicked: () => void;
}

export interface FTSwitchPropType {
    checked: boolean;
    switchName: string;
    label: string;
    onChange: (event: any) => void;
}

// Spire
export interface SpireRequestDataType {
    adviserID: string;
    payload: {
        platformDetails: {
            platformId: string;
            platformCustomAttribute1: string;
            platformCustomAttribute2: string;
            platformCustomAttribute3: string;
        };
        adviserDetails: {
            adviserId: string;
            firstName: string;
            lastName: string;
            emailAddress: string;
            fsaRegisteredNumber: string;
            firmFsaRef: string;
        };
        clientDetails: {
            clientId: string;
            title: string;
            firstName: string;
            lastName: string;
            dateOfBirth: string;
            permanentAddressLine1: string;
            permanentAddressLine2: string;
            permanentAddressLine3: string;
            permanentAddressLine4: string;
            permanentAddressPostCode: string;
            gender: string;
            emailAddress: string;
        };
        quoteDetails: {
            amount: string;
            quotationBasis: string;
            personaDetails: {
                persona: string;
            };
        };
    };
}

// Goe4d
export interface Goe4dRequestDataType {
    cashReserves: number;
    inflationRate: number;
    currentAge: number;
    retirementAge: number;
    dateOfBirth: string;
    startDate: string;
    endDate: string;
    currDate: string;
    guaranteedOtherIncome: number;
    guaranteedOtherIncomeInflation: number;
    statePension: number;
    defBenfIncome: number;
    defBenfIncomeInflation: number;
    targetConsumptionAmount: number;
    reallocationFreq: string;
    infusionType: string;
    currentWealth: number;
    existingAnnuitiesIncome: number;
    includeAnnuities: boolean;
    futureAnnuityProj: boolean;
    lossThreshold: null;
    annuityRate: number;
}

export interface SpireIndicativeQuoteType {
    quoteResponse: {
        status: string;
        errorNote: string;
    };
    platformDetails: {
        platformId: string;
        platformCustomAttribute1: string;
        platformCustomAttribute2: string;
        platformCustomAttribute3: string;
    };
    illustrationDetails: {
        quoteReference: string;
        quoteEffectiveDate: string;
        premiumValue: number;
        annualIncomeAmount: number;
        incomePaymentAmount: number;
        incomePaymentFrequency: number;
        incomeRate: number;
    };
}

export interface ContactInfo {
    contacts: {
        name: string;
        title: string;
        description: string;
        email: string;
        linkedInUrl: string;
        imageUrl: string;
    }[];
    assistanceInfo: {
        phone: string;
        email: string;
    };
}

export interface ResourceContent {
    header: string;
    subHeader: string;
    resourceUrl: string;
    imageUrl: string;
}

export interface FaqTheme {
    name: string;
    items: { q: string; a: string }[];
}
export interface EconomicAssumption {
    name: string;
    nameDesc: string;
    items: { assumption: string; assumptionDesc: string }[];
}
// TODO convert object to type and use in
// export interface Goe4dTranslationType{
//     "body": {
//         "additionalPayoutAnnuity": 412.4,
//         "allocationAnnuity": 27494.0,
//         "allocationGOE": 164529.4,
//         "bankruptcyMessage": "NA",
//         "consumptionGoal": [
//             8100.0,
//             8183.5,
//             8267.9,
//             8353.0,
//             8439.0,
//             8525.9,
//             8613.7,
//             8702.4,
//             8791.9,
//             8882.3,
//             8973.5,
//             9065.9,
//             9159.0,
//             9253.1,
//             9348.0,
//             9444.1,
//             9541.1,
//             9638.9,
//             9737.9,
//             9837.7,
//             9938.6,
//             10040.5,
//             10143.4,
//             10247.3,
//             10352.3
//         ],
//         "currentGoalProbability": 0.9713,
//         "fundedness": "OverFunded",
//         "goalProbability": 0.8705,
//         "goalProbabilityLongevityAdjusted": 0.9339,
//         "guaranteedIncomePercent": 0.19,
//         "isGoalRealistic": true,
//         "payoutAnnuity": [
//             1812.4,
//             1826.4,
//             1840.5,
//             1854.8,
//             1869.2,
//             1883.8,
//             1898.5,
//             1913.4,
//             1928.4,
//             1943.6,
//             1958.9,
//             1974.3,
//             1990.0,
//             2005.7,
//             2021.7,
//             2037.8,
//             2054.0,
//             2070.4,
//             2087.0,
//             2103.8,
//             2120.7,
//             2137.7,
//             2155.0,
//             2172.4,
//             2190.0
//         ],
//         "payoutGOE": [
//             7976.6,
//             8063.0,
//             8150.3,
//             8238.4,
//             8327.3,
//             8417.2,
//             8508.1,
//             8599.9,
//             8692.5,
//             8786.0,
//             8880.4,
//             8975.9,
//             9072.3,
//             9169.6,
//             9267.8,
//             9367.2,
//             9467.6,
//             9568.8,
//             9564.7,
//             9546.1,
//             9526.2,
//             9628.1,
//             9731.0,
//             9834.9,
//             9939.9
//         ],
//         "portfolioPath": [
//             4.0,
//             4.0,
//             4.0,
//             4.0,
//             4.0,
//             4.0,
//             4.0,
//             4.0,
//             4.0,
//             4.0,
//             3.0,
//             2.0,
//             2.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0,
//             1.0
//         ],
//         "recommendedConsumption": [
//             10289.0,
//             10391.9,
//             10495.8,
//             10600.8,
//             10706.7,
//             10813.8,
//             10922.0,
//             11031.3,
//             11141.6,
//             11253.0,
//             11365.5,
//             11479.1,
//             11594.0,
//             11709.8,
//             11826.9,
//             11945.2,
//             12064.7,
//             12185.3,
//             12200.7,
//             12201.9,
//             12201.9,
//             12323.9,
//             12447.2,
//             12571.6,
//             12697.3
//         ],
//         "recommendedPortfolioId": 4.0,
//         "riskThreshold": "High",
//         "wealthPath": [
//             164529.0,
//             167742.0,
//             159679.0,
//             151529.0,
//             143697.0,
//             140945.0,
//             135597.0,
//             130452.0,
//             125503.0,
//             120741.0,
//             116160.0,
//             109612.0,
//             105453.0,
//             99509.0,
//             92101.0,
//             86909.0,
//             78899.0,
//             73025.0,
//             65024.0,
//             56791.0,
//             48650.0,
//             40095.0,
//             31791.0,
//             22445.0,
//             12809.0
//         ]
//     },
//     "investableWealthChart": {
//         "cashReserves": [
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0
//         ],
//         "comparators": {
//             "annuitization100Percent": [
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0
//             ],
//             "fourPerRule": [
//                 200000.0,
//                 198144.0,
//                 196146.05,
//                 194000.78,
//                 191702.63,
//                 189245.89,
//                 186624.62,
//                 183832.7,
//                 180863.8,
//                 177711.38,
//                 174368.68,
//                 169670.01,
//                 164763.28,
//                 159642.41,
//                 154301.11,
//                 148732.96,
//                 142931.34,
//                 136889.47,
//                 130600.41,
//                 124057.01,
//                 117251.94,
//                 110177.68,
//                 102826.51,
//                 95190.5,
//                 87261.52
//             ],
//             "targetConsumption": [
//                 200000.0,
//                 196080.0,
//                 191931.36,
//                 187545.73,
//                 182914.49,
//                 178028.72,
//                 172879.22,
//                 167456.47,
//                 161750.64,
//                 155751.59,
//                 149448.81,
//                 141862.65,
//                 133973.62,
//                 125773.0,
//                 117251.87,
//                 108401.06,
//                 99211.15,
//                 89672.49,
//                 79775.18,
//                 69509.04,
//                 58863.65,
//                 47828.29,
//                 36391.98,
//                 24543.44,
//                 12271.11
//             ]
//         },
//         "investableWealth": [
//             156552.4,
//             159679.0,
//             151528.7,
//             143290.6,
//             135369.7,
//             132527.8,
//             127088.9,
//             121852.1,
//             116810.5,
//             111955.0,
//             107279.6,
//             100636.1,
//             96380.7,
//             90339.4,
//             82833.2,
//             77541.8,
//             69431.4,
//             63456.2,
//             55459.3,
//             47244.9,
//             39123.8,
//             30466.9,
//             22060.0,
//             12610.1,
//             2869.1000000000004
//         ]
//     },
//     "message": "Success",
//     "projectedGuaranteedIncome": [
//         2312.4,
//         2328.9,
//         2345.5,
//         2362.4,
//         2379.4,
//         2396.6,
//         2413.9,
//         2431.4,
//         2449.1,
//         2467.0,
//         2485.1,
//         2503.2,
//         2521.7,
//         2540.2,
//         2559.1,
//         2578.0,
//         2597.1,
//         2616.5,
//         2636.0,
//         2655.8,
//         2675.7,
//         2695.8,
//         2716.2,
//         2736.7,
//         2757.4
//     ],
//     "proposalIncomeChart": {
//         "achiveableIncome": [
//             8476.6,
//             8565.5,
//             8655.3,
//             8746.0,
//             8837.5,
//             8930.0,
//             9023.5,
//             9117.9,
//             9213.2,
//             9309.4,
//             9406.6,
//             9504.8,
//             9604.0,
//             9704.1,
//             9805.2,
//             9907.4,
//             10010.7,
//             10114.9,
//             10113.7,
//             10098.1,
//             10081.2,
//             10186.2,
//             10292.2,
//             10399.2,
//             10507.3
//         ],
//         "comparators": {
//             "annuitization100Percent": [
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0,
//                 30.0
//             ],
//             "fourPerRule": {
//                 "consumption4PercentRule": [
//                     8000.0,
//                     8080.0,
//                     8160.8,
//                     8242.41,
//                     8324.83,
//                     8408.08,
//                     8492.16,
//                     8577.08,
//                     8662.85,
//                     8749.48,
//                     8836.97,
//                     8925.34,
//                     9014.59,
//                     9104.74,
//                     9195.79,
//                     9287.75,
//                     9380.63,
//                     9474.44,
//                     9569.18,
//                     9664.87,
//                     9761.52,
//                     9859.14,
//                     9957.73,
//                     10057.31,
//                     10157.88
//                 ],
//                 "endingWealth": [
//                     192000.0,
//                     190064.0,
//                     187985.25,
//                     185758.37,
//                     183377.8,
//                     180837.81,
//                     178132.46,
//                     175255.62,
//                     172200.95,
//                     168961.9,
//                     165531.71,
//                     160744.67,
//                     155748.69,
//                     150537.67,
//                     145105.32,
//                     139445.21,
//                     133550.71,
//                     127415.03,
//                     121031.23,
//                     114392.14,
//                     107490.42,
//                     100318.54,
//                     92868.78,
//                     85133.19,
//                     77103.64
//                 ],
//                 "expectedReturnTDF": {
//                     "100": 2.5,
//                     "101": 2.5,
//                     "65": 3.2,
//                     "66": 3.2,
//                     "67": 3.2,
//                     "68": 3.2,
//                     "69": 3.2,
//                     "70": 3.2,
//                     "71": 3.2,
//                     "72": 3.2,
//                     "73": 3.2,
//                     "74": 3.2,
//                     "75": 2.5,
//                     "76": 2.5,
//                     "77": 2.5,
//                     "78": 2.5,
//                     "79": 2.5,
//                     "80": 2.5,
//                     "81": 2.5,
//                     "82": 2.5,
//                     "83": 2.5,
//                     "84": 2.5,
//                     "85": 2.5,
//                     "86": 2.5,
//                     "87": 2.5,
//                     "88": 2.5,
//                     "89": 2.5,
//                     "90": 2.5,
//                     "91": 2.5,
//                     "92": 2.5,
//                     "93": 2.5,
//                     "94": 2.5,
//                     "95": 2.5,
//                     "96": 2.5,
//                     "97": 2.5,
//                     "98": 2.5,
//                     "99": 2.5
//                 },
//                 "startingWealth": [
//                     200000.0,
//                     198144.0,
//                     196146.05,
//                     194000.78,
//                     191702.63,
//                     189245.89,
//                     186624.62,
//                     183832.7,
//                     180863.8,
//                     177711.38,
//                     174368.68,
//                     169670.01,
//                     164763.28,
//                     159642.41,
//                     154301.11,
//                     148732.96,
//                     142931.34,
//                     136889.47,
//                     130600.41,
//                     124057.01,
//                     117251.94,
//                     110177.68,
//                     102826.51,
//                     95190.5,
//                     87261.52
//                 ]
//             },
//             "targetConsumption": {
//                 "endingWealth": [
//                     190000.0,
//                     185980.0,
//                     181730.36,
//                     177242.72,
//                     172508.45,
//                     167518.62,
//                     162264.02,
//                     156735.12,
//                     150922.08,
//                     144814.74,
//                     138402.59,
//                     130705.97,
//                     122705.37,
//                     114392.07,
//                     105757.13,
//                     96791.37,
//                     87485.36,
//                     77829.44,
//                     67813.7,
//                     57427.95,
//                     46661.75,
//                     35504.37,
//                     23944.82,
//                     11971.81,
//                     0.0
//                 ],
//                 "expectedReturnTDF": {
//                     "100": 2.5,
//                     "101": 2.5,
//                     "65": 3.2,
//                     "66": 3.2,
//                     "67": 3.2,
//                     "68": 3.2,
//                     "69": 3.2,
//                     "70": 3.2,
//                     "71": 3.2,
//                     "72": 3.2,
//                     "73": 3.2,
//                     "74": 3.2,
//                     "75": 2.5,
//                     "76": 2.5,
//                     "77": 2.5,
//                     "78": 2.5,
//                     "79": 2.5,
//                     "80": 2.5,
//                     "81": 2.5,
//                     "82": 2.5,
//                     "83": 2.5,
//                     "84": 2.5,
//                     "85": 2.5,
//                     "86": 2.5,
//                     "87": 2.5,
//                     "88": 2.5,
//                     "89": 2.5,
//                     "90": 2.5,
//                     "91": 2.5,
//                     "92": 2.5,
//                     "93": 2.5,
//                     "94": 2.5,
//                     "95": 2.5,
//                     "96": 2.5,
//                     "97": 2.5,
//                     "98": 2.5,
//                     "99": 2.5
//                 },
//                 "startingWealth": [
//                     200000.0,
//                     196080.0,
//                     191931.36,
//                     187545.73,
//                     182914.49,
//                     178028.72,
//                     172879.22,
//                     167456.47,
//                     161750.64,
//                     155751.59,
//                     149448.81,
//                     141862.65,
//                     133973.62,
//                     125773.0,
//                     117251.87,
//                     108401.06,
//                     99211.15,
//                     89672.49,
//                     79775.18,
//                     69509.04,
//                     58863.65,
//                     47828.29,
//                     36391.98,
//                     24543.44,
//                     12271.11
//                 ],
//                 "targetConsumption": [
//                     10000.0,
//                     10100.0,
//                     10201.0,
//                     10303.01,
//                     10406.04,
//                     10510.1,
//                     10615.2,
//                     10721.35,
//                     10828.56,
//                     10936.85,
//                     11046.22,
//                     11156.68,
//                     11268.25,
//                     11380.93,
//                     11494.74,
//                     11609.69,
//                     11725.79,
//                     11843.05,
//                     11961.48,
//                     12081.09,
//                     12201.9,
//                     12323.92,
//                     12447.16,
//                     12571.63,
//                     12271.11
//                 ]
//             }
//         },
//         "definedBenfitPension": [
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0,
//             0.0
//         ],
//         "drawdownFromWealth": [
//             7976.6,
//             8063.0,
//             8150.3,
//             8238.4,
//             8327.3,
//             8417.2,
//             8508.1,
//             8599.9,
//             8692.5,
//             8786.0,
//             8880.4,
//             8975.9,
//             9072.3,
//             9169.6,
//             9267.8,
//             9367.2,
//             9467.6,
//             9568.8,
//             9564.7,
//             9546.1,
//             9526.2,
//             9628.1,
//             9731.0,
//             9834.9,
//             9939.9
//         ],
//         "otherIncome": [
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0,
//             250.0
//         ],
//         "statePension": [
//             250.0,
//             252.5,
//             255.0,
//             257.6,
//             260.2,
//             262.8,
//             265.4,
//             268.0,
//             270.7,
//             273.4,
//             276.2,
//             278.9,
//             281.7,
//             284.5,
//             287.4,
//             290.2,
//             293.1,
//             296.1,
//             299.0,
//             302.0,
//             305.0,
//             308.1,
//             311.2,
//             314.3,
//             317.4
//         ]
//     },
//     "statusCode": 200.0
// }
// Chart Data

export class AmountViewModel {
    constructor(init?: Partial<AmountViewModel>) {
        Object.assign(this, init);
    }
    age = 0;
    value = 0;
    isAmountAnnualFn = () => false;

    get amount() {
        return this.isAmountAnnualFn() ? this.value : (this.value / 12).toFixed(2);
    }
}
export interface LineData {
    data: AmountViewModel[];
    color: string;
    dashStyle: string;
    thick?: boolean;
}

export interface ChartData {
    [key: string]: {
        goodLine?: LineData;
        averageLine: LineData;
        badLine?: LineData;
        areaColor?: string;
    };
}

export interface StackedColumn {
    value: () => number[][];
    label: string;
    color: string;
    age: number;
}

import { useOktaAuth } from "@okta/okta-react";
import FTMessageBar from "atoms/FTMessageBar";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DISCLAIMER_INFO } from "../../@constants";
import SignIn from "../../containers/SignIn";
import DisclaimerContent from "./DisclaimerContent";
import Footer from "./Footer";
import MiddleContent from "./MiddleContent";
import SiteMap from "./SiteMap";
import StaticContent from "./StaticContent";
import "../styles/advisor-login.styles.scss";
import staticFloatingImage from "../../assets/images/login-floating.png";

const AdvisorLogin = () => {
    const { authState } = useOktaAuth();
    const location = useLocation();
    const [showLogoutMsg, setShowLogoutMsg] = useState(location.search.indexOf("reason=logoutSuccess") > 0);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search.indexOf("reason=logoutSuccess") > 0) {
            setShowLogoutMsg(true);
        }
        if (authState?.isAuthenticated) navigate("/advisor/landing");
    }, [authState, navigate, location.search]);

    return (
        <>
            {showLogoutMsg && (
                <div className="logout-message">
                    <FTMessageBar
                        type="success"
                        title="Log out"
                        message="You have been successfully logged out."
                        onClose={() => setShowLogoutMsg(false)}
                    />
                </div>
            )}
            <div className="box-container">
                <div className="image-container">
                    <img src={staticFloatingImage} alt="" />
                </div>
                <section className="main-content">
                    <StaticContent />
                    <SignIn />
                </section>
            </div>
            <MiddleContent />
            <DisclaimerContent />
            <section className="disclaimer-info">
                <p>{parse(DISCLAIMER_INFO)}</p>
            </section>
            <SiteMap />
            <Footer />
        </>
    );
};

export default AdvisorLogin;

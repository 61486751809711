import { useCallback, useEffect, useState } from "react";
import { getPortalUrl, getProposal, personalizedQuoteCallback, updateProposal } from "api";
import FTButton from "atoms/FTButton";
import FTDropdown from "atoms/FTDropdown";
import FTIcon from "atoms/FTIcon";
import { useAppDispatch, useAppSelector, useSelectedProposal, useSpire } from "hooks";
import { useNavigate } from "react-router-dom";
import { set_selected_proposal, set_show_external_link_modal, set_spire_portal_url } from "redux/actions";
import { formatter, randomize, toHumanReadableDate } from "utils/helper";
import { hasValue } from "utils/validateInputs";
import justLogo from "assets/logos/just-logo.png";

const AnnuityQuotation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const spirePortalURL = useAppSelector((state) => state.spirePortalURL);
    const defaultAnnuityRate = useAppSelector((state) => state?.global?.orgInfo?.settings?.defaultAnnuityRate);
    // const [spirePortalUrl, setSpirePortalUrl] = useState<string>(
    // 	""
    // 	// "https://hubuat.spireps.co.uk/SpireHub.Portal.Site/AuthenticateUser/Index?id=abc7a764-92ba-4cc8-82a9-ab5b55a7af73&t=bQBpAE0ASQBjADIAaABKAHAAegBVADkAQgA2AGwAZwA5AG4ASABWAHkAdAA2AEQAaQA4AHcAMwBSAEcAVwBxAFYAdQBQAG8ATABzAE4AWgBZADkAVwBjADMAMQBUAFAAdAAwADQAKwBsAEUAWQBGAG8AdAAvAEEATgBUAGEAQgBsAG0AYQBPAGUAaQBXAFEAegB5AEQAbQBwAGYAVQByAEgAVwBjAEUAWQBMAFIARgBnAC8AaABSAEsAegBXAFMAQQB3AFQAcAArAC8ARwBFAEMAdwBGAG0AZgBSAHEAMABwADkAZwBoAEgAQQA3AG4ANQBxADQATgAvAE4ANQAyAHgANwAzAFMAZwBZAFgAUgBzAGwAOAA1AEkARQBxAEIARwBkAFEAWABJAE4AdQBiAFoAVABVAHQAVABBADQATgBCAGoAbQB1AFYATAArAE8AdQBKAE0APQA1"
    // );
    const [portalUrlLoading, setPortalUrlLoading] = useState<boolean>(false);
    const { spireRequestData } = useSpire();
    const { selectedProposal } = useSelectedProposal();
    const [quotationsArray, setQuotationsArray] = useState<Array<any>>([]);
    const [isQuotationFinalized, setIsQuotationFinalized] = useState(false);
    const spireIndicativeQuote = useAppSelector((state) => state.spireIndicativeQuote);
    const [selectedQuotation, setSelectedQuotation] = useState<any | undefined>();
    // Spire callback service UIP mock invocation
    const callbackData = useCallback(() => {
        return {
            quoteResponse: {
                status: "Success",
                errorNote: "",
            },
            platformDetails: {
                platformId: "14075",
                platformCustomAttribute1: "",
                platformCustomAttribute2: selectedProposal?.contactId,
                platformCustomAttribute3: selectedProposal?.proposalId,
            },
            //   illustrationDetails: {
            //   annualIncomeAmount: 9996,
            //   incomePaymentAmount: 833,
            //   incomePaymentFrequency: 'Monthly',
            //   incomeRate: 9.21,
            //   premiumValue: 108485.85,
            //   quoteEffectiveDate: '2023-12-11T00:00:00',
            //   quoteReference: 'NON-GUARANTEED QUOTE'
            // }
            illustrationDetails: {
                quoteReference:
                    Math.random().toString(36).slice(2, 5).toUpperCase() +
                    "-2EWQBR" +
                    Math.floor(Math.random() * (99999 - 10000 + 1) + 10000), // random quote name/id
                quoteEffectiveDate:
                    spireIndicativeQuote?.illustrationDetails?.quoteEffectiveDate || "2023-12-11T00:00:00",
                // Spire callback service UIP mock invocation
                premiumValue: randomize(spireIndicativeQuote?.illustrationDetails?.premiumValue || 108485.85),
                annualIncomeAmount: spireIndicativeQuote?.illustrationDetails?.annualIncomeAmount || 9996,
                incomePaymentAmount: spireIndicativeQuote?.illustrationDetails?.incomePaymentAmount || 833,
                incomePaymentFrequency: spireIndicativeQuote?.illustrationDetails?.incomePaymentFrequency || "Monthly",
                incomeRate: randomize(
                    spireIndicativeQuote?.illustrationDetails?.incomeRate || defaultAnnuityRate * 100
                ), // +- ~0.05
            },
            deathBenefit: {
                years: 3,
                months: 9,
                deathBenefitSeries: [
                    {
                        year: 1,
                        amount: 97347,
                    },
                    {
                        year: 2,
                        amount: 70947,
                    },
                    {
                        year: 3,
                        amount: 44547,
                    },
                    {
                        year: 4,
                        amount: 18147,
                    },
                ],
            },
            cashInSeries: [
                {
                    year: 1,
                    amount: 97347,
                },
                {
                    year: 2,
                    amount: 70947,
                },
                {
                    year: 3,
                    amount: 44547,
                },
                {
                    year: 4,
                    amount: 18147,
                },
            ],
            cashinInterest: {
                cashInInterestRate: 3.0,
                cashInInterestSeries: [
                    {
                        year: 1,
                        amount: 81375,
                    },
                    {
                        year: 2,
                        amount: 70947,
                    },
                    {
                        year: 3,
                        amount: 44547,
                    },
                    {
                        year: 4,
                        amount: 18147,
                    },
                ],
            },
            survivalProbabilities: [
                {
                    probability: "50",
                    age: "88",
                },
                {
                    probability: "25",
                    age: "93",
                },
                {
                    probability: "10",
                    age: "96",
                },
                {
                    probability: "5",
                    age: "98",
                },
                {
                    probability: "1",
                    age: "102",
                },
            ],
            ComparisonDetails: [],
        };
    }, [spireIndicativeQuote, selectedProposal, defaultAnnuityRate]);
    const handleQuotationSelected = (e: any) => {
        setSelectedQuotation({
            label: e.target?.value,
            value: e.target?.value,
            ...(quotationsArray?.find((q) => q.name === e.target?.value) || {}),
        });
    };
    const handleQuotationSelectFocus = async (e: any) => {
        setSelectedQuotation(undefined);
        setQuotationsArray([]);
        // get proposals
        const _proposal = await getProposal(selectedProposal?.contactId, selectedProposal?.proposalId);
        dispatch(set_selected_proposal(_proposal));
    };
    const handleQuotations = async () => {
        setPortalUrlLoading(true);
        setQuotationsArray([]);
        setSelectedQuotation(undefined);
        setIsQuotationFinalized(false);
        const res = await getPortalUrl(spireRequestData());
        if (res?.data?.spirePortalUrl) {
            // open spire portal in new tab
            dispatch(set_show_external_link_modal(res?.data?.spirePortalUrl));
            // local persist for component state
            dispatch(set_spire_portal_url(res?.data?.spirePortalUrl || []));
            // clear existing quotations from current proposal
            let _proposal = JSON.parse(JSON.stringify(selectedProposal));
            _proposal.customData.quotations = [];
            try {
                // dispatch(updateGlobalLoaderState(true));
                const res = await updateProposal(_proposal, selectedProposal.contactId);
                if (res?.success) {
                    // set proposal to store
                    dispatch(set_selected_proposal(res?.proposal));
                    // dispatch(updateGlobalLoaderState(false));
                } else new Error();
            } catch (err) {
                // console.error("Update proposal error:", err);
                // dispatch(
                //     updateErrorModalState({
                //         showErrorModal: true,
                //         error: err,
                //     })
                // );
            }
            // Spire callback service UIP mock invocation
            personalizedQuoteCallback(callbackData());
        }
        setPortalUrlLoading(false);
    };
    const handleRecalculate = async () => {
        // update current proposal quotation to selected: true
        let _proposal = JSON.parse(JSON.stringify(selectedProposal));
        let _quotations = quotationsArray?.map((q) => {
            if (q?.value === selectedQuotation?.value) {
                return {
                    ...q,
                    selected: true,
                };
            }
            return {
                ...q,
                selected: false,
            };
        });
        _proposal.customData.quotations = _quotations;
        try {
            // dispatch(updateGlobalLoaderState(true));
            const res = await updateProposal(_proposal, selectedProposal.contactId);
            if (res?.success) {
                // set proposal to store
                dispatch(set_selected_proposal(res?.proposal));
                // dispatch(updateGlobalLoaderState(false));
            } else new Error();
        } catch (err) {
            // console.error("Update proposal error:", err);
            // dispatch(
            //     updateErrorModalState({
            //         showErrorModal: true,
            //         error: err,
            //     })
            // );
        }
        // re route to /view-proposal
        navigate("/home/view-proposal");
    };
    const renderSelectQuotationSection = () => {
        return (
            <div className="quotation-selection">
                <h6 className="demi-bold"> 1. Select Quotation </h6>
                <p>
                    {" "}
                    If you and your client have completed the detailed quotation questionnaire within the third party
                    system and have selected more than one set of assumptions, income options or annuity value, please
                    select below your preferred plan for the Retirement Optimiser to show and update.{" "}
                </p>
                <div className="quotation-selection__content">
                    <div className="quotation-selection__content__control">
                        {!isQuotationFinalized && (
                            <FTDropdown
                                id="quotation-dropdown"
                                data-testid="quotation-dropdown"
                                label="Quotation"
                                type="text"
                                noDataMessage=""
                                options={quotationsArray}
                                onChange={handleQuotationSelected}
                                placeholder="Quotation"
                                value={selectedQuotation?.value || ""}
                                onOpen={handleQuotationSelectFocus}
                            />
                        )}
                        <div className="quotation-info">
                            {isQuotationFinalized && (
                                <div className="key-value-line">
                                    <span>Quotation</span>
                                    <span className="align-right demi-bold">{selectedQuotation?.value || ""}</span>
                                </div>
                            )}
                            <div className="launchpad-key-value-line">
                                <span>Annuity Income Value</span>
                                <span>
                                    {selectedQuotation?.incomeValue
                                        ? formatter.format(selectedQuotation?.incomeValue)
                                        : ""}
                                </span>
                            </div>
                            <div className="launchpad-key-value-line">
                                <span>Annuity Value</span>
                                <span>
                                    {selectedQuotation?.premiumValue
                                        ? formatter.format(selectedQuotation?.premiumValue)
                                        : ""}
                                </span>
                            </div>
                            <div className="launchpad-key-value-line">
                                <span>Percentage</span>
                                <span>
                                    {selectedQuotation?.percentage ? selectedQuotation?.percentage * 100 + "%" : ""}
                                </span>
                            </div>
                            <div className="key-value-line">
                                <span>Expiry Date</span>
                                <span className="align-right demi-bold">
                                    {selectedQuotation?.expiryDate
                                        ? toHumanReadableDate(selectedQuotation?.expiryDate)
                                        : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="quotation-selection__content__logo">
                        <div className="quotation-selection__content__logo__remark">Annuity Provider:</div>
                        <img src={justLogo} onClick={() => personalizedQuoteCallback(callbackData())} alt="" />
                    </div>
                </div>
                <div className="same-line">
                    {!isQuotationFinalized && (
                        <FTButton
                            data-testid="recalculate-btn"
                            variant="outlined"
                            className="recalculate-btn medium-bold-button"
                            // data-link="/home/view-proposal?openDrawer=annuity"
                            onClick={handleRecalculate}
                            aria-label="Recalculate"
                            disabled={!Boolean(selectedQuotation) || portalUrlLoading}
                        >
                            <FTIcon iconName="ft ft-reset" className="recalc-icon" />
                            {" Recalculate"}
                        </FTButton>
                    )}
                    <FTButton
                        data-testid="update-quotation-btn"
                        className="update-quotation-btn medium-bold-button"
                        onClick={handleQuotations}
                        aria-disabled="false"
                        aria-label=" Update quotation "
                        disabled={portalUrlLoading}
                    >
                        <span className="recalc-icon">{portalUrlLoading ? "Loading..." : " Update quotation  "}</span>
                        {!portalUrlLoading && <FTIcon iconName="ft-arrow"></FTIcon>}
                    </FTButton>
                </div>
                {Boolean(selectedQuotation) && !isQuotationFinalized && (
                    <div className="info-alert">
                        <FTIcon iconName="ft-info" />
                        <span>
                            The quotation has been updated, please click “recalculate” to display up-to-date data
                        </span>
                    </div>
                )}
            </div>
        );
    };
    useEffect(() => {
        const finalQuote = selectedProposal?.customData?.quotations?.find((q: any) => q?.selected === true);
        if (finalQuote) {
            // update isQuotationFinalized to true
            setIsQuotationFinalized(true);
            // update selected quotation
            setSelectedQuotation(finalQuote);
        } else {
            let _quotationsOptions = selectedProposal?.customData?.quotations;
            // filter expired quotations
            _quotationsOptions = _quotationsOptions.filter((q: any) => new Date(q.expiryDate) > new Date());
            _quotationsOptions = _quotationsOptions.map((quotation: any) => ({
                label: quotation?.name,
                value: quotation?.name,
                ...quotation,
            }));
            setQuotationsArray(_quotationsOptions);
        }
    }, [selectedProposal?.customData?.quotations]);
    return (
        <div className="launchpad-right-card">
            <div className={`${selectedProposal?.customData?.includeAnnuities ? "" : "disabled-section low-opacity"}`}>
                <FTIcon iconName="ft-launchpad-step" />
            </div>
            {hasValue(spirePortalURL) || selectedProposal?.customData?.quotations?.length > 0 ? (
                renderSelectQuotationSection()
            ) : (
                <div>
                    <div
                        className={`${
                            selectedProposal?.customData?.includeAnnuities ? "" : "disabled-section low-opacity"
                        }`}
                    >
                        <h6 className="demi-bold">1. Finalise Your Client’s Annuity Quotation </h6>
                        <p>
                            {" "}
                            Click here to get a detailed annuity or lifetime income quote from Just. It will require you
                            and your client to complete a detailed health questionnaire. All data provided is
                            independent of the Retirement Income Optimiser and is personal to each and every client.
                            Once complete, this plan will be updated to reflect the actual yield and income that an
                            annuity can provide your client.{" "}
                        </p>
                        <FTButton
                            className={`left-aligned-btn ${
                                selectedProposal?.customData?.includeAnnuities ? "" : "disabled-cusor"
                            }`}
                            endIcon={!portalUrlLoading && <FTIcon iconName="ft-arrow" />}
                            data-link=""
                            onClick={handleQuotations}
                            disabled={portalUrlLoading || !selectedProposal?.customData?.includeAnnuities}
                        >
                            {portalUrlLoading ? "Loading..." : "Let's Go"}
                        </FTButton>
                    </div>
                    {!selectedProposal?.customData?.includeAnnuities && (
                        <div className="info-alert">
                            <FTIcon iconName="ft-info" />
                            <span>
                                Personalised quotation not available because there is no additional increase in the
                                guaranteed income allocation.{" "}
                            </span>
                            <u onClick={() => navigate("/home/view-proposal?openDrawer=annuity")}>Click here </u>
                            <span> to look at the impact of adding guaranteed income to the plan. </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AnnuityQuotation;

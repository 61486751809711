import FTNumberInput from "atoms/FTNumberInput";
import { useSelectedProposal } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { fetchCurrencySymbol, formatter } from "utils/helper";

const ExpenditureDrawer = ({
    totalExpense,
    setSubmitDisabled,
}: {
    setSubmitDisabled: (err: boolean) => void;
    totalExpense: number;
}) => {
    const { displayedProposal, setDisplayedProposal, isMonthlyOn } = useSelectedProposal();
    const lifeStyleValueMapper: any = useMemo(
        () => ({
            foodAndDrink: { label: `Food and drink ${fetchCurrencySymbol}`, value: "A + B" },
            personal: {
                label: `Personal goods and services ${fetchCurrencySymbol}`,
                value: "F",
            },
            housing: {
                label: `Housing bills and maintenance ${fetchCurrencySymbol}`,
                value: "D + E",
            },
            clothingAndFootwear: {
                label: `Clothing and footwear ${fetchCurrencySymbol}`,
                value: "C",
            },
            transport: { label: `Transport ${fetchCurrencySymbol}`, value: "G" },
            socialAndCultural: {
                label: `Social, cultural and holidays ${fetchCurrencySymbol}`,
                value: "H",
            },
        }),
        []
    );
    const [showLifestyleInputErrors, setShowLifestyleInputErrors] = useState<Array<string | undefined>>([]);

    useEffect(() => {
        // disableClosingDrawer
        if (
            showLifestyleInputErrors.includes(
                `Please enter a value less than or equal to ${formatter.format(10000000)}`
            )
        ) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [showLifestyleInputErrors, setSubmitDisabled]);

    const handleLifestyleInputValues = (id: string, val: string, idx: number) => {
        let changedExpenditures = {};
        let newErrors = [...showLifestyleInputErrors];
        const validatedOtherValue = val;
        if (Number(validatedOtherValue) > 10000000) {
            newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
            setShowLifestyleInputErrors(newErrors);
        } else {
            newErrors[idx] = undefined;
            setShowLifestyleInputErrors(newErrors);
        }
        changedExpenditures = {
            ...displayedProposal?.customData?.expenditures,
            [id]: validatedOtherValue,
        };
        let changedProposal = JSON.parse(JSON.stringify(displayedProposal));
        changedProposal.customData.expenditures = changedExpenditures;
        setDisplayedProposal(changedProposal);
    };

    // TODO
    // const hasValidationErrors = () => {
    //     return showLifestyleInputErrors.length > 0 ? "has error" : "no errors in this page"
    //     // console.log(hasValue(showLifestyleInputErrors))
    // }

    return (
        <>
            <div className="drawer-content__title-container">
                <h5 className="drawer-content__title">
                    {" "}
                    {`Total expenses ${isMonthlyOn ? "(Monthly)" : "(Annual)"}`}{" "}
                </h5>
                <h5 className="drawer-content__totalAmount"> {formatter.format(totalExpense)} </h5>
            </div>

            <div className="breakdown-block regualr-expenses-block">
                <h6>
                    REGULAR EXPENSES{" "}
                    <span className="monthly-annual-label">{isMonthlyOn ? "(Monthly)" : "(Annual)"}</span>
                </h6>

                {Object.entries(displayedProposal?.customData?.expenditures || {})?.map((item, idx: number) => (
                    <div key={idx} className="qf-input-number">
                        <FTNumberInput
                            id={`lifestyle_input_${idx}`}
                            data-testid={`lifestyle_input_${idx}`}
                            label={lifeStyleValueMapper[item[0]].label}
                            onChange={(e) => handleLifestyleInputValues(item[0], e.target.value, idx)}
                            value={item[1]}
                            disabled={!displayedProposal?.customData?.expendituresMeta?.lifestyle}
                        />
                        <span className={showLifestyleInputErrors[idx] ? "MuiFormHelperText-root" : ""}>
                            {showLifestyleInputErrors[idx] || ""}
                        </span>
                    </div>
                ))}
            </div>

            {/* Life Event Block Starts */}
            {/* <div data-testid="life-event-block" className="breakdown-block life-event-block">
                <div className="breakdown-block__title-container">
                    <h6 className="breakdown-block__title"> LIFE EVENTS </h6>
                    <div className="breakdown-block__sub-title"> Total: {"£0.00"} </div>
                </div>
                <FTButton
                    className="left-aligned-btn"
                    data-testid="add-new-life-event-btn"
                    aria-label={FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                    // onClick={handleAddLifeEvent}
                >
                    {FACTFINDINGPAGE.LABEL_CONSTANTS.ADD_NEW}
                </FTButton>
            </div> */}
            {/* Life Event Block Ends */}
        </>
    );
};

export default ExpenditureDrawer;

import { calculateTotalExpense, formatter, getMonthlyValue } from "utils/helper";
import { Box } from "@mui/material";
import FTPopover from "atoms/FTPopover";
import { useAppSelector, useSelectedProposal } from "hooks";
import ConsumptionChart from "./ConsumptionChart";
import WealthChart from "./WealthChart";
// import { goe4dTranslation } from "@constants/mock-data";

const IncomeWealthTab = () => {
    const { viewProposalGraphDescription, viewProposalDisclaimers } = useAppSelector((state) => state.bloomreachCDN);
    const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    const { proposedIncomeGraphDescription } = viewProposalGraphDescription || {};
    const { consumptionTabDisclaimer } = viewProposalDisclaimers || [];
    const { isMonthlyOn, expenditures } = useSelectedProposal();

    const renderTargetExpenditures = (isMonthlyOn: boolean) => {
        return (
            <div className="qf-label-value">
                <div className="qf-label-value__label">Target {isMonthlyOn ? "monthly" : "annual"} expenditure</div>
                <div className="qf-label-value__value">
                    {formatter.format(calculateTotalExpense(expenditures) || 0)}
                </div>
            </div>
        );
    };

    const renderRecommendedIncome = (isMonthlyOn: boolean) => {
        const monthlyRecommendedIncome = formatter.format(
            getMonthlyValue(goe4dTranslation?.body?.recommendedConsumption[0]) || 0
        );
        const annualRecommendIncome = formatter.format(goe4dTranslation?.body?.recommendedConsumption[0] || 0);

        return (
            <div className="qf-label-value">
                <div className="qf-label-value__label">Recommended {isMonthlyOn ? "monthly" : "annual"} income</div>
                <div className="qf-label-value__value">
                    {isMonthlyOn ? monthlyRecommendedIncome : annualRecommendIncome}
                </div>
            </div>
        );
    };

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <h5> Proposal Income </h5>
                <FTPopover type="secondary" message="A personalised combination of guaranteed income and drawdown." />
            </Box>
            <div className="chart-content-container">
                <div className="chart-content__content">
                    <div data-testid="target-expenditure">{renderTargetExpenditures(isMonthlyOn)}</div>
                    <div data-testid="recommended-income">{renderRecommendedIncome(isMonthlyOn)}</div>
                </div>
                <div className="chart-content__description">
                    <p className="disclaimer">{proposedIncomeGraphDescription && proposedIncomeGraphDescription}</p>
                </div>
            </div>
            {Object.entries(goe4dTranslation || {})?.length > 0 && <ConsumptionChart />}
            <div className="chart-content-container">
                <div className="chart-content__content">
                    <h5> Investable Wealth </h5>
                </div>
                <div className="chart-content__description">
                    <p className="disclaimer">
                        This graph demonstrates how your client's wealth can be preserved long into their retirement
                        future.
                    </p>
                </div>
            </div>
            {Object.entries(goe4dTranslation || {})?.length > 0 && <WealthChart />}

            <div className="chart-content__description">
                <p className="disclaimer">{consumptionTabDisclaimer && consumptionTabDisclaimer}</p>
            </div>
        </>
    );
};

export default IncomeWealthTab;

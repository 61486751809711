import Button from "@mui/material/Button";
import { ButtonProps } from "@mui/material";
import "./styles/atoms.styles.scss";

const FTButton = (props: ButtonProps) => {
	const {
		className,
		endIcon,
		children,
		variant = "contained",
		disabled,
	} = props;
	return (
		<article
			className={
				className === "parent-width-btn"
					? className
					: `button-container ${className}`
			}
		>
			<span className={disabled ? "disabled-cursor" : ""}>
				<Button
					className={className}
					variant={variant}
					disableElevation
					disableRipple
					endIcon={endIcon}
					{...props}
				>
					{children}
				</Button>
			</span>
		</article>
	);
};

export default FTButton;

import FTIcon from "atoms/FTIcon";
import { LOGINPAGE } from "../../@constants";

const StaticContent = () => (
	<section className="static-content-container">
		<h1 className="static-content-heading demi-bold">
			{LOGINPAGE.STATIC_CONTENT.HEADING}
		</h1>
		<p className="static-content-body">{LOGINPAGE.STATIC_CONTENT.BODY}</p>
		<article className="static-content-icons">
			<div className="header-icon">
				<FTIcon iconName="ft-value" />
				<h6> {LOGINPAGE.STATIC_CONTENT.FIG1} </h6>
			</div>
			<div className="header-icon">
				<FTIcon iconName="ft-zoom" />
				<h6>{LOGINPAGE.STATIC_CONTENT.FIG2}</h6>
			</div>
			<div className="header-icon">
				<FTIcon iconName="ft-growth" />
				<h6>{LOGINPAGE.STATIC_CONTENT.FIG3} </h6>
			</div>
		</article>
	</section>
);

export default StaticContent;

import { useOktaAuth } from "@okta/okta-react";
import FTGlobalLoader from "atoms/FTGlobalLoader";
import { useAppSelector, useSelectedProposal, useSpire } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "utils/helper";
import AnnualProposalDetails from "./AnnualProposalDetails";
import FTTabs from "./FTTabs";
import StepDrawers from "./StepDrawers";
import "../styles/view-proposal.styles.scss";

const ViewProposal = () => {
	const { authState } = useOktaAuth();
	const { fetchSpireData } = useSpire();
	const navigate = useNavigate();
	const selectedContact = useAppSelector((state) => state.selectedContact);
	const viewProposalLoading = useAppSelector(
		(state) => state.viewProposalLoading
	);
	const [drawerActions, setDrawerActions] = useState({
		selectedDrawer: "",
		isDrawerVisible: false,
		disabledDrawer: "",
	});
	const {
		selectedProposal,
		setAnnualDisplayedProposal,
		setOnlyDisplayedProposal,
		adjustValuesIfIsMonthlyOn,
	} = useSelectedProposal();

	const viewProposalDisclaimers = useAppSelector(
		(state) => state.bloomreachCDN.viewProposalDisclaimers
	);
	const viewProposalDisclaimer =
		Object.values(Object.values(viewProposalDisclaimers)[0])[0] || "";

	useEffect(() => {
		if (authState)
			if (!authState.isAuthenticated) navigate("/?reason=logoutSuccess");
			else if (Object.values(selectedContact)?.length === 0)
				navigate("/advisor/landing");
			else if (Object.values(selectedProposal)?.length === 0) navigate("/home");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState, selectedContact, selectedProposal, navigate]);

	useEffect(() => {
		scrollToTop();
		if (Object.entries(selectedProposal).length > 0) {
			setAnnualDisplayedProposal(selectedProposal);
			setOnlyDisplayedProposal(
				adjustValuesIfIsMonthlyOn(selectedProposal?.customData?.isMonthlyOn, selectedProposal)
			);
			fetchSpireData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<main className="view-proposal-container">
			<div className="ft-split__first">
				<StepDrawers
					drawerActions={drawerActions}
					setDrawerActions={setDrawerActions}
				/>
			</div>
			<div className="ft-split__middle">
				<FTGlobalLoader position="absolute" open={viewProposalLoading} />
				<>
					<AnnualProposalDetails drawerActions={drawerActions} />
					<FTTabs />
					<p className="bottom-disclaimer">{viewProposalDisclaimer}</p>
				</>
			</div>
		</main>
	);
};

export default ViewProposal;

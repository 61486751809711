import parse from "html-react-parser";
import FTLogo from "../../assets/logos/ft-footer-logo.png";
import { FOOTER_TEXT } from "../../@constants";

const Footer = () => {
	return (
		<>
			<section className="footer-container">
				<p>{parse(FOOTER_TEXT)}</p>
				<img className="ft-footer-logo" alt="ft-footer-logo" src={FTLogo} />
			</section>
		</>
	);
};

export default Footer;

import { CREATEPROFILEPAGE } from "@constants";
import { QUESTIONS } from "@constants/label-constants";
import { Box } from "@mui/material";
import FTBox from "atoms/FTBox";
import FTInput from "atoms/FTInput";
import FTMessageLite from "atoms/FTMessageLite";
import FTPopover from "atoms/FTPopover";
import QuestionaireCard from "components/PersonalInformation/QuestionaireCard";
import "./personal-information.styles.scss";
import moment from "moment";

const PersonalInformationView = ({
    setCustomerDetails,
    customerDetails,
    handleInputField,
    handleQuestionaireChange,
    handleInputFieldOnBlur,
    inputFieldError,
    isOnUpdatePage,
}: any) => {
    const { firstName, lastName, birthdate, postcode } = customerDetails;
    const { firstNameError, lastNameError, postcodeError, birthdateError } = inputFieldError;

    return (
        <>
            <FTBox
                header="Default"
                headerTitle={CREATEPROFILEPAGE.PERSONAL_INFORMATION}
                body={
                    <>
                        <div className="display-elements-inline">
                            <Box sx={{ flexDirection: "column", flex: "1" }}>
                                <FTInput
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    label={CREATEPROFILEPAGE.INPUT_LABELS.FIRST_NAME}
                                    onChange={handleInputField}
                                    onBlur={handleInputFieldOnBlur}
                                    value={firstName}
                                    showErrorOutline={firstNameError}
                                />
                                {firstNameError && (
                                    <p className="MuiFormHelperText-root">
                                        {
                                            CREATEPROFILEPAGE.FORM_VALIDATION_ERRORS
                                                .PLEASE_USE_ALPHANUMERIC_CHARACTERS_NUMBER_OR_SPACE
                                        }
                                    </p>
                                )}
                            </Box>

                            <Box sx={{ flexDirection: "column", flex: "1" }}>
                                <FTInput
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    label={CREATEPROFILEPAGE.INPUT_LABELS.LAST_NAME}
                                    onChange={handleInputField}
                                    onBlur={handleInputFieldOnBlur}
                                    value={lastName}
                                    showErrorOutline={lastNameError}
                                />
                                {lastNameError && (
                                    <p className="MuiFormHelperText-root">
                                        {
                                            CREATEPROFILEPAGE.FORM_VALIDATION_ERRORS
                                                .PLEASE_USE_ALPHANUMERIC_CHARACTERS_NUMBER_OR_SPACE
                                        }
                                    </p>
                                )}
                            </Box>

                            {isOnUpdatePage ? (
                                <Box sx={{ flexDirection: "column", flex: "1" }} className="label-model">
                                    <div className="label-model__label">{CREATEPROFILEPAGE.INPUT_LABELS.DOB}</div>
                                    <div className="label-model__value">{moment(birthdate).format("DD/MM/YYYY")}</div>
                                </Box>
                            ) : (
                                <Box sx={{ flexDirection: "column", flex: "1" }}>
                                    <FTInput
                                        id="birthdate"
                                        name="birthdate"
                                        type="text"
                                        label={CREATEPROFILEPAGE.INPUT_LABELS.DOB}
                                        placeholder="DD/MM/YYYY"
                                        onChange={handleInputField}
                                        onBlur={handleInputFieldOnBlur}
                                        value={birthdate}
                                        showErrorOutline={birthdateError}
                                    />
                                    {birthdateError !== "" && (
                                        <p className="MuiFormHelperText-root">{birthdateError}</p>
                                    )}
                                </Box>
                            )}

                            {isOnUpdatePage ? (
                                <Box sx={{ flexDirection: "column", flex: "1" }} className="label-model">
                                    <div className="label-model__label">{CREATEPROFILEPAGE.INPUT_LABELS.POST_CODE}</div>
                                    <div className="label-model__value">{postcode}</div>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        flexDirection: "column",
                                        flex: "1",
                                        position: "relative",
                                    }}
                                >
                                    <span className="inside-input-field">
                                        <FTPopover
                                            message={
                                                CREATEPROFILEPAGE.FORM_VALIDATION_ERRORS
                                                    .MUST_BE_VALID_UK_POSTCODE_INCLUDING_THE_SPACE
                                            }
                                        />
                                    </span>
                                    <FTInput
                                        id="postcode"
                                        name="postcode"
                                        type="text"
                                        label={CREATEPROFILEPAGE.INPUT_LABELS.POST_CODE}
                                        // placeholder={CREATEPROFILEPAGE.INPUT_LABELS.PLESE_TYPE_HERE}
                                        onChange={handleInputField}
                                        onBlur={handleInputFieldOnBlur}
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        value={postcode}
                                        showErrorOutline={postcodeError}
                                    />
                                    {postcodeError && (
                                        <p className="MuiFormHelperText-root">
                                            {CREATEPROFILEPAGE.FORM_VALIDATION_ERRORS.PLEASE_ENTER_A_VALID_POSTCODE}
                                        </p>
                                    )}
                                </Box>
                            )}
                        </div>

                        {isOnUpdatePage ? (
                            <></>
                        ) : (
                            <div className="create-warning-msg">
                                <FTMessageLite
                                    type="warning"
                                    message={CREATEPROFILEPAGE.WARNING_MESSAGES.DOB_POST_WARN}
                                />
                            </div>
                        )}

                        <hr className="_separator" />

                        <section className="questionaire">
                            {QUESTIONS?.map(({ id, question, popOverMessage, defaultValue, warningMessage }) => (
                                <QuestionaireCard
                                    key={id}
                                    questionID={id}
                                    question={question}
                                    popOverMessage={popOverMessage}
                                    setCustomerDetails={setCustomerDetails}
                                    customerDetails={customerDetails}
                                    defaultValue={defaultValue}
                                    handleQuestionaireChange={handleQuestionaireChange}
                                    warningMessage={warningMessage}
                                    showWarningBox={id === QUESTIONS[0].id ? !customerDetails[id] : customerDetails[id]}
                                />
                            ))}
                        </section>
                    </>
                }
            />
        </>
    );
};

export default PersonalInformationView;

import { DECIMAL_COMMA_COUNTRY_LIST, LONGEST_DISPLAYED_NUMBER } from "@constants";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputCustomProps } from "@types";
import { hasValue } from "utils/validateInputs";
import { useAppSelector } from "hooks";
import "./styles/atoms.styles.scss";

const FTNumberInput = (props: TextFieldProps & InputCustomProps & { onEnter?: () => void }) => {
    const { id, value, className, showErrorOutline, onEnter, onChange, placeholder, label, disabled, autoFocus } =
        props;
    const locale = useAppSelector((state) => state?.global?.orgInfo?.settings?.locale);
    const isThousandSeparatorDot = DECIMAL_COMMA_COUNTRY_LIST.includes(locale);
    const thousandSeparator: string = isThousandSeparatorDot ? "." : ",";
    const onKeyDown = (e: any) => {
        // handle Submit if Enter is pressed
        if (hasValue(value) && e.keyCode === 13 && onEnter) onEnter();
    };
    const formatValue = (str: any) => {
        let fv = str;
        if (str) {
            if (isThousandSeparatorDot && fv?.toString()?.indexOf(".") > -1) fv = fv?.toString()?.replace(".", ",");
            fv = fv?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
            return fv;
        }
        return str;
    };
    const onFormattedChange = (e: any) => {
        let formattedEvent = e;
        let formattedString = e.target.value;
        if (!isThousandSeparatorDot) formattedString = e.target.value?.toString()?.replace(/[^0-9.]/g, "");
        else formattedString = e.target.value?.toString()?.replace(/[^0-9,]/g, "");
        // replace , with . if !isThousandSeparatorDot
        if (isThousandSeparatorDot) formattedString = formattedString?.toString()?.replace(",", ".");
        // maximum allowed number edge case
        if (formattedString < LONGEST_DISPLAYED_NUMBER) {
            if (formattedString === ".") formattedString = "0.";
            else if (formattedString.indexOf("0") === 0 && formattedString.indexOf(".") !== 1)
                formattedString = formattedString.slice(1);
            let temp = formattedString?.substring(formattedString?.indexOf("."));
            if (temp?.indexOf(".") === 0) {
                temp = temp?.replace(/[^0-9]/g, "")?.substring(0, 2);
                formattedString = formattedString?.substring(0, formattedString?.indexOf(".")) + "." + temp;
            }
            formattedEvent.target.value = formattedString;
            if (onChange) onChange(formattedEvent);
        }
    };
    const onBlur = (e: any) => {
        let formattedEvent = e;
        let formattedValue = e.target.value?.toString()?.split(thousandSeparator).join("");
        // replace , with . if isThousandSeparatorDot
        if (isThousandSeparatorDot) formattedValue = formattedValue?.toString()?.replace(",", ".");
        if (formattedValue === "0.") formattedValue = "0.00";
        else if (
            formattedValue !== "" &&
            formattedValue.length > 1 &&
            formattedValue.indexOf(".") === formattedValue.length - 1
        )
            formattedValue += "00";
        else if (
            formattedValue !== "" &&
            formattedValue.length > 1 &&
            formattedValue.indexOf(".") === formattedValue.length - 2
        )
            formattedValue += "0";
        else if (formattedValue !== "" && formattedValue.indexOf(".") === -1) formattedValue += ".00";
        formattedEvent.target.value = formattedValue;
        if (onChange) onChange(formattedEvent);
    };
    const onFocus = (e: any) => {
        let formattedEvent = e;
        let formattedValue = e.target.value?.toString()?.split(thousandSeparator)?.join("");
        // replace , with . if isThousandSeparatorDot
        if (isThousandSeparatorDot) formattedValue = formattedValue?.toString()?.replace(",", ".");
        if (formattedValue !== "" && formattedValue.indexOf(".00") !== -1) {
            formattedValue = formattedValue.slice(0, -3);
        } else if (formattedValue !== "" && formattedValue.indexOf(".") !== -1 && formattedValue.slice(-1) === "0") {
            formattedValue = formattedValue.slice(0, -1);
        }
        formattedEvent.target.value = formattedValue;
        if (onChange) onChange(formattedEvent);
    };
    return (
        <article
            className={
                "input-field" + (showErrorOutline ? " hasErrorOutline " : " ") + (disabled && " disabled-cursor-input")
            }
        >
            <TextField
                data-testid={id}
                id={id}
                value={formatValue(value)}
                label={label}
                error={Boolean(showErrorOutline)}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    className: className,
                }}
                onKeyDown={onKeyDown}
                onChange={onFormattedChange}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                disabled={disabled}
                autoFocus={autoFocus}
                autoComplete="no"
            />
        </article>
    );
};

export default FTNumberInput;

import { ReactNode, RefObject, useState } from "react";
import FTButton from "./FTButton";
import FTIcon from "./FTIcon";
import { CircularProgress } from "@mui/material";
import FTModal from "./FTModal";
import { ProposalPdf as ExportProposalPdf, TransactionExecutionPdf } from "utils/pdfExport";
import { useAppSelector, useSelectedProposal } from "hooks";

interface FTPdfModalProps {
    children: ReactNode;
    open: boolean;
    onClose: () => void;
    isProposalPdf?: boolean;
    pdfContainerRef?: RefObject<HTMLDivElement>;
}

const MockDisclaimers =
    "Assumption: Platform fee is a fixed fee, applied to all wealth assets. The fund costs are derived from the fact-sheets of the underlying funds, based upon the latest valuation. Further details can be obtained from http://www.franklintempletonglobal.com As a 'distributor' of our products, you need to provide your clients with details of all the costs & charges they’ll incur as result of making their investment. This will include product and fund charges, any additional services charges and your advice charges. It is the responsibility of the Adviser to ensure the accurate costs & charges inputs have been uploaded in order to determine an accurate plan which reflects the client. <br /> <br /> Separate fact sheets exist for each underlying product in the plan. The assumption used in the plan, include growth rates. The growth rate provided in this document is an indication and is not guaranteed. Actual costs and charges may depend on the performance of the Fund. The total charges deducted for any Fund will have an impact on the investment return your client might get. Whilst performance can’t be guaranteed, we can give you examples of how the charges will affect what they might get back. <br /> <br />This illustration assumes that costs occur evenly throughout the year, charges are paid out of the investment evenly throughout the year, and that a gross growth rate (as shown in the assumptions below) applies. The figures are estimates and may change.";

const FTPdfModal = ({ children, onClose, open, pdfContainerRef, isProposalPdf }: FTPdfModalProps) => {
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const { label } = useAppSelector((state) => state?.selectedContact);
    const { selectedProposal } = useSelectedProposal();
    const exportPdf = async () => {
        setIsGeneratingPdf(true);
        if (pdfContainerRef?.current) {
            try {
                isProposalPdf
                    ? await new ExportProposalPdf({
                          selectedContact: { displayName: label },
                      }).export(pdfContainerRef.current)
                    : await new TransactionExecutionPdf(label, selectedProposal, MockDisclaimers).export(
                          pdfContainerRef.current
                      );
            } catch (error) {
                console.error("Error in generating PDF");
            }
            setIsGeneratingPdf(false);
        }
    };

    return (
        <FTModal handleClose={onClose} open={open}>
            <>
                <div className="proposal-pdf-modal">
                    <div className="proposal-pdf-modal-body">
                        <FTButton className="proposal-pdf-modal-close" size="small" onClick={onClose}>
                            <FTIcon iconName="ft-x-switch" />
                        </FTButton>
                        <FTButton
                            size="small"
                            className="proposal-pdf-modal-download"
                            onClick={exportPdf}
                            disabled={isGeneratingPdf}
                        >
                            {isGeneratingPdf ? (
                                <CircularProgress sx={{ width: "20px !important", height: "20px !important" }} />
                            ) : (
                                <FTIcon iconName="ft-download" />
                            )}
                        </FTButton>
                        <div className="proposal-pdf-modal-panel">{children}</div>
                    </div>
                </div>
            </>
        </FTModal>
    );
};

export default FTPdfModal;

import { MenuItem } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { DropdownProps } from "@types";
import "./styles/atoms.styles.scss";
import { hasValue } from "utils/validateInputs";

const FTInputXL = (props: TextFieldProps & DropdownProps & { onEnter?: () => void }) => {
    const {
			id,
            placeholder,
            label,
			value,
			className,
			select,
			options,
			error,
			onEnter,
			onBlur,
			onFocus,
            onChange,
            disabled,
            autoFocus,
            type,
		} = props;
    const onKeyDown = (e: any) => {
        // handle Submit if Enter is pressed
        if (hasValue(value) && e.keyCode === 13 && onEnter) onEnter();
    };
    const resolveClassName = () => `${select ? "select-field-xl" : "input-field-xl"} ${error ? "has-error" : ""}`;
    const renderSelectOptions = () => {
        if (select && options) {
            if (options?.length > 0)
                return options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ));
            else
                return (
                    <MenuItem value="" disabled>
                        No clients added
                    </MenuItem>
                );
        }
        return null;
    };
    return (
			<article className={resolveClassName()}>
				<TextField
					fullWidth
					data-testid={id}
					variant="standard"
					onKeyDown={onKeyDown}
					InputProps={{
						disableUnderline: true,
						className: className,
						value: value,
					}}
					id={id}
					label={label}
					value={value || ""}
					onChange={onChange}
					onBlur={onBlur}
					onFocus={onFocus}
					placeholder={placeholder}
					disabled={disabled}
					autoFocus={autoFocus}
					select={select}
					type={type}
				>
					{renderSelectOptions()}
				</TextField>
			</article>
		);
};

export default FTInputXL;

import { CHART_LABELS } from "@constants";
import FTChartControlBar from "atoms/FTChartControlBar";
import { useEffect, useState } from "react";
// import { goe4dTranslation } from "@constants/mock-data";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import more from "highcharts/highcharts-more";
import { useAppSelector, useSelectedProposal } from "hooks";
import {
    calculateCurrentAge,
    calculateOtherWealthValues,
    fetchCurrencySymbol,
    formatter,
    getSIPPValue,
} from "utils/helper";
import { Box } from "@mui/material";
// more(Highcharts); // init module

const FT_BRAND_PRIMARY_BLUE = "#3769ff";

const initalWealthComparators = {
    fourPerRule: false,
    targetConsumption: false,
    hundredPerAnnutisation: false,
};

const WealthChart = ({ isPdf = false }: { isPdf?: boolean }) => {
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    const { lifeSpan, healthPersonaPercentage } = useAppSelector((state) => state.global?.orgInfo?.settings);
    const { healthStatus } = useAppSelector((state) => state?.selectedContact);
    const { goodHealth, nonSmoker, smoker } = healthPersonaPercentage;
    const [comparators, setComparators] = useState(initalWealthComparators);
    const { wealthItems } = useSelectedProposal();
    const {
        investableWealthChart: {
            comparators: { fourPerRule, targetConsumption },
        },
    } = goe4dTranslation || {
        investableWealthChart: {
            comparators: { fourPerRule: [], targetConsumption: [] },
        },
    };

    const calculateAnnuityQuotePercent = () => {
        if (healthStatus === "AboveAverage") {
            return goodHealth;
        } else if (healthStatus === "Average") {
            return nonSmoker;
        } else {
            return smoker;
        }
    };

    const annuityRate = calculateAnnuityQuotePercent();

    const _pointHtml = (key: string, value: string, dimmed: boolean = false) => {
        return `
				<tr style="${dimmed ? "color: #A7A7A7; font-size: 12px;" : "font-size: 13.3px"}">
						<th style="font-weight: normal; padding: 8px 16px">${key}</th>
						<td style="text-align:right; font-weight: bold; padding: 8px 16px;">&nbsp;${value}</td>
				</tr>
		`;
    };

    const tooltipChartFormatter = (points: any) => {
        const ageLine = _pointHtml(CHART_LABELS.ageLabel, points[0].x.toString());
        points = points.filter((pt: any) => pt.series.name !== "hidden" && pt.series.type !== "column");

        const tooltipData = points?.map((pt: any) => ({
            key: pt.series.name,
            value: pt.y,
            dimmed: false,
        }));

        let finalTooltipData = ageLine;
        tooltipData.forEach((_item: any) => {
            const extraTooltipValue = _pointHtml(_item.key, formatter.format(_item.value), _item.dimmed);
            finalTooltipData += extraTooltipValue;
        });

        // totalWealth = investable wealth + cash reserve
        let totalWealth = tooltipData?.reduce((total: number, _itm: any) => {
            let sum = _itm.key === "Investable Wealth" || _itm.key === "Cash Reserve" ? _itm?.value : 0;
            return total + sum;
        }, 0);

        const formattedTotalWealth = _pointHtml(CHART_LABELS.totalWealth, formatter.format(totalWealth));
        return finalTooltipData + formattedTotalWealth;
    };

    const highchartOptions: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        title: { text: "" },
        // chart: {
        // },
        chart: {
            type: "column",
            borderColor: "none",
            borderRadius: 0,
            animation: false,
            backgroundColor: "transparent",
            marginTop: 30,
        },
        // legend: {
        // 	enabled: false,
        // },
        accessibility: {
            enabled: false,
        },
        xAxis: {
            min: calculateCurrentAge(selectedContact?.birthdate),
            max: lifeSpan,
            // step: 1,
            categories: [],
            title: {
                text: "",
                margin: 24,
            },
            crosshair: {
                color: FT_BRAND_PRIMARY_BLUE,
                width: 1,
            },
            // labels: {
            // format: `{value: value+${calculateCurrentAge(selectedContact?.birthdate)}}`
            // }
        },
        yAxis: {
            title: {
                text: `${CHART_LABELS.amountLabel} ${fetchCurrencySymbol}`,
                margin: 24,
            },
            stackLabels: {
                enabled: false,
            },
            maxPadding: 0.2,
            min: 0,
            gridLineDashStyle: "Dash",
            // labels: {
            //   formatter: function () {
            //     const res = this.axis.defaultLabelFormatter.call(this);
            //     return this.isLast ? '<div class="chart-label-title">Life Events</div>' : res;
            //   }
            // }
        },
        tooltip: {
            useHTML: true,
            shared: true,
            shadow: false,
            backgroundColor: "#FBFBFF",
            borderColor: FT_BRAND_PRIMARY_BLUE,
            borderRadius: 0,
            borderWidth: 1,
            padding: 0,
            formatter: function () {
                return `<table> ${tooltipChartFormatter(this.points)} </table>`;
            },
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                },
            },
            line: {
                marker: {
                    enabled: false,
                },
            },
        },
        series: [
            {
                type: "line",
                name: "Cash Reserve",
                data: [
                    ...new Array(calculateCurrentAge(selectedContact?.birthdate)).fill(
                        goe4dTranslation?.investableWealthChart?.cashReserves[0]
                    ),
                    ...goe4dTranslation?.investableWealthChart?.cashReserves,
                ],
                color: "rgb(20, 70, 225)",
                dashStyle: "ShortDash",
                lineWidth: 2,
                // thick: true,
            },
            {
                type: "line",
                name: "Investable Wealth",
                data: [
                    ...new Array(calculateCurrentAge(selectedContact?.birthdate)).fill(
                        goe4dTranslation?.investableWealthChart?.investableWealth[0]
                    ),
                    ...goe4dTranslation?.investableWealthChart?.investableWealth,
                ],
                color: "rgb(82, 145, 255)",
                dashStyle: "Solid",
                lineWidth: 2,
                // thick: true,
            },
            {
                type: "column",
                borderColor: "none",
                borderRadius: 0,
                name: "SIPP Value",
                data: [
                    ...new Array(calculateCurrentAge(selectedContact?.birthdate)).fill(0),
                    Number(getSIPPValue(wealthItems)),
                    ...new Array(goe4dTranslation?.investableWealthChart?.investableWealth.length - 2).fill(0),
                ],
                color: "rgba(255,121,78,0.5)",
            },
            {
                type: "column",
                borderColor: "none",
                borderRadius: 0,
                name: "Other Wealth",
                data: [
                    ...new Array(calculateCurrentAge(selectedContact?.birthdate)).fill(0),
                    Number(calculateOtherWealthValues(wealthItems)),
                    ...new Array(goe4dTranslation?.investableWealthChart?.investableWealth.length - 2).fill(0),
                ],
                color: "rgb(255,121,78)",
            },
            // {
            //     type: "line",
            //     name: "Achievable Income",
            //     data: [...new Array(calculateCurrentAge(selectedContact?.birthdate)-1).fill(0), ...achiveableIncome],
            //     color: 'rgb(192, 66, 234)',
            //     dashStyle: 'Solid',
            //     strokeWidth: 30,
            //     thick: true,
            // },
        ],
    };
    const [chartOptions, setChartOptions] = useState(highchartOptions);

    const updateHighcharts = () => {
        let _series = chartOptions.series;
        _series =
            _series &&
            _series.filter(
                (s: any) => s.name !== "4% Rule" && s.name !== "Target Consumption" && s.name !== "100% Annuitisation"
            );

        if (comparators.fourPerRule) {
            _series &&
                _series.push({
                    type: "line",
                    name: "4% Rule",
                    data: [
                        ...new Array(calculateCurrentAge(selectedContact?.birthdate)).fill(fourPerRule[0] || 0),
                        ...(fourPerRule || []),
                    ],
                    color: "rgb(237, 119, 0)",
                    dashStyle: "ShortDot",
                    lineWidth: 2,
                    // thick: true,
                });
        }
        if (comparators.targetConsumption) {
            _series &&
                _series.push({
                    type: "line",
                    name: "Target Consumption",
                    data: [
                        ...new Array(calculateCurrentAge(selectedContact?.birthdate)).fill(targetConsumption[0]),
                        ...targetConsumption,
                    ],
                    color: "rgba(51, 51, 51, 1)",
                    dashStyle: "LongDashDot",
                    lineWidth: 2,
                    // thick: true,
                });
        }
        if (comparators.hundredPerAnnutisation) {
            _series &&
                _series.push({
                    type: "line",
                    name: "100% Annuitisation",
                    data: new Array(lifeSpan).fill(
                        (Number(annuityRate) * (targetConsumption[0] + fourPerRule[0])) / 200
                    ),
                    color: "rgb(27, 103, 107)",
                    dashStyle: "LongDashDot",
                    lineWidth: 2,
                    // thick: true,
                });
        }
        setChartOptions({ ...chartOptions, series: _series });
    };

    useEffect(() => {
        updateHighcharts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparators, goe4dTranslation]);
    // const age = clientCurrentAge;

    return (
        <Box sx={{ marginBottom: "1.5rem" }}>
            {!isPdf && (
                <>
                    <div className="consumption__chart-header">
                        <FTChartControlBar comparators={comparators} setComparators={setComparators} />
                    </div>

                    <br />
                    <br />
                </>
            )}

            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                // data={consumptionData}
                // onlyTooltipData="{label: 'Investable Wealth', key: 'wealth', data: investableWealthForTooltip}"
                // stackedColumnData={[
                //     {
                //         "label": "Drawdown from wealth",
                //         "color": "rgba(255, 96, 53, 0.2)",
                //         "age": 73
                //     },
                //     {
                //         "label": "State Pension",
                //         "color": "rgba(82, 145, 255, 0.2)",
                //         "age": 73
                //     },
                //     {
                //         "label": "Other Income",
                //         "color": "rgba(103, 48, 227, 0.2)",
                //         "age": 73
                //     },
                //     {
                //         "label": "Defined Benefit Pension",
                //         "color": "rgba(0, 191, 179, 0.2)",
                //         "age": 73
                //     }
                // ]}
                aria-label="Proposed Income interactive chart."
            />
        </Box>
    );
};

export default WealthChart;

import { CHART_LABELS } from "@constants";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { formatter } from "utils/helper";

const FTBarChart = ({ gradientColors, data }: { gradientColors: string; data: { age: number; amount: number }[] }) => {
    // console.log("gradient color", gradientColors);

    const seriesData: any = [
        {
            type: "column",
            borderRadius: 0,
            color: "#5291FF",
            data: data?.map((v: any) => ({ x: v.age, y: v.amount })),
        },
    ];

    let barChartOptions: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        title: { text: "" },
        legend: { enabled: false },
        accessibility: {
            enabled: false,
        },
        yAxis: { title: { text: "" } },
        xAxis: { title: { text: "" } },
        tooltip: {
            useHTML: true,
            shared: true,
            borderWidth: 2,
            formatter: function () {
                return (
                    `<b>${CHART_LABELS.ageLabel}</b> <span style='float:right'>${this.x}</span>` +
                    `<br/><b>${CHART_LABELS.amountLabel}</b> &nbsp;${`${this.y && formatter.format(this.y)}`}`
                );
            },
        },
        series: seriesData,
    };

    const [chartOptions, setChartOptions] = useState(barChartOptions);

    useEffect(() => {
        setChartOptions({
            series: seriesData,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            oneToOne={true}
            updateArgs={[true]}
            aria-label="Annuities interactive chart."
        />
    );
};

export default FTBarChart;

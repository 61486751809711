import FTBox from "atoms/FTBox";
import FTButton from "atoms/FTButton";
import FTNumberInput from "atoms/FTNumberInput";
import FTPopover from "atoms/FTPopover";
import FTSwitch from "atoms/FTSwitch";
import PageHeading from "containers/PageHeading";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useSelectedProposal } from "hooks";
import { calculateTotalExpense, fetchCurrencySymbol, formatter } from "utils/helper";
import "../styles/fact-finding.styles.scss";

const Expenditure = () => {
    const orgInfo = useAppSelector((state) => state.global.orgInfo);
    const { setDisplayedProposal, expenditures, expendituresMeta, isMonthlyOn } = useSelectedProposal();
    const displayedProposal = useAppSelector((state) => state.displayedProposal);
    const lifeStyleValueMapper: any = useMemo(
        () => ({
            foodAndDrink: { label: `Food and drink ${fetchCurrencySymbol}`, value: "A + B" },
            personal: {
                label: `Personal goods and services ${fetchCurrencySymbol}`,
                value: "F",
            },
            housing: {
                label: `Housing bills and maintenance ${fetchCurrencySymbol}`,
                value: "D + E",
            },
            clothingAndFootwear: {
                label: `Clothing and footwear ${fetchCurrencySymbol}`,
                value: "C",
            },
            transport: { label: `Transport ${fetchCurrencySymbol}`, value: "G" },
            socialAndCultural: {
                label: `Social, cultural and holidays ${fetchCurrencySymbol}`,
                value: "H",
            },
        }),
        []
    );

    const [showLifestyleInputErrors, setShowLifestyleInputErrors] = useState<Array<string | undefined>>([]);
    const handleChangeLifestyle = (e: any) => {
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.expendituresMeta.lifestyle = e.target.innerHTML || e.target.innerText;
        setDisplayedProposal(updatedProposal);
    };
    const handleLifestyleInputValues = (id: string, val: string, idx: number) => {
        let changedExpenditures = {};
        let newErrors = [...showLifestyleInputErrors];
        const validatedOtherValue = val;
        if (Number(validatedOtherValue) > 10000000) {
            newErrors[idx] = `Please enter a value less than or equal to ${formatter.format(10000000)}`;
            setShowLifestyleInputErrors(newErrors);
        } else {
            newErrors[idx] = undefined;
            setShowLifestyleInputErrors(newErrors);
        }
        changedExpenditures = {
            ...displayedProposal?.customData?.expenditures,
            [id]: validatedOtherValue,
        };
        let changedProposal = {
            ...displayedProposal,
            customData: {
                ...displayedProposal?.customData,
                expenditures: displayedProposal?.customData?.expenditures,
            },
        };
        changedProposal.customData.expenditures = changedExpenditures;
        setDisplayedProposal(changedProposal);
    };
    const updateLifestyleFields = () => {
        if (displayedProposal?.customData?.expenditures && displayedProposal?.customData?.expendituresMeta) {
            const lifestyle = displayedProposal?.customData?.expendituresMeta?.lifestyle || null;
            const isInLondon = displayedProposal?.customData?.expendituresMeta?.isInLondon || false;
            const isCouple = displayedProposal?.customData?.expendituresMeta?.isCouple || false;
            const expenditureType = `${isMonthlyOn ? "Monthly /" : "Annual /"} ${isInLondon ? "London" : "Non London"}`;
            let lifestyleType = "";
            if (lifestyle === "Comfortable") lifestyleType += "Comf / ";
            else if (lifestyle === "Moderate") lifestyleType += "Mod / ";
            else if (lifestyle === "Minimum") lifestyleType += "Min / ";
            lifestyleType += isCouple ? "Couple" : "Single";
            let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));

            lifeStyleValueMapper &&
                Object.keys(lifeStyleValueMapper)?.forEach((expenseType) => {
                    updatedProposal.customData.expenditures[expenseType] =
                        orgInfo?.settings?.expenditures[expenditureType][lifeStyleValueMapper[expenseType]?.value][
                            lifestyleType
                        ];
                });
            setDisplayedProposal(updatedProposal);
        }
    };
    const renderTotalRegularExpensesSection = () => {
        return (
            <section className="expenditure-container">
                <FTSwitch
                    checked={displayedProposal?.customData?.expendituresMeta?.isInLondon || false}
                    switchName={"switch-q1"}
                    label={"Apply uplift factor (recommended for HNW clients)"}
                    labelPlacement="start"
                    onChange={(checked) => {
                        let newProposal = JSON.parse(JSON.stringify(displayedProposal));
                        newProposal.customData.expendituresMeta.isInLondon = checked;
                        setDisplayedProposal(newProposal);
                    }}
                />
                <FTSwitch
                    checked={displayedProposal?.customData?.expendituresMeta?.isCouple || false}
                    switchName={"switch-q2"}
                    label={"Is your client supporting a partner who has no income?"}
                    labelPlacement="start"
                    onChange={(checked) => {
                        let newProposal = JSON.parse(JSON.stringify(displayedProposal));
                        newProposal.customData.expendituresMeta.isCouple = checked;
                        setDisplayedProposal(newProposal);
                    }}
                />
                <article>
                    <span className="demi-bold same-line">
                        Select the closest retirement lifestyle choice for your client
                        <FTPopover
                            type="secondary"
                            message={
                                <span>
                                    The starting values in these categories are based on the PLSA retirement living
                                    standards research for UK retirees. Click{" "}
                                    <a
                                        href="https://www.retirementlivingstandards.org.uk"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {" "}
                                        here{" "}
                                    </a>{" "}
                                    to find out more
                                </span>
                            }
                        />
                    </span>
                    <p className="small-font">
                        Choose from one of these profiles to indicate your client's regular spending needs. You can
                        update the sums shown if these are not appropriate for your client.
                    </p>
                </article>
                <section className="retirement-lifestyle-buttons">
                    <FTButton
                        variant={
                            displayedProposal?.customData?.expendituresMeta?.lifestyle === "Minimum"
                                ? "contained"
                                : "outlined"
                        }
                        className="bold-button"
                        onClick={handleChangeLifestyle}
                    >
                        Minimum
                    </FTButton>
                    <FTButton
                        variant={
                            displayedProposal?.customData?.expendituresMeta?.lifestyle === "Moderate"
                                ? "contained"
                                : "outlined"
                        }
                        className="bold-button"
                        onClick={handleChangeLifestyle}
                    >
                        Moderate
                    </FTButton>
                    <FTButton
                        variant={
                            displayedProposal?.customData?.expendituresMeta?.lifestyle === "Comfortable"
                                ? "contained"
                                : "outlined"
                        }
                        className="bold-button"
                        onClick={handleChangeLifestyle}
                    >
                        Comfortable
                    </FTButton>
                </section>
                <section className="retirement-lifestyle-inputs">
                    {Object.entries(displayedProposal?.customData?.expenditures || {})?.map((item, idx: number) => (
                        <div key={idx} className="lifestyle_input_fields">
                            <FTNumberInput
                                id={`lifestyle_input_${idx}`}
                                data-testid={`lifestyle_input_${idx}`}
                                label={lifeStyleValueMapper[item[0]]?.label}
                                onChange={(e) => handleLifestyleInputValues(item[0], e.target.value, idx)}
                                value={item[1]}
                                disabled={!displayedProposal?.customData?.expendituresMeta?.lifestyle}
                                className={displayedProposal?.customData?.expendituresMeta?.lifestyle ? "" : "disabled"}
                            />
                            <span className={showLifestyleInputErrors[idx] ? "MuiFormHelperText-root" : ""}>
                                {showLifestyleInputErrors[idx] || ""}
                            </span>
                        </div>
                    ))}
                </section>
            </section>
        );
    };
    useEffect(() => {
        updateLifestyleFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expendituresMeta?.lifestyle, expendituresMeta?.isCouple, expendituresMeta?.isInLondon]);

    return (
        <div>
            <PageHeading stepCount={3} pageHeading={"Expenditure - let's zoom in"} />
            <FTBox
                header="FindingFactHeader"
                headerTitle="TOTAL REGULAR EXPENSES"
                iconLabel="ft-handMoney"
                value={calculateTotalExpense(expenditures)}
                isMonthlyOrAnnualLabel={isMonthlyOn ? "Monthly" : "Annual"}
                body={renderTotalRegularExpensesSection()}
            />
        </div>
    );
};

export default Expenditure;

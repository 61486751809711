import { useAppSelector, useSelectedProposal } from "hooks";
import React, { useEffect, useState } from "react";
import { calculateTotalExpenditureValue, floatFix, formatter, getMonthlyValue, totalIncome } from "utils/helper";

const SuccessProbability = ({ isPdf = false }: { isPdf?: boolean }) => {
    const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    const [probabilityOfSuccess, setProbabilityOfSuccess] = useState(goe4dTranslation?.body?.goalProbability || 0);
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const { annualDisplayedProposal } = useSelectedProposal();
    const { definedBenefitPensions, otherIncomeItems, expenditures, statePensionIncome, isMonthlyOn } =
        annualDisplayedProposal?.customData || selectedProposal?.customData;

    useEffect(() => {
        setProbabilityOfSuccess(goe4dTranslation?.body?.goalProbability);
    }, [goe4dTranslation]);
    const statePensionAmount = Number(statePensionIncome?.value);
    const totalIncomeYearly = Number(totalIncome(definedBenefitPensions, otherIncomeItems, statePensionAmount));
    const totalExpenditureYearly = calculateTotalExpenditureValue(expenditures);
    const totalIncomeMonthly = getMonthlyValue(totalIncomeYearly);
    const totalExpenditureMonthly = getMonthlyValue(totalExpenditureYearly);

    // Normalize to the range of 0 to 1
    function normalize(lowerBound: number, higherBound: number, value: number) {
        const clamp0to1 = (val: number) => Math.min(1, Math.max(0, val));
        return clamp0to1((value - lowerBound) / (higherBound - lowerBound));
    }

    // Linear interpolation
    function interpolate(lowerBound: number, higherBound: number, value: number) {
        return lowerBound * (1 - value) + higherBound * value;
    }

    const radialChartDegree = (probabilityOfSuccess: number) => {
        const minDegree = 232; // Corresponds to probability of 0%.
        const maxDegree = 126; // Corresponds to probability of 100%.
        const probability = probabilityOfSuccess;

        let degrees;
        if (probability < 0.5) {
            const normalizedProb = normalize(0, 0.5, probability);
            degrees = interpolate(minDegree, 360, normalizedProb);
        } else {
            const normalizedProb = normalize(0.5, 1, probability);
            degrees = interpolate(0, maxDegree, normalizedProb);
        }
        return degrees;
    };

    const radialTransformValue = (probabilityOfSuccess: number) => {
        return `rotateZ(${radialChartDegree(probabilityOfSuccess)}deg) translateY(-65px)`;
    };

    const isExpenditureHigherThanGuaranteedIncome = () => {
        const totalExpenditureYearly = calculateTotalExpenditureValue(expenditures);
        return totalExpenditureYearly > totalIncome(definedBenefitPensions, otherIncomeItems, statePensionIncome);
    };

    const successProbabilityPercentage = () => {
        return floatFix(probabilityOfSuccess * 100);
    };

    const successProbabilityLabel = () => {
        if (isNaN(probabilityOfSuccess)) {
            return "";
        }
        let probabilityLabel = Math.round(successProbabilityPercentage()) + "%";
        if (successProbabilityPercentage() >= 95 && isExpenditureHigherThanGuaranteedIncome()) {
            probabilityLabel = ">95%";
        }
        return probabilityLabel;
    };
    const pdfSummaryChartDataColor = () => {
        if (successProbabilityPercentage() > 85) {
            return "#88D8B0";
        }
        if (successProbabilityPercentage() < 65) {
            return "#FF6F69";
        }
        return "#FFCF66";
    };

    const pdfSummaryChartBackgroundColor = () => {
        if (successProbabilityPercentage() > 85) {
            return "#aedfc6";
        }
        if (successProbabilityPercentage() < 65) {
            return "#efbab9";
        }
        return "#fae6bb";
    };

    const pdfRadialTransformValue = () => {
        // gradient 2nd half
        const secondHalfMaxDegree = 90; // Corresponds to probability of 50%.

        // gradient 1st half
        const firstMinDegree = 90; // Corresponds to probability of 50%.
        const firstHalfMaxDegree = 271; // Corresponds to probability of 100%.

        const isRadialChartDegreeOver360Degree =
            radialChartDegree(probabilityOfSuccess) < 126 && radialChartDegree(probabilityOfSuccess) > 0;
        let secondDegree = radialChartDegree(probabilityOfSuccess) + (isRadialChartDegreeOver360Degree ? 360 : 0) + 89;

        let firstDegree = firstMinDegree;
        let firstDegreeColor;
        if (successProbabilityPercentage() > 50) {
            firstDegree =
                secondDegree - secondHalfMaxDegree - (isRadialChartDegreeOver360Degree ? 360 : 0) + firstMinDegree;
            secondDegree = secondHalfMaxDegree;
            firstDegreeColor = pdfSummaryChartDataColor();
        } else {
            firstDegree = firstHalfMaxDegree;
            firstDegreeColor = pdfSummaryChartBackgroundColor();
        }

        return `linear-gradient(${firstDegree}deg, ${firstDegreeColor} 50%, transparent 50%),
        linear-gradient(${secondDegree}deg, ${pdfSummaryChartDataColor()} 50%, transparent 50%)`;
    };
    const divStyle = {
        "--transform-degree": `${radialTransformValue(probabilityOfSuccess)}`,
    } as React.CSSProperties;
    return !isPdf ? (
        <>
            <span className="demi-bold">Success Probability</span>

            <div className="success-probablity-radial">
                <span>{`${successProbabilityLabel() || 0}`}</span>
                <div className="hand" style={{ transform: radialTransformValue(probabilityOfSuccess) }}></div>
            </div>

            <span className="small-text">
                The plan laid out on this screen indicates that your client will achieve the desired expenditure and
                realise their retirement plan in {successProbabilityLabel()} of scenarios that have been modelled.
            </span>
        </>
    ) : (
        <div className="pdf-content proposal-pdf-summary">
            <div className="proposal-pdf-summary__chart">
                <div
                    className="radial-pdf-modal radial--pdf"
                    style={{
                        backgroundColor: pdfSummaryChartBackgroundColor(),
                        backgroundImage: pdfRadialTransformValue(),
                    }}
                >
                    <div className="proposal-pdf-summary__chart__labels">
                        <h5>{successProbabilityLabel()}</h5>
                        <div>Probability of success</div>
                    </div>
                    <div className="hand" style={divStyle}></div>
                    <div className="radial-pdf-modal__bottom-part"></div>
                </div>
            </div>
            <div className="proposal-pdf-summary__content-container">
                <h6>Success Probability</h6>
                <div className="proposal-pdf-summary__client-result">
                    <div>
                        {" "}
                        Your client has indicated the need for an average sum of{" "}
                        <b>
                            {isMonthlyOn
                                ? formatter.format(totalExpenditureMonthly)
                                : formatter.format(totalExpenditureYearly)}
                        </b>
                        {isMonthlyOn ? " per month " : " per year "} in order to address their expenditure plans.{" "}
                    </div>
                    <br />
                    <div>
                        {" "}
                        Given they currently have{" "}
                        <b>
                            {isMonthlyOn ? formatter.format(totalIncomeMonthly) : formatter.format(totalIncomeYearly)}
                        </b>{" "}
                        {isMonthlyOn ? " per month " : " per year "} in income from all existing sources and those
                        identified in fact-finding, it indicates a gap meeting their lifestyle spending plans.
                    </div>
                    <br />
                    <div>
                        {" "}
                        The modelling has identified that the income from drawdown is likely to meet this shortfall.
                    </div>
                </div>
                <div className="proposal-pdf-summary__client-result">
                    The plan laid out on this screen indicates that your client will achieve the desired expenditure and
                    realise their retirement plan in {successProbabilityLabel()} of scenarios that have been modelled,
                    on the basis that their wealth will not be eroded before their end of life.
                </div>
            </div>
        </div>
    );
};

export default SuccessProbability;

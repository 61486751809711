import { SideNavProps } from "@types";
import FTIcon from "atoms/FTIcon";
import "./side-nav.styles.scss";

const SideNav = ({ segments, handleSegmentClicked, isActive }: SideNavProps) => (
    <section className="side-nav-container">
        {segments.map((segment, index) => (
            <article
                key={index}
                className={`side-nav-segments ${isActive(segment.routeTo) ? "active-segment" : ""}`}
                data-testid={segment.datatestid}
                onClick={() => handleSegmentClicked(segment.routeTo)}
            >
                <FTIcon iconName={segment.iconName} />
                <p>{segment.label}</p>
            </article>
        ))}
    </section>
);

export default SideNav;
